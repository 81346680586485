import moment from "moment";
import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import {
  customDateSort,
  getOrderStatusBadgeClass2,
  openOrderDtls,
  permissionCheck,
} from "../Helper/CommonMethods";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import GetSvgIcon from "../../images/svglist";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  ORDDTLS,
} from "../../ConfigConstants/ConfigConstants";
import { useLocation } from "react-router-dom";

export default function LastMilePlanningList(props) {
  const {
    planningList,
    openReplanningModal,
    openBidModal,
    printAllWaybill,
    handelOnSelect,
    mergedList,
    selectedSbu,
    hideColumns,
    unassignTrip,
    showConfirmAlert,
    getFullAddress,
  } = props;

  const userType = getUserInfo("userType");
  const isOrdDtlsAuthorized = permissionCheck(ORDDTLS, "allowedRead");

  const columns = [
    // {
    //   name: "",
    //   width: "2%",
    //   cell: (row) => (
    //     <>
    //       <input
    //         type="checkbox"
    //         checked={mergedList.includes(row.order_id + "_" + row.service_id)}
    //         onChange={(e) => handelOnSelect(e, row)}
    //         disabled={
    //           !(
    //             (row.service_id === 148 && row.order_status_code === 616) ||
    //             // &&row.status === 199
    //             (row.service_id === 9 &&
    //               (row.order_status_code === 76 ||
    //                 row.order_status_code === 611)) ||
    //             (row.order_type === 513 && row.order_status_code === 76)
    //           )
    //         }
    //       />
    //     </>
    //   ),
    //   omit: LINEHAUL_PARTNER_COMPANY_USER_TYPE.includes(userType),
    // },
    // {
    //   name: "Order No.",
    //   selector: (row) => row.order_no,
    //   sortable: true,
    //   filterable: true,
    //   width: "10%",
    // },
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      cell: (row) => (
        <>
          <div
            onClick={() =>
              isOrdDtlsAuthorized ? openOrderDtls("order", row.order_id) : ""
            }
            className={
              isOrdDtlsAuthorized
                ? "modal-links fw-bold"
                : "black-color-link fw-bold"
            }
          >
            {row.order_no}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "10%",
    },
    {
      name: "Service Type",
      selector: (row) => row.service_desc,
      sortable: true,
      filterable: true,
      width: "7%",
    },
    {
      name: "SLA",
      selector: (row) => row.sla_name,
      sortable: true,
      filterable: true,
      width: "7%",
    },
    {
      name: "Sbu",
      selector: (row) => row.sbu_name,
      sortable: true,
      filterable: true,
      width: "5%",
    },
    {
      name: "Pallets",
      selector: (row) => row.pallets,
      sortable: true,
      filterable: true,
      width: "5%",
    },
    {
      name: "Address",
      selector: (row) => getFullAddress(row),
      cell: (row) => (
        <>
          <pre className="box-text">
            <span className="d-inline-block">{getFullAddress(row)}</span>
          </pre>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "260px",
    },
    {
      name: "Vehicle No.",
      selector: (row) => row.vhcl_no,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={row.vhcl_no === "Not Assigned" ? "text-danger" : ""}>
            {row.vhcl_no}
          </div>
        </>
      ),
    },
    {
      name: "Driver",
      selector: (row) => row.dr1_name + " (" + row.dr1_code + ")",
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={row.dr1_name === "Not Assigned" ? "text-danger" : ""}>
            {!!row.dr1_code
              ? row.dr1_name + " (" + row.dr1_code + ")"
              : row.dr1_name}
          </div>
        </>
      ),
    },
    {
      name: "Trip Date",
      selector: (row) =>
        !!row.trip_date ? moment(row.trip_date).format(DATE_FORMAT_MOMENT) : "",
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.trip_date, rowB.trip_date),
    },
    {
      name: "Est. Delivery Date",
      selector: (row) =>
        !!row.est_delivery
          ? moment(row.est_delivery).format(DATE_FORMAT_MOMENT)
          : "",
      sortable: true,
      filterable: true,
      width: "130px",
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.est_delivery, rowB.est_delivery),
    },
    {
      name: "Scheduled Pickup/Delivery Date",
      selector: (row) =>
        !!row.scheduled_date
          ? moment(row.scheduled_date).format(DATE_FORMAT_MOMENT)
          : "",
      sortable: true,
      filterable: true,
      width: "210px",
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.scheduled_date, rowB.scheduled_date),
    },
    {
      name: "Order Status",
      selector: (row) => row.order_status,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={getOrderStatusBadgeClass2(row.order_status_code)}>
            {row.order_status}
          </div>
        </>
      ),
      width: "100px",
    },
    // {
    //   name: "Bid Status",
    //   selector: (row) => row.order_status,
    //   sortable: true,
    //   filterable: true,
    //   cell: (row) => (
    //     <>
    //       <div className={getOrderStatusBadgeClass2(row.bid_status)}>
    //         {/* {!!row.bid_status?row.bid_status:"Not Created"}  */}
    //         {row.bid_status}
    //       </div>
    //     </>
    //   ),
    //   width: "100px",
    // },
    {
      name: "",
      width: "5%",
      cell: (row, index) => (
        <>
          {/* {row.bid_status !== "Open" && row.bid_status !== "Closed" ? */}
          <OverlayTrigger
            trigger="click"
            key={index}
            placement="left"
            rootClose
            overlay={
              <Popover id={`popover-positioned-lefft`}>
                <Popover.Body>
                  {((row.service_id === 148 && row.order_status_code === 616) ||
                    // && row.status === 199
                    (row.service_id === 9 &&
                      (row.order_status_code === 76 ||
                        row.order_status_code === 611)) ||
                    (row.order_type === 513 &&
                      row.order_status_code === 76)) && (
                    <>
                      <div
                        className="action-item-button"
                        onClick={() => {
                          openReplanningModal(row);
                          document.body.click();
                        }}
                      >
                        {GetSvgIcon("VehicleTruck")} Trip Plan
                      </div>
                      {moment().format("HH:mm") < row.bid_stop_tm ? (
                        !row.driver1 &&
                        row.driver1 !== 0 &&
                        row.bid_status !== "Open" &&
                        row.bid_status !== "Closed" ? (
                          // <div
                          //   className="action-item-button"
                          //   onClick={() => {
                          //     openBidModal(row);
                          //     // showConfirmAlert(row)
                          //     document.body.click();
                          //   }}
                          // >
                          //   {GetSvgIcon("Geoalttrack")} Invite Bid{" "}
                          // </div>
                          <div></div>
                        ) : !row.bid_status ? (
                          <div
                            className="action-item-button"
                            onClick={() => {
                              unassignTrip(row);
                              document.body.click();
                            }}
                          >
                            {GetSvgIcon("Geoalttrack")} UnAssign Trip
                          </div>
                        ) : (
                          row.bid_status === "Closed" && (
                            <div
                              className="action-item-button"
                              onClick={() => {
                                unassignTrip(row);
                                document.body.click();
                              }}
                            >
                              {GetSvgIcon("Geoalttrack")} UnAssign Trip
                            </div>
                          )
                        )
                      ) : (
                        !!row.vehicle_id &&
                        row.vehicle_id !== 0 && (
                          <div
                            className="action-item-button"
                            onClick={() => {
                              unassignTrip(row);
                              document.body.click();
                            }}
                          >
                            {GetSvgIcon("Geoalttrack")} UnAssign Trip{" "}
                          </div>
                        )
                      )}
                    </>
                  )}
                  {(row.order_status_code === 617 ||
                    row.order_status_code === 612) && (
                    <div
                      className="action-item-button"
                      onClick={() => {
                        unassignTrip(row);
                        document.body.click();
                      }}
                    >
                      {GetSvgIcon("Geoalttrack")} UnAssign Trip
                    </div>
                  )}
                  <div
                    className="action-item-button"
                    onClick={() => {
                      printAllWaybill(row);
                      // document.body.click();
                    }}
                  >
                    {GetSvgIcon("Print")} Print All Waybill
                  </div>
                </Popover.Body>
              </Popover>
            }
          >
            <span className="row-action-button">
              {GetSvgIcon("Trippleverticaldot")}
            </span>
          </OverlayTrigger>
          {/* : ""} */}
        </>
      ),
      omit: LINEHAUL_PARTNER_COMPANY_USER_TYPE.includes(userType),
    },
  ];

  // const getFullAddress = (rowData) => {
  //   if (rowData.service_id === 9)
  //     // Pickup
  //     return `Name: ${rowData.pickup_name}\nAddress: ${rowData.pickup_address}, ${rowData.pickup_city}, ${rowData.pickup_state}\nPostCode: ${rowData.pickup_postcode}\nPhone: ${rowData.pickup_phone}`;
  //   else if (rowData.service_id === 148)
  //     // Delivery
  //     return `Name: ${rowData.deliver_name}\nAddress: ${rowData.deliver_address}, ${rowData.deliver_city}, ${rowData.deliver_state}\nPostCode: ${rowData.deliver_postcode}\nPhone: ${rowData.deliver_phone}`;
  //   else return ``;
  // };

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const order = query.get("order");
  const conditionalRowStyles = [
    {
      when: (row) => row.order_no === order,
      style: {
        backgroundColor: "#84fae0",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  return (
    <div>
      <div>
        <div className="col-md-12 table-responsive customized-data-table position-relative">
          <DataTable
            columns={columns}
            data={planningList}
            style={`table table-striped table-hover`}
            customStyles={customDataTableStyles}
            pagination
            striped
            highlightOnHover
            // defaultSortFieldId={1}
            defaultSortAsc={false}
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
            conditionalRowStyles={conditionalRowStyles}
          />
        </div>
      </div>
    </div>
  );
}
