import React from "react";
import { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

const initObj = {
  addr: "",
  state: "",
  postcode: "",
  destHubId: 0,
  oldDestId: 0,
  destHubCode: "",
  srvcAreaId: 0,
  distance: 0,
  inOrdId: 0,
  name: "",
  m_id: "",
  markings: 1,
};

const CrossBorderManifestEditDrP = ({
  show,
  handleClose,
  stateArr,
  selDropPoint,
  sbuList,
  srvcAreaOpt,
  onSubmit,
  invoiceId,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: initObj,
  });

  useEffect(() => {
    reset(selDropPoint);
  }, [selDropPoint]);

  // const onSubmit = (data) => {
  //   console.log(data);
  // };

  const isDisable = !isDirty || !isValid;
  const isActualOrder = watch("actOrdId");
  return (
    <Modal
      size="xl"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="theme-header">
        <Modal.Title>EDIT DROP POINT</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <label className="form-label theme-label">
                Name <span className="text-danger">&#9733;</span>
              </label>
              <textarea
                name="name"
                className="form-control"
                {...register("name", {
                  required: true,
                })}
                disabled={!!invoiceId && +invoiceId > 0}
              ></textarea>
            </Col>
            <Col>
              <label className="form-label theme-label">
                Address <span className="text-danger">&#9733;</span>
              </label>
              <textarea
                name="address"
                className="form-control"
                {...register("addr", {
                  required: true,
                })}
                disabled={!!isActualOrder}
              ></textarea>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>
              <label className="form-label theme-label">
                State <span className="text-danger">&#9733;</span>
              </label>

              <Controller
                name="state"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <CreatableSelect
                    className="form-control p-0"
                    isClearable
                    isSearchable
                    options={stateArr}
                    onChange={(opt) =>
                      !!opt ? onChange(opt.value) : onChange("")
                    }
                    value={
                      !!value
                        ? stateArr.find((st) => st.value === value)
                        : value
                    }
                    isDisabled={!!isActualOrder}
                  />
                )}
              />
            </Col>
            <Col md={2}>
              <label className="form-label theme-label">
                Postcode <span className="text-danger">&#9733;</span>
              </label>
              <input
                name="postcode"
                className="form-control"
                {...register("postcode", { required: true })}
                disabled={!!isActualOrder}
              />
            </Col>

            <Col md={2}>
              <label className="form-label theme-label">
                Service Area <span className="text-danger">&#9733;</span>
              </label>
              <Controller
                name="srvcAreaId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={srvcAreaOpt}
                    className="form-control p-0"
                    value={
                      !!value
                        ? srvcAreaOpt?.find((opt) => opt.value === value)
                        : value
                    }
                    onChange={(opt) => (!!opt ? onChange(opt?.value) : 0)}
                    isDisabled={!!invoiceId && +invoiceId > 0}
                  />
                )}
              />
            </Col>
            <Col>
              <label className="form-label theme-label">
                Destination <span className="text-danger">&#9733;</span>
              </label>
              <Controller
                name="destHubId"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={sbuList}
                    placeholder="Select Destination"
                    value={
                      !!value
                        ? sbuList?.find((opt) => opt.value === value)
                        : value
                    }
                    onChange={(d) => (!!d ? onChange(d.value) : onChange(0))}
                    isSearchable={true}
                    isClearable
                    isDisabled={!!isActualOrder}
                    className="form-control p-0"
                  />
                )}
              />
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button className="theme-violet" onClick={handleClose}>
          Close
        </Button>
        <Button
          className={isDisable ? "btn btn-outline-secondary" : "theme-violet"}
          disabled={isDisable}
          onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CrossBorderManifestEditDrP;
