import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import {
  BTN_TYPE_ADD,
  maxVhclSizeForHQ,
  REQUIRED_ERR_MSG,
  UPLOAD_IMG_B_TEXT,
  UPLOAD_IMG_BI_TEXT,
  UPLOAD_IMG_CI_TEXT,
  UPLOAD_IMG_F_TEXT,
  UPLOAD_IMG_L_TEXT,
  UPLOAD_IMG_R_TEXT,
} from "../Helper/Constants";
import { GetBase64Img } from "../Helper/FileStoreService";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  VHCL_OWNER_PARTNER,
  VHCL_STS_APPLIED,
  VHCL_STS_REGISTERED,
  VHCL_STS_REJECTED,
  VHCL_STS_UPD_REQ,
  VHCL_STS_UPD_REQ_REJECTED,
} from "../../ConfigConstants/ConfigConstants";
import { Alert } from "react-bootstrap";
import VehicleImage, { imgValidation } from "./VehicleImage";
import VehicleInfo, { DISABLE_INFO_ARR } from "./VehicleInfo";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import VehicleDetailsEdit from "./VehicleDetailsEdit";
import VehicleDetails from "./VehicleDetails";

const initImgData = {
  vhclImgF: "",
  vhclImgB: "",
  vhclImgL: "",
  vhclImgR: "",
  vhclImgCI: "",
  vhclImgBI: "",
};
const initLabelData = {
  vhclImgF: UPLOAD_IMG_F_TEXT,
  vhclImgB: UPLOAD_IMG_B_TEXT,
  vhclImgL: UPLOAD_IMG_L_TEXT,
  vhclImgR: UPLOAD_IMG_R_TEXT,
  vhclImgCI: UPLOAD_IMG_CI_TEXT,
  vhclImgBI: UPLOAD_IMG_BI_TEXT,
};

const VehicleAddEdit = (props) => {
  let {
    vhclInfos,
    vhclData,
    hq,
    vhclTypes,
    clearData,
    saveVhclData,
    btnType,
    inlineAlert,
    inlineAlertMsg,
    alertVariant,
    cleanUpData,
    dropdownData,
  } = props;
  const userProfileId =
    LINEHAUL_PARTNER_COMPANY_USER_TYPE === getUserInfo("userType")
      ? getUserInfo("acctId")
      : 0;
  let { allocatedTo, transferSbu } = vhclData;

  const [labelName, setLabelName] = useState(
    JSON.parse(JSON.stringify(initLabelData))
  );
  const [isDisabled, setDisabled] = useState({});

  const [imgData, setImgData] = useState(
    JSON.parse(JSON.stringify(initImgData))
  );

  let primarySbu = allocatedTo ? allocatedTo : hq.id;

  const {
    register,
    setValue,
    getValues,
    watch,
    control,
    handleSubmit,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: vhclData,
  });

  const {
    fields: vhclInfoArr,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "vhclInfos",
  });

  useEffect(() => {
    let copyVhclData = Object.assign({}, vhclData);
    copyVhclData.vhclInfos = vhclInfos;
    copyVhclData.allocatedTo =
      copyVhclData.allocatedTo > 0 ? copyVhclData.allocatedTo : hq.id;
    reset(copyVhclData);
  }, [vhclData, vhclInfos]);

  useEffect(() => {
    downloadImg(vhclData);
  }, [vhclData]);

  const watchVhvlId = watch("vhclId");
  const transferFromWatch = watch("transferFrom");
  const transferToWatch = watch("transferTo");
  const transferSbuWatch = watch("transferSbu");
  const watchStatus = watch("status");
  const watchAssignTo = watch("assignTo");
  const BLOCK_EDIT_STATUS_ARR = [851, 854];

  const setAllocatedTo = (event) => {
    clearErrors("vhclType");
    setValue(event.target.id, event.target.value);

    const selectedVhclType = vhclTypes.filter((type) => {
      return type.id === parseInt(event.target.value);
    });
    let hqId = hq.id;
    if (selectedVhclType[0].vhclSize > maxVhclSizeForHQ) {
      setValue("allocatedTo", hqId);
    }
  };

  const GetImageFromCloud = (vhcl, key, DEFAULT_IMG_TEXT) => {
    GetBase64Img(vhcl[key]?.photoFileName).then((resp) => {
      if (resp === "") {
        labelName[key] = DEFAULT_IMG_TEXT;
      } else {
        labelName[key] = vhcl[key]?.photoFileName;
      }
      setImgData((img) => ({
        ...img,
        [key]: resp,
      }));
    });
  };

  const downloadImg = (vhcl) => {
    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgF", UPLOAD_IMG_F_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgB", UPLOAD_IMG_B_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgL", UPLOAD_IMG_L_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgR", UPLOAD_IMG_R_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgCI", UPLOAD_IMG_CI_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgBI", UPLOAD_IMG_BI_TEXT);
  };

  const clear = () => {
    clearErrors();
    clearData();
    // reset();
    // setCleanUpData(new Date());
  };

  const validateAttachments = (vData) => {
    let isValid = true;
    if (+userProfileId > 0) {
      const copyVehInfos = vData.vhclInfos;
      const copyAssignTo = vData.assignTo;
      const copyVhclId = vData.vhclId;
      const isPartner =
        copyVhclId > 0
          ? copyAssignTo === VHCL_OWNER_PARTNER
          : +userProfileId > 0;

      copyVehInfos.forEach((element, index) => {
        const checkAttach =
          !isPartner && DISABLE_INFO_ARR.includes(element.infoCode);
        if (
          !checkAttach &&
          [null, undefined, ""].includes(element.attachment1.photoFileName)
        ) {
          setError(`vhclInfos.${index}.attachment1`, {
            type: "required",
            message: REQUIRED_ERR_MSG,
          });
          isValid = false;
        }
      });
      isValid = imgValidation(vData, setError);
    }
    return isValid;
  };

  const save = (data) => {
    // clear()
    if (validateAttachments(data)) {
      saveVhclData(data);
    }
  };
  const onErrors = (data) => {
    console.error(data);
  };

  const isValidTransferData = () => {
    if (transferFromWatch && transferToWatch && transferSbuWatch) {
      return true;
    } else if (!transferFromWatch && !transferToWatch && !transferSbuWatch) {
      return true;
    } else {
      return false;
    }
  };

  const getSaveBtnLabel = () => {
    let btnLabel = "";
    const isPartner =
      +userProfileId > 0 || +watchAssignTo === VHCL_OWNER_PARTNER;
    switch (watchStatus) {
      case 0:
        btnLabel = isPartner ? "Apply for Registration" : "Save";
        break;
      case VHCL_STS_REJECTED:
        btnLabel = isPartner ? "Re-Apply for Registration" : "Save";
        break;
      case VHCL_STS_REGISTERED:
      case VHCL_STS_APPLIED:
      case VHCL_STS_UPD_REQ:
      case VHCL_STS_UPD_REQ_REJECTED:
        btnLabel = isPartner ? "Request to Update" : "Update";
        break;
      default:
        btnLabel = isPartner
          ? "Request to Update"
          : watchVhvlId > 0
          ? "Update"
          : "Save";
        break;
    }
    return btnLabel;
  };

  const isReadOnly =
    (+userProfileId > 0 &&
      +watchVhvlId > 0 &&
      +watchAssignTo !== VHCL_OWNER_PARTNER) ||
    (+userProfileId === 0 &&
      +watchVhvlId > 0 &&
      +watchAssignTo === VHCL_OWNER_PARTNER);

  return (
    <div
      className="modal fade"
      id="vehicle-add-edit-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Vehicle Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={() => clear()}
            ></button>
          </div>
          {inlineAlert && (
            <div className="mt-2">
              <Alert key={alertVariant} variant={alertVariant}>
                {inlineAlertMsg}
              </Alert>
            </div>
          )}
          {BLOCK_EDIT_STATUS_ARR.includes(watchStatus) && (
            <div className="mt-2 mx-4">
              <Alert variant="warning" dismissible>
                This vehicle is under approval. You are not allowed to update
                any data.
              </Alert>
            </div>
          )}
          <div className="modal-body">
            <div className="row">
              <div className="w-100">
                <div className="row">
                  {isReadOnly ? (
                    <VehicleDetails vhclData={vhclData} />
                  ) : (
                    <VehicleDetailsEdit
                      register={register}
                      vhclTypes={vhclTypes}
                      clearErrors={clearErrors}
                      setValue={setValue}
                      errors={errors}
                      hq={hq}
                      control={control}
                      dropdownData={dropdownData}
                      watch={watch}
                    />
                  )}
                  <div className="clearfix"></div>
                  <VehicleImage
                    vhcl={vhclData}
                    register={register}
                    setValue={setValue}
                    cleanUpData={cleanUpData}
                    isReadOnly={false}
                    isMandatory={
                      +userProfileId > 0 ||
                      +watchAssignTo === VHCL_OWNER_PARTNER
                    }
                    errors={errors}
                    clearErrors={clearErrors}
                    setError={setError}
                  />
                </div>

                <div className="col-lg-12">
                  <VehicleInfo
                    vhclData={vhclData}
                    vhclInfoArr={vhclInfoArr}
                    control={control}
                    register={register}
                    setValue={setValue}
                    watch={watch}
                    getValues={getValues}
                    replace={replace}
                    update={update}
                    errors={errors}
                    clearErrors={clearErrors}
                    isMandatory={
                      (+watchVhvlId === 0 && +userProfileId > 0) ||
                      (+watchVhvlId > 0 &&
                        +watchAssignTo === VHCL_OWNER_PARTNER) ||
                      (+watchVhvlId > 0 &&
                        +watchAssignTo !== VHCL_OWNER_PARTNER &&
                        +userProfileId > 0)
                    }
                    isReadOnly={isReadOnly}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            {btnType === BTN_TYPE_ADD ? (
              <button
                type="button"
                className="btn btn-default border"
                onClick={() => clear()}
              >
                Clear
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-default border"
                data-bs-dismiss="modal"
                onClick={() => clear()}
              >
                Cancel
              </button>
            )}

            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(save, onErrors)}
              disabled={
                +userProfileId > 0
                  ? BLOCK_EDIT_STATUS_ARR.includes(watchStatus)
                  : isReadOnly || BLOCK_EDIT_STATUS_ARR.includes(watchStatus)
              }
            >
              {getSaveBtnLabel()}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleAddEdit;
