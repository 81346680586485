import React, { useEffect, useState } from "react";
import OrderInfoList from "./OrderInfoList";
import { CallApiPost } from "../Helper/serviceApi";
import Layout from "../Layout/Layout";
import moment from "moment";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  LODGEIN,
  SELF_COLLECT,
} from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import ReactDatePicker from "react-datepicker";
import { Tooltip } from "react-tooltip";
import {
  customDateSort,
  exportToExcel,
  getOrderStatusBadgeClass2,
  openOrderDtls,
  permissionCheck,
} from "../Helper/CommonMethods";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { ORDDTLS } from "../../ConfigConstants/ConfigConstants";

const initAlertConfig = {
  isSuccess: false,
  size: "fullscreen",
  show: false,
  title: "",
  description: "",
  dialogClassName: "",
};
const OrderInfoMain = () => {
  const [orderDetails, setOrderDetails] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [fromDate, setFromDate] = useState(
    moment(new Date()).subtract(7, "days").format(DATE_FORMAT_SERVER)
  );
  const [toDate, setToDate] = useState(
    moment(new Date()).add(7, "days").format(DATE_FORMAT_SERVER)
  );
  const userType = getUserInfo("userType");
  const isOrdDtlsAuthorized = permissionCheck(ORDDTLS, "allowedRead");
  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };
  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchOrderDetailsList();
  };

  const columns = [
    {
      name: "Order No.",
      selector: (row) => row.order_no,
      cell: (row) => (
        <>
          <div
            onClick={() =>
              isOrdDtlsAuthorized ? openOrderDtls("order", row.order_id) : ""
            }
            className={
              isOrdDtlsAuthorized
                ? "modal-links fw-bold"
                : "black-color-link fw-bold"
            }
          >
            {row.order_no}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "10%",
    },
    // {
    //   name: "Service Type",
    //   selector: (row) => row.serviceType,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "LodgeIn",
      selector: (row) => (row.serviceType.includes(LODGEIN) ? LODGEIN : "N/A"),
      sortable: true,
      filterable: true,
    },
    {
      name: "Self-Collect",
      selector: (row) =>
        row.serviceType.includes(SELF_COLLECT) ? SELF_COLLECT : "N/A",
      sortable: true,
      filterable: true,
    },
    {
      name: "SLA",
      selector: (row) => row.sla_name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Sbu",
      selector: (row) => row.sbu_code,
      sortable: true,
      filterable: true,
      width: "5%",
    },
    {
      name: "Pallets",
      selector: (row) => row.pallets,
      sortable: true,
      filterable: true,
      width: "5%",
    },
    {
      name: "Address",
      selector: (row) => getFullAddress(row),
      cell: (row) => (
        <>
          <pre className="box-text">
            <span className="d-inline-block">{getFullAddress(row)}</span>
          </pre>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "250px",
    },
    {
      name: "Customer Name",
      selector: (row) => row.cust_name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Contact",
      selector: (row) => row.phoneNo,
      cell: (row) => (
        <>
          <pre className="box-text">
            <span className="d-inline-block">{row.phoneNo}</span>
          </pre>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Trip Date",
      selector: (row) =>
        !!row.trip_date ? moment(row.trip_date).format(DATE_FORMAT_MOMENT) : "",
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.trip_date, rowB.trip_date),
    },
    {
      name: "Est. Delivery Date",
      selector: (row) =>
        !!row.est_delivery
          ? moment(row.est_delivery).format(DATE_FORMAT_MOMENT)
          : "",
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) =>
        customDateSort(rowA.est_delivery, rowB.est_delivery),
    },
    {
      name: "Order Status",
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <>
          <div className={getOrderStatusBadgeClass2(row.status)}>
            {row.status}
          </div>
        </>
      ),
      width: "100px",
    },
  ];

  useEffect(() => {
    fetchOrderDetailsList();
  }, []);
  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      case 200:
      case 0:
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const getFullAddress = (rowData) => {
    // `Address:${row.address}, ${row.city}, ${row.state}, ${row.postcode}\nPostCode: ${row.postcode}\nPhone: ${row.phoneNo}`,
    return `Name: ${rowData.name}\nAddress: ${rowData.address}, ${rowData.city}, ${rowData.state}\nPostCode: ${rowData.postcode}\nPhone: ${rowData.phoneNo}`;
  };
  const fetchOrderDetailsList = async () => {
    setShowSpinner(true);
    let payload = {
      fromDate: fromDate,
      toDate: toDate,
    };
    const uri = "GetOrdDetails";
    const resp = await CallApiPost(uri, payload);
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setOrderDetails(resp?.respData);
    } else {
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };
  const exportData = () => {
    exportToExcel(orderDetails, columns, "order_details.xlsx");
  };
  return (
    <>
      <Layout
        spinnershow={showSpinner}
        alertConfig={alertConfig}
        hideAlert={closePopup}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">
              LodgeIn/self Collect Order Details
            </div>
          </div>
        </div>
        <div className="d-flex align-item-center justify-content-between py-3 m-1">
          <div className="col-auto">
            <div className="border rounded p-2 me-3 wt-tbl float-start">
              <div className="d-flex align-item-center">
                <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label align-self-center me-2"
                    title="This field is mandatory"
                  >
                    From Date:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Trip Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!fromDate ? new Date(fromDate) : fromDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setFromDate(d)}
                    onSelect={(d) => setFromDate(d)}
                    maxDate={new Date(toDate)}
                  />
                  {/* <ReactDatePicker
                      placeholderText="Select Trip Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={!!tripDate ? new Date(tripDate) : tripDate}
                      onFocus={(e) => e.target.blur()}
                      onChange={(d) => setTripDate(d)}
                      onSelect={(d) => setTripDate(d)}
                    /> */}
                </div>
                <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label ms-3 align-self-center"
                    title="This field is mandatory"
                  >
                    To Date:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Trip Date"
                    className="form-control "
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!toDate ? new Date(toDate) : toDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setToDate(d)}
                    onSelect={(d) => setToDate(d)}
                    minDate={new Date(fromDate)}
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet text-nowrap float-end mt-2"
              onClick={() => fetchOrderDetailsList()}
              // disabled={!(!!tripDate && !!origin)}
              disabled={!(!!fromDate && !!toDate)}
            >
              {GetSvgIcon("Search")}
            </button>
          </div>
          <div>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet  mt-2 mb-2 ms-4"
              onClick={exportData}
              data-tooltip-id="csv-export"
            >
              {GetSvgIcon("SpreadSheet")}
            </button>
            <Tooltip
              id="csv-export"
              content="Download to Excel"
              place="top"
              style={{ zIndex: "100" }}
            />
          </div>
        </div>
        <OrderInfoList
          tableData={orderDetails}
          getFullAddress={getFullAddress}
          column={columns}
        />
      </Layout>
    </>
  );
};

export default OrderInfoMain;
