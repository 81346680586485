import React, { useEffect, useState } from "react";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Layout from "../../Layout/Layout";
import VehApprovalList from "./VehApprovalList";
import {
  inintHq,
  initDropdownData,
  initialVhclData,
} from "../../Vehicle/VehicleMain";
import VehApprovalAction from "./VehApprovalAction";
import {
  APPROVE_APRVL,
  REJECT_APRVL,
  VEHICLE_IMAGE_CODE_MAP,
} from "../../../ConfigConstants/ConfigConstants";
import ModalConfirmAlert from "../../Layout/ModalConfirmAlert";
import { TEXT_AREA_REGEX } from "../../Helper/Constants";
import MasterApprovalLogList from "../MasterApprovalLogList";
import { masterApprovalHistory } from "../../Helper/CommonMethods";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
  dialogClassName: "",
  data: null,
};

const initApprovalEntity = {
  vhclId: 0,
  vehicleNo: "V",
  vhclType: "",
  prtId: 0,
  partner: "",
  isActive: false,
  status: "",
  statusId: 0,
  approverRole: 0,
  assignedOn: "",
  approvalStepId: 0,
  approvalType: 0,
  approvalEntityId: 0,
  approvalStatus: 0,
  remarks: "",
};

const VehApprovalMain = () => {
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [vehApprovalList, setVehApprovalList] = useState([]);
  const [actionModal, setActionModal] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [vhclInfo, setVhclInfo] = useState(Object.assign({}, initialVhclData));
  const [confirmConfig, setConfirmConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [selApprovalEntity, setSelApprovalEntity] = useState(
    Object.assign({}, initApprovalEntity)
  );
  const [hq, setHq] = useState(JSON.parse(JSON.stringify(inintHq)));
  const [dropdownData, setDropdownData] = useState(
    Object.assign({}, initDropdownData)
  );
  const [approvalHistoryData, setApprovalHistoryData] = useState();

  useEffect(() => {
    fetchApproval();
    loadDropdownOpts();
    getHeadquater();
  }, []);

  const openActionModal = (argData) => {
    setSelApprovalEntity(argData);
    setActionModal({
      size: "fullscreen",
      show: true,
      title: "Vehicle Details",
    });
    fetchVhclInfos(argData.vhclId);
  };

  const fetchVhclInfos = async (vhclId) => {
    setSpinner(true);
    const uri = "GetVhclInfoById/" + vhclId;
    let resp = await CallApiGet(uri);

    if (
      (resp.respCode === 200 || resp.respCode === 0) &&
      Array.isArray(resp?.respData)
    ) {
      let respVhcl = resp?.respData[0];
      const vhclImgF = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGFCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      const vhclImgB = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      const vhclImgL = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGLCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgR = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGRCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgBI = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBICODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgCI = {
        photo: "",
        photoFileName: respVhcl.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGCICODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      respVhcl.vhclImgF = vhclImgF;
      respVhcl.vhclImgB = vhclImgB;
      respVhcl.vhclImgL = vhclImgL;
      respVhcl.vhclImgR = vhclImgR;
      respVhcl.vhclImgCI = vhclImgCI;
      respVhcl.vhclImgBI = vhclImgBI;
      respVhcl.vhclInfos = JSON.parse(respVhcl.vhclInfos);
      setVhclInfo({ ...respVhcl });
    } else {
      setVhclInfo(Object.assign({}, initialVhclData));
      if (resp.respCode === 200 || resp.respCode === 0) {
        resp.respCode = 9;
      }
      handleApiResponse(resp);
    }
    setSpinner(false);
  };

  const closeActionModal = () => {
    setActionModal({
      size: "fullscreen",
      show: false,
      title: "",
      data: null,
      dialogClassName: "",
    });
    setVhclInfo(Object.assign({}, initialVhclData));
  };

  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchApproval();
  };

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };

  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 200:
        openPopup(true, "SUCCESS", resp.respMessage);
        break;
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const fetchApproval = async () => {
    setSpinner(true);
    const resp = await CallApiGet("ListVehicleApprovals");
    if (resp.respCode === 200) {
      setVehApprovalList(!!resp.respData ? resp.respData : []);
    } else {
      handleApiResponse(resp);
    }
    setSpinner(false);
  };

  const hideConfirmAlert = (handleBackdropOpacity = true) => {
    setConfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
      data: null,
    });
    let copySelApvlEnt = { ...selApprovalEntity };
    copySelApvlEnt.remarks = "";
    copySelApvlEnt.error = { remarks: false };
    setSelApprovalEntity(copySelApvlEnt);
    if (handleBackdropOpacity) {
      setActionModal({ ...actionModal, dialogClassName: "" });
    }
  };

  const openConfirmAlert = (argData) => {
    setSelApprovalEntity(Object.assign({}, argData));
    setConfirmConfig({
      size: "md",
      show: true,
      title: argData?.title,
      data: argData,
    });
    setActionModal({ ...actionModal, dialogClassName: "opacity-css" });
  };

  const handleApprovalAction = async () => {
    if (
      selApprovalEntity.approvalStatus === REJECT_APRVL &&
      ([null, undefined, ""].includes(selApprovalEntity?.remarks) ||
        selApprovalEntity?.remarks?.length === 0)
    ) {
      setSelApprovalEntity({ ...selApprovalEntity, error: { remarks: true } });
      return;
    } else {
      setSelApprovalEntity({ ...selApprovalEntity, error: { remarks: false } });
    }
    if (selApprovalEntity.approvalType === 821) {
      selApprovalEntity.note = `Vehicle ${
        selApprovalEntity.vehicleNo
      } has been ${
        selApprovalEntity.approvalStatus === 39 ? "approved" : "rejected"
      }.`;
    } else if (selApprovalEntity.approvalType === 823) {
      selApprovalEntity.note = `Update request on vehicle ${
        selApprovalEntity.vehicleNo
      } has been ${
        selApprovalEntity.approvalStatus === 39 ? "approved" : "rejected"
      }.`;
    } else {
      selApprovalEntity.note = "";
    }
    selApprovalEntity.pageLink = "/vehicle";
    selApprovalEntity.pageParam = "";
    setSpinner(true);
    const uri = `LogMasterApproval/${selApprovalEntity.approvalType}`;
    const resp = await CallApiPost(uri, selApprovalEntity);
    if (resp.respCode === 200) {
      resp.respMessage =
        selApprovalEntity.approvalStatus === 39
          ? "Vehicle has been approved successfully!!"
          : "Approval request was rejected successfully!!";
    }
    handleApiResponse(resp);
    hideConfirmAlert(false);
    closeActionModal();
    setSpinner(false);
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (TEXT_AREA_REGEX.test(value)) {
      let copyEntity = { ...selApprovalEntity };
      copyEntity[name] = value;
      copyEntity.error = { remarks: false };
      setSelApprovalEntity(Object.assign({}, copyEntity));
    }
  };
  const loadDropdownOpts = async () => {
    const payload = ["BONDED_TYPE", "VEHICLE_OWNER_I"];
    let data = await CallApiPost("SysOptionGetByCode", payload);
    if (data.respCode === 200 || data.respCode === 0) {
      const ownerOpts = data.respData
        .filter((d) => d.optionCode === "VEHICLE_OWNER_I")
        .map((d) => {
          return { label: d.optionValue, value: d.id };
        });
      const bondedTypeOpts = data.respData
        .filter((d) => d.optionCode === "BONDED_TYPE")
        .map((d) => {
          return { label: d.optionValue, value: d.id };
        });
      setDropdownData({
        vhclOwnerOpts: ownerOpts,
        bondedTypeOpts: bondedTypeOpts,
      });
    } else {
      handleApiResponse(data);
    }
  };
  const getHeadquater = async () => {
    let data = await CallApiGet("getSbuHeadquater");
    if (data.respCode === 200 || data.respCode === 0) {
      setHq(data.respData[0]);
    } else {
      console.error(data.respMessage);
    }
  };

  const getApprovalHistory = async (entityId) => {
    setSpinner(true);
    let approvalHistoryData = await masterApprovalHistory(entityId, `821,823`);
    setSpinner(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else {
      setApprovalHistoryData([]);
      handleApiResponse(approvalHistoryData);
    }
  };

  return (
    <Layout
      spinnershow={spinner}
      alertConfig={alertConfig}
      hideAlert={closePopup}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">VEHICLE APPROVALS</div>
        </div>
      </div>
      <VehApprovalList
        vehApprovalList={vehApprovalList}
        openActionModal={openActionModal}
        getApprovalHistory={getApprovalHistory}
      />
      <VehApprovalAction
        modalConfig={actionModal}
        handleModalClose={closeActionModal}
        vhclInfo={vhclInfo}
        handleOnClick={openConfirmAlert}
        selApprovalEntity={selApprovalEntity}
        hq={hq}
        dropdownData={dropdownData}
      />
      <ModalConfirmAlert
        confirmAlertConfig={confirmConfig}
        hideConfirmAlert={hideConfirmAlert}
        confirmAlert={handleApprovalAction}
      >
        <div className="row">
          {selApprovalEntity?.approvalStatus === APPROVE_APRVL ? (
            <p> Are you sure you want to approve ? </p>
          ) : (
            <>
              <div className="col-md-3">
                <label
                  htmlFor="floatingInputGrid"
                  className="form-label theme-label"
                >
                  Reason <span className="text-danger">&#9733;</span>
                </label>
              </div>
              <div className="col-md-9 mb-2">
                <input
                  id="cancellation-reason"
                  type="text"
                  className="form-control"
                  name="remarks"
                  value={selApprovalEntity?.remarks}
                  autoComplete="false"
                  onChange={(e) => handleOnChange(e)}
                />
                {!!selApprovalEntity?.error &&
                  selApprovalEntity?.error.remarks && (
                    <div style={{ textAlign: "left" }}>
                      <span className="badge bg-danger">
                        &#9888; This field is required.
                      </span>
                    </div>
                  )}
              </div>
            </>
          )}
        </div>
      </ModalConfirmAlert>
      <MasterApprovalLogList
        showApprovalType={true}
        data={approvalHistoryData}
      />
    </Layout>
  );
};

export default VehApprovalMain;
