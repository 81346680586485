export const APPROVAL_STATUS_APPROVE = "APPROVED";
export const APPROVAL_STATUS_REJECT = "REJECT";
export const APPROVAL_STATUS_PENDING = "PENDING";
export const LINEHAUL_SYSTEM_ADMIN = "System_Admin";
export const LINEHAUL_RM_USER_TYPE = "Linehaul_RM";
export const LINEHAUL_IO_USER_TYPE = "Linehaul_IO";
export const LINEHAUL_OPERATOR_USER_TYPE = "Linehaul_Operator";
export const LINEHAUL_CLAIM_USER_TYPE = "Linehaul_Claim";
export const Linehaul_Finance_USER_TYPE = "Linehaul_Finance";
export const Linehaul_INSURANCE_USER_TYPE = "Linehaul_Insurance";
export const LINEHAUL_STAFF_USER_TYPE_LIST = [
  "Linehaul_HQ",
  LINEHAUL_RM_USER_TYPE,
  Linehaul_Finance_USER_TYPE,
  "Linehaul_HR",
  "Linehaul_ISR",
  "Linehaul_Manager",
  "Linehaul_Driver",
  "Linehaul_Operator",
  LINEHAUL_CLAIM_USER_TYPE,
  LINEHAUL_SYSTEM_ADMIN,
  LINEHAUL_IO_USER_TYPE,
];
export const LINEHAUL_ADMIN_USER_TYPE = "133";
export const LINEHAUL_STAFF_USER_TYPE = "134";
export const LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL = "135";
export const LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS = "136";
export const LINEHAUL_PARTNER_COMPANY_USER_TYPE = "138";
export const LINEHAUL_PARTNER_DRIVER_USER_TYPE = "137";
export const LINEHAUL_STAFF_ISR_USER_TYPE = "139";
export const LINEHAUL_AGENT_CUSTOMER_USER_TYPE = "141";
export const LINEHAUL_SUPPLIER_USER_TYPE = "140";

export const LINEHAUL_CUSTOMER_USER_TYPE_LIST = [
  LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL,
  LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS,
  LINEHAUL_AGENT_CUSTOMER_USER_TYPE,
];
export const LINEHAUL_PARTNER_USER_TYPE_LIST = [
  LINEHAUL_PARTNER_DRIVER_USER_TYPE,
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
];
export const LINEHAUL_PARTNER_TYPE_MAP = {
  [LINEHAUL_PARTNER_COMPANY_USER_TYPE]: "Company",
  [LINEHAUL_PARTNER_DRIVER_USER_TYPE]: "Driver",
};
export const LINEHAUL_CUSTOMER_TYPE_MAP = {
  [LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL]: "Personal Customer",
  [LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS]: "Business Customer",
};
export const VEHICLE_IMAGE_CODE_MAP = {
  IMGFCODE: "IMGFRONT",
  IMGBCODE: "IMGBACK",
  IMGLCODE: "IMGLEFT",
  IMGRCODE: "IMGRIGHT",
  IMGCICODE: "IMGCABININTERNAL",
  IMGBICODE: "IMGBONDEDINTERNAL",
};
export const LINEHAUL_USER_ROLE_REGIONAL_MANAGER = "134";
export const LINEHAUL_USER_FINANCE = "134";
export const LINEHAUL_USER_FINANCE_MANAGER = [
  LINEHAUL_USER_FINANCE,
  LINEHAUL_USER_ROLE_REGIONAL_MANAGER,
];
export const LINEHAUL_USER_FINANCE_BUSINESS = [
  LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS,
  LINEHAUL_USER_FINANCE,
];
export const INVOICE_URL = "/invoice";
export const CN_URL = "/creditNote";
export const APP_BS_URL = "/bankSlip";
export const PROFILE_STATUS_REJECT = "REJECTED";
export const REROUTETYPE = [374];
export const CANCELTYPE = [365];
export const CLAIMTYPE = [370, 372];
export const HQ_SBU_TYPE = 5;
export const PICKUP_SERVICE_ID = 9;
export const HANDLING_SERVICE_ID = 149;
export const DELIVERY_SERVICE_ID = 148;
export const PACKAGING_SERVICE_ID = 10;
export const UNPAID_INV_STAT_ID = 178;
export const MANPOWER_SERVICE_ID = 725;
export const EQUIPMENT_SERVICE_ID = 726;
export const CHARTERED_RATE_TRIP_ID = 765;
export const CHARTERED_RATE_WEIGHT_ID = 766;
export const CHARTERED_WGT_SRVC_ID = 769;
export const AD_HOC_CH_ORDER = 513;
export const SCH_CH_ORDER = 510;

// ====ORDER STATUS====
export const DLV_ORD_STS = 79; // Delivered
export const CNCL_ORD_STS = 77; // Cancelled
export const PLC_ORD_STS = 76; // Order Placed
export const PND_PYMT_ORD_STS = 75; // Pending Payment
export const TRANSIT_ORD_STS = 80; // Transit
export const wt_ORD_STS = 611; // waiting list
export const DLV_FAIL_ORD_STS = 618;

//====APPROVAL ACTIONS====
export const APPROVE_APRVL = 39;
export const REJECT_APRVL = 47;
export const PENDING_APRVL = 37;

// ====PALLET STATUS====
export const CNCL_PLT_STS = 201; // Cancelled
export const PND_PYMT_PLT_STS = 189; // Pending Payment

export const SYS_PARTNER_TYPE = [
  {
    id: LINEHAUL_PARTNER_COMPANY_USER_TYPE,
    optionValue: "Partner_Business",
  },
  {
    id: LINEHAUL_PARTNER_DRIVER_USER_TYPE,
    optionValue: "Partner_Personal",
  },
];

export const MONTH_BILL_CYCLE = 186;
export const MIDMILE_ROUTE_TYPE = 28;

// === VEHICLE STATUS ===
export const VHCL_STS_APPLIED = 851;
export const VHCL_STS_ACTIVE = 852;
export const VHCL_STS_UPD_REQ = 854;
export const VHCL_STS_REJECTED = 855;
export const VHCL_STS_UPD_REQ_REJECTED = 856;
export const VHCL_STS_REGISTERED = 858;
export const VHCL_STS_INACTIVE = 853;
export const VHCL_STS_INFO_INCOMPLETE = 857;

//=== VEHICLE OWNER ===
export const VHCL_OWNER_PARTNER = 204;
export const VHCL_OWNER_LC = 203;
export const VHCL_OWNER_LH = 202;

//=== ROUTE PLAN STATUS ===
export const PLAN_STATUS_STOP_ORDER = 571;
export const PLAN_STATUS_SCHEDULE = 572;

//=== INBOUND ORDER TYPE ===
export const ORD_INB_INT = 906;
export const ORD_INB_EXT = 907;
export const ORD_TYPE_CHECK_ARR = [ORD_INB_INT];

// === FEATURE CODE ===
export const SYSCFG = "SYSCONFIG001";
export const INSTAX = "SYSCONFIG002";
export const HOLIDAY = "SYSCONFIG003";
export const CANCEL = "SYSCONFIG004";
export const CONTDOC = "SYSCONFIG005"; //Content and Document
export const DIS = "SYSCONFIG006";
export const PROMO = "SYSCONFIG007";
export const ISRCOMMCONFIG = "SYSCONFIG008";
export const APVLWF = "SYSCONFIG009"; //Approval Workflow Configuration
export const ABOUTUS = "SYSCONFIG010";
export const LONGHAULCOMM = "SYSCONFIG011";
export const HOMECONFIG = "SYSCONFIG012";
export const INBRATECONFIG = "SYSCONFIG013";
export const SCHCHROUTESCHEDULE = "SYSCONFIG014";
export const SCHCHROUTEPLAN = "SYSCONFIG015";
export const SCHCHEXTRASRVC = "SYSCONFIG016"; //Additional Charges Config(Manpower, Weight, Equipment, Toll Gate etc)
export const SCHCHRATECONFIG = "SYSCONFIG017";
export const DRIVERGEOFENCE = "SYSCONFIG018";

export const SBU = "AD001"; // SBU Management
export const USRMGM = "AD002"; //User Management(Linehaul Staff)
export const ADMDSHB = "DSHBRD02"; //Admin Dashboard
export const PARTNERSTAFFMGM = "PM003"; //Partner Staff Management

export const ROLE = "SEC001";

export const ORDLIST = "ORM001";
export const ORDDTLS = "ORM010";
export const PROBSLIS = "ORM002";
export const SHIPVIEW = "ORM003";
export const CXLSHIP = "ORM004";
export const TRKSHIP = "ORM005S";
export const PREBOOKC = "ORM006C";
export const PREBOOKS = "ORM006S";
export const INBORDC = "ORM007C"; //International Inbound Order Customer
export const INBORDS = "ORM007S"; //International Inbound Order Staff
export const INV = "ORM009"; //Invoice List
export const INVS = "ORM009S"; // Invoice Staff
export const FTL = "ORM012"; // Full Truck
export const INVPAY = "PYMNT003"; // Invoice Payment
export const BSAPPRV = "PYMNT004"; // Bank Slip Approve
export const PATINV = "INV002"; //Invoice List
export const CROSS_BORDER_ORIGIN = "ORM014"; //Order Mangement

export const CLAIM = "ORM008";
export const LODGEINANDSELFCOLLECT = "ORM013";

export const COMMI = "COMMI";
export const COMMD = "COMMD";

export const SLA = "SRVC001";
export const RACH = "SRVC002"; //Rate Chart

export const VHCLTYPE = "VM001";
export const VEHICLE = "VM002";
export const VFLG = "VM003";
export const INSPLG = "VM004";
export const VHCLLEASE = "VM006";
export const VHCLLEASEREQ = "VM007";
export const VHCLLEASEFIN = "VM008";
export const LEASETERMINATION = "VM009";
export const VHCLDTLSEDIT = "VM011";

export const ROUT = "RM001";
export const RTPLN = "RM002";
export const RTPLNDTLS = "RM003";
export const LMP = "RM005";

export const CMGLIST = "CL001";
export const CMGDTLS = "CM002";
export const CMGREG = "CM004";
export const CMGDSHB = "DSHBRD01"; //Customer Dashboard

export const CUSTAPVL = "APVL001";
export const PARTAPPRV = "APVL002";
export const ISRAPVL = "APVL003";
export const APVLLOG = "APVL004";
export const CUSTAPVLRESUB = "APVL005";
export const LEASEAPVL = "APVL006";
export const VHCLAPVL = "APVL007";

export const PMLIST = "PM001";

export const ISRLIST = "ISRMGM1";
export const ISRREG = "ISRMGM2";
export const ISRACTD = "ISRMGM3";
export const ISRREASSIGN = "ISRMGM4";

export const PRFLCUST = "PRFL001";
export const PRFLSTAFF = "PRFL002";
export const PRFLDR = "PRFL003";
// export const PRFLDR = "PRFL003"
export const PRFLCOMP = "PRFL004";

export const ORDBKNG = "ORDBKNG1";
export const SCHCHBKNG = "ORDBKNG3";
export const ADHOCCHBKNG = "ORDBKNG4";
export const CUSTADDRBOOK = "ORDBKNG5";
export const CUSTMENU = "MENU01";
export const STAFFMENU = "MENU02";
export const SUPPLIERMENU = "MENU03";
export const DRPDWN = "OPTDATA";
export const NOTIF = "NOTIF001";
export const PARTMENU = "MENU04";

export const CUSTPRFL = "PRFL001";
export const STAFFPRFL = "PRFL002";
export const PARTCOMPPRFL = "PRFL004";
export const PARTPERSPRFL = "PRFL003";

export const STNDBKNG = "ORDBKNG1";

export const SUPPLIERMGM = "SUPMGM001";

// Claim Management
export const REQINFO = "CLM001";
export const ASSIGNOP = "CLM002";
export const ACKCLAIM = "CLM003";
export const APPROVECLAIM = "CLM004";
export const ACCEPTCLAIM = "CLM004";
export const REJECTCLAIM = "CLM005";
export const CUSTPARTFAULT = "CLM006";
export const SETCLAIMAMT = "CLM007";
export const APPEALCLAIMAMT = "CLM008";
export const PROCESSINS = "CLM009";
export const PROCESSPYMNT = "CLM010";
export const RAISECLAIM = "CLM011";
export const DWNINCRPT = "CLM012";
export const DLVEVENT = "DLVY001"; //Delivery Event
export const LEASEHISTORY = "VM010";

export const CHVIEWPLAN = "CH01";
export const CHORDERLIST = "CH02";
export const CHCXLORD = "CH03";
export const CHRECEIVEQR = "CH04";

export const LCOPRTN = "LCOPERATION";

export const BOOKING_PAYMENT_TYPE_CASH = "CASH";
export const BOOKING_PAYMENT_TYPE_CREDIT = "CREDIT";
export const BOOKING_PAYMENT_TYPE_CASHADV = "CASH&ADV";
export const STANDARD_BOOKING_PACKING_TYPE = "PALLET";

export const REPORT_OPERATION = "RPTOPS";
export const REPORT_FINANCE = "RPTFIN";

export const CUSTOMER_USER_MANAGEMENT = "CM001";

//== International Order
export const BILL_INV_INB = "INTORD01";
export const POD_DO_ATTACH_INB = "INTORD02";
export const EDIT_CUST_SUPL_INB = "INTORD03";

// Roles
export const LINEHAUL_ROLE_DRIVER = 14;
export const LINEHAUL_ROLE_ADMIN = 1;

export const ORDER_STATUS_CANCEL = 77;
export const ORDER_STATUS_PENDINGPAY_REVIEW = [75, 88];

export const LINEHAUL_CUSTOMER_BUSINESS_PARTNERS = [
  LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS,
  LINEHAUL_AGENT_CUSTOMER_USER_TYPE,
];

export const PROBLEM_VEHICLE_BREAKDOWN = 1010;
export const PROBLEM_DANGER_GOODS = 883;
export const PROBLEM_ORD_CANCEL_REQUEST = 882;
export const RECIPIENT_REJECTED_PALLET = 884;
