import React from "react";
import { Button, Col, ListGroup, Modal, Row, Table } from "react-bootstrap";
import GetSvgIcon from "../../images/svglist";
import { DownloadFile } from "../Helper/FileStoreService";

const InboundOrderAttachment = ({
  config,
  handleClose,
  attachList,
  setShowSpinner,
}) => {
  const downloadFiles = (files) => {
    setShowSpinner(true);
    new Promise((resolve) => {
      let downPromises = [];
      files?.forEach((element) => {
        const downPromise = new Promise((resolve) => {
          resolve(DownloadFile(element?.fileName));
        });
        downPromises.push(downPromise);
      });
      resolve(Promise.all(downPromises));
    }).then(() => setShowSpinner(false));
  };

  const downloadAllFiles = () => {
    const allAttachList = attachList
      .filter((att) => !!att?.attachments)
      .flatMap((att) => JSON.parse(att?.attachments));
    downloadFiles(allAttachList);
  };

  return (
    <Modal
      size="lg"
      show={config?.show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="theme-header">
        <Modal.Title>{config?.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table>
          <thead>
            <tr>
              <th className="text-center theme-violet-txt">Order Number</th>
              <th className="text-center theme-violet-txt">Attachments</th>
              <th className="text-center theme-violet-txt">Download</th>
            </tr>
          </thead>
          <tbody>
            {attachList.map((item, index) => (
              <tr>
                <td md={3} className="text-center">
                  {item?.orderNo}
                </td>
                <td md={7} className="text-center">
                  {JSON.parse(item?.attachments).map((attach, index) => (
                    <Row>
                      <Col>{attach?.fileName}</Col>
                      <Col>{`${attach?.description}${index + 1}`}</Col>
                    </Row>
                  ))}
                </td>
                <td md={2} className="text-center">
                  <span
                    className="text-primary"
                    onClick={() => downloadFiles(JSON.parse(item?.attachments))}
                  >
                    {GetSvgIcon("IconFiledownload")}
                  </span>
                  {/* <button
                    type="button"
                    className="btn btn-outline-secondary "
                    
                  >
                    
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          className="theme-violet"
          disabled={
            Array.isArray(attachList) && attachList.length > 0 ? false : true
          }
          onClick={downloadAllFiles}
        >
          DOWNLOAD ALL
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InboundOrderAttachment;
