import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import logo from "../../images/linehaul-logo.png";
import {
  setSessionInfo,
  getPersonalId,
  setUserInfo,
} from "../AuthUtils/AuthUtils";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import Footer from "../Layout/Footer";
import Popup from "../Layout/Popup";
import CustomerTypeModal from "../Registration/Customer/CustomerTypeModal";
import { CallApiPost } from "../Helper/serviceApi";
import GetSvgIcon from "../../images/svglist";
import Spinner from "../Layout/Spinner";
import { readLocalStorage, writeLocalStorage } from "../Helper/CommonMethods";
import {
  LINEHAUL_PARTNER_DRIVER_USER_TYPE,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS,
  LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL,
  LINEHAUL_AGENT_CUSTOMER_USER_TYPE,
} from "../../ConfigConstants/ConfigConstants";

const Login = () => {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [viewPswd, setViewPswd] = useState(false);
  const [show, setShow] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setIsPrivacyPolicyAccepted(event.target.checked);
  };

  const onSubmit = async (data) => {
    if (isPrivacyPolicyAccepted) {
      setShow(true);
      data.isPrivacyPolicyAccepted = isPrivacyPolicyAccepted;
      data["personalId"] = getPersonalId();
      console.log(data);

      await CallApiPost("authenticate-user", data).then(
        (res) => {
          setShow(false);
          switch (res.respCode) {
            case 200:
              setSessionInfo(res.respData.sessionId);
              const argUserType = res.respData?.userType.toString();
              const argAcctId = res.respData?.acctId;
              setUserInfo("userType", argUserType);
              setUserInfo("userId", res.respData.userId);
              setUserInfo("acctId", argAcctId);
              writeLocalStorage(
                "customerId",
                LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(argUserType)
                  ? argAcctId
                  : 0
              );
              writeLocalStorage(
                "customerType",
                LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(argUserType)
                  ? argUserType
                  : 0
              );
              setUserInfo("userActive", res.respData.userActive);
              setUserInfo(
                "accountCode",
                !!res.respData.accountCode
                  ? res.respData.accountCode
                  : !!res.respData.staffId
                  ? res.respData.staffId
                  : res.respData.supplierId
              );
              setUserInfo("userRoles", JSON.stringify(res.respData.userRoles));
              setUserInfo(
                "userApprovalStatusName",
                res.respData.userApprovalStatusName
              );
              setUserInfo("userName", res.respData.userName);
              setUserInfo(
                "userPermissions",
                JSON.stringify(res.respData.userPermissions)
              );
              setUserInfo("splPrivilege", res.respData.splPrivilege);
              const custAddrInfo = {
                address: res.respData?.custAddr,
                city: res.respData?.custCity,
                state: res.respData?.custState,
                postcode: res.respData?.custPostcode,
                phone: res.respData?.userPhone,
                name: res.respData?.userName,
              };
              setUserInfo("CustAddrInfo", JSON.stringify(custAddrInfo));
              setUserInfo(
                "userSbuList",
                JSON.stringify(res.respData.userSbuList)
              );
              //res.respData.userActive ? navigate('/dashboard') : navigate('/customerProfile')
              if (res.respData.userActive) {
                let bookinData = readLocalStorage("BookingSla");
                let ftlTruck = readLocalStorage("FtlTuck");

                //const userType = res.respData.userType.toString();
                switch (argUserType) {
                  case LINEHAUL_CUSTOMER_USER_TYPE_BUSINESS:
                  case LINEHAUL_CUSTOMER_USER_TYPE_PERSONAL:
                  case LINEHAUL_AGENT_CUSTOMER_USER_TYPE:
                    // !!bookinData ? navigate("/bookingStnd") : navigate("/home")
                    if (!!ftlTruck) navigate("/bookingChartered");
                    else if (!!bookinData) navigate("/bookingStnd");
                    else navigate("/home");
                    break;
                  // case LINEHAUL_PARTNER_DRIVER_USER_TYPE:
                  //   navigate("/personalProfile");
                  //   break;
                  // case LINEHAUL_PARTNER_COMPANY_USER_TYPE:
                  //   navigate("/companyProfile");
                  //   break;
                  default:
                    navigate("/home");
                    break;
                }
              } else {
                //const userType = res.respData.userType.toString();
                if (LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(argUserType))
                  navigate("/customerProfile");
                else if (LINEHAUL_PARTNER_COMPANY_USER_TYPE === argUserType)
                  navigate("/companyProfile");
                else if (LINEHAUL_PARTNER_DRIVER_USER_TYPE === argUserType)
                  navigate("/personalProfile");
              }
              break;
            case 204:
              onError("Wrong Username or password!!");
              break;
            case 401:
              onError("Wrong Username or password!!");
              break;

            default:
              onError("Something Went Wrong. Please try after sometime!!!");
              break;
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  };

  const viewPassword = () => {
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
      setViewPswd(true);
    } else {
      x.type = "password";
      setViewPswd(false);
    }
  };

  // const handleNavigation = (isVendor, modalId) => {
  //   if (isVendor) {
  //     navigate("/partnerRegister", { state: { stateCustType: modalId } });
  //   } else {
  //     navigate("/customerRegister", { state: { stateCustType: modalId } });
  //   }
  // };
  const handleNavigation = (isVendor, modalId) => {
    const url = isVendor
      ? `/partnerRegister?stateCustType=${modalId}`
      : `/customerRegister?stateCustType=${modalId}`;

    navigate(url);
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(onSubmit)();
    }
  };

  const onError = (msg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(msg);
    modalRef.current.click();
  };

  return (
    <>
      <div className="backdrop-image-register">
        <div className="container-fluid mb-5">
          <div className="row">
            <main className="col-md-12 p-3">
              <div className="Signupbg Registerform m-auto mt-4 pb-4 col-md-4">
                <div className="text-center w-100 clearfix max-logo d-block">
                  <img
                    src={logo}
                    className="img-fluid mx-auto d-block"
                    alt="LinehaulLogo"
                  />
                </div>
                <div className="px-4">
                  <div className="Form-title mb-1">Sign In</div>
                  <div className="row">
                    <div>
                      <div className="w-100">
                        <div className="row">
                          <div className="col-12 input-group mb-3">
                            <span
                              className="input-group-text inputsty"
                              id="basic-addon1"
                            >
                              {GetSvgIcon("IconEmail")}
                            </span>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Email/Staff ID"
                              aria-label="Email"
                              aria-describedby="basic-addon1"
                              name="userEmail"
                              {...register("userEmail", {
                                required: true,
                              })}
                              onKeyUp={handleKeypress}
                            />
                          </div>
                          {errors.userEmail && (
                            <div className="col-12 input-group mb-3">
                              <span className="badge bg-danger">
                                &#9888; This Field cannot be empty!
                              </span>
                            </div>
                          )}
                          <div className="col-12 input-group mb-3">
                            <span
                              className="input-group-text inputsty"
                              id="basic-addon1"
                            >
                              {GetSvgIcon("IconPassword")}
                            </span>
                            <input
                              type="password"
                              className="form-control"
                              placeholder="Password"
                              aria-label="Password"
                              aria-describedby="basic-addon1"
                              name="password"
                              id="password"
                              {...register("password", { required: true })}
                              onKeyUp={handleKeypress}
                            />
                            <button
                              className="btn btn-outline-secondary view-password"
                              type="button"
                              id="button-addon2"
                              onClick={() => viewPassword()}
                            >
                              {viewPswd
                                ? GetSvgIcon("BiEyeSlash")
                                : GetSvgIcon("BiEye")}
                            </button>
                          </div>
                          {errors.password && (
                            <div className="col-12 input-group mb-3">
                              <span className="badge bg-danger">
                                &#9888; This Field cannot be empty!
                              </span>
                            </div>
                          )}
                          <div className="text-left my-2 signin-link">
                            Unable to Sign In?{" "}
                            <a
                              href=""
                              data-bs-toggle="modal"
                              data-bs-target="#button-forgot-modal"
                            >
                              <strong>Click here to reset Password</strong>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={isPrivacyPolicyAccepted}
                        onChange={handleCheckboxChange}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        <a
                          href="/privacyPolicy"
                          target="_blank"
                          className="text-white"
                        >
                          Please read and accept the privacy policy
                        </a>
                      </label>
                    </div>
                  </div>
                  <div className="clearfix col-md-6 m-auto mt-3">
                    <button
                      className="btn-primary-search w-100 rounded-pill theme-violet"
                      onClick={handleSubmit(onSubmit)}
                      disabled={!isPrivacyPolicyAccepted}
                    >
                      Sign In
                    </button>
                    <button
                      data-bs-toggle="modal"
                      data-bs-target="#success-modal"
                      id="modalPopup"
                      className="d-none"
                      ref={modalRef}
                    />
                    <div className="text-center my-2 signin-link">
                      New User?
                      <a
                        href=""
                        data-bs-toggle="modal"
                        data-bs-target="#customer-type-modal"
                      >
                        {" "}
                        Register here
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <ForgotPassword />
              <CustomerTypeModal handleNavigation={handleNavigation} />
            </main>
          </div>
        </div>

        <Popup isSuccess={isSuccess} title={title} description={description} />
      </div>
      <Spinner show={show} />
      <Footer />
    </>
  );
};

export default Login;
