import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  COUNTRY_CODES,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  SERVER_ERR_MSG,
  TEXT_AREA_REGEX,
  addrKeys,
} from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";
import CustomToast from "../../Utilities/CustomToast";
import {
  DELIVERY_SERVICE_ID,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  PICKUP_SERVICE_ID,
} from "../../../ConfigConstants/ConfigConstants";
import GetSvgIcon from "../../../images/svglist";
import AddressBook from "./AddressBook";
import { getUserInfo, setUserInfo } from "../../AuthUtils/AuthUtils";
import { readLocalStorage } from "../../Helper/CommonMethods";

const AddressSearch = (props) => {
  const {
    config,
    handleClose,
    states,
    cities,
    setter,
    showSpinner,
    setCities,
    geofence,
    initCities,
    addressBook,
    setStates,
    initStates,
  } = props;
  const [showToast, setShowToast] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const {
    register: adrRegister,
    control: adrControl,
    reset: adrReset,
    watch: adrWatch,
    setValue: adrSetValue,
    handleSubmit: adrHandleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    adrReset(config?.argAddrData);
    adrSetValue("code", config?.argAddrData?.phone?.substr(0, 3));
    adrSetValue("phone", config?.argAddrData?.phone?.substr(3));
  }, [config]);

  const closeToast = () => {
    setShowToast(false);
  };

  const openToast = () => {
    setShowToast(true);
  };

  const setStateForCity = (city) => {
    if (!!city) {
      let stateOptArr = [];
      const state = geofence.filter((obj) => obj.map_city === city);
      state.forEach((element) => {
        stateOptArr.push({
          label: element.map_state,
          value: element.map_state,
        });
      });
      setStates(stateOptArr);
      if (state.length === 1) adrSetValue("state", state[0].map_state);
    } else {
      setStates(initStates);
      setCitiesForState(null);
    }
  };

  const setCitiesForState = (state) => {
    if (!!state) {
      const cities = geofence
        .filter((obj) => obj.map_state === state)
        .map((obj) => ({ label: obj.map_city, value: obj.map_city }));

      setCities(cities);
    } else {
      setCities(initCities);
      setStates(initStates);
      adrSetValue("city", "");
    }
  };

  const onSubmit = async (data) => {
    showSpinner(true);
    data.phone = !!data?.code && !!data?.phone ? data?.code + data.phone : null;
    const payload = {
      serviceId: config?.serviceId,
      location: data,
    };
    const resp = await CallApiPost("GetLastMileSbu", payload);
    if (resp.respCode === 200) {
      // const addrKeys = ["address", "city", "state", "postcode"]
      // const addrValues = Object.values(data)
      //     .filter(value => typeof value === 'string' && value !== null)
      //     .join(", ")
      const addrValues = Object.entries(data)
        .filter(([k, v]) => addrKeys.includes(k))
        .map(([k, v]) => v);
      const sbuId = resp.respData.sbuId;
      const sbuCode = resp.respData.sbuCode;
      if (config?.serviceId === PICKUP_SERVICE_ID) {
        setter("fromLabel", addrValues);
        setter("fromSbuId", sbuId);
        setter("fromSbuCode", sbuCode);
        setter("fromAdrData", data);
      } else if (config?.serviceId === DELIVERY_SERVICE_ID) {
        setter("toLabel", addrValues);
        setter("toSbuId", sbuId);
        setter("toSbuCode", sbuCode);
        setter("toAdrData", data);
      }

      //=== Store the cache id to local storage
      try {
        let tempCacheId = parseInt(resp.respData.cacheId);
        if (tempCacheId > 0) {
          setUserInfo(config?.serviceId, tempCacheId);
        }
      } catch (error) {
        console.error("Cache Id is not an integer");
      }

      handleClose();
    } else if (resp.respCode === 500 || resp.respCode === 99) {
      setErrMsg(SERVER_ERR_MSG);
      openToast();
    } else {
      setErrMsg(resp.respMessage);
      openToast();
    }

    showSpinner(false);
  };

  const handelOnAddressSelected = (data) => {
    adrReset(data);
    adrSetValue("code", data?.phone?.substr(0, 3));
    adrSetValue("phone", data?.phone?.substr(3));
  };

  const popover = (
    <Popover id="addr-book">
      <Popover.Body>
        <div className="overflow-auto adr-overlay">
          {addressBook?.length > 0 ? (
            <AddressBook
              handelOnAddressSelected={handelOnAddressSelected}
              addressBook={addressBook}
            />
          ) : (
            <Alert variant="warning">
              There are no saved address in your address book.
            </Alert>
          )}
        </div>
      </Popover.Body>
    </Popover>
  );

  const AddressBookOverlay = () => (
    <OverlayTrigger
      trigger="click"
      placement="right"
      overlay={popover}
      rootClose={true}
    >
      <Button variant="outline-success">
        Address Book {GetSvgIcon("BiBook")}
      </Button>
    </OverlayTrigger>
  );

  return (
    <>
      <Modal
        show={config?.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{config?.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              {!!getUserInfo("userId") && (
                <>
                  <Col
                    md={12}
                    // className="text-end font-weight-bold font-italic text-success"
                  >
                    <Row>
                      <Col md={6}>
                        {LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
                          getUserInfo("userType")
                        ) &&
                          config?.serviceId === PICKUP_SERVICE_ID && (
                            <>
                              <input
                                className="form-check-input border border-dark me-2"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                                onClick={(e) =>
                                  e.target.checked
                                    ? handelOnAddressSelected(
                                        JSON.parse(
                                          readLocalStorage("CustAddrInfo")
                                        )
                                      )
                                    : adrReset({
                                        address: "",
                                        city: "",
                                        state: "",
                                        postcode: "",
                                        phone: "",
                                        name: "",
                                        code: "+60",
                                      })
                                }
                              />
                              <label
                                class="form-label theme-label"
                                for="flexCheckDefault"
                              >
                                Same as sender address
                              </label>
                            </>
                          )}
                      </Col>
                      <Col
                        md={6}
                        className="text-end font-weight-bold font-italic text-success"
                      >
                        <AddressBookOverlay />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <label htmlFor="" className="form-label theme-label">
                      Name
                    </label>
                    <div>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name="name"
                        {...adrRegister("name")}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <label htmlFor="" className="form-label theme-label">
                      Phone
                    </label>
                    <div className="input-group ">
                      <select
                        name="code"
                        {...adrRegister("code")}
                        defaultValue="+60"
                        className="form-control country-code-customize"
                      >
                        {COUNTRY_CODES.map((cc, index) => (
                          <option key={index} value={cc.value}>
                            {cc.label}
                          </option>
                        ))}
                      </select>
                      <input
                        id=""
                        type="text"
                        className="form-control"
                        name=""
                        {...adrRegister("phone", {
                          pattern: {
                            value: NUMBER_REGEX,
                            message: "Incorrect Phone Number!!!",
                          },
                        })}
                      />
                    </div>
                  </Col>
                </>
              )}

              <Col md={12}>
                <label htmlFor="" className="form-label theme-label">
                  Address
                </label>
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  {...adrRegister("address", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                    pattern: {
                      value: TEXT_AREA_REGEX,
                      message: PATTERN_ERR_MSG,
                    },
                  })}
                />
                {errors.address && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.address.message}
                    </span>
                  </div>
                )}
              </Col>
              <Col md={6}>
                <label htmlFor="" className="form-label theme-label">
                  City
                </label>
                <Controller
                  name="city"
                  control={adrControl}
                  rules={{
                    required: { value: true, message: REQUIRED_ERR_MSG },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={cities}
                      placeholder="Select City"
                      value={
                        !!value
                          ? cities.filter((opt) => opt.value === value)
                          : value
                      }
                      onChange={(d) => {
                        onChange(!!d ? d.value : d);
                        if (!!d) {
                          setStateForCity(d.value);
                        } else {
                          setStateForCity(null);
                        }
                      }}
                      isSearchable={true}
                      isClearable={true}
                      className="form-control  p-0"
                    />
                  )}
                />
              </Col>
              <Col md={6}>
                <label htmlFor="" className="form-label theme-label">
                  State
                </label>
                <Controller
                  name="state"
                  control={adrControl}
                  rules={{
                    required: { value: true, message: REQUIRED_ERR_MSG },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={states}
                      placeholder="Select State"
                      value={
                        !!value
                          ? states.filter((opt) => opt.value === value)
                          : value
                      }
                      onChange={(d) => {
                        onChange(!!d ? d.value : d);
                        if (!!d) {
                          setCitiesForState(d.value);
                        } else {
                          setCitiesForState(null);
                        }
                      }}
                      isSearchable={true}
                      isClearable={true}
                      className="form-control  p-0"
                    />
                  )}
                />
              </Col>
              <Col md={12}>
                <label htmlFor="" className="form-label theme-label">
                  Postcode
                </label>
                <input
                  type="text"
                  className="form-control"
                  autoComplete="off"
                  {...adrRegister("postcode", {
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  })}
                />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button bsPrefix="btn btn-default border me-2" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            bsPrefix="btn btn-outline-secondary theme-violet text-nowrap mr-5"
            onClick={adrHandleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <CustomToast
        showToast={showToast}
        closeToast={closeToast}
        headerMsg="ERROR"
        body={errMsg}
      />
    </>
  );
};

export default AddressSearch;
