import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  DATE_FORMAT_MOMENT,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  BILL_INV_INB,
  INBORDC,
  INBORDS,
  LINEHAUL_CUSTOMER_USER_TYPE_LIST,
  LINEHAUL_SUPPLIER_USER_TYPE,
} from "../../ConfigConstants/ConfigConstants";
import { customDateSort, permissionCheck } from "../Helper/CommonMethods";
import { Tooltip } from "react-tooltip";
import { getUserInfo } from "../AuthUtils/AuthUtils";

export default function InboundOrderList(props) {
  const {
    orderList,
    handleEdit,
    openBill,
    invoiceDownload,
    dltManifest,
    cnlManifest,
  } = props;
  const navigate = useNavigate();

  const columns = [
    {
      name: "Customer Name",
      selector: (row) => row.custName,
      sortable: true,
      filterable: true,
      omit: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(getUserInfo("userType")),
    },
    {
      name: "Account Code",
      selector: (row) => row.custAcctCode,
      sortable: true,
      filterable: true,
      omit: LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(getUserInfo("userType")),
    },
    {
      name: "Supplier",
      selector: (row) => row?.supplier,
      sortable: true,
      filterable: true,
      omit: LINEHAUL_SUPPLIER_USER_TYPE === getUserInfo("userType"),
    },
    {
      name: "Manifest No",
      selector: (row) => row.orderNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Order Date",
      selector: (row) =>
        row.orderDt
          ? moment(row.orderDt).format(DATE_FORMAT_MOMENT)
          : row.orderDt,
      sortable: true,
      filterable: true,
      sortFunction: (rowA, rowB) => customDateSort(rowA.orderDt, rowB.orderDt),
    },
    {
      name: "Container No",
      selector: (row) => row.containerNo,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Port Name",
    //   selector: (row) => row.portName,
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Hub",
      selector: (row) => row.origin,
      sortable: true,
      filterable: true,
    },
    {
      name: "Drop Points",
      selector: (row) => row.drops,
      sortable: true,
      filterable: true,
    },
    {
      name: "Markings",
      selector: (row) => row?.markings,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <span className="d-flex justify-content-center">{row?.markings}</span>
      ),
    },
    {
      name: "Delivered",
      selector: (row) => row.delivered,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.statusMsg,
      cell: (row) => {
        switch (row.status) {
          case 344:
            return <Badge bg="info">{row.statusMsg}</Badge>;
          case 345:
            return <Badge bg="warning">{row.statusMsg}</Badge>;
          case 346:
            return <Badge bg="success">{row.statusMsg}</Badge>;
          case 355:
            return <Badge bg="danger">{row.statusMsg}</Badge>;
          default:
            break;
        }
      },
      filterable: true,
    },
    {
      name: "Invoice Amount",
      selector: (row) => row.invoiceAmt?.toFixed(2),
      sortable: true,
      filterable: true,
      omit: LINEHAUL_SUPPLIER_USER_TYPE === getUserInfo("userType"),
    },
    {
      name: "Invoice Status",
      selector: (row) => (!!row.invoiceId ? "GENERATED" : ""),
      cell: (row) =>
        !!row.invoiceId ? <p className="text-success m-0">GENERATED</p> : "",
      sortable: true,
      filterable: true,
      omit: LINEHAUL_SUPPLIER_USER_TYPE === getUserInfo("userType"),
    },
    {
      name: "",
      cell: (row) => (
        <>
          {!(row.status === 355) && (
            <div
              onClick={() => handleManifestSelect(row)}
              data-tooltip-id={"details-" + row.orderId}
            >
              {GetSvgIcon("BoxArrowRight")}
            </div>
          )}
          {!(row.status === 355) && (
            <Tooltip
              id={"details-" + row.orderId}
              content={"Details"}
              place="left"
            />
          )}
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },

    {
      name: "",
      allowOverflow: true,
      button: true,
      width: "20px",
      omit:
        !permissionCheck(INBORDC, "allowedRead") ||
        !permissionCheck(BILL_INV_INB, "allowedRead"),
      cell: (row) => (
        <>
          {!(row.status === 355) && (
            <OverlayTrigger
              trigger="click"
              key={row.id}
              placement="left"
              rootClose
              overlay={
                <Popover id={`popover-positioned-lefft`}>
                  <Popover.Body>
                    {permissionCheck(INBORDS, "allowedWrite") &&
                      (!!row?.invoiceId ? false : true) &&
                      row.status !== 355 && (
                        <div
                          className="action-item-button"
                          onClick={() => handleEditClick(row)}
                        >
                          {GetSvgIcon("PencilNoFill")} Edit
                        </div>
                      )}
                    {!!row.invoiceId &&
                      permissionCheck(BILL_INV_INB, "allowedRead") && (
                        <div
                          className="action-item-button"
                          onClick={() => invoiceDownload(row)}
                        >
                          <>
                            {GetSvgIcon("IconFiledownload")}
                            Download Invoice
                          </>
                        </div>
                      )}
                    {row.invoiceId === null &&
                      permissionCheck(BILL_INV_INB, "allowedWrite") &&
                      row.status !== 355 && (
                        <div
                          className="action-item-button"
                          onClick={() => handleBill(row)}
                        >
                          <>{GetSvgIcon("PayBill")} View Fare</>
                        </div>
                      )}
                    {/* {permissionCheck(BILL_INV_INB, "allowedRead") && (
                  <div
                    className="action-item-button"
                    onClick={() => {
                      !!row.invoiceId ? invoiceDownload(row) : handleBill(row);
                    }}
                  >
                    {!!row.invoiceId ? (
                      <>
                        {GetSvgIcon("IconFiledownload")}
                        Download Invoice
                      </>
                    ) : (
                      <>{GetSvgIcon("PayBill")} View Fare</>
                    )}
                  </div>
                )} */}
                    {!!(row?.status === 344 && row?.invoiceId === null) && (
                      <div
                        className="action-item-button"
                        onClick={() => handleOnDlt(row)}
                      >
                        {GetSvgIcon("Delete")} Delete Manifest
                      </div>
                    )}
                    {!!(row?.status === 345 && row?.invoiceId === null) && (
                      <div
                        className="action-item-button"
                        onClick={() => handleOnCnl(row)}
                      >
                        {GetSvgIcon("Cancel")} Cancel Manifest
                      </div>
                    )}
                  </Popover.Body>
                </Popover>
              }
            >
              <span
                id={`row-action-button-${row?.orderId}`}
                className="row-action-button"
              >
                {GetSvgIcon("Trippleverticaldot")}
              </span>
            </OverlayTrigger>
          )}
        </>
      ),
    },
  ];

  const handleManifestSelect = (row) => {
    let locData = Object.assign({}, row);
    locData.isCleanOnClose = false;
    // navigate("/inbOrderManifest", { state: row });
    const url = "/inbOrderManifest";
    const stateData = JSON.stringify(row);
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.sessionStorage.setItem("row", stateData);
      newTab.location.href = url;
    }
  };

  const handleEditClick = (argData) => {
    document.getElementById(`row-action-button-${argData?.orderId}`).click();
    let locData = Object.assign({}, argData);
    locData.isCleanOnClose = true;
    handleEdit(locData);
  };

  const handleBill = (row) => {
    document.getElementById(`row-action-button-${row?.orderId}`).click();
    openBill(row);
  };

  const handleOnDlt = (row) => {
    document.getElementById(`row-action-button-${row?.orderId}`).click();
    dltManifest(row);
  };
  const handleOnCnl = (row) => {
    document.getElementById(`row-action-button-${row?.orderId}`).click();
    cnlManifest(row);
  };
  return (
    <div>
      <div>
        <div className="col-md-12 table-responsive customized-data-table position-relative">
          <DataTable
            columns={columns}
            data={orderList}
            customStyles={customDataTableStyles}
            pagination
            striped
            highlightOnHover
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
          />
        </div>
      </div>
    </div>
  );
}
