import React from "react";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  DD_MM_YYYY_REGEX,
  ROUTE_CODE_LENGTH,
  SERVER_ERR_MSG,
  TIME_24_REGEX,
  TWO_DIGIT_DAY,
  YYYY_MM_REGEX,
} from "../Helper/Constants";
import UploadDataFile from "../Helper/DataUploadUtil";
import moment from "moment";
import {
  PLAN_STATUS_SCHEDULE,
  PLAN_STATUS_STOP_ORDER,
} from "../../ConfigConstants/ConfigConstants";

const routePlanColumns = [
  "Route Code",
  "Plan From (dd-mm-yyyy)",
  "Plan To (dd-mm-yyyy)",
  "Truck No",
  "Stop Order",
  "Stop1",
  "ETA1",
  "ETD1",
  "Stop2",
  "ETA2",
  "ETD2",
  "Stop3",
  "ETA3",
  "ETD3",
  "Stop4",
  "ETA4",
  "ETD4",
  "Stop5",
  "ETA5",
  "ETD5",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
  "Sun",
];

export const driverPlanColumns = [
  "Route Code",
  "Year-Month(yyyy-mm)",
  "Driver1",
  "Driver2",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const UploadPlan = (props) => {
  const { openPopup, uploadType } = props;
  const templatePlan = require(`../../assets/templates/RouteUploadTemplate.xlsx`);
  const templateDriver = require(`../../assets/templates/RoutePlanDriverUploadTemplate.xlsx`);

  const validateRouteData = (fileData) => {
    let isValid = true;
    fileData.forEach((row) => {
      let errMsg = [];
      routePlanColumns.forEach((col) => {
        validateEachCol(col, errMsg, row);
      });
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };

  const matchStopAndTime = (row, col, errMsg) => {
    switch (col) {
      case "ETD1":
        if (!!row["Stop1"]) {
          errMsg.push("ETD1 is mandatory for Stop1");
        }
        break;
      case "ETA2":
        if (!!row["Stop2"]) {
          errMsg.push("ETA2 is mandatory for Stop2");
        }
        break;
      case "ETD2":
        if (!!row["Stop2"] && !!row["Stop3"]) {
          errMsg.push("ETD2 is mandatory for Stop2");
        }
        break;
      case "ETA3":
        if (!!row["Stop3"]) {
          errMsg.push("ETA3 is mandatory for Stop3");
        }
        break;
      case "ETD3":
        if (!!row["Stop3"] && !!row["Stop4"]) {
          errMsg.push("ETD3 is mandatory for Stop3");
        }
        break;
      case "ETA4":
        if (!!row["Stop4"]) {
          errMsg.push("ETA4 is mandatory for Stop4");
        }
        break;
      case "ETD4":
        if (!!row["Stop4"] && !!row["Stop5"]) {
          errMsg.push("ETD4 is mandatory for Stop4");
        }
        break;
      case "ETA5":
        if (!!row["Stop5"]) {
          errMsg.push("ETA5 is mandatory for Stop5");
        }
        break;
      default:
        break;
    }
  };

  const validateEachCol = (col, errMsg, row) => {
    if (!!row[col]) {
      switch (col) {
        case "Plan From (dd-mm-yyyy)":
        case "Plan To (dd-mm-yyyy)":
          validateDate(errMsg, col, row);
          break;
        case "Mon":
        case "Tue":
        case "Wed":
        case "Thu":
        case "Fri":
        case "Sat":
        case "Sun":
          if (!(row[col] === "Y" || row[col] === "y")) {
            errMsg.push(col + ": Please pass 'Y' or 'y' or no value ");
          }
          break;
        case "ETA1":
        case "ETD1":
        case "ETA2":
        case "ETD2":
        case "ETA3":
        case "ETD3":
        case "ETA4":
        case "ETD4":
        case "ETA5":
        case "ETD5":
          if (!TIME_24_REGEX.test(row[col])) {
            errMsg.push(col + " is not in 24 hour time format(hh:mm) ");
          }
          break;
        case "Route Code":
          if (row[col].length > ROUTE_CODE_LENGTH) {
            errMsg.push(
              "Length of " +
                col +
                " cannot be greater than " +
                ROUTE_CODE_LENGTH
            );
          }
          break;
        case "Stop Order":
          if (!!row[col] && !["y", "yes"].includes(row[col].toLowerCase())) {
            errMsg.push(col + ": Please pass 'Y' or no value");
          }
          break;
        default:
          if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + " contains some invalid character ");
          }
          break;
      }
    } else {
      switch (col) {
        case "Plan From (dd-mm-yyyy)":
        case "Plan To (dd-mm-yyyy)":
        case "Route Code":
        case "Truck No":
          errMsg.push(col + " is mandatory");
          break;
        case "ETA1":
        case "ETD1":
        case "ETA2":
        case "ETD2":
        case "ETA3":
        case "ETD3":
        case "ETA4":
        case "ETD4":
        case "ETA5":
        case "ETD5":
          matchStopAndTime(row, col, errMsg);
          break;
        default:
          break;
      }
    }
  };

  const formatRouteData = (fileData) => {
    let fileDataOut = [];
    fileData.forEach((row, index) => {
      let rowOut = {};
      rowOut.userId = getUserInfo("userId");
      rowOut.RowIndex = index;
      rowOut.ErrorMessage = "Duplicate plan";
      rowOut.planId = 0;
      rowOut.routeCode = row["Route Code"];
      rowOut.validFrom = row["Plan From (dd-mm-yyyy)"];
      rowOut.validTo = row["Plan To (dd-mm-yyyy)"];
      rowOut.truckNo = row["Truck No"];
      rowOut.planSts =
        !!row["Stop Order"] &&
        ["y", "yes"].includes(row["Stop Order"].toLowerCase())
          ? PLAN_STATUS_STOP_ORDER
          : PLAN_STATUS_SCHEDULE;
      let ptrnWeekDay = row["Mon"] === "Y" || row["Mon"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Tue"] === "Y" || row["Tue"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Wed"] === "Y" || row["Wed"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Thu"] === "Y" || row["Thu"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Fri"] === "Y" || row["Fri"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Sat"] === "Y" || row["Sat"] === "y" ? "1" : "0";
      ptrnWeekDay += row["Sun"] === "Y" || row["Sun"] === "y" ? "1" : "0";
      rowOut.patternMonSun = ptrnWeekDay;
      let itinList = [];
      let itin1 = {};
      itin1.stopSeq = 1;
      itin1.stopSbuCode = row["Stop1"];
      itin1.sta = row["ETA1"];
      itin1.std = row["ETD1"];
      itinList.push(itin1);
      let itin2 = {};
      itin2.stopSeq = 2;
      itin2.stopSbuCode = row["Stop2"];
      itin2.sta = row["ETA2"];
      itin2.std = row["ETD2"];
      itinList.push(itin2);
      if (row["Stop3"]) {
        let itin3 = {};
        itin3.stopSeq = 3;
        itin3.stopSbuCode = row["Stop3"];
        itin3.sta = row["ETA3"];
        itin3.std = row["ETD3"];
        itinList.push(itin3);
      }
      if (row["Stop4"]) {
        let itin4 = {};
        itin4.stopSeq = 4;
        itin4.stopSbuCode = row["Stop4"];
        itin4.sta = row["ETA4"];
        itin4.std = row["ETD4"];
        itinList.push(itin4);
      }
      if (row["Stop5"]) {
        let itin5 = {};
        itin5.stopSeq = 5;
        itin5.stopSbuCode = row["Stop5"];
        itin5.sta = row["ETA5"];
        itin5.std = row["ETD5"];
        itinList.push(itin5);
      }
      rowOut.itinerary = itinList;
      fileDataOut.push(rowOut);
    });
    return fileDataOut;
  };

  const formatDriverData = (fileData) => {
    let fileDataOut = [];
    fileData.forEach((row, index) => {
      const yrMonth = row["Year-Month(yyyy-mm)"];

      driverPlanColumns.forEach((col) => {
        if (TWO_DIGIT_DAY.test(col) && !!row[col]) {
          const inTripDate = yrMonth + "-" + col;
          if (moment(inTripDate, DATE_FORMAT_SERVER, true).isValid()) {
            let rowOut = {};
            rowOut.rowId = index;
            rowOut.routeCode = row["Route Code"];
            rowOut.driver1 = row["Driver1"];
            rowOut.driver2 = row["Driver2"];
            rowOut.tripDate = inTripDate + " 00:00";
            fileDataOut.push(rowOut);
          }
        }
      });
    });
    return fileDataOut;
  };

  const validateDriverData = (fileData) => {
    let isValid = true;
    fileData.forEach((row) => {
      let errMsg = [];
      let dateDataCnt = { cnt: 0 };
      driverPlanColumns.forEach((col) => {
        validateInData(row, col, errMsg, dateDataCnt);
      });
      if (dateDataCnt.cnt < 1) {
        errMsg.push("Please fill atleast one date");
      }
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };

  const validateInData = (row, col, errMsg, dateDataCnt) => {
    switch (col) {
      case "Year-Month(yyyy-mm)":
        if (!!row[col]) {
          if (!YYYY_MM_REGEX.test(row[col])) {
            errMsg.push(col + " Invalid Data");
          }
        } else {
          errMsg.push(col + " is absent");
        }
        break;
      case "Driver1":
        if (!!row[col]) {
          if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + " contains some invalid character ");
          }
        } else {
          errMsg.push(col + " is absent");
        }
        break;
      case "Route Code":
        if (!!row[col]) {
          if (row[col].length > 25) {
            errMsg.push(
              "Length of " +
                col +
                " cannot be greater than " +
                ROUTE_CODE_LENGTH
            );
          }
          if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + " contains some invalid character ");
          }
        } else {
          errMsg.push(col + " is absent");
        }
        break;
      case "Driver2":
        if (!ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
          errMsg.push(col + " contains some invalid character ");
        }
        break;
      default:
        if (!!row[col] && row[col] !== "Y" && row[col] !== "y") {
          errMsg.push(col + " accepts Y or y or no value ");
        }
        if (!!row[col]) {
          dateDataCnt.cnt++;
        }
        break;
    }
  };

  const validateDate = (errMsg, col, row) => {
    if (!DD_MM_YYYY_REGEX.test(row[col])) {
      errMsg.push(col + ":Invalid date format ");
    } else {
      const isValidDate = moment(row[col], DATE_FORMAT_MOMENT, true).isValid();
      if (!isValidDate) {
        errMsg.push(col + " contains invalid date");
      } else {
        const today = moment().startOf("day");
        const inpDt = moment(row[col], DATE_FORMAT_MOMENT, true).format(
          DATE_FORMAT_SERVER
        );
        if (moment(inpDt).isBefore(today)) {
          errMsg.push(col + " is in the past ");
        }
      }
    }

    if (col === "Plan To (dd-mm-yyyy)") {
      const from = moment(
        row["Plan From (dd-mm-yyyy)"],
        DATE_FORMAT_MOMENT,
        true
      );
      const to = moment(row["Plan To (dd-mm-yyyy)"], DATE_FORMAT_MOMENT, true);
      if (to.isBefore(from)) {
        errMsg.push("Plan To is before Plan From");
      }
    }
  };

  const onSuccess = () => {
    openPopup(
      true,
      "SUCCESS",
      uploadType === "P"
        ? "Route scheduled successfully!!!"
        : "Driver added successfully!!!"
    );
  };

  const onError = (resp) => {
    let msg;
    if (
      resp.respMessage === undefined ||
      resp.respMessage === null ||
      resp.respMessage === "null"
    ) {
      msg = SERVER_ERR_MSG;
    } else {
      msg = resp.respMessage;
    }
    openPopup(false, "ERROR", msg);
  };

  return (
    <UploadDataFile
      dataUploadUrl={
        uploadType === "P" ? "SaveRoutPlan" : "SaveRoutePlanDriver"
      }
      cbValidateData={
        uploadType === "P" ? validateRouteData : validateDriverData
      }
      cbPreUpload={uploadType === "P" ? formatRouteData : formatDriverData}
      onSuccess={onSuccess}
      template={uploadType === "P" ? templatePlan : templateDriver}
      downloadFileName={
        uploadType === "P"
          ? "RouteUploadTemplate.xlsx"
          : "RoutePlanDriverUploadTemplate.xlsx"
      }
      onError={onError}
      downloadFileServer={uploadType === "P" ? false : true}
      downloadUrl="DownloadDrExcelForPlan?param1=0"
      header={uploadType === "P" ? routePlanColumns : driverPlanColumns}
    />
  );
};

export default UploadPlan;
