import React, { useEffect, useState } from "react";
import { toBase64 } from "../Helper/CommonMethods";
import GetSvgIcon, { GetSvgUpload } from "../../images/svglist";
import { VEHICLE_IMAGE_CODE_MAP } from "../../ConfigConstants/ConfigConstants";
import { DownloadFile, GetBase64Img } from "../Helper/FileStoreService";
import {
  REQUIRED_ERR_MSG,
  UPLOAD_IMG_B_TEXT,
  UPLOAD_IMG_BI_TEXT,
  UPLOAD_IMG_CI_TEXT,
  UPLOAD_IMG_F_TEXT,
  UPLOAD_IMG_L_TEXT,
  UPLOAD_IMG_R_TEXT,
} from "../Helper/Constants";
import { Row, Spinner as BootstrapSpinner } from "react-bootstrap";
import Spinner from "../Layout/Spinner";

const initImgData = {
  vhclImgF: "",
  vhclImgB: "",
  vhclImgL: "",
  vhclImgR: "",
  vhclImgCI: "",
  vhclImgBI: "",
};
const initLabelData = {
  vhclImgF: UPLOAD_IMG_F_TEXT,
  vhclImgB: UPLOAD_IMG_B_TEXT,
  vhclImgL: UPLOAD_IMG_L_TEXT,
  vhclImgR: UPLOAD_IMG_R_TEXT,
  vhclImgCI: UPLOAD_IMG_CI_TEXT,
  vhclImgBI: UPLOAD_IMG_BI_TEXT,
};
const initImgLoader = {
  vhclImgF: true,
  vhclImgB: true,
  vhclImgL: true,
  vhclImgR: true,
  vhclImgCI: true,
  vhclImgBI: true,
};
const initAttachment = {
  photo: "",
  photoFileName: "",
  photoFileType: "",
  photoBucketUrl: "",
};
export const imgValidation = (data, setError) => {
  let isValid = true;
  const regExp = new RegExp("vhclImg");
  Object.entries(data).map(([key, value]) => {
    if (
      regExp.test(key) &&
      [null, undefined, ""].includes(value?.photoBucketUrl) &&
      [null, undefined, ""].includes(value?.photo)
    ) {
      setError(
        key,
        { type: "custom", message: REQUIRED_ERR_MSG },
        { shouldFocus: true }
      );
      isValid = false;
    }
  });
  return isValid;
};
const VehicleImage = ({
  vhcl,
  cleanUpData,
  register,
  setValue,
  isReadOnly = false,
  isMandatory = false,
  errors,
  clearErrors,
  setError,
}) => {
  const [imgData, setImgData] = useState(
    JSON.parse(JSON.stringify(initImgData))
  );
  const [labelName, setLabelName] = useState(
    JSON.parse(JSON.stringify(initLabelData))
  );
  const [imgLoader, setImgLoader] = useState(Object.assign({}, initImgLoader));
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    downloadImg(vhcl);
  }, [vhcl]);

  useEffect(() => {
    setImgData(JSON.parse(JSON.stringify(initImgData)));
    setLabelName(JSON.parse(JSON.stringify(initLabelData)));
    setImgLoader(Object.assign(initImgLoader));
  }, [cleanUpData]);

  const GetImageFromCloud = (vhcl, key, DEFAULT_IMG_TEXT) => {
    GetBase64Img(vhcl[key]?.photoFileName).then((resp) => {
      if (resp === "") {
        labelName[key] = DEFAULT_IMG_TEXT;
      } else {
        labelName[key] = vhcl[key]?.photoFileName;
        let copyImg = vhcl[key];
        copyImg.photoBucketUrl = vhcl[key]?.photoFileName;
        setValue(key, copyImg);
      }
      setImgData((img) => ({
        ...img,
        [key]: resp,
      }));
      setImgLoader((loader) => ({
        ...loader,
        [key]: false,
      }));
    });
  };

  const downloadImg = (vhcl) => {
    setImgLoader(Object.assign({}, initImgLoader));
    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgF", UPLOAD_IMG_F_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgB", UPLOAD_IMG_B_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgL", UPLOAD_IMG_L_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgR", UPLOAD_IMG_R_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgCI", UPLOAD_IMG_CI_TEXT);

    vhcl.vhclImgF.photoFileName !== "" &&
      GetImageFromCloud(vhcl, "vhclImgBI", UPLOAD_IMG_BI_TEXT);
  };

  const selectFile = async (code, key, event) => {
    const file = event.target.files;

    if (file.length === 0) {
      setLabelName((label) => ({
        ...label,
        [key]: "",
      }));

      const newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
      setValue(key, newAttachment, { shouldDirty: true });
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      if (["jpg", "jpeg", "png"].includes(ext)) {
        setLabelName((label) => ({
          ...label,
          [key]: fileName,
        }));
        var photo = "";
        try {
          photo = await toBase64(file[0]);
        } catch (error) {
          console.error(error);
        }
        const newAttachment = {
          photo: photo,
          photoFileName: code,
          photoFileType: ext,
          photoBucketUrl: "",
        };
        setValue(key, newAttachment, { shouldDirty: true });
        clearErrors(key);
        setImgData((img) => ({
          ...img,
          [key]: photo,
        }));
      } else {
        if (imgData[key] === "") {
          const newAttachment = {
            photo: "",
            photoFileName: "",
            photoFileType: "",
            photoBucketUrl: "",
          };
          setValue(key, newAttachment, { shouldDirty: true });
          setError(
            key,
            {
              type: "custom",
              message: "Please select file of type jpg, jpeg, or png",
            },
            { shouldFocus: true }
          );
        }
      }
    }
  };

  const removeImg = (key) => {
    setValue(key, initAttachment);
    setImgData((img) => ({
      ...img,
      [key]: "",
    }));

    switch (key) {
      case "vhclImgF":
        labelName[key] = UPLOAD_IMG_F_TEXT;
        break;
      case "vhclImgB":
        labelName[key] = UPLOAD_IMG_B_TEXT;
        break;
      case "vhclImgL":
        labelName[key] = UPLOAD_IMG_L_TEXT;
        break;
      case "vhclImgR":
        labelName[key] = UPLOAD_IMG_R_TEXT;
        break;
      case "vhclImgCI":
        labelName[key] = UPLOAD_IMG_CI_TEXT;
        break;
      case "vhclImgBI":
        labelName[key] = UPLOAD_IMG_BI_TEXT;
        break;
      default:
        break;
    }
  };

  const downloadImgToSys = (argFileName) => {
    setShowSpinner(true);
    DownloadFile(
      argFileName,
      () => {},
      () => {},
      "jpg"
    ).then((resp) => {
      setShowSpinner(false);
    });
  };

  return (
    <Row>
      <div className="col-md-3 mb-2">
        <label htmlFor="" className="form-label theme-label">
          Vehicle Image (Front)
          {isMandatory && <span className="text-danger">&#9733;</span>}
          <span
            className="ms-1"
            title="Please upload a clear image. 
            Maximum file size: 10 MB. 
            Supported formats: JPEG, PNG."
          >
            {GetSvgIcon("ToolTip")}{" "}
          </span>
        </label>
        {!isReadOnly && (
          <div className="Upload-Photo w-100 mb-2" {...register("vhclImgF")}>
            <input
              type="file"
              accept="application/pdf, image/jpeg, image/jpg"
              id="vhclImgF"
              onChange={(e) =>
                selectFile(VEHICLE_IMAGE_CODE_MAP.IMGFCODE, "vhclImgF", e)
              }
              key={labelName.vhclImgF}
            />
            <div className="uploaded-text ms-2">
              {GetSvgUpload({ required: false })}
              {labelName.vhclImgF}
            </div>
          </div>
        )}
        {errors?.vhclImgF && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.vhclImgF?.message}
            </span>
          </div>
        )}
        {imgLoader.vhclImgF && vhcl.vhclId > 0 && (
          <div className="w-100 text-center position-relative">
            <BootstrapSpinner animation="border" variant="info" />
          </div>
        )}
        {imgData.vhclImgF !== "" && (
          <div className="w-100 text-center position-relative">
            <img
              src={"data:image/jpeg;base64," + imgData.vhclImgF}
              height="100px"
              width="100px"
              alt="img"
              onClick={() => downloadImgToSys(labelName.vhclImgF)}
              className="cursor-pointer"
            />
            <span
              className="position-absolute end-0 top-0 fw-bold text-danger cursor-pointer"
              onClick={() => removeImg("vhclImgF")}
            >
              x
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="" className="form-label theme-label">
          Vehicle Image (Back)
          {isMandatory && <span className="text-danger">&#9733;</span>}
          <span
            className="ms-1"
            title="Please upload a clear image. 
            Maximum file size: 10 MB. 
            Supported formats: JPEG, PNG."
          >
            {GetSvgIcon("ToolTip")}{" "}
          </span>
        </label>

        {!isReadOnly && (
          <div className="Upload-Photo w-100 mb-2" {...register("vhclImgB")}>
            <input
              type="file"
              accept="application/pdf, image/jpeg, image/jpg"
              id="vhclImgB"
              onChange={(e) =>
                selectFile(VEHICLE_IMAGE_CODE_MAP.IMGBCODE, "vhclImgB", e)
              }
              key={labelName.vhclImgB}
            />
            <div className="uploaded-text ms-2">
              {GetSvgUpload({ required: false })}
              {labelName.vhclImgB}
            </div>
          </div>
        )}
        {errors?.vhclImgB && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.vhclImgB?.message}
            </span>
          </div>
        )}
        {imgLoader.vhclImgB && vhcl.vhclId > 0 && (
          <div className="w-100 text-center position-relative">
            <BootstrapSpinner animation="border" variant="info" />
          </div>
        )}
        {imgData.vhclImgB !== "" && (
          <div className="w-100 text-center w-100 text-center position-relative">
            <img
              src={"data:image/jpeg;base64," + imgData.vhclImgB}
              height="100px"
              width="100px"
              alt="img"
              onClick={() => downloadImgToSys(labelName.vhclImgB)}
              className="cursor-pointer"
            />
            <span
              className="position-absolute end-0 top-0 fw-bold text-danger cursor-pointer"
              onClick={() => removeImg("vhclImgB")}
            >
              x
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="" className="form-label theme-label">
          Vehicle Image (Left)
          {isMandatory && <span className="text-danger">&#9733;</span>}
          <span
            className="ms-1"
            title="Please upload a clear image. 
            Maximum file size: 10 MB. 
            Supported formats: JPEG, PNG."
          >
            {GetSvgIcon("ToolTip")}{" "}
          </span>
        </label>

        {!isReadOnly && (
          <div className="Upload-Photo w-100 mb-2">
            <input
              type="file"
              accept="application/pdf, image/jpeg, image/jpg"
              id="vhclImgL"
              onChange={(e) =>
                selectFile(VEHICLE_IMAGE_CODE_MAP.IMGLCODE, "vhclImgL", e)
              }
              key={labelName.vhclImgL}
            />
            <div className="uploaded-text ms-2" {...register("vhclImgL")}>
              {GetSvgUpload({ required: false })}
              {labelName.vhclImgL}
            </div>
          </div>
        )}
        {errors?.vhclImgL && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.vhclImgL?.message}
            </span>
          </div>
        )}
        {imgLoader.vhclImgL && vhcl.vhclId > 0 && (
          <div className="w-100 text-center position-relative">
            <BootstrapSpinner animation="border" variant="info" />
          </div>
        )}
        {imgData.vhclImgL !== "" && (
          <div className="w-100 text-center position-relative">
            <img
              src={"data:image/jpeg;base64," + imgData.vhclImgL}
              height="100px"
              width="100px"
              alt="img"
              onClick={() => downloadImgToSys(labelName.vhclImgL)}
              className="cursor-pointer"
            />
            <span
              className="position-absolute end-0 top-0 fw-bold text-danger cursor-pointer"
              onClick={() => removeImg("vhclImgL")}
            >
              x
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="" className="form-label theme-label">
          Vehicle Image (Right)
          {isMandatory && <span className="text-danger">&#9733;</span>}
          <span
            className="ms-1"
            title="Please upload a clear image. 
            Maximum file size: 10 MB. 
            Supported formats: JPEG, PNG."
          >
            {GetSvgIcon("ToolTip")}{" "}
          </span>
        </label>

        {!isReadOnly && (
          <div className="Upload-Photo w-100 mb-2">
            <input
              type="file"
              accept="application/pdf, image/jpeg, image/jpg"
              id="vhclImgR"
              onChange={(e) =>
                selectFile(VEHICLE_IMAGE_CODE_MAP.IMGRCODE, "vhclImgR", e)
              }
              key={labelName.vhclImgR}
            />
            <div className="uploaded-text ms-2" {...register("vhclImgR")}>
              {GetSvgUpload({ required: false })}
              {labelName.vhclImgR}
            </div>
          </div>
        )}
        {errors?.vhclImgR && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.vhclImgR?.message}
            </span>
          </div>
        )}
        {imgLoader.vhclImgR && vhcl.vhclId > 0 && (
          <div className="w-100 text-center position-relative">
            <BootstrapSpinner animation="border" variant="info" />
          </div>
        )}
        {imgData.vhclImgR !== "" && (
          <div className="w-100 text-center position-relative">
            <img
              src={"data:image/jpeg;base64," + imgData.vhclImgR}
              height="100px"
              width="100px"
              alt="img"
              onClick={() => downloadImgToSys(labelName.vhclImgR)}
              className="cursor-pointer"
            />
            <span
              className="position-absolute end-0 top-0 fw-bold text-danger cursor-pointer"
              onClick={() => removeImg("vhclImgR")}
            >
              x
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="" className="form-label theme-label">
          Vehicle Image (Cabin Interior)
          {isMandatory && <span className="text-danger">&#9733;</span>}
          <span
            className="ms-1"
            title="Please upload a clear image. 
            Maximum file size: 10 MB. 
            Supported formats: JPEG, PNG."
          >
            {GetSvgIcon("ToolTip")}{" "}
          </span>
        </label>

        {!isReadOnly && (
          <div className="Upload-Photo w-100 mb-2">
            <input
              type="file"
              accept="application/pdf, image/jpeg, image/jpg"
              id="vhclImgCI"
              onChange={(e) =>
                selectFile(VEHICLE_IMAGE_CODE_MAP.IMGCICODE, "vhclImgCI", e)
              }
              key={labelName.vhclImgCI}
            />
            <div className="uploaded-text ms-2" {...register("vhclImgCI")}>
              {GetSvgUpload({ required: false })}
              {labelName.vhclImgCI}
            </div>
          </div>
        )}
        {errors?.vhclImgCI && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.vhclImgCI?.message}
            </span>
          </div>
        )}
        {imgLoader.vhclImgCI && vhcl.vhclId > 0 && (
          <div className="w-100 text-center position-relative">
            <BootstrapSpinner animation="border" variant="info" />
          </div>
        )}
        {imgData.vhclImgCI !== "" && (
          <div className="w-100 text-center position-relative">
            <img
              src={"data:image/jpeg;base64," + imgData.vhclImgCI}
              height="100px"
              width="100px"
              alt="img"
              onClick={() => downloadImgToSys(labelName.vhclImgCI)}
              className="cursor-pointer"
            />
            <span
              className="position-absolute end-0 top-0 fw-bold text-danger cursor-pointer"
              onClick={() => removeImg("vhclImgCI")}
            >
              x
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="" className="form-label theme-label">
          Vehicle Image (Bonded Interior)
          {isMandatory && <span className="text-danger">&#9733;</span>}
          <span
            className="ms-1"
            title="Please upload a clear image. 
            Maximum file size: 10 MB. 
            Supported formats: JPEG, PNG."
          >
            {GetSvgIcon("ToolTip")}{" "}
          </span>
        </label>

        {!isReadOnly && (
          <div className="Upload-Photo w-100 mb-2" {...register("vhclImgBI")}>
            <input
              type="file"
              accept="application/pdf, image/jpeg, image/jpg"
              id="vhclImgBI"
              onChange={(e) =>
                selectFile(VEHICLE_IMAGE_CODE_MAP.IMGBICODE, "vhclImgBI", e)
              }
              key={labelName.vhclImgBI}
            />
            <div className="uploaded-text ms-2">
              {GetSvgUpload({ required: false })}
              {labelName.vhclImgBI}
            </div>
          </div>
        )}
        {errors?.vhclImgBI && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.vhclImgBI?.message}
            </span>
          </div>
        )}
        {imgLoader.vhclImgBI && vhcl.vhclId > 0 && (
          <div className="w-100 text-center position-relative">
            <BootstrapSpinner animation="border" variant="info" />
          </div>
        )}
        {imgData.vhclImgBI !== "" && (
          <div className="w-100 text-center position-relative">
            <img
              src={"data:image/jpeg;base64," + imgData.vhclImgBI}
              height="100px"
              width="100px"
              alt="img"
              onClick={() => downloadImgToSys(labelName.vhclImgBI)}
              className="cursor-pointer"
            />
            <span
              className="position-absolute end-0 top-0 fw-bold text-danger cursor-pointer"
              onClick={() => removeImg("vhclImgBI")}
            >
              x
            </span>
          </div>
        )}
      </div>
      <Spinner show={showSpinner} />
    </Row>
  );
};

export default VehicleImage;
