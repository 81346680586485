import React, { useEffect, useState } from "react";
import Layout from "../Layout/Layout";
import VehicleLeaseList from "./VehicleLeaseList";
import { CallApiGet, CallApiPost } from "../Helper/serviceApi";
import GetSvgIcon from "../../images/svglist";
import { Button } from "react-bootstrap";
import VehicleLeaseAddEdit from "./VehicleLeaseAddEdit";
import {
  APPROVE_APRVL,
  REJECT_APRVL,
  VEHICLE_IMAGE_CODE_MAP,
  VHCLLEASEREQ,
} from "../../ConfigConstants/ConfigConstants";
import { initialVhclData } from "../Vehicle/VehicleMain";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import {
  DATE_FORMAT_CLIENT,
  REQUIRED_ERR_MSG,
  TEXT_AREA_REGEX,
} from "../Helper/Constants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { masterApprovalHistory, permission } from "../Helper/CommonMethods";
import { Controller, useForm } from "react-hook-form";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import MasterApprovalLogList from "../ApprovalWorkFlow/MasterApprovalLogList";

const initAlertConfig = {
  isSuccess: false,
  size: "fullscreen",
  show: false,
  title: "",
  description: "",
  dialogClassName: "",
};
const initApproval = {
  id: 0,
  vhclId: 0,
  vehicleNo: "",
  prtId: 0,
  partner: "",
  isActive: false,
  leasingPrice: 0.0,
  startDate: null,
  endDate: null,
  terminatedOn: null,
  terminatedBy: null,
  terminateUser: null,
  terminationReason: null,
  approverRole: 0,
  assignedOn: "",
  approvalStepId: 0,
  approvalType: 0,
  approvalEntityId: 0,
  approvalStatus: 0,
  termination: false,
  update: false,
  remarks: "",
  status: "",
  statusId: 0,
  attachment:
    '{"photo" : null, "photoFileName" : null, "photoFileType" : null, "photoBucketUrl" : null, "photoRemark" : null}',
};
const initApiPayload = {
  leaseDto: initApproval,
  logDto: initApproval,
};
const VehicleLeaseMain = () => {
  const { control, errors } = useForm();
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [vhclLease, setVhclLease] = useState([]);
  const [avlVhcl, setAvlVhcl] = useState([]);
  const [avlPtr, setAvlPtr] = useState([]);

  const [isFinAllowed, setIsFinAllowed] = useState(false);
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [modalConfirm, setModalConfirm] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [vhclInfo, setVhclInfo] = useState(Object.assign({}, initialVhclData));
  const [selVhclLease, setSelVhclLease] = useState(
    Object.assign({}, initApproval)
  );
  const [apiPayload, setApiPayload] = useState(
    Object.assign({}, initApiPayload)
  );
  const [terminationClick, setTerminationClick] = useState(false);
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  useEffect(() => {
    fetchVhclLeaseList();
    fetchAvlVhcl();
    fetchPtrCompany();
  }, []);

  const openPopup = (boolVal, argTitle, argDesc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "",
      show: true,
      title: argTitle,
      description: argDesc,
    });
  };
  const closePopup = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
    fetchVhclLeaseList();
  };
  const setModalData = () => {
    setModalConfig({
      size: "fullscreen",
      show: true,
      title: "Vehicle Lease",
    });
  };
  const hideConfirmAlert = (handleBackdropOpacity = true) => {
    setModalConfirm({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
      data: null,
    });
    if (handleBackdropOpacity) {
      setModalConfig({ ...modalConfig, dialogClassName: "" });
    }
  };

  const openConfirmAlert = (argData) => {
    let copyData = Object.assign({}, argData);
    copyData.termination = false;
    setApiPayload(Object.assign({}, copyData));
    setModalConfirm({
      isSuccess: true,
      size: "md",
      show: true,
      title: argData?.logDto?.title,
      data: argData,
    });
    setModalConfig({ ...modalConfig, dialogClassName: "opacity-css" });
  };

  const openTerminationAlert = (argData) => {
    let copyData = Object.assign({}, argData);
    copyData.termination = true;
    const payload = {
      leaseDto: copyData,
      logDto: copyData,
    };
    setApiPayload(Object.assign({}, payload));
    setModalConfirm({
      isSuccess: true,
      size: "md",
      show: true,
      title: "TERMINATE",
      data: copyData,
    });

    setModalConfig({ ...modalConfig, dialogClassName: "opacity-css" });
    // setTerminationClick(true);
  };
  const closeTerminationAlert = (argData) => {
    setModalConfirm({
      isSuccess: true,
      size: "md",
      show: false,
      title: "Termination",
      data: argData,
    });
  };

  const handleModalClose = () => {
    setModalConfig(Object.assign({}, initAlertConfig));
    setVhclInfo(Object.assign({}, initialVhclData));
    setSelVhclLease(Object.assign({}, initApproval));
    // setTerminationClick(false);
  };
  const openLeaseModal = (data) => {
    setSelVhclLease(data);
    setModalData();
  };
  const handleSave = async (data) => {
    setShowSpinner(true);
    const resp = await CallApiPost("SaveVhclLeaseDetails", data);
    if (resp.respCode === 200) {
      resp.respMessage =
        "Thank You, New lease request registered successfully!!";
      openPopup(true, "SUCCESS", resp.respMessage);
      fetchAvlVhcl();
    } else {
      handleApiResponse(resp);
    }
    handleModalClose();
    setShowSpinner(false);
  };

  const handleOnTerminationConfirm = async () => {
    setShowSpinner(true);
    let payload = Object.assign({}, apiPayload);
    payload.leaseDto.termination = true;
    payload.leaseDto.update = true;
    payload.leaseDto.terminationReason = payload.logDto.remarks;
    if (payload.logDto?.termination && !payload.logDto?.terminatedOn) {
      payload.logDto.error = { terminatedOn: true };
      setApiPayload(payload);
      setShowSpinner(false);
      return;
    } else if (payload.logDto?.remarks?.length === 0) {
      payload.logDto.error = { remarks: true };
      setApiPayload(payload);
      setShowSpinner(false);
      return;
    } else {
      payload.logDto.error = { remarks: false };
      setApiPayload(payload);
    }
    const resp = await CallApiPost("TerminationLease", payload.leaseDto);
    if (resp.respCode === 200) {
      resp.respMessage = "Termination request processed successfully!!";
      openPopup(true, "SUCCESS", resp.respMessage);
    } else {
      handleApiResponse(resp);
    }
    handleModalClose();
    hideConfirmAlert(false);
    setShowSpinner(false);
  };

  const handleApiResponse = (resp) => {
    switch (resp.respCode) {
      case 500:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after sometime!!"
        );
        break;
      case 99:
        openPopup(false, "ERROR", "Network Error!!");
        break;
      case 200:
      case 0:
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const fetchVhclLeaseList = async () => {
    setShowSpinner(true);
    const uri = "VhclLeaseInfo";
    const resp = await CallApiGet(uri);
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setVhclLease(resp?.respData);
    } else {
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };
  const fetchAvlVhcl = async () => {
    setShowSpinner(true);
    const uri = "GetVhclDropdown";
    const resp = await CallApiGet(uri);
    if (
      (resp?.respCode === 200 || resp?.respCode === 0) &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setAvlVhcl(resp?.respData);
    } else {
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };
  const fetchPtrCompany = async () => {
    setShowSpinner(true);
    const uri = "GetPtrCompany";
    const resp = await CallApiGet(uri);
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData)
    ) {
      setAvlPtr(resp?.respData);
    } else {
      handleApiResponse(resp);
    }
  };
  const fetchVhclInfos = async (vhclId) => {
    setShowSpinner(true);
    const uri = "GetVhclInfoById/" + vhclId;
    let resp = await CallApiGet(uri);

    if (
      (resp.respCode === 200 || resp.respCode === 0) &&
      Array.isArray(resp?.respData) &&
      !!resp.respData[0]
    ) {
      let respVhcl = resp?.respData[0];
      const vhclImgF = {
        photo: "",
        photoFileName: respVhcl?.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGFCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      const vhclImgB = {
        photo: "",
        photoFileName: respVhcl?.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      const vhclImgL = {
        photo: "",
        photoFileName: respVhcl?.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGLCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgR = {
        photo: "",
        photoFileName: respVhcl?.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGRCODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgBI = {
        photo: "",
        photoFileName:
          respVhcl?.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBICODE,
        photoFileType: "",
        photoBucketUrl: "",
      };

      const vhclImgCI = {
        photo: "",
        photoFileName:
          respVhcl?.vhclId + "_" + VEHICLE_IMAGE_CODE_MAP.IMGCICODE,
        photoFileType: "",
        photoBucketUrl: "",
      };
      respVhcl.vhclImgF = vhclImgF;
      respVhcl.vhclImgB = vhclImgB;
      respVhcl.vhclImgL = vhclImgL;
      respVhcl.vhclImgR = vhclImgR;
      respVhcl.vhclImgCI = vhclImgCI;
      respVhcl.vhclImgBI = vhclImgBI;
      respVhcl.vhclInfos = JSON.parse(respVhcl.vhclInfos);
      setVhclInfo({ ...respVhcl });
    } else {
      setVhclInfo(Object.assign({}, initialVhclData));
      handleApiResponse(resp);
    }
    setShowSpinner(false);
  };

  const onConfirmClick = () => {
    if (apiPayload?.leaseDto?.termination) {
      handleOnTerminationConfirm();
    } else {
      onConfirm();
    }
  };

  const onConfirm = async () => {
    setShowSpinner(true);
    let payload = Object.assign({}, apiPayload);
    payload.logDto.userId = getUserInfo("userId");
    if (
      payload.logDto?.approvalStatus === REJECT_APRVL &&
      payload.logDto?.remarks?.length === 0
    ) {
      payload.logDto.error = { remarks: true };
      setApiPayload(payload);
      setShowSpinner(false);
      return;
    } else {
      payload.logDto.error = { remarks: false };
      payload.logDto.seqNo = payload.logDto.seqno;
      payload.logDto.maxSeqNo = payload.logDto.maxseqno;
      setApiPayload(payload);
    }
    const resp = await CallApiPost("OperateOnApproval", payload);
    if (resp.respCode === 200) {
      openPopup(
        true,
        "SUCCESS",
        "Thank You, Your data processed successfully!! "
      );
    } else {
      handleApiResponse(resp);
    }
    handleModalClose();
    hideConfirmAlert(false);
    setShowSpinner(false);
  };
  const handleOnChange = (event) => {
    const { name, value } = event.target;
    if (TEXT_AREA_REGEX.test(value)) {
      let copyEntity = { ...apiPayload };
      copyEntity.logDto[name] = value;
      copyEntity.logDto.error = { remarks: false };
      setApiPayload(Object.assign({}, copyEntity));
    }
  };

  const handleOnChangeTrDate = (event) => {
    let copyEntity = { ...apiPayload };
    copyEntity.logDto["terminatedOn"] = event;
    copyEntity.logDto.error = { terminatedOn: false };
    setApiPayload(Object.assign({}, copyEntity));
  };

  const getApprovalHistory = async (entityId) => {
    setShowSpinner(true);
    let approvalHistoryData = await masterApprovalHistory(entityId, 822);
    setShowSpinner(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else {
      setApprovalHistoryData([]);
      handleApiResponse(approvalHistoryData);
    }
  };

  return (
    <>
      <Layout
        spinnershow={showSpinner}
        alertConfig={alertConfig}
        hideAlert={closePopup}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">Vehicle Lease Management</div>
          </div>
          <div className="float-end d-flex align-items-center">
            {permission(VHCLLEASEREQ)?.allowedWrite && (
              <Button
                className="theme-violet"
                onClick={() => openLeaseModal(initApproval)}
              >
                {GetSvgIcon("PlusCircle")} Lease a Vehicle
              </Button>
            )}
          </div>
        </div>
        <VehicleLeaseList
          tableData={vhclLease}
          handleClipboardClick={openLeaseModal}
          handleTerminnationClick={openTerminationAlert}
          getApprovalHistory={getApprovalHistory}
        />
        <VehicleLeaseAddEdit
          modalConfig={modalConfig}
          handleModalClose={handleModalClose}
          onSave={handleSave}
          vhclOpts={avlVhcl}
          avlPtr={avlPtr}
          tableData={vhclInfo}
          fetchVhclInfos={fetchVhclInfos}
          vhclData={vhclInfo}
          setVhclInfo={setVhclInfo}
          selVhclLease={selVhclLease}
          handleOnClick={openConfirmAlert}
        />
        <ModalConfirmAlert
          confirmAlertConfig={modalConfirm}
          hideConfirmAlert={hideConfirmAlert}
          confirmAlert={onConfirmClick}
        >
          {!!apiPayload.logDto.termination &&
            apiPayload.logDto.termination === true && (
              <div className="row">
                <div className="col-md-4">
                  <label className="form-label theme-label">
                    Termination Date{""}
                    <span className="text-danger">&#9733;</span>
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <ReactDatePicker
                    placeholderText="Termination Date"
                    wrapperClassName="w-100"
                    className="form-control"
                    name="terminatedOn"
                    dateFormat={DATE_FORMAT_CLIENT}
                    showMonthYearDropdown
                    selected={
                      !!apiPayload.logDto?.terminatedOn
                        ? new Date(apiPayload.logDto?.terminatedOn)
                        : null
                    }
                    minDate={
                      !!apiPayload.logDto?.startDate
                        ? new Date(apiPayload.logDto.startDate) < new Date()
                          ? new Date()
                          : new Date(apiPayload.logDto.startDate)
                        : new Date()
                    }
                    maxDate={
                      !!apiPayload.logDto.endDate &&
                      new Date(apiPayload.logDto.endDate)
                    }
                    onChange={(d) =>
                      !!d
                        ? handleOnChangeTrDate(moment(d).format("YYYY-MM-DD"))
                        : ""
                    }
                  />

                  {!!apiPayload.logDto?.error &&
                    apiPayload.logDto?.error.terminatedOn && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; This field is required.
                        </span>
                      </div>
                    )}
                </div>
              </div>
            )}
          <div className="row">
            {apiPayload.logDto?.approvalStatus === APPROVE_APRVL ? (
              <p> Are you sure you want to approve ? </p>
            ) : (
              <>
                <div className="col-md-4">
                  <label
                    htmlFor="floatingInputGrid"
                    className="form-label theme-label"
                  >
                    Reason <span className="text-danger">&#9733;</span>
                  </label>
                </div>
                <div className="col-md-8 mb-2">
                  <input
                    id="cancellation-reason"
                    type="text"
                    className="form-control"
                    name="remarks"
                    value={apiPayload.logDto?.remarks}
                    autoComplete="false"
                    onChange={(e) => handleOnChange(e)}
                    maxLength={250}
                  />
                  {!!apiPayload.logDto?.error &&
                    apiPayload.logDto?.error.remarks && (
                      <div style={{ textAlign: "left" }}>
                        <span className="badge bg-danger">
                          &#9888; This field is required.
                        </span>
                      </div>
                    )}
                </div>
              </>
            )}
          </div>
        </ModalConfirmAlert>
        <MasterApprovalLogList
          showApprovalType={false}
          data={approvalHistoryData}
        />
      </Layout>
    </>
  );
};

export default VehicleLeaseMain;
