import moment from "moment";
import { CLAIM_ACTION, FILE_UPLOAD_TEXT, URL_REGEX } from "./Constants";
import { CallApiGet } from "../Helper/serviceApi";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { useCallback } from "react";
import XLSX from "xlsx";

const { Buffer } = require("buffer");

export const sessionRefresh = () => {
  localStorage.clear();
  window.location.redirect("/home");
};

export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const data = reader.result.toString().split(",")[1];
      resolve(data);
    };
    reader.onerror = (error) => reject(error);
  });
};

export const getFileName = (data) => {
  if (data === null) {
    return FILE_UPLOAD_TEXT;
  } else {
    if (URL_REGEX.test(data)) {
      return data.substring(data.lastIndexOf("/") + 1);
    } else {
      return data;
    }
  }
};

export function writeLocalStorage(key, value) {
  localStorage.setItem(key, value);
}
export function readLocalStorage(key) {
  const data = localStorage.getItem(key);
  return data;
}

export function validateColumns(fileData, columns) {
  let isValid = true;
  fileData.forEach((element) => {
    let errMsg = [];
    columns.forEach((col) => {
      if (!element.hasOwnProperty(col)) {
        errMsg.push(col + " is absent");
      }
    });
    if (errMsg.length > 0) {
      element.dataValid = "false";
      element.errorMessage = errMsg.toString();
      isValid = false;
    }
  });
  return isValid;
}

export const printWaybill = (data) => {
  let waybillArray = [];
  waybillArray = Array.isArray(data?.waybill_no)
    ? data?.waybill_no
    : [data?.waybill_no];
  if (waybillArray.length > 0) {
    const waybillData = {
      waybillNo: waybillArray,
      shipmentId: data?.shipmentId,
    };
    const encodedData = Buffer.from(JSON.stringify(waybillData)).toString(
      "base64"
    );
    const url = `/printWayBill/${encodedData}`;
    window.open(url, "_blank");
  }
};

export const printAWaybill = (data) => {
  let waybillArray = [];
  // waybillArray = Array.isArray(data?.waybill_no) ? data?.waybill_no : [data?.waybill_no]
  waybillArray = Array.isArray(data?.waybill_no)
    ? data?.waybill_no
    : [data.waybillNo];
  if (waybillArray.length > 0) {
    const waybillData = { waybillNo: waybillArray, shipmentId: data?.orderId };
    const encodedData = Buffer.from(JSON.stringify(waybillData)).toString(
      "base64"
    );
    const url = `/printWayBill/${encodedData}`;
    document.body.click();
    window.open(url, "_blank");
  }
};

export const trackWaybill = (data) => {
  let urlData = [];
  if (!Array.isArray(data?.waybill_no)) {
    // data?.waybill_no.forEach(obj => urlData.push({
    //   label: obj,
    //   value: obj,
    // }))
    urlData.push({
      label: data?.waybill_no,
      value: data?.waybill_no,
    });
  } else {
    urlData = data?.waybill_no.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }
  const encodedData = Buffer.from(JSON.stringify(urlData)).toString("base64");
  const url = `/trackWaybill?data=${encodedData}`;
  window.open(url, "_blank");
};

export const getKiplForm = (data, orderId) => {
  const form =
    "<br /><br /><br /><br /><center><h3 className='text-danger'>Please Wait. Payment is in progress...</h3><h5 className='text-danger'>Do not click any button or refresh the page.</h5></center><div style='display:none'><form action='" +
    data.gwConnectLink +
    "' method='post' id='onlinepayment' >	" +
    "<br />Order Date : 			<input type='text' 	readonly='readonly' name='ord_date' 		value='" +
    data.ord_date +
    "' >" +
    "		<br />Order Total : 		<input type='text' 	readonly='readonly' name='ord_totalamt' 	value='" +
    data.ord_totalamt +
    "' />" +
    "	<input type='hidden' name='ord_gstamt' 		value='" +
    data.ord_gstamt +
    "' />" +
    "		<br />Ship to Name : 		<input type='text' 	readonly='readonly' name='ord_shipname' 	value='" +
    data.ord_shipname +
    "' />" +
    "									<input type='hidden' 					name='ord_shipcountry' 	value='" +
    data.ord_shipcountry +
    "' />" +
    "		<br />Order Ref : 			<input type='text' 	readonly='readonly' name='ord_mercref' 		value='" +
    data.ord_mercref +
    "' />" +
    "									<input type='hidden' 					name='ord_telephone' 	value='' />" +
    "									<input type='hidden' 					name='ord_email' 		value='' />" +
    "									<input type='hidden' 					name='ord_delcharges' 	value='' />" +
    "									<input type='hidden' 					name='ord_svccharges' 	value='' />" +
    "									<input type='hidden' 					name='ord_mercID' 		value='" +
    data.ord_mercID +
    "' />" +
    "		<br />Hash Type : <input type='text' 	readonly='readonly' name='hash_type'	value='2'/>" +
    "		<br />Merchant Hash Value : <input type='text' 	readonly='readonly' name='merchant_hashvalue'	value='" +
    data.merchant_hashvalue +
    "' />" +
    "		<br />Return URL : 			<input type='text' 	readonly='readonly' name='ord_returnURL' 	value='" +
    data.ord_returnURL +
    "' />" +
    "		<br />Version : 		<input type='text' 	readonly='readonly' name='version' value='" +
    data.version +
    "' />" +
    "		<br />Custom Field 1 : 		<input type='text' 	readonly='readonly' name='ord_customfield1' value='" +
    orderId +
    "' />" +
    "		<br />Custom Field 2 : 		<input type='text' 	readonly='readonly' name='ord_customfield2' value='" +
    data.ord_customfield2 +
    "' />" +
    "		<br />Dynamic Callback URL : 		<input type='text' 	readonly='readonly' name='dynamic_callback_url' value='" +
    data.dynamic_callback_url +
    "' />" +
    "		<br /><input type='Submit'  value='Confirm & Pay' />" +
    "	</form></div><script>document.onlinepayment.submit();</script>";

  return form;
};

export const getOrderStatusBadgeClass = (data) => {
  let badgeClass = "";
  switch (data.status) {
    // case 75:
    //   badgeClass = 'badge bg-danger'
    //   break;
    case 80:
    case 76:
      badgeClass = getConditionalBadgeColor(data?.shipDateDiffInDays);
      break;
    case 75:
    case 77:
      badgeClass = "badge bg-secondary";
      break;
    case 86:
      badgeClass = "badge bg-warning";
      break;
    case 79:
      badgeClass = "badge bg-success";
      break;
    // case 80:
    //   badgeClass = 'badge bg-info'
    //   break;
    case 87:
      badgeClass = "badge bg-info";
      break;
    case 88:
      badgeClass = "badge bg-warning";
      break;
    default:
      badgeClass = "badge bg-light text-dark";
      break;
  }

  return badgeClass;
};

export const getOrderStatusBadgeClass2 = (data) => {
  let badgeClass = "";
  switch (data) {
    case 75:
    case 77:
      badgeClass = "badge bg-secondary";
      break;
    case 86:
      badgeClass = "badge bg-warning";
      break;
    case 79:
      badgeClass = "badge bg-success";
      break;
    case 76:
    case 87:
      badgeClass = "badge bg-info";
      break;
    case 80:
    case 88:
      badgeClass = "badge bg-warning";
      break;
    default:
      badgeClass = "badge bg-light text-dark";
      break;
  }

  return badgeClass;
};
export const getPalletStatusBadgeClass = (data) => {
  let badgeClass = "";
  if (Number(data?.palletDeliveryStatus) === 95) {
    badgeClass = "badge bg-success";
  } else if (
    Number(data?.palletDeliveryStatus) === 189 ||
    Number(data?.palletDeliveryStatus) === 201
  ) {
    badgeClass = "badge bg-secondary";
  } else {
    badgeClass = getConditionalBadgeColor(data?.shipDateDiffInDays);
    // badgeClass = 'badge bg-success'
  }
  return badgeClass;
};

export const getPalletStatusBadgeClassNew = (data) => {
  let badgeClass = "";
};

export const getProblemStatusBadgeClass = (data) => {
  let badgeClass = "";
  if (Number(data?.problem_status_code) === 403) {
    //Resolved
    badgeClass = "badge bg-success";
  } else if (
    Number(data?.problem_status_code) === 401 ||
    Number(data?.problem_status) === 0
  ) {
    //Open or Unknown
    badgeClass = "badge bg-danger";
  } else {
    badgeClass = "badge bg-warning";
  }
  return badgeClass;
};

const getConditionalBadgeColor = (diffDays) => {
  let badgeClass = "";
  if (Number(diffDays) === 0) {
    badgeClass = "badge bg-warning";
  } else if (Number(diffDays) < 0) {
    badgeClass = "badge bg-danger";
  } else if (Number(diffDays) > 0) {
    badgeClass = "badge bg-primary";
  }
  return badgeClass;
};

export const customDateSort = (dtA, dtB) => {
  return moment(dtA).diff(moment(dtB));
};

export const approvalHistory = async (profileId, userType) => {
  return await CallApiGet(`get-approval-log/${profileId}/${userType}`);
};

export const masterApprovalHistory = async (entityId, approvalType) => {
  return await CallApiGet(
    `get-master-approval-log/${entityId}/${approvalType}`
  );
};

export const deliveryEventlog = async (wb) => {
  return await CallApiGet(`getWBEventLog/${wb}`);
};

export const deliveryEventlogChartered = async (orderId) => {
  return await CallApiGet(`getCharteredEventLog/${orderId}`);
};

export const getLogs = async (tableName, tableId) => {
  return await CallApiGet(
    "get-audit-data?tableName=" + tableName + "&tableId=" + tableId
  );
};

export const isAuthorized = (argFeatCode) => {
  const userPermissions = JSON.parse(getUserInfo("userPermissions"));
  const feature = userPermissions?.find((up) => up.featureCode === argFeatCode);
  return !!feature ? feature?.allowedRead : false;
};
export const permission = (argFeatCode) => {
  const userPermissions = JSON.parse(getUserInfo("userPermissions"));
  const feature = userPermissions?.find((up) => up.featureCode === argFeatCode);
  return !!feature
    ? feature
    : { allowedRead: false, allowedWrite: false, allowedDelete: false };
};

export const permissionCheck = (argFeatCode, permissionText) => {
  const userPermissions = JSON.parse(getUserInfo("userPermissions"));
  return userPermissions?.find(
    (up) => up.featureCode === argFeatCode && up[permissionText]
  );
};

/**
 * The function retrieves address book data for a specific customer ID and sets it as the address
 * book while also setting a flag indicating the availability of the address book.
 */
export const fetchAddressBook = async () => {
  let addrBook = {};
  addrBook.addressBook = [];
  addrBook.isAddrsBookAvl = false;
  if (!!getUserInfo("userId")) {
    var uid = !!getUserInfo("userId") ? getUserInfo("userId") : 0;
    const uri = "CustAddrBookList?custId=" + uid;
    let addrData = await CallApiGet(uri);
    if (addrData.respCode === 200) {
      addrBook.addressBook = addrData.respData;
      addrBook.isAddrsBookAvl = true;
    }
  }

  return addrBook;
};

export const decodeUrlData = (encodedData) => {
  const decodedBuffer = Buffer.from(encodedData, "base64");
  const decodedString = decodedBuffer.toString("utf-8");
  return JSON.parse(decodedString);
};

export const getActionTitle = (argAction) => {
  let title = "";
  switch (argAction) {
    case CLAIM_ACTION.RejectClaim:
      title = "REJECT CLAIM";
      break;
    case CLAIM_ACTION.ApproveClaim:
      title = "APPROVE CLAIM";
      break;
    case CLAIM_ACTION.InformationRequest:
      title = "REQUEST MORE INFO";
      break;
    case CLAIM_ACTION.InformationSubmitted:
      title = "INFORMATION SUBMISSION";
      break;
    case CLAIM_ACTION.AssignedToOperation:
      title = "ASSIGN TO OPERATIONS";
      break;
    case CLAIM_ACTION.AcknowledgeAndFillIncidentReport:
      title = "ACKNOWLEDGEMENT";
      break;
    case CLAIM_ACTION.MentionCustomerPartnerFault:
      title = "OTHER PARTY FAULT";
      break;
    case CLAIM_ACTION.OtherOperationTeamFault:
      title = "ASSIGN TO OTHER OPERATION";
      break;
    case CLAIM_ACTION.FixClaimAmount:
      title = "UPDATE CLAIM AMOUNT";
      break;
    case CLAIM_ACTION.AcceptClaimAmount:
      title = "ACCEPT CLAIM AMOUNT";
      break;
    case CLAIM_ACTION.AppealForClaimAmount:
      title = "APPEAL FOR CLAIM AMOUNT";
      break;
    case CLAIM_ACTION.ProcessInsurance:
      title = "PROCESS INSURANCE";
      break;
    case CLAIM_ACTION.ProcessPayment:
      title = "PROCESS PAYMENT";
      break;
    case CLAIM_ACTION.ReqMoreInfoToOp:
      title = "REQUEST MORE INFORMATION TO OPERATOR";
      break;
    case CLAIM_ACTION.ApproveByClaimOfficer:
      title = "APPROVE";
      break;
    default:
      title = "";
      break;
  }
  return title;
};

export const downloadFiles = (files) => {
  new Promise((resolve) => {
    const temporaryDownloadLink = document.createElement("a");
    temporaryDownloadLink.style.display = "none";
    document.body.appendChild(temporaryDownloadLink);
    resolve(temporaryDownloadLink);
  })
    .then((temporaryDownloadLink) => {
      // return new Promise((resolve) => {});
      const downloadPromises = [];
      files.forEach((element, index) => {
        const downloadPromise = new Promise((resolve) => {
          setTimeout(() => {
            const fileName = element?.filename.split("/").pop();
            temporaryDownloadLink.setAttribute("href", element?.filename);
            temporaryDownloadLink.setAttribute(
              "download",
              fileName + "_" + index
            );
            temporaryDownloadLink.click();
            resolve(temporaryDownloadLink);
          }, 1000 * index);
        });
        downloadPromises.push(downloadPromise);
      });
      return Promise.all(downloadPromises);
    })
    .then(([temporaryDownloadLink]) => {
      document.body.removeChild(temporaryDownloadLink);
    });
};

export const exportToExcel = (data, columns, fileName) => {
  const dataToExport = getDataForExport(data, columns);
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(dataToExport);
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  // if(!!header)
  // XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });
  XLSX.writeFile(wb, fileName);
};

const getDataForExport = (data, columns) => {
  let filteredData = [];
  data.forEach((row) => {
    let dataObj = {};
    columns.forEach((c) => {
      if (!c.omit || (!!c.omit && c.omit === false)) {
        if (!!c.selector) {
          if (!!c.name.props) dataObj[c.name.props.children] = c.selector(row);
          else dataObj[c.name] = c.selector(row);
        }
      }
    });
    filteredData.push(dataObj);
  });
  return filteredData;
};

export const createExcelFromJson = (data, fileName) => {
  var wb = XLSX.utils.book_new(),
    ws = XLSX.utils.json_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Data");
  XLSX.writeFile(wb, fileName);
};

export const openOrderDtls = (type, orderId) => {
  const url = `/orderDetails?data=${orderId}`;
  window.open(url, "_blank");
};

// Helper function to check if an object with the same properties exists in the set
export function isObjectInSet(set, obj) {
  for (let item of set) {
    if (JSON.stringify(item) === JSON.stringify(obj)) {
      return true;
    }
  }
  return false;
}

export const cleanNewLine = (argStr) => {
  if (typeof argStr === "string") {
    return argStr.replace(/\s+/g, " ").replace(/"/g, " ");
  } else if (argStr === undefined || argStr === null) {
    return "";
  } else {
    return argStr;
  }
};

export const getRandomInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const binarySearchArrOfObj = (arr, targetKey, targetValue) => {
  let left = 0;
  let right = arr.length - 1;

  while (left <= right) {
    let mid = Math.floor((left + right) / 2);

    if (caseInsensitiveRegex(arr[mid][targetKey], targetValue)) {
      // Element found, find all occurrences
      let resultArray = [arr[mid]];
      let leftPointer = mid - 1;
      let rightPointer = mid + 1;

      // Search to the left
      while (
        leftPointer >= 0 &&
        caseInsensitiveRegex(arr[leftPointer][targetKey], targetValue)
      ) {
        resultArray.unshift(arr[leftPointer]);
        leftPointer--;
      }

      // Search to the right
      while (
        rightPointer < arr.length &&
        caseInsensitiveRegex(arr[rightPointer][targetKey], targetValue)
      ) {
        resultArray.push(arr[rightPointer]);
        rightPointer++;
      }

      return resultArray;
    } else if (arr[mid][targetKey] < targetValue) {
      left = mid + 1; // Target is on the right half
    } else {
      right = mid - 1; // Target is on the left half
    }
  }

  return []; // Element not found
};

export const cropToTwoDeciaml = (value) => {
  let str = value;
  if (typeof value !== "string") {
    str = value.toString();
  }
  let match = str.match(/^-?\d+(\.\d{0,2})?/);
  return match ? match[0] : str;
};

export const caseInsensitiveRegex = (str, substr) => {
  let pattern = new RegExp(substr, "i");
  return pattern.test(str);
};
export const coalesce = (arg1, arg2) => {
  return [null, undefined, ""].includes(arg1) ? arg2 : arg1;
};
