import React, { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import Select from "react-select";
import { customReactSelectMenuStyle } from "../Helper/Constants";

const AutoAssignAdd = ({
  modalConfig,
  onHide,
  onSubmit,
  orderTypeOpts,
  priorityOpts,
  selDriverGeofence,
  geofenceBySbu,
  geofenceDataMap,
  driverArr,
  srvcArr,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: selDriverGeofence });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "",
  });

  const [cityArr, setCityArr] = useState([]);
  const [postcodeArr, setPostcodeArr] = useState([]);
  const [initialDriverValues, setInitialDriverValues] = useState([]);

  useEffect(() => {
    if (!!selDriverGeofence.sbuCode && selDriverGeofence.sbuCode !== "")
      updateCityPost(selDriverGeofence.sbuCode);
    reset(Object.assign({}, selDriverGeofence));
    setInitialDriverValues(selDriverGeofence.driverId);
  }, [selDriverGeofence]);

  const updateCityPost = (sbu) => {
    if (!geofenceDataMap[sbu]) {
      // Default to empty arrays if no data found for the SBU
      setCityArr([]);
      setPostcodeArr([]);
      setValue("city", []);
      setValue("postcode", []);
      return;
    }

    const sbuCities = geofenceDataMap[sbu].city || [];
    const cityOptions = sbuCities.map((city) => ({
      value: city,
      label: city,
    }));

    const sbuPostcodes = geofenceDataMap[sbu].postcode || [];
    const postcodeOptions = sbuPostcodes.map((postcode) => ({
      value: postcode,
      label: postcode,
    }));
    setValue(
      "city",
      sbuCities.map((city) => city)
    );
    setValue(
      "postcode",
      sbuPostcodes.map((postcode) => postcode)
    );
    setCityArr(cityOptions);
    setPostcodeArr(postcodeOptions);
  };

  const handleModalClose = () => {
    reset();
    onHide();
    setCityArr([]);
    setPostcodeArr([]);
  };

  const onError = (err) => {
    console.error(err);
  };

  const styles = {
    multiValueRemove: (base, state) => {
      // Hide the remove button (cross icon) for initial values (those that exist in initialDriverValues)
      return initialDriverValues.includes(state.data.value)
        ? { ...base, display: "none" }
        : base;
    },
    multiValue: (base, state) => {
      // Optionally customize the background color for initial selected values
      return initialDriverValues.includes(state.data.value)
        ? { ...base, backgroundColor: "gray" }
        : base;
    },
    multiValueLabel: (base, state) => {
      // Optionally customize the label style for initial selected values
      return initialDriverValues.includes(state.data.value)
        ? { ...base, fontWeight: "bold", color: "white" }
        : base;
    },
  };

  return (
    <Modal
      show={modalConfig.show}
      onHide={handleModalClose}
      backdrop="static"
      keyboard={false}
      size="xl"
    >
      <Modal.Header closeButton onClick={handleModalClose}>
        <Modal.Title id="contained-modal-title-vcenter">
          <h5 className="modal-title section-title form-label">
            {modalConfig?.title}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
            <Col md={6}>
              <label className="form-label theme-label">SBU</label>
              <span className="text-danger">&#9733;</span>
              <Controller
                name="sbuCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!!!",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={geofenceBySbu}
                    placeholder="Select SBU"
                    value={
                      value && value.length > 0
                        ? geofenceBySbu.filter((opt) =>
                            value.includes(opt.value)
                          )
                        : []
                    }
                    onChange={(opt) => {
                      const selectedSbu = opt ? [opt.value] : [];
                      onChange(selectedSbu);
                      updateCityPost(selectedSbu[0]);
                    }}
                    className="col-md-4 form-control p-0"
                    isSearchable={true}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    styles={customReactSelectMenuStyle}
                    isDisabled={!!selDriverGeofence?.sbuCode}
                  />
                )}
              />
              {errors.sbuCode && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.sbuCode.message}
                  </span>
                </div>
              )}
            </Col>
            <Col md={6}>
              <label className="form-label theme-label">Priority</label>
              <span className="text-danger">&#9733;</span>
              <Controller
                name="priority"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!!!",
                  },
                  min: {
                    value: 1,
                    message: "This field is required!!!",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={priorityOpts}
                    placeholder="Select Priority"
                    value={
                      value === ""
                        ? value
                        : priorityOpts.find((opt) => opt.value === value)
                    }
                    onChange={(val) => {
                      onChange(val ? val.value : "");
                    }}
                    className="col-md-4 form-control p-0"
                    isSearchable={true}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    styles={customReactSelectMenuStyle}
                    isDisabled={!!selDriverGeofence?.priority}
                  />
                )}
              />
              {errors.priority && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.priority.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="form-label theme-label">Driver</label>
              <span className="text-danger">&#9733;</span>
              <Controller
                name="driverId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!!!",
                  },
                  min: {
                    value: 1,
                    message: "This field is required!!!",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={driverArr}
                    value={driverArr?.filter((el) => value?.includes(el.value))}
                    // onChange={(opts) =>
                    //   onChange(opts ? opts.map((opt) => opt.value) : [])
                    // }
                    onChange={(opts) => {
                      // Get selected options and filter out the unremovable initial ones
                      const selectedValues = opts
                        ? opts.map((opt) => opt.value)
                        : [];

                      // Merge initial values with new selected ones, ensuring initial values remain
                      const finalValues = [
                        ...new Set([...initialDriverValues, ...selectedValues]),
                      ];
                      onChange(finalValues);
                    }}
                    placeholder="Select Driver"
                    isSearchable={true}
                    isClearable={false}
                    className="form-control p-0"
                    isMulti={true}
                    styles={styles}
                  />
                )}
              />
              {errors.driverId && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.driverId.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <label className="form-label theme-label">Service</label>
                <span className="text-danger">&#9733;</span>
                <Controller
                  name="ordService"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!!!",
                    },
                    min: {
                      value: 1,
                      message: "This field is required!!!",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={srvcArr}
                      placeholder="Select Service"
                      value={
                        value === ""
                          ? value
                          : srvcArr.filter((el) => value?.includes(el.value))
                      }
                      onChange={(opts) =>
                        onChange(opts ? opts.map((opt) => opt.value) : [])
                      }
                      className="col-md-4 form-control p-0"
                      isSearchable={true}
                      isClearable={true}
                      menuPortalTarget={document.body}
                      styles={customReactSelectMenuStyle}
                      isMulti={true}
                    />
                  )}
                />
                {errors.ordService && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.ordService.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label className="form-label theme-label">Order Type</label>
                <span className="text-danger">&#9733;</span>
                <Controller
                  name="orderType"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required!!!",
                    },
                    min: {
                      value: 1,
                      message: "This field is required!!!",
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={orderTypeOpts}
                      placeholder="Select Order Type"
                      value={
                        value === ""
                          ? value
                          : orderTypeOpts.filter((el) =>
                              value?.includes(el.value)
                            )
                      }
                      onChange={(opts) =>
                        onChange(opts ? opts.map((opt) => opt.value) : [])
                      }
                      className="col-md-4 form-control p-0"
                      isSearchable={true}
                      isClearable={true}
                      menuPortalTarget={document.body}
                      styles={customReactSelectMenuStyle}
                      isMulti={true}
                    />
                  )}
                />
                {errors.orderType && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.orderType.message}
                    </span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="form-label theme-label">City</label>
              <span className="text-danger">&#9733;</span>
              <Controller
                name="city"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!!!",
                  },
                  min: {
                    value: 1,
                    message: "This field is required!!!",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={cityArr}
                    placeholder="Select City"
                    value={cityArr.filter((opt) => value?.includes(opt.value))}
                    onChange={(opts) => {
                      onChange(opts ? opts.map((opt) => opt.value) : []);
                    }}
                    className="col-md-4 form-control p-0"
                    isSearchable={true}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    styles={customReactSelectMenuStyle}
                    isMulti={true}
                  />
                )}
              />
              {errors.city && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.city.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <label className="form-label theme-label">Postcode</label>
              <span className="text-danger">&#9733;</span>
              <Controller
                name="postcode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: "This field is required!!!",
                  },
                  min: {
                    value: 1,
                    message: "This field is required!!!",
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <Select
                    options={postcodeArr}
                    placeholder="Select Postcode"
                    value={
                      value === ""
                        ? value
                        : postcodeArr?.filter((opt) =>
                            value?.includes(opt.value)
                          )
                    }
                    onChange={(opts) => {
                      onChange(opts ? opts.map((opt) => opt.value) : []);
                    }}
                    className="col-md-4 form-control p-0"
                    isSearchable={true}
                    isClearable={true}
                    menuPortalTarget={document.body}
                    styles={customReactSelectMenuStyle}
                    isMulti={true}
                  />
                )}
              />
              {errors.postcode && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.postcode.message}
                  </span>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer style={{ justifyContent: "right" }}>
        <Button
          variant="primary"
          className="theme-violet"
          onClick={handleSubmit(onSubmit, onError)}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutoAssignAdd;
