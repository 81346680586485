import { useEffect } from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  BTN_TYPE_EDIT,
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
// import { GetBase64Img } from "../Helper/FileStoreService";
import {
  LEASEHISTORY,
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
} from "../../ConfigConstants/ConfigConstants";
import { Tooltip } from "react-tooltip";
import { permissionCheck } from "../Helper/CommonMethods";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import moment from "moment";

const VehicleList = (props) => {
  const {
    vehicleList,
    isVhclLoaded,
    fetchVehicles,
    fetchVhclInfos,
    setVhclData,
    setBtnType,
    toggleAlert,
    handleActiveDeactive,
    setVhclId,
    downloadVhclQr,
    fetchLeaseHistory,
    handleOnClick,
    activeKey,
  } = props;

  useEffect(() => {
    fetchVehicles();
  }, []);

  const userProfileId =
    LINEHAUL_PARTNER_COMPANY_USER_TYPE === getUserInfo("userType")
      ? getUserInfo("acctId")
      : 0;

  // const handleOnClick = (data) => {
  //   const {
  //     id,
  //     sbuId,
  //     vhclTypeId,
  //     vhclNo,
  //     vhclOwner,
  //     assignTo,
  //     transferSbu,
  //     transferFrom,
  //     transferTo,
  //     status,
  //     statusVal,
  //   } = data;
  //   const vhclImgF = {
  //     photo: "",
  //     photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGFCODE,
  //     photoFileType: "",
  //     photoBucketUrl: "",
  //   };
  //   const vhclImgB = {
  //     photo: "",
  //     photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBCODE,
  //     photoFileType: "",
  //     photoBucketUrl: "",
  //   };
  //   const vhclImgL = {
  //     photo: "",
  //     photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGLCODE,
  //     photoFileType: "",
  //     photoBucketUrl: "",
  //   };

  //   const vhclImgR = {
  //     photo: "",
  //     photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGRCODE,
  //     photoFileType: "",
  //     photoBucketUrl: "",
  //   };

  //   const vhclImgBI = {
  //     photo: "",
  //     photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBICODE,
  //     photoFileType: "",
  //     photoBucketUrl: "",
  //   };

  //   const vhclImgCI = {
  //     photo: "",
  //     photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGCICODE,
  //     photoFileType: "",
  //     photoBucketUrl: "",
  //   };

  //   setVhclData({
  //     vhclId: id,
  //     vhclType: vhclTypeId,
  //     vhclNo: vhclNo,
  //     vhclOwner: vhclOwner,
  //     assignTo: assignTo,
  //     allocatedTo: sbuId,
  //     transferSbu: transferSbu,
  //     transferFrom: transferFrom,
  //     transferTo: transferTo,
  //     vhclLength: data?.vhclLength,
  //     bdm: data?.bdm,
  //     btm: data?.btm,
  //     tailGate: data?.tailGate,
  //     bondedType: data?.bondedType,
  //     vhclImgF: vhclImgF,
  //     vhclImgB: vhclImgB,
  //     vhclImgL: vhclImgL,
  //     vhclImgR: vhclImgR,
  //     vhclImgCI: vhclImgCI,
  //     vhclImgBI: vhclImgBI,
  //     status: status,
  //     statusVal: statusVal,
  //     vhclInfos: [],
  //   });
  //   fetchVhclInfos(id);
  // };

  const getStatusBadge = (argStatusId, argStatusVal, additionalInfo = "") => {
    switch (argStatusId) {
      case 852:
        return (
          <Badge bg="success" className="text-wrap">{`${
            activeKey === "L" ? "Leased" : argStatusVal
          }${additionalInfo ? ` (${additionalInfo})` : ""}`}</Badge>
        );
      case 858:
        return (
          <Badge bg="success" className="text-wrap">{`${argStatusVal}${
            additionalInfo ? ` (${additionalInfo})` : ""
          }`}</Badge>
        );
      case 856:
      case 855:
      case 853:
        return (
          <Badge bg="danger" className="text-wrap">{`${argStatusVal}${
            additionalInfo ? ` (${additionalInfo})` : ""
          }`}</Badge>
        );
      case 851:
      case 854:
        return (
          <Badge bg="info" className="text-wrap">{`${argStatusVal}${
            additionalInfo ? ` (${additionalInfo})` : ""
          }`}</Badge>
        );
      case 857:
        return (
          <Badge bg="warning" className="text-wrap">{`${argStatusVal}${
            additionalInfo ? ` (${additionalInfo})` : ""
          }`}</Badge>
        );
      default:
        return (
          <Badge bg="light" className="text-wrap">{`${argStatusVal}${
            additionalInfo ? ` (${additionalInfo})` : ""
          }`}</Badge>
        );
    }
  };

  const columns = [
    {
      name: "Vehicle Type",
      selector: (row) => row.vhclTypeName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle No",
      selector: (row) => row.vhclNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Owner",
      selector: (row) => row.vhclOwner,
      sortable: true,
      filterable: true,
    },
    {
      name: activeKey === "L" ? "Partner" : "Assigned To",
      selector: (row) =>
        activeKey === "L" ? row?.leasedVhclPartner : row?.assignToVal,
      sortable: true,
      filterable: true,
      omit: userProfileId > 0,
    },
    {
      name: "Allocated To",
      selector: (row) => row.sbuCode,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Transfer SBU",
    //   selector: (row) => (row.transferSbuCode ? row.transferSbuCode : "NA"),
    //   sortable: true,
    //   filterable: true,
    // },

    {
      name: "Lease Start Date",
      selector: (row) =>
        row.leaseStart
          ? moment(row.leaseStart, "YYYY-MM-DD", true).format(
              DATE_FORMAT_MOMENT
            )
          : "NA",
      sortable: true,
      filterable: true,
      omit: ["P", "I"].includes(activeKey),
    },
    {
      name: "Lease End Date",
      selector: (row) =>
        row.leaseEnd
          ? moment(row.leaseEnd, "YYYY-MM-DD", true).format(DATE_FORMAT_MOMENT)
          : "NA",
      sortable: true,
      filterable: true,
      omit: ["P", "I"].includes(activeKey),
    },
    {
      name: "Status",
      selector: (row) => row?.statusVal,
      filterable: true,
      omit: activeKey === "I",
      cell: (row) => getStatusBadge(row?.status, row?.statusVal),
    },
    {
      name: "Active",
      selector: (row) => (row.active ? "YES" : "NO"),
      filterable: true,
      omit: ["P", "L"].includes(activeKey),
      conditionalCellStyles: [
        {
          when: (row) => row.active === true,
          style: {
            color: "green",
          },
        },
        {
          when: (row) => row.active === false,
          style: {
            color: "red",
          },
        },
      ],
      width: "8%",
    },
    {
      name: "",
      cell: (row, index) => (
        <>
          <OverlayTrigger
            trigger="click"
            key={index}
            placement="left"
            rootClose
            overlay={
              <Popover id={`popover-positioned-lefft`}>
                <Popover.Body>
                  <div
                    className="action-item-button"
                    data-bs-toggle="modal"
                    data-bs-target="#vehicle-add-edit-modal"
                    data-tooltip-id={"edit-" + row.id}
                    onClick={() => {
                      setBtnType(BTN_TYPE_EDIT);
                      handleOnClick(row);
                      document.body.click();
                    }}
                  >
                    {GetSvgIcon("EditPencil")} Edit
                  </div>
                  <Tooltip
                    id={"edit-" + row.id}
                    content={"Edit"}
                    place="left"
                  />
                  {LINEHAUL_PARTNER_COMPANY_USER_TYPE !==
                    getUserInfo("userType") &&
                    activeKey === "I" && (
                      <>
                        <div
                          className="action-item-button"
                          data-tooltip-id={"vhcl-" + row.id}
                          onClick={() => {
                            handleActiveDeactive(row.active, row.vhclNo);
                            setVhclId(row.id);
                            toggleAlert(true);
                            document.body.click();
                          }}
                        >
                          {row.active === true
                            ? GetSvgIcon("BixCircle")
                            : GetSvgIcon("BiCheck2Circle")}{" "}
                          {row.active ? "Deactivate" : "Activate"}
                        </div>
                        <Tooltip
                          id={"vhcl-" + row.id}
                          content={
                            row.active === true
                              ? "Deactivate " + row.vhclNo
                              : "Activate " + row.vhclNo
                          }
                          place="left"
                        />
                      </>
                    )}

                  {permissionCheck(LEASEHISTORY, "allowedRead") && (
                    <>
                      <div
                        className="action-item-button"
                        onClick={() => {
                          fetchLeaseHistory(row.id);
                          document.body.click();
                        }}
                        data-tooltip-id={"lease-history-" + row.id}
                        data-bs-toggle="modal"
                        data-bs-target="#lease-history-modal"
                      >
                        {GetSvgIcon("BiBoxArrowInLeft")} Lease History
                      </div>
                      <Tooltip
                        id={"lease-history-" + row.id}
                        content={"Lease History"}
                        place="left"
                      />
                    </>
                  )}
                </Popover.Body>
              </Popover>
            }
          >
            <span className="row-action-button">
              {GetSvgIcon("Trippleverticaldot")}
            </span>
          </OverlayTrigger>
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="theme-violet-txt ps-2"
            onClick={() => {
              downloadVhclQr(row);
            }}
            data-tooltip-id={"qr-" + row.id}
          >
            {GetSvgIcon("Qrcode")}
          </div>
          <Tooltip id={"qr-" + row.id} content={"Download QR"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];

  return (
    <div>
      <div className="col-md-12 customized-data-table position-relative">
        {isVhclLoaded ? (
          <DataTable
            columns={columns}
            data={vehicleList}
            pagination
            striped
            highlightOnHover
            customStyles={customDataTableStyles}
            paginationRowsPerPageOptions={defaultRowsPerPageOptions}
            paginationPerPage={defaultPageLimit}
          />
        ) : (
          <div id="noRecId">
            <span>
              <center>
                <b>No Record Found</b>
              </center>
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleList;
