import moment from "moment";
import {
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
  DD_MM_YYYY_REGEX,
  NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR,
  TWO_DECIMAL,
} from "../Helper/Constants";
import UploadDataFile from "../Helper/DataUploadUtil";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  VHCL_OWNER_LH,
  VHCL_OWNER_PARTNER,
} from "../../ConfigConstants/ConfigConstants";

const vehicleColumns = [
  "Vehicle Type",
  "Vehicle No",
  "Owner",
  "Assign To",
  "Primary SBU",
  "Vehicle Length(ft)",
  "BDM(kg)",
  "BTM(kg)",
  "Tail Gate",
  "Bonded Type",
  "Road Tax Expiry(dd-mm-yyyy)",
  "Puspakom Expiry(dd-mm-yyyy)",
  "Insurance Expiry(dd-mm-yyyy)",
  "Permit Expiry(dd-mm-yyyy)",
];
const UploadVehicle = (props) => {
  let { onSuccess, onUploadErr, dropdownData } = props;
  const template = require(`../../assets/templates/InHouseVehicleUploadTemplate.xlsx`);
  const partnerTemplate = require(`../../assets/templates/PartnerVehicleUploadTemplate.xlsx`);
  const isPartnerCompany =
    getUserInfo("userType") === LINEHAUL_PARTNER_COMPANY_USER_TYPE;

  const validateVehicleData = (fileData) => {
    let isValid = true;
    fileData.forEach((row) => {
      let errMsg = [];
      vehicleColumns.forEach((col) => {
        validateEachCol(col, errMsg, row);
      });
      if (errMsg.length > 0) {
        row.dataValid = false;
        row.errorMessage = errMsg.toString();
        isValid = false;
      }
    });
    return isValid;
  };
  const validateEachCol = (col, errMsg, row) => {
    switch (col) {
      case "Primary SBU":
        if (
          !row.hasOwnProperty(col) &&
          !isPartnerCompany &&
          [null, undefined, ""].includes(row[col])
        ) {
          errMsg.push(col + " is absent");
        }
        break;
      case "Vehicle Type":
      case "Vehicle No":
      case "Owner":
        if (
          !row.hasOwnProperty(col) &&
          [null, undefined, ""].includes(row[col])
        ) {
          errMsg.push(col + " is absent");
        }
        break;
      case "Vehicle Length(ft)":
      case "BDM(kg)":
      case "BTM(kg)":
        if (!TWO_DECIMAL.test(row[col])) {
          errMsg.push(col + "Accept numeric characters only!!!");
        }
        break;
      case "Road Tax No":
        if (!!row[col]) {
          if (!NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + ": contains some invalid character ");
          } else if (!!row[col]) {
            if (row["Road Tax Expiry(dd-mm-yyyy)"] === undefined) {
              errMsg.push("Road Tax Expiry(dd-mm-yyyy) provide the date");
            }
          }
        }
        break;

      case "Road Tax Expiry(dd-mm-yyyy)":
        if (!!row[col]) {
          // if (row["Road Tax No"] === undefined) {
          //   errMsg.push("Road Tax No. should not be blank");
          // }
          if (!!row[col]) {
            if (!DD_MM_YYYY_REGEX.test(row[col])) {
              errMsg.push(col + " :Invalid date format ");
            } else {
              const today = moment().startOf("day");
              const inpDt = moment(row[col], DATE_FORMAT_MOMENT, true).format(
                DATE_FORMAT_SERVER
              );
              if (moment(inpDt).isBefore(today)) {
                errMsg.push(col + ": is in the past ");
              }
            }
          }
        }
        break;

      case "Puspakom No":
        if (!!row[col]) {
          if (!NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + ": contains some invalid character ");
          } else if (!!row[col]) {
            if (row["Puspakom Expiry(dd-mm-yyyy)"] === undefined) {
              errMsg.push("Puspakom Expiry(dd-mm-yyyy) provide the date");
            }
          }
        }
        break;

      case "Puspakom Expiry(dd-mm-yyyy)":
        if (!!row[col]) {
          // if (row["Puspakom No"] === undefined) {
          //   errMsg.push("Puspakom No should not be blank");
          // }
          if (!!row[col]) {
            if (!DD_MM_YYYY_REGEX.test(row[col])) {
              errMsg.push(col + " :Invalid date format ");
            } else {
              const today = moment().startOf("day");
              const inpDt = moment(row[col], DATE_FORMAT_MOMENT, true).format(
                DATE_FORMAT_SERVER
              );
              if (moment(inpDt).isBefore(today)) {
                errMsg.push(col + ": is in the past ");
              }
            }
          }
        }
        break;
      case "Insurance No":
        if (!!row[col]) {
          if (!NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
            errMsg.push(col + ": contains some invalid character ");
          } else if (!!row[col]) {
            if (row["Insurance Expiry(dd-mm-yyyy)"] === undefined) {
              errMsg.push("Insurance Expiry(dd-mm-yyyy) provide the date");
            }
          }
        }
        break;
      case "Insurance Expiry(dd-mm-yyyy)":
        if (!!row[col]) {
          // if (row["Insurance No"] === undefined) {
          //   errMsg.push("Insurance No should not be blank");
          // }
          if (!!row[col]) {
            if (!DD_MM_YYYY_REGEX.test(row[col])) {
              errMsg.push(col + " :Invalid date format ");
            } else {
              const today = moment().startOf("day");
              const inpDt = moment(row[col], DATE_FORMAT_MOMENT, true).format(
                DATE_FORMAT_SERVER
              );
              if (moment(inpDt).isBefore(today)) {
                errMsg.push(col + ": is in the past ");
              }
            }
          }
        }
        break;

      default:
        if (!NON_EMPTY_ALPHANUM_SPACE_SPCL_CHAR.test(row[col])) {
          errMsg.push(col + " contains some invalid character ");
        }
        break;
    }
  };
  
  const postExcelFileRead = (fileData) => {
    const fileDataOut = [];
    fileData.map((row) => {
      let rowOut = {};
      rowOut.truckType = row["Vehicle Type"];
      rowOut.plateNo = row["Vehicle No"];
      rowOut.owner = row["Owner"];
      rowOut.allocatedTo = row["Primary SBU"];
      if (isPartnerCompany) {
        rowOut.assignTo = VHCL_OWNER_PARTNER;
      } else if (!!row["Assign To"]) {
        let assgnToOpt = dropdownData.vhclOwnerOpts.find(
          (own) => own.label === row["Assign To"]
        );
        rowOut.assignTo = assgnToOpt?.value;
      } else {
        rowOut.assignTo = VHCL_OWNER_LH;
      }
      let bondedTypeOpt = dropdownData.bondedTypeOpts.find(
        (bto) => bto.label === row["Bonded Type"]
      );
      rowOut.bondedType = bondedTypeOpt?.value;
      rowOut.vhclLength = row["Vehicle Length(ft)"];
      rowOut.bdm = row["BDM(kg)"];
      rowOut.btm = row["BTM(kg)"];
      rowOut.tailGate = row["Tail Gate"] === "NO" ? false : true;
      // rowOut.roadTaxNo = row["Road Tax No"];
      rowOut.roadTaxExpy = row["Road Tax Expiry(dd-mm-yyyy)"];
      // rowOut.puspakomNo = row["Puspakom No"];
      rowOut.puspakomExpy = row["Puspakom Expiry(dd-mm-yyyy)"];
      // rowOut.insuranceNo = row["Insurance No"];
      rowOut.insuranceExpy = row["Insurance Expiry(dd-mm-yyyy)"];
      rowOut.permitExpy = row["Permit Expiry(dd-mm-yyyy)"];
      fileDataOut.push(rowOut);
      return row;
    });
    return fileDataOut;
  };

  return (
    <UploadDataFile
      dataUploadUrl="upload-vehicle-excel-data"
      cbValidateData={validateVehicleData}
      cbPreUpload={postExcelFileRead}
      onSuccess={onSuccess}
      template={isPartnerCompany ? partnerTemplate : template}
      downloadFileName="VehicleUploadTemplate.xlsx"
      onError={onUploadErr}
    />
  );
};

export default UploadVehicle;
