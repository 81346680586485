import React, { useEffect } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useFieldArray, useForm } from "react-hook-form";
import { getRandomInteger, toBase64 } from "../Helper/CommonMethods";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import { initManfObj } from "../Inbound Order/InboundManifestEdit";

const CrossBorderManifestEdit = ({
  show,
  handleClose,
  selDropPoint,
  manfData,
}) => {
  const {
    register,
    reset,
    control,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: initManfObj,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "attachments",
  });

  useEffect(() => {
    reset(manfData);
  }, [manfData]);

  const handleAttch = async (event, attachId, attachType, key) => {
    const file = event.target.files;
    const photo = await toBase64(file[0]);
    const argFileName = file[0].name;
    var ext = argFileName.substring(argFileName.lastIndexOf(".") + 1);
    const locShipNo = getValues("shipmentNo");
    const fileName = !!locShipNo
      ? locShipNo.substring(0, 10)
      : "SHIPNO" +
        "_" +
        attachType +
        "_" +
        getRandomInteger(1, 10) +
        "_" +
        moment().format("YYYYMMDD") +
        moment().milliseconds() +
        "." +
        ext;

    if (!!photo) {
      let attchObj = {};
      attchObj.refId = getValues("manifestId");
      attchObj.attachName = fileName;
      attchObj.attchBase64 = photo;
      attchObj.attachUrl = "";
      attchObj.attachId = !!attachId ? attachId : 0;
      attchObj.attachType = !!attachType ? 701 : attachType;
      let attchArr = [attchObj];
      setValue(key, attchArr, { shouldDirty: true });
    }
  };

  const onClose = () => {
    // reset(JSON.parse(JSON.stringify(initManfObj)));
    handleClose();
  };

  return (
    <Modal
      size="xl"
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton className="theme-header">
        <Modal.Title>EDIT ITEM DETAILS</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            <Col>
              <label className="form-label theme-label"> Quantity </label>
              <input
                name="qty"
                className="form-control"
                {...register("qty")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label"> Weight</label>
              <input
                name="wgt"
                className="form-control"
                {...register("wgt")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label"> CN Job</label>
              <input
                name="cnJob"
                className="form-control"
                {...register("cnJob")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label">IN Date</label>
              <input
                name="inDt"
                className="form-control"
                {...register("inDt")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label"> Location</label>
              <input
                name="location"
                className="form-control"
                {...register("location")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label className="form-label theme-label">M3</label>
              <input
                name="m3"
                className="form-control"
                {...register("m3")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label">Status</label>
              <input
                name="status"
                className="form-control"
                {...register("status")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label">Job No.</label>
              <input
                name="jobNo"
                className="form-control"
                {...register("jobNo")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>

            <Col>
              <label className="form-label theme-label">Shipment No.</label>
              <input
                name="shipmentNo"
                className="form-control"
                {...register("shipmentNo")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
            <Col>
              <label className="form-label theme-label">Marking</label>
              <input
                name="marking"
                className="form-control"
                {...register("marking")}
                disabled={!!selDropPoint?.actOrdId}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label className="form-label theme-label">Contact</label>
              <input
                name="contact"
                className="form-control"
                {...register("contact")}
              />
            </Col>

            <Col>
              {fields.map((attch, index) => (
                <div
                  key={attch.id}
                  {...register(`attachments.${index}.attachName`)}
                >
                  <label className="form-label theme-label">Attachment</label>
                  <div className="input-group">
                    <label className="form-control attachment-label">
                      <span className=" attachment-label-no-ellipsis">
                        {attch?.attachName}
                      </span>
                      <input
                        type="file"
                        className="UploadFilesty "
                        aria-describedby="button-addon2"
                        accept="application/pdf, image/jpeg, image/jpg"
                        id={`attachments.${index}.attachType`}
                        onChange={(e) =>
                          handleAttch(
                            e,
                            attch?.attachId,
                            attch?.attachType,
                            "attachments"
                          )
                        }
                        disabled={!!selDropPoint?.actOrdId}
                      />
                    </label>
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      disabled={
                        typeof attch.attachUrl === "string" &&
                        attch.attachUrl.trim().length > 0
                          ? false
                          : true
                      }
                      //   onClick={() => DownloadFile(attch.attachUrl)}
                    >
                      {GetSvgIcon("IconFiledownload")}
                    </button>
                  </div>
                </div>
              ))}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <label className="form-label theme-label">Remarks</label>
              <textarea
                name="remarks"
                className="form-control"
                {...register("remarks")}
                disabled={!!selDropPoint?.actOrdId}
              ></textarea>
            </Col>
            <Col>
              <label className="form-label theme-label">Special Remarks</label>
              <textarea
                name="specialRemark"
                className="form-control"
                {...register("specialRemark")}
                disabled={!!selDropPoint?.actOrdId}
              ></textarea>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button
          className={!isDirty ? "btn btn-outline-secondary" : "theme-violet"}
          disabled={!isDirty}
          //   onClick={handleSubmit(onSubmit)}
        >
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CrossBorderManifestEdit;
