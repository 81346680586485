import React, { useState, useEffect, useRef } from "react";
import ModalLayout from "../Layout/ModalLayout";
import { Col, Container, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {
  DATE_FORMAT_SERVER,
  DATE_FORMAT_CLIENT,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import Select from "react-select";
import moment from "moment";
import Toggler from "../Utilities/Toggler";
import { Controller, useForm } from "react-hook-form";

const LastMileRePlanningModal = ({
  closeReplanningModal,
  replanningModalConfig,
  vehicles,
  drivers,
  doRePlanning,
  showAlert,
  selectedRowData,
  lhUsrs,
  partnerList,
  setSelectedPartner,
  selectedPartner,
  getLastMileVhclDriver,
  getPartnerData,
  setLhVehicleDriberData,
}) => {
  const [usrs, setUsrs] = useState([]);
  const {
    register,
    setValue,
    // getValues,
    watch,
    handleSubmit,
    // clearErrors,
    control,
    // formState: { errors, isDirty },
  } = useForm();
  const watchPtype = watch("pType");
  const watchDriver = watch("driver");
  const watchVehicle = watch("vehicle");
  const watchTripDate = watch("tripDate");
  const watchTripTime = watch("tripTime");
  const selectPartnerRef = useRef();
  const selectDriverRef = useRef();
  const selectVehicleRef = useRef();

  useEffect(() => {
    setUsrs(lhUsrs);
    setValue("vehicle", selectedRowData?.vehicle_id);
    setValue("driver", selectedRowData?.driver1);
    setValue(
      "tripDate",
      selectedRowData?.trip_date
        ? new Date(selectedRowData?.trip_date)
        : new Date()
    );
    setValue(
      "tripTime",
      (selectedRowData?.vehicle_id === null ||
        selectedRowData?.vehicle_id === 0) &&
        (!selectedRowData?.driver1 ||
          selectedRowData?.driver1 === null ||
          selectedRowData?.driver1 === 0)
        ? moment().format("HH:mm")
        : selectedRowData?.trip_start_time
    );

    getLastMileVhclDriver(
      0,
      selectedRowData?.trip_date
        ? selectedRowData?.trip_date
        : moment().format("YYYY-MM-DD")
    );
    if (
      !selectedRowData ||
      !selectedRowData.user_type ||
      selectedRowData.user_type === 134
    ) {
      setValue("pType", "lh");
      setValue("partnerId", 0);
      // setTimeout(setValue("partner", 0), 4000);
    } else {
      setValue("pType", "Partner");
      setUsrs(partnerList);
      updatePatId(selectedRowData?.driver1);
    }
  }, [selectedRowData]);

  const onError = (err) => {
    console.error(err);
  };

  const updatePatId = async (drId) => {
    let patId = await getPartnerData(drId);
    await getLastMileVhclDriver(
      patId,
      watchTripDate ? watchTripDate : moment().format("YYYY-MM-DD")
    );
    setValue("partnerId", patId);
    setSelectedPartner(patId);
  };

  const rePlan = (data) => {
    doRePlanning(
      data.vehicle,
      data.driver,
      moment(data.tripDate).format("YYYY-MM-DD"),
      data.tripTime
    );
  };

  const togglerHandler = (label, value) => {
    clearSelection();
    if (label === "Partner") {
      setUsrs(partnerList);
      setSelectedPartner();
      setValue("partnerId", 0);
      setValue("pType", "Partner");
      //clear partner dropdown
    } else {
      setValue("pType", "lh");
      setUsrs(lhUsrs);
      setValue("partnerId", -1);
      setLhVehicleDriberData();
    }
  };

  const clearSelection = () => {
    if (selectVehicleRef.current) {
      selectVehicleRef.current.setValue(null);
    }
    if (selectDriverRef.current) {
      selectDriverRef.current.setValue(null);
    }
    if (selectPartnerRef.current) {
      selectPartnerRef.current.setValue(null);
    }
  };

  const handelPartnerChange = (val, values) => {
    if (!!val && val.value !== 0) {
      setSelectedPartner(val ? val.value : val);
      setValue("partnerId", val ? val.value : val);
      getLastMileVhclDriver(
        val.value,
        watchTripDate ? watchTripDate : moment().format("YYYY-MM-DD")
      );
    } else {
      setSelectedPartner(val ? val.value : val);
      setValue("partnerId", val ? val.value : val);
    }
  };

  const header = <h5 className="modal-title section-title">Trip Planning</h5>;

  const footer = (
    <button
      className="btn btn-outline-secondary theme-violet text-nowrap mr-5"
      type="button"
      // onClick={() => rePlan()}
      onClick={handleSubmit(rePlan, onError)}
      disabled={!watchTripDate || !watchDriver || !watchTripTime}
    >
      Submit
    </button>
  );

  return (
    <>
      {!!replanningModalConfig && (
        <ModalLayout
          moadlConfig={replanningModalConfig}
          header={header}
          footer={footer}
          handleMoalClose={closeReplanningModal}
        >
          <Container>
            <Row>
              <Col md="3" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Trip Date
                  <span className="text-danger">&#9733;</span>
                </label>
                <div>
                  <Controller
                    control={control}
                    name="tripDate"
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <ReactDatePicker
                        placeholderText="Select Date"
                        className="form-control w-100"
                        dateFormat={DATE_FORMAT_CLIENT}
                        selected={
                          watchTripDate ? new Date(watchTripDate) : new Date()
                        }
                        onFocus={(e) => e.target.blur()}
                        minDate={new Date()}
                        // onChange={(d) =>
                        //   setTripDate(
                        //     d !== null
                        //       ? moment(d).format(DATE_FORMAT_SERVER)
                        //       : ""
                        //   )
                        // }
                        onChange={(d) =>
                          onChange(
                            d !== null
                              ? moment(d).format(DATE_FORMAT_SERVER)
                              : ""
                          )
                        }
                        // onSelect={(d) =>
                        //   setTripDate(
                        //     d !== null
                        //       ? moment(d).format(DATE_FORMAT_SERVER)
                        //       : ""
                        //   )
                        // }
                      />
                    )}
                  />
                </div>
              </Col>
              <Col md="3" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Time<span className="text-danger">&#9733;</span>
                </label>
                <input
                  type="time"
                  className="form-control"
                  // defaultValue={tripTime}
                  // onChange={(e) => setTripTime(e.target.value)}
                  {...register("tripTime")}
                />
              </Col>
              <Col md="6" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Please Choose LH / Partner
                </label>
                {/* <Row>
                  <Col md="6"> */}
                <Toggler
                  btnName="is_full_truck"
                  isBtn1Checked={watchPtype === "lh"}
                  isBtn2Checked={watchPtype === "Partner"}
                  btn1Value={true}
                  btn2Value={false}
                  btn1Label="LH"
                  btn2Label="Partner"
                  handler={togglerHandler}
                />
              </Col>
            </Row>
            <Row className="mt-2">
              {watchPtype === "Partner" && (
                <Col>
                  <label
                    htmlFor=""
                    className="form-label theme-label"
                    title="This field is mandatory"
                  >
                    Partner List <span className="text-danger">&#9733;</span>
                  </label>
                  <Controller
                    control={control}
                    name="partnerId"
                    rules={{
                      required: {
                        value: true,
                        message: REQUIRED_ERR_MSG,
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        options={usrs}
                        placeholder="Select Partner"
                        value={
                          !!value
                            ? usrs?.find((opt) => opt.value === value)
                            : usrs.length > 1
                            ? value
                            : usrs[0]
                        }
                        onChange={(opt) => {
                          // onChange(!!opt ? opt.value : 0);
                          handelPartnerChange(opt, value);
                        }}
                        isSearchable={true}
                        isClearable={true}
                        className="form-control  p-0"
                        defaultValue={selectedPartner}
                        ref={selectPartnerRef}
                      />
                    )}
                  />
                </Col>
              )}
              <Col md="6" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Driver<span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  control={control}
                  name="driver"
                  rules={{
                    required: {
                      value: true,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={drivers}
                      placeholder="Select Driver"
                      value={
                        watchDriver === ""
                          ? ""
                          : drivers.find((opt) => opt.value === value)
                      }
                      onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                      isSearchable={true}
                      isClearable={true}
                      className="form-control  p-0"
                      isDisabled={watchPtype === "Partner" && !selectedPartner}
                      ref={selectDriverRef}
                      isOptionDisabled={(option) => option.disabled === true}
                    />
                  )}
                />
              </Col>
            </Row>

            <Row>
              {/* <Col md="6" className="mb-1">
                <label
                  htmlFor=""
                  className="form-label theme-label"
                  title="This field is mandatory"
                >
                  Vehicle
                  <span className="text-danger">&#9733;</span>
                </label>
                <Controller
                  control={control}
                  name="vehicle"
                  rules={{
                    required: {
                      value: false,
                      message: REQUIRED_ERR_MSG,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={vehicles}
                      placeholder="Select Vehicle"
                      value={
                        watchVehicle === ""
                          ? ""
                          : vehicles.find((opt) => opt.value === value)
                      }
                      onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                      isSearchable={true}
                      isClearable={true}
                      className="form-control  p-0"
                      isDisabled={watchPtype === "Partner" && !selectedPartner}
                      ref={selectVehicleRef}
                      isOptionDisabled={(option) => option.disabled === true}
                      
                    />
                  )}
                />
              </Col> */}
            </Row>
          </Container>
        </ModalLayout>
      )}
    </>
  );
};

export default LastMileRePlanningModal;
