import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import GetSvgIcon, { GetSvgUpload } from "../../../../images/svglist";
import { getFileName, toBase64 } from "../../../Helper/CommonMethods";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  EMAIL,
  PATTERN_ERR_MSG,
  PHONE_NUM,
  REQUIRED_ERR_MSG,
} from "../../../Helper/Constants";
import { DownloadFile } from "../../../Helper/FileStoreService";
import { CallApiGet, CallApiPost } from "../../../Helper/serviceApi";
import Spinner from "../../../Layout/Spinner";
import { addWatermarkToImage } from "../../../Helper/WaterMarkHelper";
import { LINEHAUL_PARTNER_COMPANY_USER_TYPE } from "../../../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../../../AuthUtils/AuthUtils";

export default function DriverModal(props) {
  const {
    driverDetails,
    countryCodes,
    onSuccess,
    onError,
    fetchPartnerStaff,
    isPartner,
  } = props;
  const navigate = useNavigate();
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [failMsg, setFailMsg] = useState();
  const [fileInputDummyKey, setFileInputDummyKey] = useState(Date.now());
  const [show, setShow] = useState(false);
  const [isDisabled, setDisabled] = useState({});
  const [photoImg, setPhotoImg] = useState();
  const [imageData, setImageData] = useState("");
  const [minExpDays, setMinExpDays] = useState(new Date());
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm({ defaultValues: driverDetails });

  const isPartnerCompany =
    getUserInfo("userType") === LINEHAUL_PARTNER_COMPANY_USER_TYPE;

  useEffect(() => {
    getConfigData();
  }, []);
  useEffect(() => {
    clearData();
    let profilePic = driverDetails.profilePhoto;
    setImageData(
      profilePic && profilePic.photoBucketUrl ? profilePic.photoBucketUrl : ""
    );
  }, [driverDetails]);

  const onSubmit = async (data) => {
    setShow(true);
    if (data.phone !== "") {
      data.phone = data.phoneCode + data.phone;
    }
    delete data.phoneCode;
    let userId = data.userId;
    let resp = await CallApiPost("register-partner", data);
    setShow(false);
    if (resp.respCode === 200) {
      clearData();
      onSuccess(
        userId
          ? `Driver data successfully updated.`
          : isPartnerCompany
          ? "Driver has been registered, waiting for Line Haul approval."
          : `Driver has been added successfully.`
      );
      fetchPartnerStaff();
    } else if (resp.respCode === 401) {
      setSubmitStatusFail(true);
      setFailMsg("Sorry, you don't have the write permission!!");
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else if (resp.respCode === 400) {
      setSubmitStatusFail(true);
      setFailMsg(resp.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    } else {
      onError(resp.respMessage);
    }
  };

  const formErrors = (err) => {
    console.log(err);
  };

  const clearData = () => {
    clearErrors();
    reset(driverDetails);
    setFileInputDummyKey(Date.now());
    setPhotoImg();
    setImageData("");
  };

  const clearInfo = () => {
    clearErrors();
    reset(driverDetails);
    setFileInputDummyKey(Date.now());
    setPhotoImg();
    setImageData("");
  };

  const getFontSize = (width, height) => Math.min(width, height) * 0.1;

  const selectFile = async (key, event) => {
    let newAttachment = {};
    const file = event.target.files;
    let outputFile;
    if (file.length === 0) {
      newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        if (key === "profilePhoto") {
          outputFile = file[0];
        } else {
          const fontSize = getFontSize(file[0].width, file[0].height);
          outputFile = await addWatermarkToImage(
            file[0],
            "For LineHaul Purpose Only",
            fontSize
          );
        }

        photo = await toBase64(outputFile);
      } catch (error) {
        console.error(error);
      }

      newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: fileName,
      };
    }

    if (key === "profilePhoto") setPhotoImg(outputFile);
    setValue(`${key}.photo`, newAttachment.photo);
    setValue(`${key}.photoFileName`, newAttachment.photoFileName);
    setValue(`${key}.photoFileType`, newAttachment.photoFileType);
    setValue(`${key}.photoBucketUrl`, newAttachment.photoBucketUrl);
    clearErrors(key);
  };
  const getConfigData = async () => {
    const uri = "GetConfigDataById?id=65";
    let resp = await CallApiGet(uri);

    if (resp?.respCode === 200 && !!resp?.respData) {
      setMinExpDays(
        new Date(
          moment()
            .add(resp?.respData[0].value_num, "days")
            .format(DATE_FORMAT_SERVER)
        )
      );
    }
  };
  return (
    <div
      className="modal fade"
      id="add-modal-driver"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          {submitStatusFail && (
            <div id="errorMsg" className="alert alert-danger" role="alert">
              {" "}
              {failMsg}
            </div>
          )}
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              {driverDetails.userId ? "Edit" : "Add"} Driver
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clearData}
            ></button>
          </div>
          <div className="modal-body modal-scroll overflow-auto">
            <div className="col-md-12 card card-body">
              <div className="w-100">
                <div className="row">
                  <div className="col-md-3">
                    <div
                      className="mb-1 file-placeholder thumbnail-pic"
                      {...register("profilePhoto", {
                        validate: (v) =>
                          v.photoBucketUrl !== null && v.photoBucketUrl !== "",
                        required: {
                          value: !driverDetails.userId,
                          message: REQUIRED_ERR_MSG,
                        },
                      })}
                    >
                      <input
                        type="file"
                        accept="image/png, image/jpeg "
                        onChange={(e) => selectFile("profilePhoto", e)}
                        key={fileInputDummyKey}
                        disabled={
                          driverDetails.userId > 0 &&
                          !(isPartner && driverDetails.active === true)
                        }
                      />
                      {photoImg ? (
                        <img
                          src={URL.createObjectURL(photoImg)}
                          alt="Profile Photo"
                        />
                      ) : imageData !== "" ? (
                        <img
                          src={imageData !== "" ? imageData : ""}
                          alt="img"
                        />
                      ) : (
                        <div>
                          {GetSvgUpload({ required: false })}
                          Upload Photo{" "}
                        </div>
                      )}
                    </div>
                    {!driverDetails.userId && errors.profilePhoto && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.profilePhoto.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Name
                      <span className="text-danger">&#9733;</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Driver Name"
                      aria-label="input example"
                      {...register("name", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: ALPHANUM_SPACE_SPCL_CHAR,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="200"
                      disabled={
                        driverDetails.userId > 0 &&
                        !(isPartner && driverDetails.active === true)
                      }
                    />
                    {errors.name && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.name.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Email<span className="text-danger">&#9733;</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email"
                      aria-label="input example"
                      disabled={driverDetails.userId}
                      {...register("email", {
                        required: {
                          value: true,
                          message: REQUIRED_ERR_MSG,
                        },
                        pattern: {
                          value: EMAIL,
                          message: PATTERN_ERR_MSG,
                        },
                      })}
                      maxLength="200"
                    />
                    {errors.email && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.email.message}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 mb-2">
                    <label
                      htmlFor=""
                      className="form-label theme-label"
                      title="This field is mandatory"
                    >
                      Phone<span className="text-danger">&#9733;</span>
                    </label>
                    <div className="input-group">
                      <select
                        name="countryCode"
                        {...register("phoneCode")}
                        defaultValue="+60"
                        className="form-control country-code-customize"
                        disabled={
                          driverDetails.userId > 0 &&
                          !(isPartner && driverDetails.active === true)
                        }
                      >
                        {countryCodes.map((cc, index) => (
                          <option key={index} value={cc.value}>
                            {cc.label}
                          </option>
                        ))}
                      </select>
                      <input
                        type="tel"
                        className="form-control"
                        placeholder="Phone"
                        aria-label="input example"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: PHONE_NUM,
                            message: PATTERN_ERR_MSG,
                          },
                        })}
                        maxLength="11"
                        disabled={
                          driverDetails.userId > 0 &&
                          !(isPartner && driverDetails.active === true)
                        }
                      />
                    </div>
                    {errors.countryCode && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.countryCode.message}
                        </span>
                      </div>
                    )}
                    {errors.phone && (
                      <div>
                        <span className="badge bg-danger">
                          &#9888; {errors.phone.message}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 mb-2">
                    <div className="table-responsive">
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Information</th>
                            {/* <th scope="col">Doc Number </th> */}
                            <th scope="col">Expires On </th>
                            <th scope="col">
                              Picture
                              <span
                                className="ms-1 tooltip-with-image"
                                title="Please upload a clear image.
                                Maximum file size: 10 MB.
                                Supported formats: JPEG, PNG."
                              >
                                {GetSvgIcon("ToolTip")}
                              </span>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>{"DRIVER NRIC"} </td>
                            <td></td>
                            <td>
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register(`driverNRICImage`, {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "",
                                  required: {
                                    value: !driverDetails.userId,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              >
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg "
                                  onChange={(e) =>
                                    selectFile(`driverNRICImage`, e)
                                  }
                                  disabled={
                                    driverDetails.userId > 0 &&
                                    !(
                                      isPartner && driverDetails.active === true
                                    )
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: true })}
                                  {watch(`driverNRICImage.photoBucketUrl`) ===
                                    null ||
                                  watch(`driverNRICImage.photoBucketUrl`) === ""
                                    ? "Upload Image"
                                    : getFileName(
                                        watch(`driverNRICImage.photoBucketUrl`)
                                      )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                  disabled={
                                    isDisabled[
                                      `driverNRICImage.photoBucketUrl` +
                                        "attachment"
                                    ]
                                  }
                                  onClick={() =>
                                    DownloadFile(
                                      getFileName(
                                        watch(`driverNRICImage.photoBucketUrl`)
                                      )
                                    )
                                  }
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                              {!driverDetails.userId &&
                                errors.driverNRICImage && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.driverNRICImage.message}
                                    </span>
                                  </div>
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>{"DRIVER DRIVING LICENSE"} </td>
                            <td>
                              <Controller
                                control={control}
                                name={`driverLicenseExpiryDate`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                }}
                                disabled={
                                  driverDetails.userId > 0 &&
                                  !(isPartner && driverDetails.active === true)
                                }
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <ReactDatePicker
                                      placeholderText="Expiry Date"
                                      className="form-control"
                                      dateFormat={DATE_FORMAT_CLIENT}
                                      selected={value ? new Date(value) : value}
                                      minDate={minExpDays}
                                      popperPlacement="auto"
                                      disabled={
                                        driverDetails.userId > 0 &&
                                        !(
                                          isPartner &&
                                          driverDetails.active === true
                                        )
                                      }
                                      onChange={(d) =>
                                        onChange(
                                          d !== null
                                            ? moment(d).format("YYYY-MM-DD")
                                            : ""
                                        )
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.driverLicenseExpiryDate && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888;{" "}
                                    {errors.driverLicenseExpiryDate.message}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register(`driverLicenseImage`, {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "",
                                  required: {
                                    value: !driverDetails.userId,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              >
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg "
                                  onChange={(e) =>
                                    selectFile(`driverLicenseImage`, e)
                                  }
                                  disabled={
                                    driverDetails.userId > 0 &&
                                    !(
                                      isPartner && driverDetails.active === true
                                    )
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: true })}
                                  {watch(
                                    `driverLicenseImage.photoBucketUrl`
                                  ) === null ||
                                  watch(`driverLicenseImage.photoBucketUrl`) ===
                                    ""
                                    ? "Upload Image"
                                    : getFileName(
                                        watch(
                                          `driverLicenseImage.photoBucketUrl`
                                        )
                                      )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                  disabled={
                                    isDisabled[
                                      `driverLicenseImage.photoBucketUrl` +
                                        "attachment"
                                    ]
                                  }
                                  onClick={() =>
                                    DownloadFile(
                                      getFileName(
                                        watch(
                                          `driverLicenseImage.photoBucketUrl`
                                        )
                                      )
                                    )
                                  }
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                              {!driverDetails.userId &&
                                errors.driverLicenseImage && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888;{" "}
                                      {errors.driverLicenseImage.message}
                                    </span>
                                  </div>
                                )}
                            </td>
                          </tr>
                          <tr>
                            <td>{"DRIVER GDL LICENSE"} </td>
                            <td>
                              <Controller
                                control={control}
                                name={`driverGDLExpiryDate`}
                                rules={{
                                  required: {
                                    value: true,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                }}
                                disabled={
                                  driverDetails.userId > 0 &&
                                  !(isPartner && driverDetails.active === true)
                                }
                                render={({ field: { onChange, value } }) => {
                                  return (
                                    <ReactDatePicker
                                      placeholderText="Expiry Date"
                                      className="form-control"
                                      dateFormat={DATE_FORMAT_CLIENT}
                                      selected={value ? new Date(value) : value}
                                      minDate={minExpDays}
                                      popperPlacement="auto"
                                      disabled={
                                        driverDetails.userId > 0 &&
                                        !(
                                          isPartner &&
                                          driverDetails.active === true
                                        )
                                      }
                                      onChange={(d) =>
                                        onChange(
                                          d !== null
                                            ? moment(d).format("YYYY-MM-DD")
                                            : ""
                                        )
                                      }
                                    />
                                  );
                                }}
                              />
                              {errors.driverGDLExpiryDate && (
                                <div>
                                  <span className="badge bg-danger">
                                    &#9888; {errors.driverGDLExpiryDate.message}
                                  </span>
                                </div>
                              )}
                            </td>
                            <td>
                              <div
                                className="Upload-Photo w-100 mb-2"
                                {...register(`driverGDLImage`, {
                                  validate: (v) =>
                                    v.photoBucketUrl !== null &&
                                    v.photoBucketUrl !== "",
                                  required: {
                                    value: !driverDetails.userId,
                                    message: REQUIRED_ERR_MSG,
                                  },
                                })}
                              >
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg "
                                  onChange={(e) =>
                                    selectFile(`driverGDLImage`, e)
                                  }
                                  disabled={
                                    driverDetails.userId > 0 &&
                                    !(
                                      isPartner && driverDetails.active === true
                                    )
                                  }
                                />
                                <div className="uploaded-text ms-2">
                                  {GetSvgUpload({ required: true })}
                                  {watch(`driverGDLImage.photoBucketUrl`) ===
                                    null ||
                                  watch(`driverGDLImage.photoBucketUrl`) === ""
                                    ? "Upload Image"
                                    : getFileName(
                                        watch(`driverGDLImage.photoBucketUrl`)
                                      )}
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-outline-secondary theme-violet  position-absolute download-uploaded-file"
                                  disabled={
                                    isDisabled[
                                      `driverGDLImage.photoBucketUrl` +
                                        "attachment"
                                    ]
                                  }
                                  onClick={() =>
                                    DownloadFile(
                                      getFileName(
                                        watch(`driverGDLImage.photoBucketUrl`)
                                      )
                                    )
                                  }
                                >
                                  {GetSvgIcon("IconFiledownload")}
                                </button>
                              </div>
                              {!driverDetails.userId &&
                                errors.driverGDLImage && (
                                  <div>
                                    <span className="badge bg-danger">
                                      &#9888; {errors.driverGDLImage.message}
                                    </span>
                                  </div>
                                )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(!driverDetails.userId ||
            (isPartner && driverDetails.active === true)) && (
            <div className="modal-footer">
              {!driverDetails.userId && (
                <button
                  type="reset"
                  className="btn btn-default border"
                  onClick={clearInfo}
                >
                  Clear
                </button>
              )}
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet"
                onClick={handleSubmit(onSubmit, formErrors)}
              >
                {driverDetails.active === true ? "Save" : "Register"}
              </button>
            </div>
          )}
        </div>
      </div>
      <Spinner show={show} />
    </div>
  );
}
