import React, { useEffect, useState } from "react";
import ModalLayout from "../../Layout/ModalLayout";
import { Button } from "react-bootstrap";
import VehicleInfo from "../../Vehicle/VehicleInfo";
import VehicleImage from "../../Vehicle/VehicleImage";
import VehicleDetails from "../../Vehicle/VehicleDetails";
import { useFieldArray, useForm } from "react-hook-form";
import {
  APPROVE_APRVL,
  PENDING_APRVL,
  REJECT_APRVL,
  VHCL_OWNER_PARTNER,
  VHCL_STS_ACTIVE,
  VHCL_STS_APPLIED,
  VHCL_STS_INACTIVE,
  VHCL_STS_REGISTERED,
  VHCL_STS_REJECTED,
  VHCL_STS_UPD_REQ,
  VHCL_STS_UPD_REQ_REJECTED,
  VHCLAPVL,
  VHCLDTLSEDIT,
} from "../../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { permission } from "../../Helper/CommonMethods";
import VehicleDetailsEdit from "../../Vehicle/VehicleDetailsEdit";

const VehApprovalAction = ({
  modalConfig,
  handleModalClose,
  vhclInfo,
  handleOnClick,
  selApprovalEntity,
  hq,
  dropdownData,
}) => {
  const userRoles = JSON.parse(getUserInfo("userRoles"));
  const isReadOnly = !(
    permission(VHCLDTLSEDIT)?.allowedWrite &&
    userRoles.some((role) => role.roleId === selApprovalEntity?.approverRole)
  );
  const [cleanUpData, setCleanUpData] = useState(new Date());
  const {
    register,
    setValue,
    reset,
    control,
    watch,
    setError,
    clearErrors,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    fields: vhclInfoArr,
    replace,
    update,
  } = useFieldArray({
    control,
    name: "vhclInfos",
  });
  const handleOnClose = () => {
    setCleanUpData(new Date());
    handleModalClose();
  };

  useEffect(() => {
    reset(vhclInfo);
  }, [vhclInfo]);
  const watchAssignTo = watch("assignTo");
  const setVhclStatus = (argVehicle, argActionCode) => {
    const isPartner = +argVehicle.assignTo === 204;
    const isVehApprove = argActionCode === APPROVE_APRVL;

    switch (argVehicle.status) {
      case 0:
        argVehicle.status = isPartner ? VHCL_STS_APPLIED : VHCL_STS_ACTIVE;
        argVehicle.active = isPartner ? false : true;
        break;
      case VHCL_STS_APPLIED:
        argVehicle.status = isPartner
          ? isVehApprove
            ? VHCL_STS_REGISTERED
            : VHCL_STS_REJECTED
          : VHCL_STS_INACTIVE;
        argVehicle.active = isPartner ? (isVehApprove ? true : false) : false;
        break;
      case VHCL_STS_UPD_REQ:
        argVehicle.status = isVehApprove
          ? isPartner
            ? VHCL_STS_REGISTERED
            : VHCL_STS_ACTIVE
          : VHCL_STS_UPD_REQ_REJECTED;
        break;
      default:
        argVehicle.status = argVehicle.status;
        break;
    }
    return argVehicle;
  };

  const onError = (err) => {
    console.error(err);
  };

  const onSave = (data) => {
    let entity = Object.assign({}, selApprovalEntity);
    entity.title = data.title;
    entity.approvalStatus = data.approvalStatus;
    data = setVhclStatus(data, entity.approvalStatus);
    entity.entity = { vhclDto: data };
    handleOnClick(entity);
  };

  const handleAction = (argTitle, argActionCode) => {
    setValue("title", argTitle);
    setValue("approvalStatus", argActionCode);

    handleSubmit(onSave, onError)();
  };

  const footerBtns = (
    <div className="d-flex justify-content-center w-100 m-0">
      {userRoles.some(
        (role) => role.roleId === selApprovalEntity?.approverRole
      ) &&
        selApprovalEntity?.statusId === PENDING_APRVL &&
        permission(VHCLAPVL)?.allowedWrite && (
          <>
            <Button
              variant="danger"
              onClick={() =>
                handleAction("Reject Approval Request", REJECT_APRVL)
              }
            >
              Reject
            </Button>
            <Button
              className="theme-violet ms-1"
              onClick={() =>
                handleAction("Approve Approval Request", APPROVE_APRVL)
              }
            >
              Approve
            </Button>
          </>
        )}
    </div>
  );

  return (
    <ModalLayout
      moadlConfig={modalConfig}
      handleMoalClose={handleOnClose}
      footer={footerBtns}
    >
      <div className="fw-bold  theme-violet-txt border-bottom">
        Vehicle Details
      </div>
      {isReadOnly ? (
        <VehicleDetails vhclData={vhclInfo} />
      ) : (
        <VehicleDetailsEdit
          register={register}
          clearErrors={clearErrors}
          setValue={setValue}
          errors={errors}
          control={control}
          hq={hq}
          dropdownData={dropdownData}
          watch={watch}
        />
      )}
      <div className="fw-bold my-2 theme-violet-txt border-bottom">
        Vehicle Images
      </div>
      <VehicleImage
        vhcl={vhclInfo}
        cleanUpData={cleanUpData}
        register={register}
        setValue={setValue}
        isReadOnly={false}
        isMandatory={+watchAssignTo === VHCL_OWNER_PARTNER}
        errors={errors}
        clearErrors={clearErrors}
        setError={setError}
      />
      <VehicleInfo
        vhclData={vhclInfo}
        vhclInfoArr={vhclInfoArr}
        control={control}
        register={register}
        setValue={setValue}
        getValues={getValues}
        watch={watch}
        replace={replace}
        update={update}
        isReadOnly={false}
        errors={errors}
        isMandatory={+watchAssignTo === VHCL_OWNER_PARTNER}
        clearErrors={clearErrors}
      />
    </ModalLayout>
  );
};

export default VehApprovalAction;
