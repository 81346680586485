import React from "react";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../images/svglist";
import { Tooltip } from "react-tooltip";

const AutoAssignList = ({
  driverGeofence,
  handleAction,
  setGeofenceStatus,
  handleActiveDeactive,
  toggleAlert,
}) => {
  const columns = [
    {
      name: "SBU",
      selector: (row) => row.sbuCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Priority",
      selector: (row) => row.priority,
      sortable: true,
      filterable: true,
    },
    {
      name: "Driver",
      selector: (row) => row.driverNames,
      sortable: true,
      filterable: true,
      width: "40%",
    },

    {
      name: "Order Type",
      selector: (row) => row.orderTypeName,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            className="me-3"
            data-tooltip-id={"edit-" + row.parentId}
            onClick={() => handleAction(row, "EDIT AUTO ASSIGN")}
          >
            {GetSvgIcon("EditPencil")}
          </div>
          <Tooltip id={"edit-" + row.parentId} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      minWidth: "4%",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"delete-" + row.parentId}
            onClick={() => {
              handleActiveDeactive(row.status, row.sbuCode);
              setGeofenceStatus({
                parentId: row.parentId,
                active: row.status,
              });
              toggleAlert(true);
            }}
          >
            {GetSvgIcon("BiTrash3Fill")}
          </div>
          <Tooltip
            id={"delete-" + row.parentId}
            // content={row.status === true ? "Deactivate " : "Activate "}
            content="Delete"
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      minWidth: "3%",
    },
  ];

  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={driverGeofence}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default AutoAssignList;
