import React, { useState } from "react";
import ModalLayout from "../Layout/ModalLayout";
import moment from "moment";
import {
  customReactSelectMenuStyle,
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_MOMENT,
  DATE_FORMAT_SERVER,
} from "../Helper/Constants";
import { Col, Container, Row } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { handleAttch } from "./InboundOrderMain";
import { DownloadFile } from "../Helper/FileStoreService";
import GetSvgIcon from "../../images/svglist";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { permission } from "../Helper/CommonMethods";
import { EDIT_CUST_SUPL_INB } from "../../ConfigConstants/ConfigConstants";

const InboundOrderEdit = (props) => {
  const {
    modalConfig,
    handleClose,
    order,
    handleOnChange,
    handleFieldChange,
    handleDateChange,
    handleFileAttach,
    handleSubmit,
    dropdownData,
    error,
    setError,
  } = props;

  const validateField = () => {
    if (!order?.custId) {
      setError(true);
    } else {
      setError(false);
    }
  };
  const { control } = useForm();
  const footer = (
    <button
      type="button"
      className="btn btn-outline-secondary theme-violet"
      onClick={handleSubmit}
    >
      Save
    </button>
  );

  const header = (
    <h5 className="modal-title section-title" id="exampleModalLabel">
      EDIT ORDER
    </h5>
  );
  const isEditable =
    order?.status === 344 &&
    (order?.invoiceId === null || order?.invoiceId === 0) &&
    permission(EDIT_CUST_SUPL_INB)?.allowedWrite;
  return (
    <ModalLayout
      moadlConfig={modalConfig}
      handleMoalClose={handleClose}
      footer={footer}
      header={header}
    >
      <Container>
        <Row>
          <Col md={6} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Order Date{" "}
            </label>
            {/* <input
              className="form-control"
              value={moment(order.orderDt).format(DATE_FORMAT_MOMENT)}
              readOnly
            /> */}
            <div className="grd-bg p-2 rounded-3">
              {moment(order.orderDt).format(DATE_FORMAT_MOMENT)}
            </div>
          </Col>
          <Col md={6} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Origin Hub Code{" "}
            </label>
            {/* <input
              name="origin"
              className="form-control"
              defaultValue={order.origin}
              // onChange={handleOnChange}
              readOnly
            /> */}
            <div className="grd-bg p-2 rounded-3">{order.origin}</div>
          </Col>
          <Col md={6} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Account Code<span className="text-danger">&#9733;</span>
            </label>
            {/* <input
              className="form-control"
              value={order.custAcctCode}
              readOnly
            /> */}
            {/* <div className="grd-bg p-2 rounded-3">{order.custAcctCode}</div> */}
            <Select
              placeholder="Select Account"
              isSearchable={true}
              isClearable={true}
              className="form-control p-0"
              options={dropdownData?.customerList}
              value={
                !!order?.custId
                  ? dropdownData?.customerList?.find(
                      (opt) => opt.value === order?.custId
                    )
                  : null
              }
              name="custAcctCode"
              defaultValue={order.custId}
              // onChange={handleFieldChange("custId")}
              onChange={(selectedOption) => {
                handleFieldChange("custId")(selectedOption);
                setError(!selectedOption); // Clear error on valid selection
              }}
              onBlur={validateField}
              menuPortalTarget={document.body}
              styles={customReactSelectMenuStyle}
              isDisabled={!isEditable}
            />
            {error === true && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {"This field is mandatory."}
                </span>
              </div>
            )}
          </Col>
          <Col md={6} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Supplier
            </label>
            <Select
              placeholder="Select Supplier"
              isSearchable={true}
              isClearable={true}
              className="form-control p-0"
              options={dropdownData?.supplierList}
              value={
                !!order?.suppId
                  ? dropdownData?.supplierList?.find(
                      (opt) => opt.value === order?.suppId
                    )
                  : null
              }
              name="suppId"
              defaultValue={order.suppId}
              onChange={handleFieldChange("suppId")}
              menuPortalTarget={document.body}
              styles={customReactSelectMenuStyle}
              isDisabled={!permission(EDIT_CUST_SUPL_INB)?.allowedWrite}
            />
          </Col>
          {/* <Col md={3} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Estimated Arrival Date{" "}
            </label>
            <input
              className="form-control"
              value={moment(order.eda).format(DATE_FORMAT_MOMENT)}
              readOnly
            />
          </Col> */}
          <Col md={4} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Container Arrival Date{" "}
            </label>
            {/* <input className="form-control" value={moment(order.ada).format(DATE_FORMAT_MOMENT)} onChange={handleOnChange} /> */}
            <ReactDatePicker
              className="form-control"
              placeholderText="Enter Actual Arrival"
              dateFormat={DATE_FORMAT_CLIENT}
              selected={!!order.ada ? new Date(order.ada) : order.ada}
              onChange={(d) =>
                handleDateChange(
                  "ada",
                  !!d ? moment(d).format(DATE_FORMAT_SERVER) : d
                )
              }
              minDate={new Date(order.orderDt)}
              // maxDate={new Date()}
            />
          </Col>
          <Col md={4} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Port Name{" "}
            </label>
            <input
              name="portName"
              className="form-control"
              defaultValue={order.portName}
              onChange={handleOnChange}
            />
          </Col>
          <Col md={4} mb={2}>
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Container Number{" "}
            </label>
            <input
              name="containerNo"
              className="form-control"
              defaultValue={order.containerNo}
              onChange={handleOnChange}
            />
          </Col>
        </Row>
        <Row>
          {!!order?.attachments &&
            Array.isArray(order?.attachments) &&
            order?.attachments?.map((attach, index) => (
              <Col key={index}>
                <div>
                  <label className="form-label theme-label">Attachment</label>
                  <div className="input-group">
                    <label className="form-control attachment-label">
                      <span className=" attachment-label-no-ellipsis">
                        {attach?.attachName}
                      </span>
                      <input
                        type="file"
                        className="UploadFilesty "
                        aria-describedby="button-addon2"
                        accept="application/pdf, image/jpeg, image/jpg"
                        id={`attachments.${index}`}
                        key={attach?.attachName}
                        onChange={(e) =>
                          handleAttch(
                            e,
                            order?.orderId,
                            attach?.attachId,
                            attach?.attachType,
                            handleFileAttach
                          )
                        }
                      />
                    </label>
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet"
                      disabled={
                        typeof attach.attachUrl === "string" &&
                        attach.attachUrl.trim().length > 0
                          ? false
                          : true
                      }
                      onClick={() => DownloadFile(attach?.attachUrl)}
                    >
                      {GetSvgIcon("IconFiledownload")}
                    </button>
                  </div>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </ModalLayout>
  );
};

export default InboundOrderEdit;
