import React, { useEffect, useRef, useState } from "react";
import Layout from "../Layout/Layout";
import {
  NET_ERR_MSG,
  SERVER_ERR_MSG,
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
  BLACK_SPCL_CHARS,
  FILE_UPLOAD_TEXT,
  M_Y_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
} from "../Helper/Constants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
} from "react-bootstrap";
import {
  CallApiGet,
  CallApiPost,
  LCWebsiteURL,
  LCTrackWaybillURL,
  CallApiPut,
} from "../Helper/serviceApi";
import { Tooltip } from "react-tooltip";
import {
  binarySearchArrOfObj,
  getOrderStatusBadgeClass,
  permissionCheck,
  trackWaybill,
} from "../Helper/CommonMethods";
import {
  CNCL_ORD_STS,
  CXLSHIP,
  DLV_FAIL_ORD_STS,
  DLV_ORD_STS,
  INBORDS,
  LINEHAUL_ADMIN_USER_TYPE,
  LINEHAUL_STAFF_USER_TYPE,
  PLC_ORD_STS,
  POD_DO_ATTACH_INB,
  wt_ORD_STS,
} from "../../ConfigConstants/ConfigConstants";
import GetSvgIcon from "../../images/svglist";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import useClaimHook from "../CustomHooks/useClaimHook";
import DataTable from "react-data-table-component-with-filter";
import CrossBorderManifestEdit from "./CrossBorderManifestEdit";
import CrossBorderManifestEditDrP from "./CrossBorderManifestEditDrP";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import InboundOrderAttachment from "../Inbound Order/InboundOrderAttachment";
import moment from "moment";

const initSearchObj = {
  searchKey: "shipmentNo",
  searchVal: "",
};

const searchOpts = [
  {
    label: "Shipment No.",
    value: "shipmentNo",
  },
  {
    label: "Marking",
    value: "marking",
  },
];

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initCxlObj = {
  mIds: "",
  shipmentId: 0,
  customerId: 0,
  remarks: "CANCALLED",
  isRemarkBlank: false,
};

const initAttachModalConfig = {
  show: false,
  title: "",
};

const initManfAttach = {
  attachId: 0,
  refId: 0,
  attachType: 701,
  attachName: FILE_UPLOAD_TEXT,
  attachUrl: "",
  attachInfo: "",
  remarks: "",
  attchBase64: "",
};

const initManObj = {
  manifestId: 0,
  qty: 0,
  wgt: 0,
  remarks: "",
  cnJob: "",
  contact: "",
  inDt: "",
  location: "",
  m3: 0,
  marking: "",
  shipmentNo: "",
  status: "",
  jobNo: "",
  specialRemark: "",
  attachments: [initManfAttach],
};

const CrossBorderManifest = () => {
  const { state } = useLocation();
  const [mainItemArr, setMainItemArr] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isOriginEmpty, setOriginEmpty] = useState(false);
  const [requestId, setRequestId] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearRows, setClearRows] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    JSON.parse(JSON.stringify(initAlertConfig))
  );
  const modalRef = useRef();
  const navigate = useNavigate();
  const [selDropPoint, setSelDropPoint] = useState();
  const [showIbOrdSumm, setShowIbOrdSumm] = useState(false);
  const [selectableRowsBack, setSelectableRowsBack] = useState();
  const [showEditDropPoint, setShowEditDropPoint] = useState(false);
  const [stateArr, setStateArr] = useState([]);
  const [sbuList, setSbuList] = useState([]);
  const [srvcAreaOpt, setSrvcAreaOpt] = useState([]);
  const [showManifEdit, setShowManifEdit] = useState(false);
  const [manfData, setManfData] = useState(initManObj);
  const [cancelconfirmConfig, setCancelconfirmConfig] =
    useState(initAlertConfig);
  const [cxlObj, setCxlObj] = useState(JSON.parse(JSON.stringify(initCxlObj)));
  const [attachModalConfig, setAttachModalConfig] = useState(
    JSON.parse(JSON.stringify(initAttachModalConfig))
  );
  const [attachList, setAttachList] = useState([]);
  const [searchObj, setSearchObj] = useState(Object.assign({}, initSearchObj));
  const [userType, setUserType] = useState("");
  const [bookingType, setBookingType] = useState("");
  const { showClaimForm, ClaimHookComponent } = useClaimHook();

  useEffect(() => {
    // updateOrderState(rowData);
    const rowData = JSON.parse(sessionStorage.getItem("row"));
    getSbuData();
    fetchStates();
    getSrvcDropDownOpt();
    fetchOrderManifest(rowData.orderId);
    if (
      rowData.origin === null ||
      rowData.origin === undefined ||
      rowData.origin === ""
    ) {
      setOriginEmpty(true);
    }
    setUserType(getUserInfo("userType"));
  }, []);

  const updateOrderState = (argOrder) => {
    let selOrder = Object.assign({}, argOrder);
    selOrder.attachments = JSON.parse(selOrder?.attachments);
    // setOrder(selOrder);
  };

  const conditionalRowStyles = [
    {
      when: (row) =>
        row?.addr === selDropPoint?.addr &&
        row?.state === selDropPoint?.state &&
        row?.postcode === selDropPoint?.postcode &&
        row?.destHubId === selDropPoint?.destHubId &&
        row?.actOrdId === selDropPoint?.actOrdId,
      style: {
        backgroundColor: "#84fae0",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const getSbuData = async () => {
    let cityResponse = await CallApiGet("getBookingStateAndCity?argCustId=0");
    if (cityResponse.respCode === 0 && !!cityResponse.respData) {
      setSbuList(cityResponse?.respData);
    }
  };

  const getSrvcDropDownOpt = async () => {
    let data = await CallApiGet("ServiceAreaDropDown");
    if (
      data.respCode === 0 &&
      !!data?.respData &&
      Array.isArray(data.respData)
    ) {
      setSrvcAreaOpt(data.respData);
    }
  };

  const handleRowClick = (row) => {
    let tempRow = { ...row };
    tempRow.manf_data =
      typeof tempRow?.manf_data === "string"
        ? JSON.parse(tempRow.manf_data)
        : tempRow?.manf_data;
    tempRow.attachments =
      !!tempRow.attachments && typeof tempRow.attachments === "string"
        ? JSON.parse(tempRow.attachments)
        : [];
    setSelDropPoint(tempRow);
  };

  const handleTrackWB = (row) => {
    document.body.click();
    if (row?.lcBooking === true || row?.lcBooking === "true") {
      trackInLC(row?.waybillNo);
    } else {
      const payLoad = { waybill_no: row.waybill_nos.split(",") };
      trackWaybill(payLoad);
    }
  };

  const handlePrintWB = (row) => {
    document.body.click();
    if (row?.lcBooking === true || row?.lcBooking === "true") {
      printLCWaybill(row?.waybillNo);
    } else {
      printLhWB(row);
    }
  };

  const printLhWB = async (data) => {
    setShowSpinner(true);
    const payload = {
      shipmentIdList: [data?.actOrdId],
      palletIdList: [],
      isSearchByOrder: true,
    };
    const resp = await CallApiPost("printWaybill", payload);
    setShowSpinner(false);
    if (resp.respCode === 200) {
      window.open(resp.respData, "_blank");
    }
  };

  const updateCxlObj = (data) => {
    document.getElementById(`row-action-button-${data?.rowId}`).click();
    setCancelconfirmConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Confirm Cancellation",
      description: "Are you sure you want to cancel the order",
    });
    let tempManifestIds = data?.m_id?.replace("{", "").replace("}", "");
    setCxlObj({
      mIds: tempManifestIds?.split(","),
      shipmentId: data?.actOrdId,
      customerId: data?.customerId,
      remarks: "",
      isRemarkBlank: false,
    });
  };

  const fetchAttachments = async (argAttachType, argTitle) => {
    setShowSpinner(true);
    let locItems = [].concat(itemList);

    const tShipmentIdList = locItems
      .filter((order) => !!order?.actOrdId)
      .map((order) => order?.actOrdId);

    const payload = {
      shipmentIdList: tShipmentIdList,
      attachType: argAttachType,
    };

    let resp =
      tShipmentIdList.length > 0
        ? await CallApiPost("GetShipmentAttachments", payload)
        : {
            respCode: 409,
            respMessage:
              "Sorry, there are no attachments available for downloading!!",
          };
    if (
      resp?.respCode === 200 &&
      !!resp?.respData &&
      Array.isArray(resp?.respData) &&
      resp?.respData.length > 0
    ) {
      setAttachList(resp?.respData);
      setAttachModalConfig({
        show: true,
        title: argTitle,
      });
      setShowSpinner(false);
    } else {
      if (resp.respCode === 200 || resp?.respData?.length === 0) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, there are no attachments available for downloading!!";
      }
      setShowSpinner(false);
      handlePostApiCall(
        resp,
        "Sorry, we failed to process your request. Please try after sometime!!"
      );
    }
  };

  const closeAttachModal = () => {
    setAlertConfig([]);
    setAttachModalConfig(JSON.parse(JSON.stringify(initAttachModalConfig)));
  };
  const handleRemoveDropPoint = async (data) => {
    document.body.click();
    setShowSpinner(true);
    let manifestIds = data.m_id;
    manifestIds = manifestIds.slice(1, -1);
    manifestIds = manifestIds.split(",").map((s) => +s);

    const payload = {
      manifestId: manifestIds,
    };
    const resp = await CallApiPost("DeleteDropPoint", payload);
    handlePostApiCall(resp, resp.respMessage);
    setShowSpinner(false);
  };

  const handleClaimClick = (row) => {
    const payLoad = {
      shipmentId: row?.actOrdId,
      palletId: 0,
      orderNo: row?.orderNo,
      origin: { sbuCode: row?.actOrgCode },
      destination: { sbuCode: row?.actDestCode },
      shipmentDate: row?.shipOn,
      estDeliveryDate: row?.estDelDate,
      statusText: row?.ordStatus,
    };
    showClaimForm(payLoad);
  };
  const dropCols = [
    {
      name: "Drop Point",
      selector: (row) => getFullAddress(row),
      cell: (row) => (
        <>
          <pre className="box-text p-1" onClick={() => handleRowClick(row)}>
            <span className="d-inline-block">{getFullAddress(row)}</span>
          </pre>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "300px",
    },
    {
      name: "Destination",
      selector: (row) => row?.destHubCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Distance (km)",
      selector: (row) => row?.distance,
      sortable: true,
      filterable: true,
    },
    {
      name: "Volume (m3)",
      selector: (row) => row?.volume,
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Markings",
    //   selector: (row) => row?.markings,
    //   sortable: true,
    //   filterable: true,
    //   cell: (row) => (
    //     <span className="d-flex justify-content-center">{row?.markings}</span>
    //   ),
    // },
    {
      name: "Service Area",
      selector: (row) => row?.srvcArea,
      sortable: true,
      filterable: true,
    },
    {
      name: "Waybill Count",
      selector: (row) => (!!row?.actOrdId ? row?.pallets : ""),
      sortable: true,
      filterable: true,
    },
    {
      name: "Waybill No",
      selector: (row) =>
        !!row?.waybill_nos && !!row?.orderNo ? getFullWaybill(row) : "",
      cell: (row) =>
        !!row?.waybill_nos &&
        !!row?.orderNo && (
          <>
            <span
              data-tooltip-id={"wbill-" + row.m_id}
              // className="d-inline-block text-truncate modal-links"
              className="modal-links fw-bold"
              onClick={() => handleTrackWB(row)}
            >
              {getFullWaybill(row)}
            </span>
            <Tooltip
              id={"wbill-" + row.m_id}
              content={getFullWaybill(row)}
              place="left"
            />
          </>
        ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Order No",
      selector: (row) => row.orderNo,
      cell: (row) => (
        <>
          <div
            onClick={() => openOrderDtls(row.actOrdId)}
            className="modal-links fw-bold"
          >
            {row.orderNo}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
    },
    {
      name: "Booked On",
      selector: (row) =>
        row.bookOn
          ? moment(row.bookOn).format(DATE_TIME_FORMAT_MOMENT)
          : row.bookOn,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.ordStatus,
      sortable: true,
      filterable: true,
      cell: (row) => (
        <div className={getOrderStatusBadgeClass(row)}>{row?.ordStatus}</div>
      ),
    },
    // {
    //   name: "Booked In",
    //   selector: (row) =>
    //     !!row?.waybill_nos && !!row?.orderNo
    //       ? row.lcBooking
    //         ? "LC"
    //         : "LH"
    //       : "",
    //   sortable: true,
    //   filterable: true,
    // },
    // {
    //   name: "",
    //   width: "15px",
    //   cell: (row) => {
    //     return (
    //       <>
    //         {permissionCheck(INBORDS, "allowedDelete") &&
    //           row?.actOrdId === null &&
    //           row?.status !== DLV_ORD_STS &&
    //           state?.invoiceId === null && (
    //             <span
    //               // className="action-item-button"
    //               onClick={() => handleRemoveDropPoint(row)}
    //             >
    //               {GetSvgIcon("BiTrash3Fill")}
    //             </span>
    //           )}
    //       </>
    //     );
    //   },
    // },
    {
      name: "",
      width: "15px",
      cell: (row) => (
        <>
          {!!row?.waybill_nos && !!row?.orderNo && (
            <OverlayTrigger
              trigger="click"
              key={row.id}
              placement="left"
              rootClose
              overlay={
                <Popover id={`popover-positioned-lefft`}>
                  <Popover.Body>
                    <div
                      className="action-item-button"
                      onClick={() => handlePrintWB(row)}
                    >
                      {GetSvgIcon("Print")} Print Waybills
                    </div>
                    <div
                      className="action-item-button"
                      onClick={() => handleTrackWB(row)}
                    >
                      {GetSvgIcon("Geoalttrack")} Track Waybill
                    </div>
                    {/* {permissionCheck(CXLSHIP, "allowedDelete") &&
                      row?.status !== CNCL_ORD_STS &&
                      row?.status !== DLV_ORD_STS &&
                      +state?.invoiceId === 0 && (
                        <div
                          className="action-item-button"
                          onClick={() => updateCxlObj(row)}
                        >
                          {GetSvgIcon("IconDiscount")} Cancel Order
                        </div>
                      )}
                    {!!row?.actOrdId &&
                      +row?.actOrdId > 0 &&
                      [
                        CNCL_ORD_STS,
                        PLC_ORD_STS,
                        wt_ORD_STS,
                        DLV_FAIL_ORD_STS,
                      ].includes(row?.status) &&
                      +state?.invoiceId === 0 && (
                        <>
                          <span
                            className="action-item-button"
                            data-tooltip-id={"ord-" + row.actOrdId}
                            onClick={() => handleReorder(row)}
                          >
                            {GetSvgIcon("Reboot")} Re-Order
                          </span>
                          <Tooltip
                            id={"ord-" + row.actOrdId}
                            content="Re-Order"
                            place="left"
                          />
                        </>
                      )}
                    {![75, 77, 87, 88, 611].includes(row.status) &&
                      +userType !== 134 &&
                      !row?.isClaimApplied && (
                        <div
                          className="action-item-button"
                          onClick={() => handleClaimClick(row)}
                        >
                          {GetSvgIcon("PaymentDetails")} Claim
                        </div>
                      )} */}
                  </Popover.Body>
                </Popover>
              }
            >
              <span
                id={`row-action-button-${row?.rowId}`}
                className="row-action-button"
              >
                {GetSvgIcon("Trippleverticaldot")}
              </span>
            </OverlayTrigger>
          )}
        </>
      ),
    },
  ];

  /**
   * This Functionality was from initital requirement
   * Code to be removed
   */
  const handleMakePallet = async () => {
    if (selectedRows.length <= 0) {
      openPopup(false, "ERROR", "There are no selected items to palletize!!");
      return;
    }
    if (!isValidPalletization(selectedRows)) {
      openPopup(
        false,
        "ERROR",
        "You are not allowed to palletize items for different destinations!!"
      );
      return;
    }
    let argItemIds = [];
    selectedRows.forEach((row) => argItemIds.push(row.id));
    const payLoad = {
      orderId: state.orderId,
      itemIds: argItemIds,
    };
    const resp = await CallApiPost("MakePallet", payLoad);
    handlePostApiCall(resp, "Items Palletized successfully!!!");
  };

  const handleLCBooking = async () => {
    setShowSpinner(true);
    if (selectedRows.length <= 0) {
      openPopup(
        false,
        "ERROR",
        "There are no selected items to create booking!!"
      );
      setShowSpinner(false);
      return;
    }

    let payload = [];
    selectedRows.forEach((row) => payload.push({ ...row, itemId: row.id }));
    const resp = await CallApiPost("bookInboundLC", payload);
    handlePostApiCall(resp, resp.respMessage);
    setShowSpinner(false);
  };

  /**
   * This Functionality was from initital requirement
   * Code to be removed
   */
  const handleGenerateWaybill = async () => {
    if (selectedRows.length <= 0) {
      openPopup(false, "ERROR", "Sorry, You have not selected any items!!");
      return;
    }
    let argItemIds = [];
    selectedRows.forEach((row) => argItemIds.push(row.id));
    const payLoad = {
      orderId: state.orderId,
      itemIds: argItemIds,
    };
    const resp = await CallApiPost("GenerateWaybill", payLoad);
    handlePostApiCall(resp, "Waybill generated for the selected items!!!");
  };

  const isValidPalletization = (rows) => {
    let tempDest = new Set();
    rows.forEach((item) => tempDest.add(item.destHubCode));
    if (tempDest.size > 1) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * This Functionality was from initital requirement
   * Code to be removed
   */
  const handleDePallet = async () => {
    const resp = await CallApiPost("DePalletAll", { orderId: state.orderId });
    handlePostApiCall(resp, "Items De-Palletized successfully!!!");
  };

  const handleCreateBooking = async () => {
    const dropPoints = selectedRows?.filter(
      (row) =>
        (row?.palletSpace === 0 || !!row?.palletSpace) &&
        parseInt(row?.palletSpace) >= 0
    );

    const newOrdReq = {
      orderId: state?.orderId,
      manfAddrModels: dropPoints,
      addrMod: dropPoints,
    };

    const reOrdReq = {
      inbOrdDto: newOrdReq,
      cxlDto: cxlObj,
    };

    setShowSpinner(true);
    const resp =
      bookingType === "N"
        ? await CallApiPost("CreateBooking", newOrdReq)
        : await CallApiPost("ReOrderManifest", reOrdReq);
    handlePostApiCall(resp, resp.respMessage);
    setShowSpinner(false);
    closeInbOrdSumm();
  };

  const handleDropPointChange = async (data) => {
    setShowSpinner(true);
    const resp = await CallApiPost("UpdateDropPoint", [data]);
    handlePostApiCall(resp, resp.respMessage);
    setShowSpinner(false);
    setShowEditDropPoint(false);
  };

  const handleManfDataChange = async (data) => {
    setShowSpinner(true);
    const resp = await CallApiPost("UpdateManf", data);
    handlePostApiCall(resp, resp.respMessage);
    setShowSpinner(false);
    closeManfModal();
  };

  const handlePostApiCall = (resp, sucMsg) => {
    switch (resp.respCode) {
      case 200:
        setRequestId();
        openPopup(true, "SUCCESS", sucMsg);
        break;
      case 500:
        setRequestId(resp.respData);
        openPopup(false, "ERROR", resp.respMessage || SERVER_ERR_MSG);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the write permission!!"
        );
        break;
      case 99:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after soemtime!!"
        );
        break;
      default:
        setRequestId();
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const fetchOrderManifest = async (orderId) => {
    setShowSpinner(true);
    const uri = "GetInboundManifestList?orderId=" + orderId;
    const resp = await CallApiGet(uri);
    switch (resp.respCode) {
      case 200:
        setItemList(resp.respData);
        setMainItemArr(resp.respData);
        let tempRow = { ...resp.respData[0] };
        tempRow.manf_data = !!tempRow.manf_data
          ? JSON.parse(tempRow.manf_data)
          : [];
        tempRow.attachments = !!tempRow.attachments
          ? JSON.parse(tempRow.attachments)
          : Array.from(Object.assign({}, initManfAttach));
        setSelDropPoint(tempRow);

        break;
      case 500:
        setRequestId(resp.respData);
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        setRequestId(resp.respData);
        openPopup(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        setRequestId();
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
    setShowSpinner(false);
  };

  const getFullAddress = (rowData) => {
    return `Name: ${rowData?.name},\nAddress: ${rowData?.addr},\nState: ${rowData?.state}\nPostCode: ${rowData?.postcode}`;
  };

  const getFullWaybill = (rowData) => {
    if (rowData.lcBooking === true && rowData.waybillNo)
      return `${rowData.waybillNo} (LC)`;
    else if (rowData.lcBooking === false && rowData.waybillNo)
      return `${rowData.waybillNo} (LH)`;
    else return rowData.waybill_nos;
  };

  const handleSelectable = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const isAdminOrStaff =
    userType === LINEHAUL_STAFF_USER_TYPE ||
    userType === LINEHAUL_ADMIN_USER_TYPE;

  const showSelectableRows = isAdminOrStaff;

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setAlertConfig({
      isSuccess: boolData,
      size: "md",
      show: true,
      title: popupTitle,
      description: popupDesc,
    });
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    callback();
  };

  const callback = () => {
    clearSelection();
    // fetchOrderManifest(state.orderId);
    window.location.reload();
  };

  const clearSelection = () => {
    setSelectedRows([]);
    setClearRows(!clearRows);
  };

  const trackInLC = (lcWaybill) => {
    // Copy the given input
    navigator.clipboard
      .writeText(lcWaybill)
      .then(() => {
        window.open(LCWebsiteURL, "_blank");
      })
      .catch(() => {
        openPopup(false, "ERROR", SERVER_ERR_MSG);
      });
  };

  const printLCWaybill = (lcWaybill) => {
    window.open(`${LCTrackWaybillURL}${lcWaybill}`, "_blank");
  };

  const openOrderDtls = (shipmentId) => {
    const url = `/orderDetails?data=${shipmentId}`;
    window.open(url, "_blank");
  };

  const closeInbOrdSumm = () => {
    setShowIbOrdSumm(false);
    setSelectedRows(selectableRowsBack);
  };

  const openInbOrdSumm = (data = selectedRows, argOrdType) => {
    setShowIbOrdSumm(true);
    setSelectableRowsBack(data);
    setBookingType(argOrdType);
  };

  const fetchStates = async () => {
    let locStateArr = [];
    let data = await CallApiGet("getStateAndCityList");
    let state = {};
    if (data.respCode === 0) {
      data?.respData?.forEach((el) => {
        state = {
          label: el.map_state,
          value: el.map_state,
        };
        if (!locStateArr.some((obj) => obj.value === state.value)) {
          locStateArr.push(state);
        }
      });
    }
    setStateArr(locStateArr);
  };

  const openManfModal = (argManf, argAttach) => {
    const isInvoiceGen = !!state?.invoiceId;
    if (permissionCheck(INBORDS, "allowedWrite") && !isInvoiceGen) {
      setShowManifEdit(true);
      let attach =
        !!argAttach && Array.isArray(argAttach)
          ? argAttach.find((at) => at?.refId === argManf?.manifestId)
          : Object.assign({}, initManfAttach);
      argManf.attachments = [attach];
      setManfData(argManf);
    }
  };

  const closeManfModal = () => {
    setShowManifEdit(false);
    setManfData(JSON.parse(JSON.stringify(initManObj)));
  };

  const hideCancelConfirmAlert = () => {
    setCancelconfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const cancelShipment = async (data = cxlObj) => {
    if (!!data?.remarks && data?.remarks.length > 0) {
      const payLoad = {
        manifestIdArr: data?.mIds,
        cxlDto: data,
      };
      const resp = await CallApiPut("CancelManifest", payLoad);
      hideCancelConfirmAlert();
      handlePostApiCall(resp, resp.respMessage);
      setCxlObj(Object.assign({}, initCxlObj));
    } else {
      setCxlObj((state) => ({
        ...state,
        isRemarkBlank: true,
      }));
    }
  };

  const updateRemak = (value) => {
    let locCxlObj = Object.assign({}, cxlObj);
    locCxlObj.remarks = value;
    if (!!locCxlObj?.remarks && locCxlObj?.remarks?.length > 0) {
      locCxlObj.isRemarkBlank = false;
    } else {
      locCxlObj.isRemarkBlank = true;
    }
    setCxlObj(locCxlObj);
  };

  const handleSearchCriteriaChange = (key, value) => {
    switch (key) {
      case "searchKey":
        setSearchObj({
          searchKey: value,
          searchVal: "",
        });
        fetchOrderManifest(state?.orderId);
        break;
      case "searchVal":
        if (!BLACK_SPCL_CHARS.test(value)) {
          setSearchObj((state) => ({
            ...state,
            [key]: value,
          }));
        }
        break;
      default:
        break;
    }
  };

  const setDropPoint = (arr) => {
    let tempRow = arr[0];
    tempRow.attachments =
      !!tempRow?.attachments && typeof tempRow?.attachments === "string"
        ? JSON.parse(tempRow?.attachments)
        : Array.from(Object.assign({}, initManfAttach));
    setSelDropPoint(tempRow);
  };

  const handleFilter = () => {
    let locItemList = [...mainItemArr];
    let resultArr = [];
    locItemList.forEach((element) => {
      let tempArr = binarySearchArrOfObj(
        typeof element?.manf_data === "string"
          ? JSON.parse(element?.manf_data)
          : element?.manf_data,
        searchObj?.searchKey,
        searchObj?.searchVal
      );
      if (tempArr.length > 0) {
        element.manf_data = tempArr;
        resultArr.push(element);
      }
    });
    setItemList([...resultArr]);
    if (resultArr.length > 0) {
      setDropPoint(resultArr);
    } else {
      setSelDropPoint({});
    }
  };

  const clearFilter = () => {
    // fetchOrderManifest(state?.orderId);
    setItemList([...mainItemArr]);
    setDropPoint([...mainItemArr]);
    setSearchObj(Object.assign({}, initSearchObj));
    handleRowClick(mainItemArr[0]);
  };

  const bulkPrintWB = async () => {
    setShowSpinner(true);
    const locShipIdList = itemList
      .filter(
        (item) => !!item?.actOrdId && !!item?.status && item?.status !== 77
      )
      .map((item) => item.actOrdId);
    const payload = {
      shipmentIdList: locShipIdList,
      palletIdList: [],
      isSearchByOrder: true,
    };
    if (!!locShipIdList && locShipIdList.length > 0) {
      const resp = await CallApiPost("printWaybill", payload);
      if (resp.respCode === 200 && !!resp.respData) {
        window.open(resp.respData, "_blank");
      } else {
        openPopup(
          false,
          "ERROR",
          "Sorry, failed to process your request due to some technical error. Please try after sometime!!"
        );
      }
    } else {
      openPopup(
        false,
        "ERROR",
        "Sorry, failed to process your request as there are no waybills available to print!!"
      );
    }

    setShowSpinner(false);
  };

  const handleReorder = async (data) => {
    document.body.click();
    setSelectedRows([data]);
    setCxlObj({
      shipmentId: data?.actOrdId,
      customerId: data?.customerId,
      remarks: "",
    });
    openInbOrdSumm([data], "R");
  };

  const btn = (
    <>
      <ButtonGroup>
        {permissionCheck(POD_DO_ATTACH_INB, "allowedRead") && (
          <>
            <Button
              variant="primary"
              className="theme-violet pe-1"
              data-tooltip-id="btn-pod"
              onClick={() => fetchAttachments("POD", "POD Attachments")}
            >
              POD {GetSvgIcon("BiArrowDown")}
            </Button>
            <Button
              variant="primary"
              className="theme-violet pe-1"
              data-tooltip-id="btn-do"
              onClick={() => fetchAttachments("514", "DO Attachments")}
            >
              DO {GetSvgIcon("BiArrowDown")}
            </Button>
            <Button
              variant="primary"
              className="theme-violet"
              data-tooltip-id="btn-print-wb"
              onClick={bulkPrintWB}
            >
              {GetSvgIcon("Print")}
            </Button>
          </>
        )}
        {/* <Button
          variant="secondary"
          data-tooltip-id="btn-back"
          onClick={() => navigate(-1)}
        >
          {GetSvgIcon("BiSkipBackwardCircle")}
        </Button> */}
      </ButtonGroup>
      <Tooltip id="btn-back" content="Back" place="left" />
      <Tooltip id="btn-pod" content="POD Attachment" place="left" />
      <Tooltip id="btn-do" content="DO Attachment" place="left" />
      <Tooltip id="btn-print-wb" content="Print Waybill" place="left" />
    </>
  );

  return (
    <>
      <Layout spinnershow={showSpinner}>
        <div className="clearfix border-bottom row">
          <div className="col d-flex ">
            <div className="section-title my-1">Cross Border Manifest</div>
          </div>
          <div className="text-center col pt-1">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input formsty"
                type="radio"
                name="filterType"
                id="inlineRadio1"
                value="shipmentNo"
                checked={searchObj?.searchKey === "shipmentNo" ? true : false}
                onChange={(e) =>
                  handleSearchCriteriaChange("searchKey", e.target.value)
                }
              />
              <label
                className="form-check-label fw-bold ms-2"
                htmlFor="inlineRadio1"
              >
                Shipment No.
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input formsty"
                type="radio"
                name="filterType"
                id="inlineRadio2"
                value="marking"
                checked={searchObj?.searchKey === "marking" ? true : false}
                onChange={(e) =>
                  handleSearchCriteriaChange("searchKey", e.target.value)
                }
              />
              <label
                className="form-check-label fw-bold ms-2"
                htmlFor="inlineRadio2"
              >
                Marking
              </label>
            </div>
            <div className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={
                  searchOpts.find((opt) => opt.value === searchObj?.searchKey)
                    .label
                }
                value={searchObj.searchVal}
                onChange={(e) =>
                  handleSearchCriteriaChange("searchVal", e.target.value)
                }
              />
              <button
                className="input-group-text btn-outline-secondary theme-violet"
                id="basic-addon2"
                onClick={clearFilter}
              >
                {GetSvgIcon("Cross")}
              </button>
              <button
                className="input-group-text btn-outline-secondary theme-violet"
                id="basic-addon2"
                onClick={handleFilter}
              >
                {GetSvgIcon("Search")}
              </button>
            </div>
          </div>
          {!!state?.invoiceId ? (
            <div className="col d-flex align-items-center justify-content-end px-1">
              {btn}
            </div>
          ) : (
            <div className="text-end col mt-2">{btn}</div>
          )}
        </div>
        {/* {isOriginEmpty && (
          <Alert variant="warning" className="mt-2">
            Please add Origin Hub for this order to proceed for Booking
          </Alert>
        )} */}
        <Row className="mt-1">
          <Col md={9} className="customize-tbl-design pe-0">
            <div className="col-md-12 table-responsive customized-data-table position-relative ">
              <DataTable
                columns={dropCols}
                data={itemList}
                customStyles={customDataTableStyles}
                pagination
                striped
                highlightOnHover
                fixedHeader
                selectableRows={showSelectableRows}
                onSelectedRowsChange={handleSelectable}
                selectableRowDisabled={(row) => (!!row.orderNo ? true : false)}
                clearSelectedRows={clearRows}
                paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                paginationPerPage={defaultPageLimit}
                onRowClicked={(row) => handleRowClick(row)}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </Col>
          <Col md={3} className="p-0">
            <div className="container-fluid overflow-auto mt-1 vh-100 font-12">
              {/* <div className="d-flex justify-content-end mb-2">
                {permissionCheck(INBORDS, "allowedWrite") &&
                  JSON.stringify(selDropPoint) !== "{}" &&
                  (!!state?.invoiceId ? false : true) && (
                    <Button
                      onClick={() => setShowEditDropPoint(true)}
                      className="theme-violet font-12"
                    >
                      {" "}
                      Edit Drop Point
                    </Button>
                  )}
              </div> */}
              {!!selDropPoint?.manf_data &&
                Array.isArray(selDropPoint?.manf_data) &&
                selDropPoint?.manf_data?.map((item, index) => (
                  <Card
                    bg="Light"
                    className="mb-2 card-click"
                    key={index}
                    // onClick={() =>
                    //   openManfModal(item, selDropPoint?.attachments)
                    // }
                  >
                    <Card.Header className="theme-header">
                      <div className="d-flex justify-content-between">
                        <span>{item?.shipmentNo} (Shipment No.)</span>
                        {permissionCheck(INBORDS, "allowedWrite") && (
                          <span>{GetSvgIcon("PencilNoFill")}</span>
                        )}
                      </div>
                    </Card.Header>
                    <Card.Body className="p-2">
                      <Row className="mb-1">
                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Marking{" "}
                          </label>{" "}
                          <p className="m-0">{item?.marking}</p>
                        </Col>

                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            IN Date{" "}
                          </label>{" "}
                          <p className="m-0">
                            {/* {moment(item?.inDt, DATE_FORMAT_SERVER, true).format(
                            DATE_FORMAT_MOMENT
                          )} */}
                            {item?.inDt}
                          </p>
                        </Col>
                        <Col md={3} className="m-0">
                          <label className="form-label text-primary m-0">
                            CN Job{" "}
                          </label>{" "}
                          <p className="m-0">{item?.cnJob}</p>
                        </Col>
                        <Col md={2} className="m-0">
                          <label className="form-label text-primary m-0">
                            M3{" "}
                          </label>{" "}
                          <p className="m-0">{item?.m3}</p>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Quantity{" "}
                          </label>{" "}
                          <p className="m-0">{item?.qty}</p>
                        </Col>
                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Weight{" "}
                          </label>{" "}
                          <p className="m-0">{item?.wgt}</p>
                        </Col>

                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Job No{" "}
                          </label>{" "}
                          <p className="m-0">{item?.jobNo}</p>
                        </Col>
                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Status{" "}
                          </label>{" "}
                          <p className="m-0">{item?.status}</p>
                        </Col>
                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Location{" "}
                          </label>{" "}
                          <p className="m-0">{item?.location}</p>
                        </Col>
                      </Row>
                      <Row className="mb-1">
                        {!!item?.remarks && (
                          <Col className="m-0">
                            <label className="form-label text-primary m-0">
                              Remarks{" "}
                            </label>{" "}
                            <p className="m-0">{item?.remarks}</p>
                          </Col>
                        )}
                        {!!item?.specialRemark && (
                          <Col className="m-0">
                            <label className="form-label text-primary m-0">
                              Special Remarks{" "}
                            </label>{" "}
                            <p className="m-0">{item?.specialRemark}</p>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col className="m-0">
                          <label className="form-label text-primary m-0">
                            Contact{" "}
                          </label>{" "}
                          <p className="m-0">{item?.contact}</p>
                        </Col>
                        <Col md={6} className="m-0">
                          <label className="form-label text-primary m-0">
                            Attachment{" "}
                          </label>{" "}
                          <p className="m-0">
                            {!!selDropPoint?.attachments &&
                            Array.isArray(selDropPoint?.attachments)
                              ? selDropPoint?.attachments.find(
                                  (at) => at?.refId === item?.manifestId
                                )?.attachName
                              : ""}
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ))}
            </div>
          </Col>
        </Row>
        <CrossBorderManifestEdit
          show={showManifEdit}
          handleClose={closeManfModal}
          selDropPoint={selDropPoint}
          manfData={manfData}
        />
        <CrossBorderManifestEditDrP
          show={showEditDropPoint}
          handleClose={() => setShowEditDropPoint(false)}
          stateArr={stateArr}
          selDropPoint={selDropPoint}
          sbuList={sbuList}
          srvcAreaOpt={srvcAreaOpt}
          onSubmit={handleDropPointChange}
          invoiceId={state?.invoiceId}
        />
        <InboundOrderAttachment
          config={attachModalConfig}
          handleClose={closeAttachModal}
          attachList={attachList}
          setShowSpinner={setShowSpinner}
        />

        <ClaimHookComponent />
        <ModalConfirmAlert
          confirmAlertConfig={cancelconfirmConfig}
          hideConfirmAlert={hideCancelConfirmAlert}
          confirmAlert={() => cancelShipment()}
        >
          <div className="row">
            <div className="col-md-3">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Reason <span className="text-danger">&#9733;</span>
              </label>
            </div>
            <div className="col-md-9 mb-2">
              <input
                id="cancellation-reason"
                type="text"
                className="form-control"
                name="cancellationReason"
                value={cxlObj?.remarks}
                autoComplete="false"
                onChange={(e) => updateRemak(e.target.value)}
              />
              {cxlObj?.isRemarkBlank && (
                <div style={{ textAlign: "left" }}>
                  <span className="badge bg-danger">
                    &#9888; This field is required.
                  </span>
                </div>
              )}
            </div>
          </div>
        </ModalConfirmAlert>
      </Layout>
    </>
  );
};

export default CrossBorderManifest;
