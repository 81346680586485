import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { Tooltip } from "react-tooltip";
import { GetSvgDownload } from "../../images/svglist";
import { DownloadFile } from "../Helper/FileStoreService";

const VehicleLeaseHistory = (props) => {
  const columns = [
    {
      name: "Lease Start Date",
      selector: (row) => row.start_date,
      sortable: true,
      filterable: true,
    },
    {
      name: "Lease End Date",
      selector: (row) => row.end_date,
      sortable: true,
      filterable: true,
    },
    {
      name: "Lease Recipient",
      selector: (row) => `${row.partner_acct} (${row.partner_comp})`,
      sortable: true,
      filterable: true,
    },
    {
      name: "Lease Cost",
      selector: (row) => `RM ${row.leasing_price}`,
      sortable: true,
      filterable: true,
    },
    {
        name: "Terminated On",
        selector: (row) => row.terminated_on ? row.terminated_on : "NA",
        sortable: true,
        filterable: true,
    },
    {
        name: "Terminated By",
        selector: (row) => row.terminated_by ? row.terminated_by : "NA",
        sortable: true,
        filterable: true,
    },
    {
      name: "Termination Reason",
      selector: (row) => row.termination_reason,
      cell: (row) => (
        row.termination_reason ? (
            <>
                <span data-tooltip-id={"reason-" + row.lease_id}>{row.termination_reason}</span>
                <Tooltip id={"reason-" + row.lease_id} content={row.termination_reason} place="left" />
            </>
        ): (
            "NA"
        )
      ),
      filterable: true,
    },
    {
      name: "Agreement",
      selector: (row) => row.agreement,
      cell: (row) =>
        row.agreement ? (
          <>
            <span onClick={() => DownloadFile(row.agreement)}>
              {GetSvgDownload({ required: false })}
            </span>
          </>
        ) : (
          "NA"
        ),
    },
  ];

  return (
    <div
      className="modal fade"
      id="lease-history-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Lease History
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="clearfix"></div>
                    <div className="col-md-12 mt-1 popup-data-table position-relative">
                      <DataTable
                        columns={columns}
                        data={props.data}
                        pagination
                        striped
                        highlightOnHover
                        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                        paginationPerPage={defaultPageLimit}
                        customStyles={customDataTableStyles}
                        defaultSortAsc={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default border me-2" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VehicleLeaseHistory;
