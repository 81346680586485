import React, { useState } from "react";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";
import ModalAlert from "../Layout/ModalAlert";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";
import { DATE_FORMAT_MOMENT, SERVER_ERR_MSG } from "../Helper/Constants";
import moment from "moment";
import { CallApiGet } from "../Helper/serviceApi";
import Spinner from "../Layout/Spinner";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const useClaimHook = () => {
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [claimConfirmConfig, setClaimConfirmConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [showSpinner, setShowSpinner] = useState(false);
  const [claimData, setClaimData] = useState();
  const navigate = useNavigate();

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const openAlert = (argConfig) => {
    setAlertConfig({
      isSuccess: argConfig?.isSuccess,
      size: "sm",
      show: !!argConfig?.show ? argConfig.show : false,
      title: argConfig.title,
      description: argConfig.description,
    });
  };

  const hideClaimConfirm = () => {
    setClaimConfirmConfig({
      isSuccess: false,
      size: "",
      show: false,
      title: "",
      description: "",
    });
  };

  const navToClaimRaisePage = (argData) => {
    if (argData?.shipmentId === null || argData?.shipmentId === undefined) {
      argData = JSON.parse(JSON.stringify(claimData));
    }
    let encoded = Buffer.from(
      JSON.stringify({
        claimId: 0,
        orderId: argData?.shipmentId,
        palletId: 0,
        orderNo: argData?.orderNo,
        origin: argData?.origin?.sbuCode,
        destination: argData?.destination?.sbuCode,
        shipDt: moment(argData?.shipmentDate).format(DATE_FORMAT_MOMENT),
        estDt: moment(argData?.estDeliveryDate).format(DATE_FORMAT_MOMENT),
        ordSts: argData?.statusText,
      })
    ).toString("base64");
    //window.open(`/raiseClaim?data=${encoded}`, "_blank");
    navigate(`/raiseClaim?data=${encoded}`);
  };

  const fetchClaimCnt = async (argData) => {
    setShowSpinner(true);
    const uri = "ValidateClaimRaise?orderId=" + argData?.shipmentId;
    const resp = await CallApiGet(uri);
    if (resp.respCode === 200 && !Number.isNaN(parseInt(resp.respData))) {
      let claimCnt = parseInt(resp.respData);
      if (claimCnt === 0) {
        navToClaimRaisePage(argData);
      } else if (claimCnt > 0 && claimCnt < 3) {
        setClaimData(argData);
        document.body.click();
        setClaimConfirmConfig({
          isSuccess: false,
          size: "md",
          show: true,
          title: "Claim Request Confirmation",
          description: "You are left with " + (3 - claimCnt) + " more attempts",
        });
      } else {
        openAlert({
          isSuccess: false,
          title: "ERROR",
          description:
            "Sorry, You can not proceed further as your request has been rejected 3 times already!!",
        });
      }
    } else {
      openAlert({
        isSuccess: false,
        title: "ERROR",
        description: SERVER_ERR_MSG,
      });
    }
    setShowSpinner(false);
  };

  const showClaimForm = (argData) => {
    fetchClaimCnt(argData);
  };

  const ClaimHookComponent = () => (
    <>
      {!!alertConfig && (
        <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
          <p>{alertConfig.description}</p>
        </ModalAlert>
      )}

      <ModalConfirmAlert
        confirmAlertConfig={claimConfirmConfig}
        hideConfirmAlert={hideClaimConfirm}
        confirmAlert={navToClaimRaisePage}
      >
        <div>
          <p className="fw-bold">{claimConfirmConfig?.description}</p>
          <p>Are you sure you want to continue?</p>
        </div>
      </ModalConfirmAlert>
      <Spinner show={showSpinner} />
    </>
  );

  return { openAlert, showClaimForm, ClaimHookComponent };
};

export default useClaimHook;
