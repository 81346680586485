import React, { useEffect, useRef, useState } from "react";
import Layout from "../Layout/Layout";
import CrossBorderBookingList from "./CrossBorderBookingList";
import { CallApiDelete, CallApiGet, CallApiPost } from "../Helper/serviceApi";
import {
  CROSS_BORDER_ORIGIN,
  ORD_INB_EXT,
} from "../../ConfigConstants/ConfigConstants";
import { NET_ERR_MSG, SERVER_ERR_MSG } from "../Helper/Constants";
import InboundBill from "../Inbound Order/InboundBill";
import {
  getRandomInteger,
  permission,
  toBase64,
} from "../Helper/CommonMethods";
import moment from "moment";
import Popup from "../Layout/Popup";
import ModalAlert from "../Layout/ModalAlert";
import ModalConfirmAlert from "../Layout/ModalConfirmAlert";

const initModalConfig = {
  show: false,
  size: "",
  title: "",
};
const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initDropdown = {
  supplierList: [],
  customerList: [],
};

export const handleAttchment = async (
  event,
  argRefId,
  attachId,
  attachType,
  callback
) => {
  const file = event.target.files;
  const photo = await toBase64(file[0]);
  const argFileName = file[0].name;
  var ext = argFileName.substring(argFileName.lastIndexOf(".") + 1);
  const fileName =
    "INB_" +
    attachType +
    "_" +
    getRandomInteger(1, 1000) +
    "_" +
    moment().format("YYYYMMDD") +
    moment().milliseconds() +
    "." +
    ext;

  if (!!photo) {
    let attchObj = {};
    attchObj.refId = argRefId;
    attchObj.attachName = fileName;
    attchObj.attchBase64 = photo;
    attchObj.attachUrl = "";
    attchObj.attachId = !!attachId ? attachId : 0;
    attchObj.attachType = !!attachType ? 700 : attachType;
    let attchArr = [attchObj];
    if (typeof callback === "function") {
      callback(attchArr);
    }
  }
};

const CrossBorderBookingMain = () => {
  const [crossBorderOrderList, setcrossBorderOrderList] = useState([]);
  const [showSpinner, setShowSpinner] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [billData, setBillData] = useState();
  const [billConfig, setBillConfig] = useState(
    JSON.parse(JSON.stringify(initModalConfig))
  );
  const [modalConfig, setModalConfig] = useState(
    JSON.parse(JSON.stringify(initModalConfig))
  );
  const [inbDeleteConfig, setInbDeleteConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const modalRef = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const [dropdownData, setDropdownData] = useState(
    Object.assign({}, initDropdown)
  );
  const [error, setError] = useState(false);

  useEffect(() => {
    fetchAllCrossBorderOrder();
  }, []);

  const fetchAllCrossBorderOrder = async () => {
    setShowSpinner(true);
    const uri = "GetInboundOrderList?orderType=" + ORD_INB_EXT;
    const data = await CallApiGet(uri);
    setcrossBorderOrderList(data.respData);
    setShowSpinner(false);
  };

  const handleEdit = (argOrder) => {
    // updateOrderState(argOrder);
    openEditModal();
  };

  const openEditModal = () => {
    setModalConfig({
      show: true,
      size: "lg",
      title: "EDIT ORDER",
    });
  };

  const showAlert = (boolVal, title, desc) => {
    setAlertConfig({
      isSuccess: boolVal,
      size: "md",
      show: true,
      title: title,
      description: desc,
    });
  };

  const invoiceDownload = async (orderData) => {
    document.getElementById(`row-action-button-${orderData?.orderId}`).click();
    setShowSpinner(true);
    //== This customer type is made false intentionally
    //== Invoice template of International Inbound Order is always of Personal Customer type
    let custType = false;
    const response = await CallApiGet(
      `generateAllInvoicePdf/${orderData.invoiceNo}/${custType}`
    );
    setShowSpinner(false);
    if (response.respCode === 200) {
      window.open(response.respData, "_blank");
    }
  };
  const generateInvoice = async (data) => {
    setShowSpinner(true);
    handleBillClose();
    const resp = await CallApiPost("createInbInvoice", data);
    switch (resp.respCode) {
      case 200:
      case 0:
        fetchAllCrossBorderOrder();
        showAlert(true, "SUCCESS", "Invoice Generated successfully.");

        break;
      case 500:
        showAlert(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", resp.respMessage);
        break;
    }
    setShowSpinner(false);
  };
  const handleManifestBill = async (row) => {
    setShowSpinner(true);
    let payload = {
      orderId: row.orderId,
      userId: row.custId,
    };
    const resp = await CallApiPost("getInboundBillDtls", payload);
    switch (resp.respCode) {
      case 200:
      case 0:
        if (!!resp.respData) {
          setBillData(resp.respData);
          openBillModal();
        } else {
          showAlert(false, "ERROR", resp.respMessage);
        }
        break;
      case 500:
        showAlert(false, "ERROR", resp.respMessage);
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", resp.respMessage);
        break;
    }
    setShowSpinner(false);
  };

  const handleBillClose = () => {
    setBillConfig({
      show: false,
      size: "",
      title: "",
    });
    // setOrder(initInbOrd);
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const hideDeleteAlert = () => {
    setInbDeleteConfig({
      isSuccess: false,
      size: "md",
      show: false,
      title: "",
      description: "",
      data: null,
    });
  };

  const openDeleteAlert = (argData) => {
    setInbDeleteConfig({
      isSuccess: true,
      size: "md",
      show: true,
      title: "Delete Manifest",
      description: (
        <>
          Are you sure you want to delete manifest <b>{argData?.orderNo} ?</b>
        </>
      ),
      data: argData,
    });
  };

  const openBillModal = () => {
    setBillConfig({
      show: true,
      size: "xl",
      title: "BILL",
    });
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    // setOrder((state) => ({
    //   ...state,
    //   [name]: value,
    // }));
  };
  const handleFieldChange = (field) => (selectedOption) => {
    // setOrder((prevOrder) => ({
    //   ...prevOrder,
    //   [field]: selectedOption ? selectedOption.value : null,
    // }));
  };
  const handleDateChange = (name, value) => {
    // setOrder((state) => ({
    //   ...state,
    //   [name]: value,
    // }));
  };

  const handleFileAttach = (argAttach) => {
    // setOrder((state) => ({
    //   ...state,
    //   attachments: argAttach,
    // }));
  };
  // const handleSubmit = async () => {
  //   if (!error) {
  //     setShowSpinner(true);
  //     order["items"] = [];
  //     const resp = await CallApiPost("UpdateInbOrder", order);
  //     handleClose();
  //     handelResp(resp);
  //     setShowSpinner(false);
  //   }
  // };

  const handelResp = (resp) => {
    switch (resp.respCode) {
      case 200:
      case 0:
        showAlert(true, "SUCCESS", resp.respMessage);
        fetchAllCrossBorderOrder();
        break;
      case 500:
        showAlert(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        showAlert(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        showAlert(false, "ERROR", resp.respMessage);
        break;
    }
  };
  const callback = () => {
    fetchAllCrossBorderOrder();
  };

  const handleDltManifest = async () => {
    const { data } = inbDeleteConfig;
    setShowSpinner(true);
    const resp = await CallApiDelete(`DeleteInbOrder?orderId=${data.orderId}`);
    hideDeleteAlert();
    handelResp(resp);
    setShowSpinner(false);
  };

  return (
    permission(CROSS_BORDER_ORIGIN).allowedRead && (
      <>
        <Layout spinnershow={showSpinner}>
          <div className="clearfix border-bottom pb-2">
            <div className="float-start">
              <div className="section-title mt-3">CROSS BORDER ORDERS</div>
            </div>
          </div>
          <CrossBorderBookingList
            crossBorderOrderList={crossBorderOrderList}
            invoiceDownload={invoiceDownload}
            openBill={handleManifestBill}
            dltManifest={openDeleteAlert}
            handleEdit={handleEdit}
          />

          <ModalConfirmAlert
            confirmAlertConfig={inbDeleteConfig}
            hideConfirmAlert={hideDeleteAlert}
            confirmAlert={handleDltManifest}
          >
            <p>{inbDeleteConfig.description}</p>
          </ModalConfirmAlert>

          {billConfig?.show && (
            <InboundBill
              modalConfig={billConfig}
              handleClose={handleBillClose}
              billData={billData}
              generateInvoice={generateInvoice}
            />
          )}

          {/* {modalConfig?.show && (
          <CrossBorderOrderEdit
            modalConfig={modalConfig}
            handleClose={handleClose}
            order={order}
            handleOnChange={handleOnChange}
            handleDateChange={handleDateChange}
            handleSubmit={handleSubmit}
            handleFileAttach={handleFileAttach}
            dropdownData={dropdownData}
            handleFieldChange={handleFieldChange}
            error={error}
            setError={setError}
          />
        )} */}

          {alertConfig?.show && (
            <ModalAlert alertConfig={alertConfig} hideAlert={hideAlert}>
              <p>{alertConfig.description}</p>
            </ModalAlert>
          )}
          <Popup
            isSuccess={isSuccess}
            title={title}
            description={description}
            handler={callback}
            // requestId={requestId}
          />
        </Layout>
      </>
    )
  );
};

export default CrossBorderBookingMain;
