import React, { useState } from "react";
import Layout from "../../Layout/Layout";
import GetSvgIcon from "../../../images/svglist";
import NewsAndBulletinList from "./NewsAndBulletinList";
import NewsAndBulletinAddEdit, { initNewsObj } from "./NewsAndBulletinAddEdit";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import { useEffect } from "react";
import CustomAlert from "../../Layout/CustomAlert/CustomAlert";
import Popup from "../../Layout/Popup";

const initModalConfig = {
  show: false,
  title: "",
};
const NewsAndBulletinMain = () => {
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );
  const [newsList, setNewsList] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [selNews, setSelNews] = useState(Object.assign({}, initNewsObj));

  const [newsId, setNewsId] = useState(0);
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [showCustomAlert, setShowAlert] = useState(false);

  const initAlertConfig = {
    isSuccess: false,
    size: "",
    show: false,
    title: "",
    description: "",
  };
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);

  const hideAlert = async () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
    setSpinner(true);
    await getNews();
    setSpinner(false);
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const handleBtnClick = (argData, argTitle) => {
    setSelNews(Object.assign({}, argData));
    setModalConfig({
      show: true,
      title: argTitle,
    });
  };

  const handleCLose = () => {
    setModalConfig({
      show: false,
      title: "",
    });
  };

  const handleActiveDeactive = (argRow) => {
    if (!argRow.active) {
      setAlertBody(
        <>
          Are you sure, you want to deactivate <b>{argRow.bulletinTitle}</b>
        </>
      );
      setAlertTitle("Deactivate News");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate <b>{argRow.bulletinDescription}</b>
        </>
      );
      setAlertTitle("Activate News");
    }
  };

  useEffect(() => {
    setSpinner(true);
    Promise.all([getNews()])
      .then(() => setSpinner(false))
      .catch((error) => {
        console.error("Error:", error);
        setSpinner(false);
      });
  }, []);

  const getNews = async () => {
    const uri = "NewsAndBulletin";
    let data = await CallApiGet(uri);
    if (data.respCode === 200 || data.respCode === 0) {
      setNewsList(data.respData);
    } else if (data.respCode === 401) {
      console.error(data);
    } else {
      console.error(data);
    }
  };

  const updateNewsStatus = async () => {
    const uri = "ActiveDeactiveBulletin?id=" + newsId;
    const response = await CallApiPost(uri, {});
    handlePostApi(response);
    toggleAlert(false);
  };

  const handlePostApi = (argResp) => {
    if (argResp?.respCode === 200) {
      showAlert({
        isSuccess: true,
        title: "SUCCESS",
        description: argResp?.respMessage,
      });
    } else if (argResp?.respCode === 409) {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: argResp?.respMessage,
      });
    } else {
      showAlert({
        isSuccess: false,
        title: "ERROR",
        description: "Something went wrong. Please try after sometime!!",
      });
    }
  };

  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2 mb-2">
          <div className="float-start">
            <div className="section-title mt-1">News And Bulletin </div>
          </div>
          <div className="float-end d-flex align-items-center">
            <div className="mt-1">
              <button
                className="btn btn-outline-secondary theme-violet text-nowrap"
                type="button"
                onClick={() =>
                  handleBtnClick(initNewsObj, "ADD NEWS & BULLETIN")
                }
              >
                {GetSvgIcon("PlusCircle")} Add News
              </button>
            </div>
          </div>
        </div>
        <NewsAndBulletinList
          newsList={newsList}
          handleAction={handleBtnClick}
          handleActiveDeactive={handleActiveDeactive}
          toggleReset={toggleAlert}
          setNewsId={setNewsId}
        />
        {showCustomAlert && (
          <CustomAlert
            toggleReset={toggleAlert}
            alertTitle={alertTitle}
            alertBody={alertBody}
            callback={updateNewsStatus}
          />
        )}

        <NewsAndBulletinAddEdit
          modalConfig={modalConfig}
          onHide={handleCLose}
          handlePostApi={handlePostApi}
          selNews={selNews}
        />
      </Layout>
    </>
  );
};

export default NewsAndBulletinMain;
