import moment from "moment";
import React from "react";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  DATE_TIME_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import { Tooltip } from "react-tooltip";
import DataTable from "react-data-table-component-with-filter";
import GetSvgIcon from "../../../images/svglist";
import { custConditionalStyle, custConditionalStyle2 } from "../InvoiceList";
import { UNPAID_INV_STAT_ID } from "../../../ConfigConstants/ConfigConstants";

const PartnerInvoiceList = ({
  invoiceDataList,
  paymentStatusBtn,
  setInvoiceDistributionList,
  clearSelectedRows,
  setTotalaBillAmount,
  prtInvDownload,
  customerDtls,
  latestInvAmt,
  avlCreditLimit,
}) => {
  const prtInvoiceCol = [
    {
      name: "Trans Date",
      selector: (row) => moment(row.trans_date).format(DATE_FORMAT_MOMENT),
      // selector: (row) => new Date(row.trans_date),
      cell: (row) => moment(row.trans_date).format(DATE_TIME_FORMAT_MOMENT),
      // sortable: true,
      filterable: true,
      // omit: hideColums,s
      // sortType: (a, b) => {
      //   return new Date(b.values.trans_date) - new Date(a.values.trans_date);
      // },
    },
    {
      name: "Inv/Payment No",
      selector: (row) => row.ref_no,
      sortable: true,
      filterable: true,
      // omit: hideColums,
      cell: (row) => (
        <>
          <div>
            {row.ref_no}
            {!!row.ref_no && row.ref_no.includes("MTR") && (
              <span
                data-tooltip-id={"msg-" + row.ref_no}
                className="mx-2 my-3 theme-violet-txt position-absolute top-0 end-0"
              >
                {GetSvgIcon("ToolTip")}
              </span>
            )}
          </div>
          <Tooltip
            id={"msg-" + row.ref_no}
            content={
              !!row.ref_no && row.ref_no.includes("MTR") ? row.mp_note : ""
            }
            place="right"
            style={{ zIndex: "100" }}
          />
        </>
      ),
    },
    {
      name: "Transaction Detail",
      selector: (row) =>
        row.particulars + (!!row.mp_note ? " (" + row.mp_note + ")" : ""),

      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      filterable: true,
    },
    {
      name: "Debit",
      filterable: true,
      selector: (row) => row.dr_amt.toFixed(2),
      conditionalCellStyles: custConditionalStyle2,
    },
    {
      name: "Credit",
      selector: (row) => row.cr_amt.toFixed(2),
      filterable: true,
      conditionalCellStyles: custConditionalStyle,
    },
  ];

  const ptrInvColumns = [
    ...prtInvoiceCol,
    {
      name: "",
      cell: (row) =>
        row.inv_id > 0 && !row?.ref_no?.includes("-DN-") ? (
          <>
            <div
              data-tooltip-id={"inv-" + row?.ref_no + row?.trans_date}
              className="me-3"
              onClick={() => prtInvDownload(row)}
            >
              {GetSvgIcon("IconFiledownload")}
            </div>
            <Tooltip
              id={"inv-" + row.ref_no}
              content="Download Invoice"
              place="left"
            />
          </>
        ) : (
          ""
        ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];

  const selectInvoice = ({ selectedRows }) => {
    let dataList = [];
    selectedRows.forEach((v, i) => {
      dataList.push({
        invoiceId: v.inv_id,
        invoiceAmount: v.dr_amt,
        invoiceNo: v.ref_no,
      });
    });
    setInvoiceDistributionList(dataList);
    let totalAmount = 0.0;
    dataList.forEach((v, k) => {
      totalAmount = totalAmount + v.invoiceAmount;
    });
    setTotalaBillAmount(totalAmount);
  };

  const rowDisabledCriteria = (row) => row.stat_id !== UNPAID_INV_STAT_ID;

  return (
    <>
      <div className="theme-blue border border-1 rounded-2 p-2 mt-4 ">
        {/* <div className="text-center f"><h3>Current Statement Amount</h3></div> */}
        <div className="row">
          <div className="col-md-3 my-2 ">
            <div className="fw-bold">Partner :</div>
            <div className="mt-1 font-18">
              {customerDtls.custName}({customerDtls.custAccount})
            </div>
          </div>
          {/* <div className="col my-2 ">
            <div className="fw-bold">Opening Balance : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custOpeningBls?.toFixed(2)}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Total Bill : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custTotalBill?.toFixed(2)}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Total Paid : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custTotalPaid?.toFixed(2)}
            </div>
          </div> */}
          <div className="col my-2">
            <div className="fw-bold">Latest Monthly Statement Amount : </div>
            <div className="mt-1 font-18">
              {!!latestInvAmt ? "RM " + latestInvAmt?.toFixed(2) : "N/A"}
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Outstanding : </div>
            <div className="mt-1 font-18">
              RM {customerDtls.custOutStndBls?.toFixed(2)}
              {/* {JSON.stringify(customerDtls)} */}
            </div>
          </div>
          {/* <div className="col my-2">
            <div className="fw-bold">Available Credit Note:</div>
            <div className="mt-1 font-18">
              RM{" "}
              <button
                className=" bg-white theme-violet-txt fw-bold rounded-pill"
                // onClick={openCustCn}
              >
                {" "}
                {!!avlCreditLimit ? avlCreditLimit?.toFixed(2) : "0.00"}
              </button>
            </div>
          </div>
          <div className="col my-2">
            <div className="fw-bold">Un-Invoiced Bill:</div>
            <div className="mt-1 font-18">
              RM{" "}
              {!!customerDtls.custUnBill && customerDtls.custUnBill !== 0 ? (
                <button
                  className=" bg-white theme-violet-txt fw-bold rounded-pill"
                  // onClick={() => {
                  //   fetchAllInvoiceList(customerDtls.custId, "UBL").then(
                  //     (res) => openModal()
                  //   );
                  // }}
                >
                  {" "}
                  {customerDtls.custUnBill?.toFixed(2)}
                </button>
              ) : (
                "0.00"
              )}
            </div>
          </div> */}

          {/* <div className="col align-self-center">
            <button
              className="bg-white border border-0 px-3 py-2 fw-bold rounded-pill"
              onClick={() => viewAllCnAndDnList("cd")}
            >
              DN/CN Details
            </button>
          </div> */}
        </div>
      </div>
      <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
        <DataTable
          columns={ptrInvColumns}
          data={invoiceDataList}
          customStyles={customDataTableStyles}
          selectableRows={paymentStatusBtn}
          onSelectedRowsChange={selectInvoice}
          selectableRowDisabled={rowDisabledCriteria}
          clearSelectedRows={clearSelectedRows}
          pagination
          striped
          highlightOnHover
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>
    </>
  );
};

export default PartnerInvoiceList;
