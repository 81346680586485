import React, { useEffect, useState } from "react";
import {
  ALPHANUM,
  ALPHANUM_NAME,
  maxVhclSizeForHQ,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  TWO_DECIMAL,
} from "../Helper/Constants";
import { Controller } from "react-hook-form";
import { CallApiGet } from "../Helper/serviceApi";
import Select from "react-select";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  VHCL_OWNER_PARTNER,
} from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const initialVhclType = {
  id: 0,
  vhclType: "",
  vhclSize: 0,
  maxWeight: 0,
  maxPallets: 0,
  palletHeight: 0,
  palletWeight: 0,
  maxPalletWeight: 0,
};
const initDropdownData = {
  vhclOwnerOpts: [],
  bondedTypeOpts: [],
};
const initialHub = {
  id: 0,
  name: "",
  code: "",
  phone: "",
  address: "",
  sbyType: 0,
  sbuLat: 0.0,
  sbuLong: 0.0,
  parent: null,
};
const tailGateOpts = [
  { label: "YES", value: true },
  { label: "NO", value: false },
];
const VehicleDetailsEdit = (props) => {
  const {
    register,
    clearErrors,
    setValue,
    watch,
    errors,
    control,
    dropdownData,
    hq,
  } = props;
  const [vhclTypes, setVhclTypes] = useState([initialVhclType]);
  const [sbuList, setSbuList] = useState([initialHub]);
  const userProfileId =
    LINEHAUL_PARTNER_COMPANY_USER_TYPE === getUserInfo("userType")
      ? getUserInfo("acctId")
      : 0;
  useEffect(() => {
    loadVhclTypes();
    loadHubs();
  }, []);

  const watchVhclId = watch("vhclId");
  const watchAssignTo = watch("assignTo");

  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");
    if (data.respCode === 200 || data.respCode === 0) {
      setVhclTypes(!!data.respData ? data.respData : []);
    }
  };
  const setAllocatedTo = (event) => {
    clearErrors("vhclType");
    setValue(event.target.id, event.target.value);

    const selectedVhclType = vhclTypes.filter((type) => {
      return type.id === parseInt(event.target.value);
    });
    let hqId = hq.id;
    if (selectedVhclType[0].vhclSize > maxVhclSizeForHQ) {
      setValue("allocatedTo", hqId);
    }
  };
  const loadHubs = async () => {
    let data = await CallApiGet("getAllSbuList");
    if (data.respCode === 200 || data.respCode === 0) {
      setSbuList(data.respData);
    }
  };
  return (
    <div className="row">
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Vehicle Type <span className="text-danger">&#9733;</span>
        </label>
        <select
          id="vhclType"
          className="form-select"
          aria-label="Default select example"
          name="vhclType"
          {...register("vhclType", {
            required: {
              value: true,
              message: REQUIRED_ERR_MSG,
            },
          })}
          onChange={(event) => setAllocatedTo(event)}
        >
          <option value="">-Select-</option>
          {vhclTypes.map((data, index) => {
            const { id, vhclType } = data;
            if (vhclType === id) {
              return (
                <option key={index} value={id} selected>
                  {vhclType}
                </option>
              );
            } else {
              return (
                <option key={index} value={id}>
                  {vhclType}
                </option>
              );
            }
          })}
        </select>

        {errors.vhclType && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.vhclType.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Vehicle No<span className="text-danger">&#9733;</span>
        </label>

        <input
          id="vhclNo"
          type="text"
          className="form-control"
          name="vhclNo"
          disabled={+watchVhclId > 0}
          {...register("vhclNo", {
            required: {
              value: true,
              message: REQUIRED_ERR_MSG,
            },
            pattern: {
              value: ALPHANUM,
              message: PATTERN_ERR_MSG,
            },
            maxLength: {
              value: 20,
              message: "Field should contain 20 characters!!!",
            },
          })}
        />
        {errors.vhclNo && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.vhclNo.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Owner<span className="text-danger">&#9733;</span>
        </label>
        <input
          id="vhclOwner"
          type="text"
          className="form-control"
          name="vhclOwner"
          {...register("vhclOwner", {
            required: {
              value: true,
              message: REQUIRED_ERR_MSG,
            },
            pattern: {
              value: ALPHANUM_NAME,
              message: PATTERN_ERR_MSG,
            },
            maxLength: {
              value: 50,
              message: "Field should contain 50 characters!!!",
            },
          })}
        />
        {errors.vhclOwner && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.vhclOwner.message}
            </span>
          </div>
        )}
      </div>
      {+userProfileId === 0 && (
        <div className="col-md-3 mb-2">
          <label htmlFor="floatingInputGrid" className="form-label theme-label">
            Assign To<span className="text-danger">&#9733;</span>
          </label>
          {+watchAssignTo === VHCL_OWNER_PARTNER ? (
            <input
              name="assignToVal"
              className="form-control"
              disabled
              {...register("assignToVal")}
            />
          ) : (
            <Controller
              name="assignTo"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: REQUIRED_ERR_MSG,
                },
                min: {
                  value: 1,
                  message: REQUIRED_ERR_MSG,
                },
              }}
              render={({ field: { onChange, value } }) => (
                <Select
                  placeholder="Select Assignee"
                  isSearchable={true}
                  isClearable={true}
                  className="form-control p-0"
                  options={dropdownData?.vhclOwnerOpts}
                  value={
                    !!value
                      ? dropdownData?.vhclOwnerOpts?.filter(
                          (vc) => vc.value === value
                        )
                      : value
                  }
                  onChange={(opt) => onChange(!!opt ? opt.value : 0)}
                />
              )}
            />
          )}

          {errors?.assignTo && (
            <div>
              <span className="text-danger fw-bold font-12">
                &#9888; {errors?.assignTo.message}
              </span>
            </div>
          )}
        </div>
      )}

      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Primary SBU<span className="text-danger">&#9733;</span>
        </label>
        <Controller
          name="allocatedTo"
          className="form-select"
          control={control}
          rules={{
            required: { value: true, message: REQUIRED_ERR_MSG },
            validate: (v) => (+v === 0 || v === "" ? REQUIRED_ERR_MSG : true),
          }}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Select Primary SBU"
              isSearchable={true}
              isClearable={true}
              className="form-control p-0"
              options={sbuList}
              value={
                !!value ? sbuList?.find((opt) => opt.value === value) : value
              }
              onChange={(opt) => onChange(!!opt ? opt.value : 0)}
            />
          )}
        />
        {errors?.allocatedTo && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors?.allocatedTo.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Vehicle Length <span className="text-danger">&#9733;</span>(ft)
        </label>
        <input
          id="vhclLength"
          type="number"
          className="form-control"
          name="vhclLength"
          {...register("vhclLength", {
            required: {
              value: true,
              message: "This Field is Required!!!",
            },
            pattern: {
              value: TWO_DECIMAL,
              message: "Please check format: 999999999.99",
            },
            min: {
              value: 1,
              message: "Please add a valid length!!!",
            },
          })}
        />
        {errors.vhclLength && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.vhclLength.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          BDM <span className="text-danger">&#9733;</span>(kg)
        </label>
        <input
          id="bdm"
          type="number"
          className="form-control"
          name="bdm"
          {...register("bdm", {
            required: {
              value: true,
              message: "This Field is Required!!!",
            },
            pattern: {
              value: TWO_DECIMAL,
              message: "Please check format: 999999999.99",
            },
            min: {
              value: 1,
              message: "Please add a valid weight!!!",
            },
            valueAsNumber: true,
          })}
        />
        {errors.bdm && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.bdm.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          BTM <span className="text-danger">&#9733;</span>(kg)
        </label>
        <input
          id="btm"
          type="number"
          className="form-control"
          name="btm"
          {...register("btm", {
            required: {
              value: true,
              message: "This Field is Required!!!",
            },
            pattern: {
              value: TWO_DECIMAL,
              message: "Please check format: 999999999.99",
            },
            min: {
              value: 1,
              message: "Please add a valid weight!!!",
            },
          })}
        />
        {errors.btm && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.btm.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Tail Gate<span className="text-danger">&#9733;</span>
        </label>
        <Controller
          name="tailGate"
          control={control}
          // rules={{
          //   required: {
          //     value: true,
          //     message: REQUIRED_ERR_MSG,
          //   },
          // }}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Select Tail Gate"
              isSearchable={true}
              isClearable={true}
              className="form-control p-0"
              options={tailGateOpts}
              value={tailGateOpts?.filter(
                (tg) => Boolean(tg.value) === Boolean(value)
              )}
              onChange={(opt) => onChange(!!opt ? opt.value : null)}
            />
          )}
        />
        {errors.tailGate && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.tailGate.message}
            </span>
          </div>
        )}
      </div>
      <div className="col-md-3 mb-2">
        <label htmlFor="floatingInputGrid" className="form-label theme-label">
          Bonded Type<span className="text-danger">&#9733;</span>
        </label>
        <Controller
          name="bondedType"
          control={control}
          rules={{
            required: {
              value: true,
              message: "This Field is Required!!!",
            },
            min: {
              value: 1,
              message: REQUIRED_ERR_MSG,
            },
          }}
          render={({ field: { onChange, value } }) => (
            <Select
              placeholder="Select Bonded Type"
              isSearchable={true}
              isClearable={true}
              className="form-control p-0"
              options={dropdownData?.bondedTypeOpts}
              value={
                !!value
                  ? dropdownData?.bondedTypeOpts.filter(
                      (bt) => bt.value === value
                    )
                  : value
              }
              onChange={(opt) => onChange(!!opt ? opt.value : 0)}
            />
          )}
        />
        {errors.bondedType && (
          <div>
            <span className="text-danger fw-bold font-12">
              &#9888; {errors.bondedType.message}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default VehicleDetailsEdit;
