export const addWatermarkToImage = (
  file,
  watermarkText = "Watermark",
  fontSize
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      const img = new Image();
      img.src = e.target.result;

      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        ctx.save();
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(-Math.atan(canvas.height / canvas.width));
        ctx.font =
          fontSize || Math.min(canvas.width, canvas.height) * 0.1 + "px Arial";
        ctx.fillStyle = "rgba(255, 255, 255, 0.9)"; // semi-transparent white
        ctx.textAlign = "center";
        ctx.fillText(watermarkText, 0, 0);
        ctx.restore();

        // Convert canvas to Blob (file-like object)
        canvas.toBlob(function (blob) {
          const watermarkedFile = new File([blob], file.name, {
            type: "image/png",
          });
          resolve(watermarkedFile); // Return the watermarked file
        }, "image/png");
      };

      img.onerror = reject; // Handle image load errors
    };

    reader.onerror = reject; // Handle file read errors
    reader.readAsDataURL(file);
  });
};
