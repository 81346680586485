import React, { useState } from "react";
import Layout from "../Layout/Layout";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import { CallApiPostWithHead } from "../Helper/serviceApi";
import fileDownload from "js-file-download";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initFormData = {
  reportCode: { label: "", value: "" },
  days: "",
};
const options = [
  {
    label: "Delivery Data",
    value: "DELIVERYDATA",
  },
  {
    label: "Delivery Report",
    value: "DELIVERYREPORT",
  },
  {
    label: "Route Report",
    value: "ROUTEREPORT",
  },
  {
    label: "Route Utilitilization",
    value: "ROUTEUTILIZATION",
  },
  {
    label: "Transport Data",
    value: "TRANSPORTDATA",
  },
  {
    label: "Hub/Depot Utilization",
    value: "HUBDEPOTUTILIZATION",
  },
  {
    label: "Origin-Destination Utilization",
    value: "ORIDESTUTILIZATION",
  },
  {
    label: "Last Mile Report",
    value: "LASTMILEREPORT",
  },
  {
    label: "Cancellation Report",
    value: "CANCELREPORT",
  },
  {
    label: "Vehicle Report",
    value: "VHCLEXPRPT",
  },
];
const dateNotRequired = ["VHCLEXPRPT"];
const OperationalReports = () => {
  const [spinner, setSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);

  const {
    register,
    watch,
    handleSubmit,
    control,
    // reset,
    setValue,
    formState: { errors },
  } = useForm(initFormData);

  // useEffect(() => {
  //   getMinDate("DEFAULT");
  // }, []);

  const watchRptType = watch("reportCode");
  

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const isDataValid = (data) => {
    const tFromDt = moment(data.from, DATE_FORMAT_SERVER, true);
    const tToDt = moment(data.to, DATE_FORMAT_SERVER, true);
    const diff = tToDt.diff(tFromDt, "days");

    if (diff >= 0 && diff <= 30) {
      return true;
    } else {
      return false;
    }
  };

  const handelonDownlodCliclicked = async (data) => {
    setSpinner(true);
    if (dateNotRequired.includes(watchRptType.value)) {
      data.from = moment().subtract(2, "d").format(DATE_FORMAT_SERVER);
      data.to = moment().format(DATE_FORMAT_SERVER);
    }
    if (isDataValid(data)) {
      const payload = {
        reportCode: data.reportCode.value,
        fromDate: data.from,
        toDate: data.to,
        reportFeatureCode: "RPTOPS",
      };

      let downloadResponse = await CallApiPostWithHead(
        "DownloadReport",
        payload
      );

      if (downloadResponse.status === 200 || downloadResponse.status === 0) {
        fileDownload(downloadResponse.data, data.reportCode.label + ".xlsx");
        setSpinner(false);
      } else {
        setSpinner(false);

        showAlert({
          isSuccess: false,
          description: getErrorMsg(downloadResponse.status),
          title: "",
        });
      }
    } else {
      setSpinner(false);
      showAlert({
        isSuccess: false,
        description: "You can download report for only one month!!",
        title: "ERROR",
      });
    }

    // reset(initFormData)
  };

  const getErrorMsg = (errCode) => {
    let msg;
    switch (errCode) {
      case 204:
        msg = "No Data Available";
        break;
      case 401:
        msg = "FAILURE: Insufficient Privilege!!";
        break;
      case 403:
        msg = "FAILURE: Invalid session";
        break;

      default:
        msg = "something went wrong";
        break;
    }
    return msg;
  };

  const getMinDate = (argReportCode) => {
    setValue("from", "");
    setValue("to", "");
    switch (argReportCode) {
      case "ROUTEREPORT":
        if (!!watch("to")) {
          let dayA = moment(watch("to"), DATE_FORMAT_SERVER, true);
          let dayB = moment();
          if (dayA.diff(dayB, "days") > 30) {
            setValue("minDate", moment(dayA).subtract(30, "days").toDate());
          } else {
            setValue("minDate", moment().toDate());
          }
        } else {
          setValue("minDate", moment().toDate());
        }

        break;
      default:
        setValue(
          "minDate",
          !!watch("to")
            ? moment(watch("to"), DATE_FORMAT_SERVER, true)
                .subtract(30, "days")
                .toDate()
            : moment().subtract(30, "days").toDate()
        );
        setValue(
          "maxDate",
          !!watch("to")
            ? moment(watch("to"), DATE_FORMAT_SERVER, true).toDate()
            : moment().toDate()
        );
        break;
    }
  };

  const updateFrom = (argToDate) => {
    setValue("from", "");
    if (!!argToDate) {
      let tempTo = moment(argToDate, DATE_FORMAT_SERVER, true);
      setValue("minDate", tempTo.subtract(30, "days").toDate());
      setValue("maxDate", moment(argToDate, DATE_FORMAT_SERVER, true).toDate());
    } else {
      setValue("maxDate", moment().toDate());
      setValue("minDate", moment().subtract(30, "days").toDate());
    }
  };

  return (
    <Layout
      spinnershow={spinner}
      alertConfig={alertConfig}
      hideAlert={hideAlert}
    >
      <div className="clearfix border-bottom pb-2">
        <div className="float-start">
          <div className="section-title mt-1">Operational Reports</div>
        </div>
      </div>
      <div className="container m-auto mt-3">
        <div className="row">
          <div className="col-md-3">
            <label htmlFor="" className="form-label theme-label">
              Report{" "}
            </label>
            <Controller
              name="reportCode"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select
                  options={options}
                  placeholder="-Select-"
                  onChange={(d) => {
                    onChange(d);
                    // getMinDate(!!d ? d.value : d);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  className=""
                  // isDisabled={watchIsRegion ? false : true}
                  menuPlacement="auto"
                  // isDisabled={ptatus === "Published" ? true : false}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: REQUIRED_ERR_MSG,
                },
              }}
            />
            {errors.reportCode && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors.reportCode.message}
                </span>
              </div>
            )}
          </div>
          {/* <div className="col-md-5">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Days
              <span className="smaller_font theme-red-txt"> (Max 15 Days)</span>
            </label>
            <div>
              <input
                type="text"
                className="form-control"
                {...register("days", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                  pattern: {
                    value: NUMBER_REGEX,
                    message: "Enter a valid Days!",
                  },
                  max: {
                    value: 15,
                    message: "Can not more than 15!",
                  },
                })}
                // disabled={watchId > 0 ? true : false}
              />
              {errors.days && (
                <div>
                  <span className="badge bg-danger">
                    &#9888; {errors.days.message}
                  </span>
                </div>
              )}
            </div>
          </div> */}

          {!dateNotRequired.includes(watchRptType?.value) && (
            <>
              {" "}
              <div className="col-auto">
                <label htmlFor="" className="form-label theme-label">
                  From{" "}
                </label>
                <div className="clearfix"></div>
                <Controller
                  name="from"
                  control={control}
                  rules={{
                    required: { value: true, message: REQUIRED_ERR_MSG },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Select Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      onChange={(val) =>
                        onChange(
                          !!val ? moment(val).format(DATE_FORMAT_SERVER) : ""
                        )
                      }
                      showTimeSelect={false}
                      showYearDropdown
                      // minDate={watch("minDate")}
                      // maxDate={watch("maxDate")}
                    />
                  )}
                />
                {errors.from && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.from.message}
                    </span>
                  </div>
                )}
              </div>
              <div className="col-auto">
                <label htmlFor="" className="form-label theme-label">
                  To{" "}
                </label>
                <div className="clearfix"></div>
                <Controller
                  name="to"
                  control={control}
                  rules={{
                    required: { value: true, message: REQUIRED_ERR_MSG },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      placeholderText="Select Date"
                      className="form-control"
                      dateFormat={DATE_FORMAT_CLIENT}
                      selected={value ? new Date(value) : value}
                      onChange={(val) => {
                        onChange(
                          !!val ? moment(val).format(DATE_FORMAT_SERVER) : ""
                        );
                        // updateFrom(
                        //   !!val ? moment(val).format(DATE_FORMAT_SERVER) : val
                        // );
                      }}
                      showTimeSelect={false}
                      showYearDropdown
                      // minDate={
                      //   watch("reportCode")?.value === "ROUTEREPORT"
                      //     ? new Date()
                      //     : ""
                      // }
                      // maxDate={
                      //   watch("reportCode")?.value === "ROUTEREPORT"
                      //     ? ""
                      //     : new Date()
                      // }
                    />
                  )}
                />
                {errors.to && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.to.message}
                    </span>
                  </div>
                )}
              </div>
            </>
          )}
          <div className="col-auto mt-4">
            <label htmlFor="" className="form-label theme-label"></label>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(handelonDownlodCliclicked)}
            >
              Download
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OperationalReports;
