import React, { useEffect, useState } from "react";
import GetSvgIcon from "../../images/svglist";
import Layout from "../Layout/Layout";
import AutoAssignConfigList from "./AutoAssignConfigList";
import AutoAssignConfigAdd from "./AutoAssignConfigAdd";
import { CallApiDelete, CallApiGet, CallApiPost } from "../Helper/serviceApi";
import { NET_ERR_MSG, SERVER_ERR_MSG } from "../Helper/Constants";
import CustomAlert from "../Layout/CustomAlert/CustomAlert";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const initModalConfig = {
  show: false,
  title: "",
};

const initObj = {
  argId: 0,
  driverId: [],
  orderType: [],
  priority: 0,
  sbuCode: "",
  city: [],
  postcode: [],
  ordService: [],
};

const orderTypeOpts = [
  { label: "Standard", value: 511 },
  { label: "International", value: 647 },
];

const priorityOpts = [
  { label: "One", value: 1 },
  { label: "Two", value: 2 },
];

const AutoAssignMain = () => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [alertConfig, setAlertConfig] = useState(
    Object.assign({}, initAlertConfig)
  );
  const [modalConfig, setModalConfig] = useState(
    Object.assign({}, initModalConfig)
  );
  const [driverGeofence, setDriverGeofence] = useState([]);
  const [selDriverGeofence, setSelDriverGeofence] = useState(
    Object.assign({}, initObj)
  );
  const [geofenceBySbu, setGeofenceBySbu] = useState([]);
  const [geofenceDataMap, setGeofenceDataMap] = useState([]);
  const [driverArr, setDriverArr] = useState([]);
  const [srvcArr, setSrvcArr] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [geofenceStatus, setGeofenceStatus] = useState();
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");

  useEffect(() => {
    Promise.all([
      fetchDriverGeofence(),
      fetchGeofenceBySbu(),
      getAllDriver(),
      fetchService(),
    ]);
  }, []);

  /*Get all driver geofence List */
  const fetchDriverGeofence = async () => {
    setShowSpinner(true);
    const resp = await CallApiGet("ListDriverGeofence");
    if (resp.respCode === 200) {
      setDriverGeofence(resp.respData);
    }
    setShowSpinner(false);
  };

  //This function is responsible for saving driver geofence data
  const onSubmit = async (data) => {
    setShowSpinner(true);
    const payload = { ...data };
    payload.sbuCode =
      typeof data.sbuCode === "string" ? data.sbuCode : data.sbuCode[0];
    payload.argId = !!data.parentId ? data.parentId : 0;
    payload.city =
      typeof payload.city === "string" ? payload.city.split(",") : payload.city;
    payload.postcode =
      typeof payload.postcode === "string"
        ? payload.postcode.split(",")
        : payload.postcode;
    const resp = await CallApiPost("saveDriverGeofence", [payload]);
    handlePostApiCall(resp, resp.respMessage);
    onHide();
    fetchDriverGeofence();
    setShowSpinner(false);
  };

  //Get all SBU with coressponding city and postcode
  const fetchGeofenceBySbu = async () => {
    let sbuArrList = [];
    const sbuDataMap = {};
    const data = await CallApiGet("GeofenceListBySbu");
    data.respData.forEach((item) => {
      const { sbuCode, geofenceData } = item;
      const geofence = JSON.parse(geofenceData.value);
      sbuDataMap[sbuCode] = {
        city: geofence.city || [],
        postcode: geofence.postcode || [],
      };
      sbuArrList.push({ value: sbuCode, label: sbuCode });
    });
    setGeofenceBySbu(sbuArrList);
    setGeofenceDataMap(sbuDataMap);
  };

  // handleAction opens the add edit modal
  const handleAction = (argData, argTitle) => {
    let copyObject = Object.assign({}, argData);
    copyObject.driverId =
      typeof copyObject.driverId === "string"
        ? copyObject.driverId.split(",").map((a) => parseInt(a))
        : copyObject.driverId;
    copyObject.orderType =
      typeof copyObject.orderType === "string"
        ? copyObject.orderType.split(",").map((a) => parseInt(a))
        : copyObject.orderType;
    copyObject.ordService =
      typeof copyObject.ordService === "string"
        ? copyObject.ordService.split(",").map((a) => parseInt(a))
        : copyObject.ordService;

    // copyObject.city =
    //   typeof copyObject.city === "string"
    //     ? copyObject.city.split(",")
    //     : copyObject.city;
    // copyObject.postcode =
    //   typeof copyObject.postcode === "string"
    //     ? copyObject.postcode.split(",")
    //     : copyObject.postcode;
    setSelDriverGeofence(Object.assign({}, copyObject));

    setModalConfig({
      show: true,
      title: argTitle,
    });
  };
  const onHide = () => {
    setSelDriverGeofence(Object.assign({}, initObj));
    setModalConfig({
      show: false,
      title: "",
    });
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setAlertConfig({
      isSuccess: boolData,
      size: "md",
      show: true,
      title: popupTitle,
      description: popupDesc,
    });
  };
  const closePopup = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };

  const handlePostApiCall = (resp, msg) => {
    switch (resp.respCode) {
      case 200:
        if (resp.respData && resp.respData.some((data) => data.RetCode === 9)) {
          openPopup(false, "DUPLICATE", "Duplicate Parent data found!");
        }
         else if (
           resp.respData &&
           resp.respData.some((data) => data.RetCode === 0)
         ) {
           openPopup(true, msg, "Driver Geofence Added Successfully!!!!");
         } else {
           openPopup(true, msg, "Driver Geofence Updated Successfully!!!!");
         }
        break;
      case 500:
        openPopup(false, "ERROR", resp.respMessage || SERVER_ERR_MSG);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the write permission!!"
        );
        break;
      case 99:
        openPopup(
          false,
          "ERROR",
          "Something went wrong. Please try after soemtime!!"
        );
    }
  };

  const getAllDriver = async () => {
    setShowSpinner(true);
    const resp = await CallApiGet("GetDropdowndriverGeofence");
    if (resp.respCode === 200) {
      const data = resp.respData[0].combinedInfo
        .split("][") // Split concatenated JSON arrays
        .map((part, index, array) => {
          if (index === 0) return part + "]";
          if (index === array.length - 1) return "[" + part;
          return "[" + part + "]";
        });

      const parsedDrivers = data.flatMap((part) => JSON.parse(part));

      // Set the state for the dropdown
      setDriverArr(parsedDrivers);
    }
    setShowSpinner(false);
  };

  //fetch all services
  const fetchService = async () => {
    const resp = await CallApiPost("ListServiceForBooking?argCustId=0");
    const serviceOpts = [];
    resp.respData.forEach((item) => {
      const serviceIds = item.serviceCode.split("-")[0];
      serviceOpts.push({
        label: item.serviceName,
        value: parseInt(serviceIds),
      });
    });
    setSrvcArr([...serviceOpts]);
  };

  //Delete Driver GeofenceData function
  // const deleteDriverGeofence = async (parentId) => {
  //   setShowSpinner(true);
  //   const resp = await CallApiDelete(
  //     "/DeleteDriverGeofence?parentId=" + parentId
  //   );
  //   deleteDrvGeoAlert();
  //   setShowSpinner(false);
  // };

  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };

  const deleteDrvGeoAlert = async () => {
    const resp = await CallApiDelete(
      `DeleteDriverGeofence?parentId=${geofenceStatus.parentId}`,
      geofenceStatus
    );
    switch (resp.respCode) {
      case 200:
        openPopup(true, "", "Driver Geofence status updated successfully!!");
        break;
      case 500:
        openPopup(false, "ERROR", SERVER_ERR_MSG);
        break;
      case 99:
        openPopup(false, "ERROR", NET_ERR_MSG);
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
    fetchDriverGeofence();
    setShowAlert(false);
  };

  const handleActiveDeactive = (status) => {
    if (status) {
      setAlertBody(<>Are you sure, you want to delete this geofence</>);
      setAlertTitle("Delete Driver Geofence");
    } else {
      setAlertBody(<>Are you sure, you want to delete this geofence</>);
      setAlertTitle("Delete Driver Geofence");
    }
  };

  return (
    <Layout
      spinnershow={showSpinner}
      alertConfig={alertConfig}
      hideAlert={closePopup}
    >
      <div className="clearfix border-bottom pb-2 mb-2">
        <div className="float-start">
          <div className="section-title mt-1">Driver Geofence</div>
        </div>
        <div className="float-end d-flex align-items-center">
          <div className="mt-1">
            <button
              className="btn btn-outline-secondary theme-violet text-nowrap"
              type="button"
              onClick={() => handleAction(initObj, "AUTO ASSIGN")}
            >
              {GetSvgIcon("PlusCircle")} ADD
            </button>
          </div>
        </div>
      </div>
      <AutoAssignConfigList
        driverGeofence={driverGeofence}
        handleAction={handleAction}
        setGeofenceStatus={setGeofenceStatus}
        toggleAlert={toggleAlert}
        handleActiveDeactive={handleActiveDeactive}
      />
      <AutoAssignConfigAdd
        modalConfig={modalConfig}
        onHide={onHide}
        onSubmit={onSubmit}
        orderTypeOpts={orderTypeOpts}
        priorityOpts={priorityOpts}
        selDriverGeofence={selDriverGeofence}
        geofenceBySbu={geofenceBySbu}
        geofenceDataMap={geofenceDataMap}
        initObj={initObj}
        driverArr={driverArr}
        srvcArr={srvcArr}
      />

      {showAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={deleteDrvGeoAlert}
        />
      )}
    </Layout>
  );
};

export default AutoAssignMain;
