import React, { useState } from "react";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import ModalLayout from "../../Layout/ModalLayout";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};

const PartnerSummaryList = ({
  fetchAllInvoiceList,
  setSearchBtn,
  enableBackBtn,
  setUpCustDtls,
  setSummaryStatus,
  financeRow,
}) => {
  const [modalConfig, setModalConfig] = useState(null);
  const [alertConfig, setAlertConfig] = useState(
    JSON.parse(JSON.stringify(initAlertConfig))
  );
  const [showProf, setShowProf] = useState(false);
  const [custDtls, setCustDtls] = useState({});
  const columns = [
    {
      name: "Partner",
      selector: (row) => row.partner_name + "(" + row.acct_code + ")",
      cell: (row) => (
        <>
          <div
            className="text-primary cursor-pointer"
            onClick={() => {
              openModal("prf", row);
            }}
          >
            {!!row?.business_name && row?.business_name?.length > 0
              ? row?.business_name + "(" + row.acct_code + ")"
              : row.partner_name + "(" + row.acct_code + ")"}
          </div>
        </>
      ),
      sortable: true,
      filterable: true,
      width: "300px",
    },
    {
      name: "Op. Balance",
      selector: (row) =>
        (
          (!!row.old_inv_amt ? row.old_inv_amt : 0) -
          (!!row.old_pmt_amt ? row.old_pmt_amt : 0)
        ).toFixed(2),
    },

    {
      name: "Invoices",
      selector: (row) => row.inv_count,
      sortable: true,
    },
    {
      name: "Total Bill",
      selector: (row) => row.inv_amt,
      sortable: true,
    },
    {
      name: "Total Paid",
      selector: (row) => row.pmt_amt,
    },
    {
      name: "Outstanding",
      selector: (row) => row.outstand_amount.toFixed(2),
      sortable: true,
    },

    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={"invDTLs-" + row.partner_id}
            className="me-3"
            onClick={() => {
              openInvDtls(row, "BL");
            }}
          >
            {GetSvgIcon("PaymentDetails")}
          </div>
          <Tooltip
            id={"invDTLs-" + row.partner_id}
            content="View Details"
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "30px",
    },
  ];

  const openInvDtls = (row, type) => {
    fetchAllInvoiceList(row.partner_id, type).then((res) => {
      switch (type) {
        case "BL":
          setSearchBtn(false);
          enableBackBtn();
          setUpCustDtls(row, row.partner_id);
          setSummaryStatus(false);
          break;
        case "UBL":
          openModal("UBL", row);
          break;
        default:
          break;
      }
      // setPaymentStatusBtn(false);
    });
  };

  const openModal = (type, data) => {
    if (type === "prf") {
      setShowProf(true);
      setCustDtls(data);
    }
    setModalConfig({
      size: type !== "prf" ? "xl" : "lg",
      fullscreen: type !== "prf" ? true : false,
      show: true,
      title: type !== "prf" ? "Un-Billed Details" : "Partner Details",
    });
  };
  const closeModal = () => {
    setShowProf(false);
    setCustDtls({});
    setModalConfig({
      size: "xl",
      fullscreen: false,
      show: false,
      title: "",
    });
  };

  return (
    <>
      <div className="col-md-12 table-responsive customized-data-table position-relative popup-data-table">
        <DataTable
          columns={columns}
          data={financeRow}
          pagination
          striped
          highlightOnHover
          customStyles={customDataTableStyles}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      </div>

      {!!modalConfig && (
        <ModalLayout moadlConfig={modalConfig} handleMoalClose={closeModal}>
          <>
            <div className="d-flex">
              <div className="col-6 grd-bg p-2 rounded-3">
                <label className="font-12">Name : </label>
                <div>{custDtls?.partner_name}</div>
              </div>
              <div className="col-6 grd-bg p-2 rounded-3">
                <label className="font-12">Email : </label>
                <div>{custDtls?.partner_email}</div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-6 grd-bg p-2 rounded-3">
                <label className="font-12">Phone : </label>
                <div>{custDtls?.partner_phone}</div>
              </div>
              <div className="col-6 grd-bg p-2 rounded-3">
                <label className="font-12">User IC : </label>
                <div>{custDtls?.partner_ic}</div>
              </div>
            </div>
            <div className="d-flex mt-3">
              <div className="col-12 grd-bg p-2 rounded-3">
                <label className="font-12">Address : </label>
                <div>{custDtls?.partner_addr}</div>
              </div>
            </div>
          </>
        </ModalLayout>
      )}
    </>
  );
};

export default PartnerSummaryList;
