import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  APPROVAL_STATUS_PENDING,
  APPROVAL_STATUS_REJECT,
  LINEHAUL_PARTNER_DRIVER_USER_TYPE,
  PRFLDR,
} from "../../../ConfigConstants/ConfigConstants";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { getPersonalId, getUserInfo } from "../../AuthUtils/AuthUtils";
import {
  approvalHistory,
  getFileName,
  toBase64,
} from "../../Helper/CommonMethods";

import { SERVER_ERR_MSG } from "../../Helper/Constants";
import { DownloadFile } from "../../Helper/FileStoreService";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import Header from "../../Layout/Header";
import Popup from "../../Layout/Popup";
import Spinner from "../../Layout/Spinner";
import ApprovalLogList from "../ApprovalLogList";
import { initValPartnerPrf } from "./initValPartnerPrf";

const initImgData = {
  vhclImgF: "",
  vhclImgB: "",
  vhclImgL: "",
  vhclImgR: "",
  vhclImgI: "",
};

let paramUserType = 0;
let paramPrflId = 0;
let paramParentAccount = "";

export default function PartnerStaffDetails() {
  const { state } = useLocation();
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [partnerPrflDetails, setPartnerPrflDetails] =
    useState(initValPartnerPrf);
  const LOG_TYPE_APPROVE = "APPROVE";
  const LOG_TYPE_REJECT = "REJECT";
  const [show, setShow] = useState(false);
  const callbackHinstory = () => {
    navigate("/partnerApproval");
  };
  const [approvalHistoryData, setApprovalHistoryData] = useState();
  const navigate = useNavigate();
  const [driverLicenseData, setDriverLicenseData] = useState({});
  const [driverNRICData, setDriverNRICData] = useState({});
  const [driverGDLData, setDriverGDLData] = useState({});
  const [searchParams] = useSearchParams();

  const {
    reset,
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: partnerPrflDetails,
  });

  useEffect(() => {
    const userPerm = JSON.parse(getUserInfo("userPermissions"));
    const partnerFeat = userPerm?.filter((feat) => feat.featureCode === PRFLDR);
    const drFeat = partnerFeat?.find((feat) => feat.featureCode === PRFLDR);
    paramUserType = state?.userType || Number(searchParams.get("userType"));
    paramPrflId = state?.profileId || Number(searchParams.get("profileId"));
    paramParentAccount =
      state?.parentAcctCode || searchParams.get("parentAcctCode");
    if (
      parseInt(paramUserType) === parseInt(LINEHAUL_PARTNER_DRIVER_USER_TYPE) &&
      drFeat?.allowedRead
    ) {
      getPartnersDetails(paramPrflId, drFeat);
    } else {
      navigate("/unauthorized");
    }
  }, []);

  const getPartnersDetails = async (profileId, argIsDriverPrfl) => {
    let roles = JSON.parse(getUserInfo("userRoles"));
    const userType = paramUserType;
    let uri = `GetPartnerProfile?profileId=${profileId}&roleId=${roles[0].roleId}&userType=${userType}`;
    setShow(true);
    let data = await CallApiGet(uri);

    setShow(false);
    if (data.respCode === 200 || data.respCode === 0) {
      setPartnerPrflDetails(data.respData);
      let partnerPrflDetails = data.respData;
      reset(data.respData);
      if (!!argIsDriverPrfl && argIsDriverPrfl?.allowedRead) {
        setDriverLicenseData(
          partnerPrflDetails && partnerPrflDetails.userInfoList
            ? partnerPrflDetails.userInfoList.filter(
                (info) => info.infoCode === 124
              )[0]
            : {}
        );
        setDriverNRICData(
          partnerPrflDetails && partnerPrflDetails.userInfoList
            ? partnerPrflDetails.userInfoList.filter(
                (info) => info.infoCode === 577
              )[0]
            : {}
        );
        setDriverGDLData(
          partnerPrflDetails && partnerPrflDetails.userInfoList
            ? partnerPrflDetails.userInfoList.filter(
                (info) => info.infoCode === 578
              )[0]
            : {}
        );
      }
    } else if (data.respCode === 500) {
      onFailure(SERVER_ERR_MSG);
    } else {
      onFailure(data.respMessage);
    }
  };

  const onSubmit = async (data, requestType) => {
    if (requestType !== LOG_TYPE_APPROVE && data.remarks === "") {
      onFailure(
        "Please provide a valid comment as you are rejecting the driver"
      );
      return;
    } else if (requestType === LOG_TYPE_APPROVE && data.remarks === "") {
      data.remarks = "Approved";
    }
    const model = {
      approvalUserId: paramPrflId, // data.userId,
      logText: data.remarks,
      logAttachment: {
        photo: "",
        photoFileType: "",
        photoBucketUrl: "",
      },
      personalId: getPersonalId(),
      logType: requestType,
      isCustomer: false,
      approvalUserTypeId: partnerPrflDetails.approvalUserTypeId,
    };
    setShow(true);
    let approvalResp = await CallApiPost("log-approval", model);
    setShow(false);
    if (approvalResp.respCode === 200 || approvalResp.respCode === 0) {
      switch (requestType) {
        case "APPROVE":
          onSuccess("APPROVED", "");
          break;
        case "REJECT":
          onSuccess("REJECTED", "");
          break;
        default:
          break;
      }
    } else if (approvalResp.respCode === 406) {
      onFailure(approvalResp.respMessage);
    } else if (approvalResp.respCode === 400 || approvalResp.respCode === 401) {
      onFailure("Sorry, you don't have the write permission!!");
    } else {
      onFailure(SERVER_ERR_MSG);
    }
  };
  const onSuccess = (title, body) => {
    setIsSuccess(true);
    setTitle(title);
    setDescription(body);
    modalRef.current.click();
  };

  const onFailure = (errMsg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(errMsg);
    modalRef.current.click();
  };

  const callback = () => {
    navigate("/partnerApproval");
  };

  const onError = (error, status) => {
    if (status === APPROVAL_STATUS_REJECT) {
      onSubmit(getValues(), status);
    } else {
      error && onFailure("Profile is not completed");
    }
  };

  const getApprovalHistory = async () => {
    setShow(true);
    let approvalHistoryData = await approvalHistory(
      partnerPrflDetails.partnerId,
      partnerPrflDetails.approvalUserTypeId
    );
    setShow(false);
    if (approvalHistoryData.respCode === 200) {
      let data = approvalHistoryData.respData;
      setApprovalHistoryData(data);
    } else if (approvalHistoryData.respCode === 500) {
      onFailure();
    } else {
      setApprovalHistoryData([]);
      localStorage.clear();
      navigate("/home");
    }
  };
  const sucs = () => {};
  const download = (fileName) => {
    if (fileName !== "") {
      setShow(true);
      DownloadFile(fileName, sucs, sucs);
      setShow(false);
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="col-md-12 mt-3">
              {partnerPrflDetails.approveStatus === APPROVAL_STATUS_PENDING ? (
                <div className="alert alert-info">
                  <div>
                    <div className="float-start">
                      <h5 className="card-title">Approval Checklist</h5>
                      <p className="card-text">
                        {partnerPrflDetails.approvalChecklist}
                      </p>
                    </div>
                    <button
                      type="button"
                      className="btn btn-outline-secondary theme-violet me-1 float-end"
                      onClick={getApprovalHistory}
                      data-bs-toggle="modal"
                      data-bs-target="#approval-history-modal"
                    >
                      Approval History
                    </button>
                    <div className="clearfix"></div>
                  </div>
                </div>
              ) : (
                <div className="float-end position-absolute end-0">
                  <button
                    type="button"
                    className="btn btn-outline-secondary theme-violet me-2"
                    onClick={getApprovalHistory}
                    data-bs-toggle="modal"
                    data-bs-target="#approval-history-modal"
                  >
                    Approval History
                  </button>
                </div>
              )}
              <div
                className="accordion form-register"
                id="accordionPanelsStayOpenExample"
              >
                <div>
                  <h5 className="theme-violet-txt"> Driver Details </h5>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="This field is mandatory"
                        >
                          Parent Partner Account Code{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={paramParentAccount}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          data-toggle="tooltip"
                          data-placement="right"
                          title="This field is mandatory"
                        >
                          Driver Name{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.driverName}
                          disabled
                        />
                      </div>
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Phone{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.phone}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-4 mb-2">
                        <label
                          htmlFor=""
                          className="form-label theme-label"
                          title="This field is mandatory"
                        >
                          Email{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={partnerPrflDetails.email}
                          disabled
                          maxLength="100"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="my-4">
                  <h5 className="theme-violet-txt">Driver Document</h5>
                  <div className="w-100">
                    <div className="row">
                      <div className="col-lg-12 mb-2">
                        <div className="table-responsive">
                          <table className="table table-striped table-hover">
                            <thead>
                              <tr>
                                <th scope="col">Information</th>
                                <th scope="col">Expires On </th>
                                <th scope="col">Picture</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{"DRIVER NRIC"} </td>
                                <td></td>
                                <td>
                                  <div className="mb-2 position-relative">
                                    <div className="Upload-Photo w-100">
                                      <div
                                        className="uploaded-text ms-2"
                                        disabled
                                      >
                                        {GetSvgUpload({ required: false })}
                                        {!!driverNRICData?.infoAttachment
                                          ?.photoBucketUrl
                                          ? driverNRICData?.infoAttachment
                                              ?.photoBucketUrl
                                          : "Upload Driver NRIC"}
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file-ptn download-uploaded-file-ptn-left"
                                      disabled={
                                        typeof driverNRICData?.infoAttachment
                                          ?.photoBucketUrl === "undefined" ||
                                        driverNRICData?.infoAttachment
                                          ?.photoBucketUrl === null
                                      }
                                      onClick={() =>
                                        download(
                                          getFileName(
                                            driverNRICData?.infoAttachment
                                              ?.photoBucketUrl
                                          )
                                        )
                                      }
                                    >
                                      {GetSvgIcon("IconFiledownload")}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>{"DRIVER DRIVING LICENSE"} </td>
                                <td>
                                  <div className="col-lg-4 mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={
                                        driverLicenseData?.infoExpiry
                                      }
                                      disabled
                                      maxLength="100"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-2 position-relative">
                                    <div className="Upload-Photo w-100">
                                      <div
                                        className="uploaded-text ms-2"
                                        disabled
                                      >
                                        {GetSvgUpload({ required: false })}
                                        {!!driverLicenseData?.infoAttachment
                                          ?.photoBucketUrl
                                          ? driverLicenseData?.infoAttachment
                                              ?.photoBucketUrl
                                          : "Upload Driver Driving License"}
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file-ptn download-uploaded-file-ptn-left"
                                      disabled={
                                        typeof driverLicenseData?.infoAttachment
                                          ?.photoBucketUrl === "undefined" ||
                                        driverLicenseData?.infoAttachment
                                          ?.photoBucketUrl === null
                                      }
                                      onClick={() =>
                                        download(
                                          getFileName(
                                            driverLicenseData?.infoAttachment
                                              ?.photoBucketUrl
                                          )
                                        )
                                      }
                                    >
                                      {GetSvgIcon("IconFiledownload")}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td>{"DRIVER GDL LICENSE"} </td>
                                <td>
                                  <div className="col-lg-4 mb-2">
                                    <input
                                      type="text"
                                      className="form-control"
                                      defaultValue={driverGDLData?.infoExpiry}
                                      disabled
                                      maxLength="100"
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className="mb-2 d-flex position-relative">
                                    <div className="Upload-Photo flex-grow-1">
                                      <div
                                        className="uploaded-text ms-2"
                                        disabled
                                      >
                                        {GetSvgUpload({ required: false })}
                                        {!!driverGDLData?.infoAttachment
                                          ?.photoBucketUrl
                                          ? driverGDLData?.infoAttachment
                                              ?.photoBucketUrl
                                          : "Upload Driver Driving License"}
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      className="btn btn-outline-secondary theme-violet position-absolute download-uploaded-file-ptn download-uploaded-file-ptn-left"
                                      disabled={
                                        typeof driverGDLData?.infoAttachment
                                          ?.photoBucketUrl === "undefined" ||
                                        driverGDLData?.infoAttachment
                                          ?.photoBucketUrl === null
                                      }
                                      onClick={() =>
                                        download(
                                          getFileName(
                                            driverGDLData?.infoAttachment
                                              ?.photoBucketUrl
                                          )
                                        )
                                      }
                                    >
                                      {GetSvgIcon("IconFiledownload")}
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {partnerPrflDetails.approveStatus !== APPROVAL_STATUS_PENDING && (
              <div className="col-lg-4 mb-2">
                <div className="float-end position-absolute end-0 p-2">
                  <button
                    type="button"
                    className="btn btn-default border me-3 floatEnd"
                    onClick={callbackHinstory}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
            {partnerPrflDetails.approveStatus === APPROVAL_STATUS_PENDING && (
              <div className="col-lg-4 mb-2">
                <label htmlFor="" className="form-label theme-label">
                  Remarks
                </label>
                <textarea
                  rows="3"
                  className="form-control"
                  {...register("remarks")}
                />
                {errors.remarks && (
                  <div>
                    <span className="badge bg-danger">
                      &#9888; {errors.remarks.message}
                    </span>
                  </div>
                )}
                <div className="float-end position-absolute end-0 p-2">
                  <button
                    type="button"
                    className="btn btn-default border me-3 floatEnd"
                    onClick={callbackHinstory}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn btn-default border me-3 floatEnd"
                    // data-bs-dismiss="modal"
                    onClick={handleSubmit(
                      (data) => onSubmit(data, LOG_TYPE_REJECT),
                      (err) => onError(err, LOG_TYPE_REJECT)
                    )}
                  >
                    Reject
                  </button>
                  <button
                    type="submit"
                    className="btn btn-outline-secondary theme-violet"
                    onClick={handleSubmit(
                      (data) => onSubmit(data, LOG_TYPE_APPROVE),
                      (err) => onError(err, LOG_TYPE_APPROVE)
                    )}
                  >
                    Approve
                  </button>
                </div>
              </div>
            )}
            <button
              data-bs-toggle="modal"
              data-bs-target="#success-modal"
              id="modalPopup"
              className="d-none"
              ref={modalRef}
            />
            <ApprovalLogList data={approvalHistoryData} />
            <Spinner show={show} />
            <Popup
              isSuccess={isSuccess}
              title={title}
              description={description}
              handler={callback}
            />
          </main>
        </div>
      </div>
    </div>
  );
}
