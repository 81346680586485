import Header from "../Layout/Header";
import VehicleList from "./VehicleList";
import VehicleAddEdit from "./VehicleAddEdit";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import UploadVehicle from "./UploadVehicle";
import "./VehicleStyle.css";
import { useRef } from "react";
import Popup from "../Layout/Popup";
import {
  BTN_TYPE_ADD,
  BTN_TYPE_EDIT,
  SERVER_ERR_MSG,
} from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import Spinner from "../Layout/Spinner";
import { CallApiDelete, CallApiGet, CallApiPost } from "../Helper/serviceApi";
import CustomAlert from "../Layout/CustomAlert/CustomAlert";
import {
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  VEHICLE_IMAGE_CODE_MAP,
  VHCL_OWNER_LC,
  VHCL_OWNER_LH,
  VHCL_OWNER_PARTNER,
  VHCL_STS_ACTIVE,
  VHCL_STS_APPLIED,
  VHCL_STS_INFO_INCOMPLETE,
  VHCL_STS_REGISTERED,
  VHCL_STS_REJECTED,
  VHCL_STS_UPD_REQ,
  VHCL_STS_UPD_REQ_REJECTED,
} from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import VehicleLeaseHistory from "./VehicleLeaseHistory";
import { Nav } from "react-bootstrap";

const initialHub = {
  id: 0,
  name: "",
  code: "",
  phone: "",
  address: "",
  sbyType: 0,
  sbuLat: 0.0,
  sbuLong: 0.0,
  parent: null,
};

const initialVhclType = {
  id: 0,
  vhclType: "",
  vhclSize: 0,
  maxWeight: 0,
  maxPallets: 0,
  palletHeight: 0,
  palletWeight: 0,
  maxPalletWeight: 0,
};

const initAttachment = {
  photo: "",
  photoFileName: "",
  photoFileType: "",
  photoBucketUrl: "",
};

export const initialVhclData = {
  vhclId: 0,
  vhclType: 0,
  vhclTypeName: "",
  vhclNo: "",
  vhclOwner: "",
  vhclOwnerId: 0,
  allocatedTo: 0,
  primarySbu: "",
  transferSbu: 0,
  assignTo: 0,
  vhclLength: 0,
  transferTo: "",
  transferFrom: "",
  bdm: 0,
  btm: 0,
  tailGate: false,
  bondedType: 0,
  bondedTypeVal: "",
  status: 0,
  active: false,
  vhclImgF: Object.assign({}, initAttachment),
  vhclImgB: Object.assign({}, initAttachment),
  vhclImgL: Object.assign({}, initAttachment),
  vhclImgR: Object.assign({}, initAttachment),
  vhclImgCI: Object.assign({}, initAttachment),
  vhclImgBI: Object.assign({}, initAttachment),
  vhclInfos: [],
};

export const inintHq = {
  id: 0,
  sbuCode: "",
  sbuName: "",
};

export const initDropdownData = {
  vhclOwnerOpts: [],
  bondedTypeOpts: [],
};

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const navMenu = [
  { key: "I", label: "LH/AGENT" },
  { key: "P", label: "Partner Vehicle" },
  { key: "L", label: "Leased Vehicle" },
];
const VehicleMain = () => {
  const [show, setShow] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [inlineAlert, setInlineAlert] = useState(false);
  const [inlineAlertMsg, setInlineAlertMsg] = useState("");
  const [alertVariant, setAlertVariant] = useState("");
  const [allocatedTo, setAllocatedTo] = useState([initialHub]);
  const [vhclTypes, setVhclTypes] = useState([initialVhclType]);
  const [vhclInfos, setVhclInfos] = useState([]);
  const [initialVhclInfos, setInitialVhclInfos] = useState([]);
  const [vhclData, setVhclData] = useState(
    JSON.parse(JSON.stringify(initialVhclData))
  );
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [vhclId, setVhclId] = useState(0);
  const navigate = useNavigate();
  const [vehicleList, setVehicleList] = useState([]);
  const [isVhclLoaded, setIsVhclLoaded] = useState(false);
  const [hq, setHq] = useState(JSON.parse(JSON.stringify(inintHq)));
  const [dropdownData, setDropdownData] = useState(
    Object.assign({}, initDropdownData)
  );
  const [btnType, setBtnType] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const userProfileId =
    LINEHAUL_PARTNER_COMPANY_USER_TYPE === getUserInfo("userType")
      ? getUserInfo("acctId")
      : 0;

  const [leaseHistory, setLeaseHistory] = useState();
  const [cleanUpData, setCleanUpData] = useState(new Date());
  const [searchParams] = useSearchParams();
  const [listData, setListData] = useState({
    lhLc: { key: "I", dataList: [] },
    leasedVhcl: { key: "L", dataList: [] },
    ptrVhcl: { key: "P", dataList: [] },
  });
  const [activeKey, setActiveKey] = useState("");
  let vehicleParamsId;

  useEffect(() => {
    if (searchParams.get("vehicleId"))
      vehicleParamsId = +searchParams.get("vehicleId");
    loadHubs();
    loadVhclTypes();
    getHeadquater();
    fetchVhclInfos(0);
    loadDropdownOpts();
    setActiveKey(+userProfileId > 0 ? "P" : "I");
    // downloadVhclQr();
  }, []);
  // const navMenu =
  //   userProfileId > 0
  //     ? [
  //         { key: "P", label: "Your Vehicle" },
  //         { key: "L", label: "Leased Vehicle" },
  //       ]
  //     : [
  //         { key: "I", label: "LH/LC" },
  //         { key: "P", label: "Partner Vehicle" },
  //         { key: "L", label: "Leased Vehicle" },
  //       ];
  const loadHubs = async () => {
    let data = await CallApiGet("getAllSbuList");
    if (data.respCode === 200 || data.respCode === 0) {
      setAllocatedTo(data.respData);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const getHeadquater = async () => {
    let data = await CallApiGet("getSbuHeadquater");
    if (data.respCode === 200 || data.respCode === 0) {
      setHq(data.respData[0]);
    } else {
      console.error(data.respMessage);
      onError();
    }
  };

  const loadVhclTypes = async () => {
    let data = await CallApiGet("getAllVhclTypes");
    if (data.respCode === 200 || data.respCode === 0) {
      setVhclTypes(data.respData ? data.respData : []);
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const loadDropdownOpts = async () => {
    const payload = ["BONDED_TYPE", "VEHICLE_OWNER_I"];
    let data = await CallApiPost("SysOptionGetByCode", payload);
    if (data.respCode === 200 || data.respCode === 0) {
      const ownerOpts = data.respData
        .filter((d) => d.optionCode === "VEHICLE_OWNER_I")
        .map((d) => {
          return { label: d.optionValue, value: d.id };
        });
      const bondedTypeOpts = data.respData
        .filter((d) => d.optionCode === "BONDED_TYPE")
        .map((d) => {
          return { label: d.optionValue, value: d.id };
        });
      setDropdownData({
        vhclOwnerOpts: ownerOpts,
        bondedTypeOpts: bondedTypeOpts,
      });
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const clearData = () => {
    setVhclData(JSON.parse(JSON.stringify(initialVhclData)));
    setVhclInfos(JSON.parse(JSON.stringify(initialVhclInfos)));
    setCleanUpData(new Date());
  };

  const fetchVhclInfos = async (vhclId) => {
    const uri = "getVehicleInfo";
    let data = await CallApiPost(uri, { vhclId });

    if (data.respCode === 200 || data.respCode === 0) {
      let infos = data.respData;
      infos.forEach((element) => {
        element.attachment1 = {
          photo: "",
          photoFileName:
            element.attachment1 === null ? "" : element.attachment1,
          photoFileType: "",
          photoBucketUrl: "",
        };
        element.id = element.id === null ? 0 : element.id;
        element.vhclId = element.vhclId === null ? 0 : element.vhclId;
        element.expyDate = element.expyDate === null ? "" : element.expyDate;
        element.remarks = element.remarks === null ? "" : element.remarks;
      });
      setVhclInfos([...infos]);
      if (vhclId === 0) {
        setInitialVhclInfos(infos);
      }
    } else if (data.respCode === 400 || data.respCode === 401) {
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      console.error(data);
      onError();
    }
  };

  const setVhclStatus = (argVehicle) => {
    const isPartner =
      argVehicle.vhclId > 0
        ? argVehicle.assignTo === VHCL_OWNER_PARTNER
        : +userProfileId > 0;

    switch (argVehicle.status) {
      case 0:
        argVehicle.status = isPartner ? VHCL_STS_APPLIED : VHCL_STS_ACTIVE;
        argVehicle.active = isPartner ? false : true;
        break;
      case VHCL_STS_REJECTED:
        argVehicle.status = isPartner ? VHCL_STS_APPLIED : VHCL_STS_ACTIVE;
        break;
      case VHCL_STS_UPD_REQ_REJECTED:
        argVehicle.status = isPartner
          ? VHCL_STS_UPD_REQ
          : +userProfileId > 0
          ? VHCL_STS_UPD_REQ
          : VHCL_STS_ACTIVE;
        break;
      case VHCL_STS_REGISTERED:
        argVehicle.status = isPartner ? VHCL_STS_UPD_REQ : VHCL_STS_REGISTERED;
        break;
      case VHCL_STS_INFO_INCOMPLETE:
        argVehicle.status = isPartner ? VHCL_STS_APPLIED : VHCL_STS_ACTIVE;
        break;
      default:
        argVehicle.status =
          +userProfileId > 0 ? VHCL_STS_UPD_REQ : argVehicle.status;
        break;
    }
    return argVehicle;
  };

  const saveVhclData = async (saveData) => {
    setShow(true);
    if (saveData.allocatedTo === 0 || saveData.allocatedTo === "") {
      saveData.allocatedTo = hq.id;
    }
    saveData = setVhclStatus(saveData);
    const copyAssignTo = saveData.assignTo;
    const copyVhclId = saveData.vhclId;
    saveData.partnerId =
      copyVhclId > 0
        ? copyAssignTo === VHCL_OWNER_PARTNER
          ? +userProfileId
          : 0
        : +userProfileId;
    const uri = "saveVehicle";
    let data = await CallApiPost(uri, saveData);

    if (data.respCode === 200 || data.respCode === 0) {
      setShow(false);
      clearData();
      const isPartnerComp =
        getUserInfo("userType") === LINEHAUL_PARTNER_COMPANY_USER_TYPE;
      onSuccess(
        isPartnerComp
          ? "Vehicle registered successfully, waiting Line Haul approval"
          : "Vehicle saved successfully !!!"
      );
    } else if (data.respCode === 409) {
      setShow(false);
      setInlineAlert(true);
      setAlertVariant("danger");
      setInlineAlertMsg(data.respMessage);
      setTimeout(() => {
        setInlineAlert(false);
      }, 5000);
    } else {
      setShow(false);
      onError();
      clearData();
    }
  };
  const openVhclModal = (data) => {
    if (!!vehicleParamsId) {
      const d = data.filter((a) => a.id === vehicleParamsId);
      setBtnType(BTN_TYPE_EDIT);
      handleOnClick(d[0]);
      document.getElementById("mdl1").click();
    }
  };

  const fetchVehicles = async () => {
    setShow(true);
    let uri = "getVehicleList";
    let data = await CallApiPost(uri, { partnerId: +userProfileId });
    if (data.respCode === 200 || data.respCode === 0) {
      setShow(false);
      if (data.respData.length > 0) {
        const lhLcVehicles = [];
        const leasedVehicles = [];
        const ptrVehicles = [];
        // setVehicleList(data.respData);
        setIsVhclLoaded(true);
        openVhclModal(data.respData);
        data.respData.forEach((v) => {
          if (
            [VHCL_OWNER_LC, VHCL_OWNER_LH].includes(v.assignTo) &&
            v.leaseId === null
          ) {
            lhLcVehicles.push(v);
          } else if (v.partnerId > 0 && v.assignTo === VHCL_OWNER_PARTNER) {
            ptrVehicles.push(v);
          } else if (
            [VHCL_OWNER_LC, VHCL_OWNER_LH].includes(v.assignTo) &&
            v.partnerId === 0 &&
            !!v.leaseId
          ) {
            leasedVehicles.push(v);
          }
        });
        setListData((prevListData) => ({
          ...prevListData,
          lhLc: {
            ...prevListData.lhLc,
            dataList: lhLcVehicles,
          },
          leasedVhcl: {
            ...prevListData.leasedVhcl,
            dataList: leasedVehicles,
          },
          ptrVhcl: {
            ...prevListData.ptrVhcl,
            dataList: ptrVehicles,
          },
        }));
        setVehicleList(+userProfileId > 0 ? ptrVehicles : lhLcVehicles);
      } else {
        setIsVhclLoaded(false);
      }
    } else if (data.respCode === 400 || data.respCode === 401) {
      setShow(false);
      setIsSuccess(false);
      setTitle("UNAUTHORIZED");
      setDescription("Sorry, you don't have the read permission!!");
      modalRef.current.click();
    } else {
      setShow(false);
      console.error(data);
      onError();
    }
  };

  const deleteVhcl = async () => {
    const uri = "deleteVehicle?vhclId=" + vhclId;
    let resp = await CallApiDelete(uri);
    clearData();
    setShowAlert(false);
    switch (resp.respCode) {
      case 200:
      case 0:
        openPopup(true, "", "Vehicle status updated successfully !!!");
        break;
      case 409:
        openPopup(false, "Error!", resp.respMessage);
        break;
      case 401:
        openPopup(
          false,
          "UNAUTHORIZED",
          "Sorry, you don't have the permission. Please contact to Admin!!"
        );
        break;
      default:
        onError();
        break;
    }
  };

  const callback = () => {
    fetchVehicles();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const onSuccess = (sucMsg = "Vehicle saved successfully !!!") => {
    setIsSuccess(true);
    setTitle("Congratulations!");
    setDescription(sucMsg);
    modalRef.current.click();
  };

  const onError = () => {
    setIsSuccess(false);
    setTitle("Error!");
    setDescription(SERVER_ERR_MSG);
    modalRef.current.click();
  };

  const onUploadErr = (resp) => {
    switch (resp?.respCode) {
      case 500:
        onError();
        break;
      case 401:
        setIsSuccess(false);
        setTitle("UNAUTHORIZED");
        setDescription("Sorry, you don't have the read permission!!");
        modalRef.current.click();
        break;
      default:
        openPopup(false, "ERROR", resp.respMessage);
        break;
    }
  };

  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };

  const handleActiveDeactive = (active, vhclNo) => {
    if (active) {
      setAlertBody(
        <>
          Are you sure, you want to deactivate vehicle <b>{vhclNo}</b>
        </>
      );
      setAlertTitle("Deactivate Vehicle");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate vehicle <b>{vhclNo}</b>
        </>
      );
      setAlertTitle("Activate Vehicle");
    }
  };

  const downloadVhclQr = async (argData) => {
    setShow(true);
    document.body.click();
    const payload = {
      vhclId: argData?.id,
      vhclNo: argData?.vhclNo,
    };
    const uri = `DownloadVehicleQr`;
    const resp = await CallApiPost(uri, payload);
    if (resp?.respCode === 200 && !!resp?.respData) {
      window.open(resp.respData, "_blank");
    } else {
      if (resp.respCode === 200) {
        resp.respCode = 409;
        resp.respMessage =
          "Sorry, failed to download. Please try after sometime!!";
      }
      // handleRespPostApiCall(resp);
      onError();
    }
    setShow(false);
  };

  const fetchLeaseHistory = async (vehicleId) => {
    setShow(true);
    let leaseData = await CallApiGet(`FetchLeaseHistory/${vehicleId}`);
    setShow(false);
    if (leaseData.respCode === 200) {
      let data = leaseData.respData;
      setLeaseHistory(data);
    } else {
      setLeaseHistory([]);
      onError();
    }
  };

  const openVhclDtlsModal = () => {
    let copyVhclData = Object.assign({}, initialVhclData);
    copyVhclData.vhclInfos = vhclInfos;
    setVhclData(copyVhclData);
    setBtnType(BTN_TYPE_ADD);
  };
  const handleOnClick = (data) => {
    const {
      id,
      sbuId,
      sbuName,
      sbuCode,
      vhclType,
      vhclTypeName,
      vhclNo,
      vhclOwner,
      assignTo,
      transferSbu,
      transferFrom,
      transferTo,
      status,
      active,
      statusVal,
    } = data;
    const vhclImgF = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGFCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };
    const vhclImgB = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };
    const vhclImgL = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGLCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    const vhclImgR = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGRCODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    const vhclImgBI = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGBICODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    const vhclImgCI = {
      photo: "",
      photoFileName: id + "_" + VEHICLE_IMAGE_CODE_MAP.IMGCICODE,
      photoFileType: "",
      photoBucketUrl: "",
    };

    setVhclData({
      vhclId: id,
      vhclType: vhclType,
      vhclTypeName: vhclTypeName,
      vhclNo: vhclNo,
      vhclOwner: vhclOwner,
      assignTo: assignTo,
      allocatedTo: sbuId,
      primarySbu: `${sbuName} (${sbuCode})`,
      transferSbu: transferSbu,
      transferFrom: transferFrom,
      transferTo: transferTo,
      vhclLength: data?.vhclLength,
      bdm: data?.bdm,
      btm: data?.btm,
      tailGate: data?.tailGate,
      bondedType: data?.bondedType,
      bondedTypeVal: data?.bondedTypeVal,
      vhclImgF: vhclImgF,
      vhclImgB: vhclImgB,
      vhclImgL: vhclImgL,
      vhclImgR: vhclImgR,
      vhclImgCI: vhclImgCI,
      vhclImgBI: vhclImgBI,
      status: status,
      active: active,
      statusVal: statusVal,
      vhclInfos: [],
    });
    fetchVhclInfos(id);
  };
  const filterData = (argKey) => {
    switch (argKey) {
      case "I":
        setVehicleList(listData.lhLc.dataList);
        break;
      case "P":
        setVehicleList(listData.ptrVhcl.dataList);
        break;
      case "L":
        setVehicleList(listData.leasedVhcl.dataList);
        break;

      default:
        setVehicleList([]);
        break;
    }
    setActiveKey(argKey);
  };

  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Vehicle</div>
              </div>
              <div className="float-end d-flex">
                <div className="mt-1">
                  <button
                    className="btn btn-outline-secondary theme-violet"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#upload-file-modal"
                  >
                    {GetSvgIcon("IconArrowup")} Upload
                  </button>
                </div>
                <div className="float-end mt-1">
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap ms-2"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#vehicle-add-edit-modal"
                    onClick={openVhclDtlsModal}
                  >
                    {GetSvgIcon("PlusCircle")} Add Vehicle
                  </button>
                </div>
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#success-modal"
                  id="modalPopup"
                  className="d-none"
                  ref={modalRef}
                />
              </div>
            </div>
            <>
              <Nav
                variant="tabs"
                activeKey={activeKey}
                onSelect={(eventKey) => filterData(eventKey)}
              >
                {navMenu.map((b) => {
                  if (b.key === "I") {
                    return (
                      <>
                        {+userProfileId === 0 && (
                          <Nav.Item>
                            <Nav.Link eventKey={b.key}>{b.label}</Nav.Link>
                          </Nav.Item>
                        )}
                      </>
                    );
                  } else {
                    return (
                      <Nav.Item>
                        <Nav.Link eventKey={b.key}>{b.label}</Nav.Link>
                      </Nav.Item>
                    );
                  }
                })}
              </Nav>
            </>
            <VehicleList
              fetchVhclInfos={fetchVhclInfos}
              setVhclData={setVhclData}
              vehicleList={vehicleList}
              isVhclLoaded={isVhclLoaded}
              fetchVehicles={fetchVehicles}
              setBtnType={setBtnType}
              toggleAlert={toggleAlert}
              handleActiveDeactive={handleActiveDeactive}
              setVhclId={setVhclId}
              downloadVhclQr={downloadVhclQr}
              fetchLeaseHistory={fetchLeaseHistory}
              handleOnClick={handleOnClick}
              activeKey={activeKey}
            />
          </main>
        </div>
      </div>
      <VehicleAddEdit
        alloctatedTo={allocatedTo}
        vhclTypes={vhclTypes}
        vhclData={vhclData}
        vhclInfos={vhclInfos}
        saveVhclData={saveVhclData}
        clearData={clearData}
        hq={hq}
        btnType={btnType}
        inlineAlert={inlineAlert}
        inlineAlertMsg={inlineAlertMsg}
        alertVariant={alertVariant}
        dropdownData={dropdownData}
        cleanUpData={cleanUpData}
      />
      <UploadVehicle
        onSuccess={onSuccess}
        onUploadErr={onUploadErr}
        dropdownData={dropdownData}
      />
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={show} />
      {showAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={deleteVhcl}
        />
      )}
      <VehicleLeaseHistory data={leaseHistory} />
      <div
        id="mdl1"
        className="action-item-button d-non"
        data-bs-toggle="modal"
        data-bs-target="#vehicle-add-edit-modal"
        onClick={() => {
          setBtnType(BTN_TYPE_EDIT);
        }}
      ></div>
    </div>
  );
};

export default VehicleMain;
