const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function generateString(length) {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function getSessionInfo() {
  const sesionData = localStorage.getItem("SessionInfo");
  // console.log('session data LS:' + sesionData);
  return sesionData ? sesionData : undefined;
}

export function setSessionInfo(sessionData) {
  const sesionData = localStorage.setItem("SessionInfo", sessionData);
}

export function setUserInfo(key, info) {
  const userInfo = localStorage.setItem(key, info);
}

export function getUserInfo(key) {
  const userInfo = localStorage.getItem(key);
  return userInfo;
}

export function setPersonalId() {
  // const sessKey = Math.floor(Math.random() * 100000);

  const sessKey = generateString(10);
  localStorage.setItem("SessionKey", sessKey);
  return sessKey;
}

export function getPersonalId() {
  var sessKey = localStorage.getItem("SessionKey");
  if (!sessKey) sessKey = setPersonalId();
  return sessKey;
}
