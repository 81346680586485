import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_TIME_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../../Helper/Constants";
import GetSvgIcon from "../../../images/svglist";
import { Tooltip } from "react-tooltip";
import moment from "moment";

const VehApprovalList = ({ vehApprovalList, openActionModal, getApprovalHistory }) => {
  const columns = [
    {
      name: "Vehicle",
      selector: (row) => row?.vehicleNo,
      sortable: true,
      filterable: true,
    },
    {
      name: "Vehicle Type",
      selector: (row) => row?.vhclType,
      sortable: true,
      filterable: true,
    },
    {
      name: "Partner",
      selector: (row) => row?.partner,
      sortable: true,
      filterable: true,
    },
    {
      name: "Active",
      selector: (row) =>
        row?.isActive ? (
          <p className="text-success">YES</p>
        ) : (
          <p className="text-danger">NO</p>
        ),
      sortable: true,
      filterable: true,
    },
    // {
    //   name: "Assigned On",
    //   selector: (row) =>
    //     row?.assignedOn
    //       ? moment(row?.assignedOn, "DD-MM-YYYY HH:mm", true).format(
    //           DATE_TIME_FORMAT_MOMENT
    //         )
    //       : "",
    //   sortable: true,
    //   filterable: true,
    // },
    {
      name: "Status",
      selector: (row) => row?.status,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={`EDIT-${row?.vhclId}`}
            onClick={() => openActionModal(row)}
          >
            {GetSvgIcon("BiClipboardCheckFill")}
          </div>
          <Tooltip id={`EDIT-${row?.vhclId}`} content={"Edit"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "80px",
    },
    {
      name: "",
      cell: (row) => (
        <>
          <div
            data-tooltip-id={`approval-history-${row?.vhclId}`}
            onClick={() => getApprovalHistory(row?.vhclId)}
            data-bs-toggle="modal"
            data-bs-target="#master-approval-history-modal"
          >
            {GetSvgIcon("History")}
          </div>
          <Tooltip id={`approval-history-${row?.vhclId}`} content={"Approval History"} place="left" />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "80px",
    },
  ];

  return (
    <div className="col-md-12 customized-data-table position-relative">
      <DataTable
        columns={columns}
        data={vehApprovalList}
        pagination
        striped
        highlightOnHover
        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
        paginationPerPage={defaultPageLimit}
        customStyles={customDataTableStyles}
      />
    </div>
  );
};

export default VehApprovalList;
