import React, { useEffect } from "react";
import { useState } from "react";
import { initService, initServiceDetails } from "./initRateCode";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  DATE_FORMAT_CLIENT,
  NUMBER_REGEX,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
  customReactSelectMenuStyle,
} from "../../Helper/Constants";
import GetSvgIcon from "../../../images/svglist";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Select from "react-select";
import { CallApiPost } from "../../Helper/serviceApi";

export default function SLAAddEdit(props) {
  const [submitStatusFail, setSubmitStatusFail] = useState(false);
  const [failMsg, setFailMsg] = useState();
  const {
    rateCodeList,
    slaService,
    clearService,
    customerList,
    openPopup,
    setSpinner,
  } = props;
  const {
    register,
    control,
    watch,
    reset,
    clearErrors,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({ defaultValues: slaService });
  const {
    fields: serviceDtlsList,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "serviceDtlsList",
  });
  useEffect(() => {
    reset(slaService);
  }, [slaService]);
  const watchServiceId = watch("serviceId");
  const clearData = () => {
    reset(JSON.parse(JSON.stringify(initService)));
    clearService();
  };
  const resetData = () => {
    reset(slaService);
  };
  const saveSLA = async (formData) => {
    setSpinner(true);
    let serviceStatus = false;
    let serviceDtls = false;
    let data = await CallApiPost("SaveService", formData);
    if (data.respCode === 0) {
      serviceStatus = true;
      let SLAData = data.respData;
      let serviceData = [];
      formData.serviceDtlsList.map((e) => {
        e["serviceId"] = SLAData[0].serviceId;

        if (!!e?.customerIds && e.customerIds.length > 0) {
          e.customerIds.forEach((element) => {
            let obj = Object.assign({}, e);
            obj.customerId = element;
            obj.customerIds = obj.customerIds.join();
            serviceData.push(obj);
          });
        } else {
          let obj = Object.assign({}, e);
          obj.customerId = 0;
          obj.customerIds = "";
          serviceData.push(obj);
        }
      });
      let sData = await CallApiPost("SaveServiceDetail", serviceData);
      if (sData.respCode === 0) {
        serviceDtls = true;
      }
    } else if (data.respCode === 500) {
      setSubmitStatusFail(true);
      setFailMsg(data.respMessage);
      setTimeout(() => {
        setSubmitStatusFail(false);
      }, 10000);
    }
    if (serviceStatus === true && serviceDtls === true) {
      afterSuccess();
      openPopup(
        true,
        "SUCCESS",
        formData.serviceId > 0
          ? "Service updated successfully!!"
          : "Service created successfully!!"
      );
    }
    setSpinner(false);
  };
  const afterSuccess = () => {
    reset();
    clearErrors();
    clearData();
  };
  return (
    <div
      className="modal fade"
      id="service-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-fullscreen modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              {watchServiceId === 0 ? "Add" : "EDIT"} Service Details
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={clearData}
            ></button>
          </div>
          {submitStatusFail && (
            <div id="errorMsg" className="alert alert-danger" role="alert">
              {failMsg}{" "}
            </div>
          )}
          <div className="modal-body overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="col-lg-3 mb-2">
                      <label
                        htmlFor=""
                        className="form-label theme-label"
                        title="This field is mandatory"
                      >
                        Service Name{" "}
                        <span className="text-danger">&#9733;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="serviceName"
                        {...register("serviceName", {
                          required: {
                            value: true,
                            message: "This Field cannot be blank!!",
                          },
                          maxLength: {
                            value: 20,
                            message: "Field should contain 20 characters!!",
                          },
                          pattern: {
                            value: ALPHANUM_SPACE_SPCL_CHAR,
                            message: "Incorrect Data!!!",
                          },
                        })}
                      />
                      {errors.serviceName && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.serviceName.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-2">
                      <label htmlFor="" className="form-label theme-label">
                        Priority<span className="text-danger">&#9733;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="priority"
                        {...register("priority", {
                          required: {
                            value: true,
                            message: REQUIRED_ERR_MSG,
                          },
                          pattern: {
                            value: NUMBER_REGEX,
                            message: PATTERN_ERR_MSG,
                          },
                          maxLength: {
                            value: 5,
                            message: "Field should contain 5 characters",
                          },
                        })}
                      />
                      {errors?.priority && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors?.priority?.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-3 mb-2">
                      <label htmlFor="" className="form-label theme-label">
                        Description
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="serviceDesc"
                        {...register("serviceDesc", {
                          pattern: {
                            value: ALPHANUM_SPACE_SPCL_CHAR,
                            message: "Incorrect Data!!!",
                          },
                          maxLength: {
                            value: 50,
                            message: "Field should contain 50 characters",
                          },
                        })}
                      />
                      {errors?.serviceDesc && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors?.serviceDesc?.message}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-3 mb-2">
                      <label></label>
                      <div className="row mt-2">
                        <div className="col-md-6 mb-2">
                          <input
                            className="form-check-input p-0 me-2"
                            type="checkbox"
                            {...register("allowPickup", {
                              //onChange: (e) => clearState(e.target.value),
                            })}
                          />
                          <span className="form-label theme-label">
                            Allow Pickup{" "}
                          </span>
                        </div>

                        <div className="col-md-6 mb-2">
                          <input
                            className="form-check-input p-0 me-2"
                            type="checkbox"
                            {...register("allowDelivery", {
                              //onChange: (e) => clearState(e.target.value),
                            })}
                          />
                          <span className="form-label theme-label">
                            Allow Delivery{" "}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="col-md-3">
                              Customer / Sales Partner (Multi Select)
                            </th>
                            <th className="col-md-2">
                              Valid From{" "}
                              <span className="text-danger">&#9733;</span>
                            </th>
                            <th className="col-md-2">
                              Valid To{" "}
                              <span className="text-danger">&#9733;</span>
                            </th>
                            <th className="col-md-auto text-nowrap">
                              Days <span className="text-danger">&#9733;</span>
                            </th>
                            <th className="col-md-1">
                              Pickup{" "}
                              <span className="text-danger">&#9733;</span>
                            </th>
                            <th className="col-md-1">
                              Lodge-In{" "}
                              <span className="text-danger">&#9733;</span>
                            </th>
                            <th className="col-md-3">
                              Rate Code{" "}
                              <span className="text-danger">&#9733;</span>
                            </th>
                            <th className="w-25">
                              <div className="px-2 text-success">
                                <div
                                  onClick={() =>
                                    append(
                                      JSON.parse(
                                        JSON.stringify(
                                          Object.assign({}, initServiceDetails)
                                        )
                                      )
                                    )
                                  }
                                >
                                  {GetSvgIcon("PlusCircle")}
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {!!serviceDtlsList &&
                            serviceDtlsList?.map((data, index) => {
                              let {
                                id,
                                customerId,
                                customerIds,
                                validFrom,
                                validTo,
                                targetDays,
                                lastPickup,
                                lastLodgein,
                                ratechartId,
                              } = data;
                              return (
                                <tr key={id}>
                                  <td className="text-nowrap col-md-4">
                                    <Controller
                                      name={`serviceDtlsList.${index}.customerIds`}
                                      // defaultValue={customerId}
                                      control={control}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          options={customerList}
                                          placeholder="Select Customer/Sales Partner"
                                          value={customerList.filter((opt) =>
                                            Array.isArray(value)
                                              ? value?.includes(opt.value)
                                              : 0
                                          )}
                                          onChange={(d) =>
                                            onChange(
                                              d ? d.map((opt) => opt.value) : []
                                            )
                                          }
                                          className="col-md-4 form-control p-0 "
                                          isSearchable={true}
                                          isClearable={true}
                                          isMulti={true}
                                          menuPortalTarget={document.body}
                                          styles={customReactSelectMenuStyle}
                                        />
                                      )}
                                    />
                                  </td>
                                  <td>
                                    <Controller
                                      name={`serviceDtlsList.${index}.validFrom`}
                                      defaultValue={validFrom}
                                      control={control}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Please select date!",
                                        },
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <ReactDatePicker
                                          placeholderText="Select Date"
                                          className="form-control"
                                          dateFormat={DATE_FORMAT_CLIENT}
                                          selected={
                                            value ? new Date(value) : value
                                          }
                                          showTimeSelect={false}
                                          onChange={(d) => {
                                            onChange(
                                              d !== null
                                                ? moment(d).format("YYYY-MM-DD")
                                                : ""
                                            );
                                          }}
                                          minDate={new Date()}
                                          showYearDropdown
                                        />
                                      )}
                                    />
                                    {!!errors?.serviceDtlsList &&
                                      !!errors?.serviceDtlsList[index]
                                        ?.validFrom && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888;{" "}
                                            {
                                              errors?.serviceDtlsList[index]
                                                ?.validFrom.message
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <Controller
                                      name={`serviceDtlsList.${index}.validTo`}
                                      defaultValue={validTo}
                                      control={control}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Please select date!",
                                        },
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <ReactDatePicker
                                          placeholderText="Select Date"
                                          className="form-control"
                                          dateFormat={DATE_FORMAT_CLIENT}
                                          selected={
                                            value ? new Date(value) : value
                                          }
                                          showTimeSelect={false}
                                          onChange={(d) => {
                                            onChange(
                                              d !== null
                                                ? moment(d).format("YYYY-MM-DD")
                                                : ""
                                            );
                                          }}
                                          minDate={new Date()}
                                          showYearDropdown
                                        />
                                      )}
                                    />
                                    {!!errors?.serviceDtlsList &&
                                      !!errors?.serviceDtlsList[index]
                                        ?.validTo && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888;{" "}
                                            {
                                              errors?.serviceDtlsList[index]
                                                ?.validTo.message
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="number"
                                      defaultValue={targetDays}
                                      name={`serviceDtlsList.${index}.targetDays`}
                                      {...register(
                                        `serviceDtlsList.${index}.targetDays`,
                                        {
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                          pattern: {
                                            value: NUMBER_REGEX,
                                            message: PATTERN_ERR_MSG,
                                          },
                                          maxLength: {
                                            value: 5,
                                            message: "Limit: 5 characters!!!",
                                          },
                                          // valueAsNumber: true,
                                        }
                                      )}
                                      className=" col-md-4 form-control px-1"
                                      placeholder="Days"
                                    />
                                    {!!errors?.serviceDtlsList &&
                                      !!errors?.serviceDtlsList[index]
                                        ?.targetDays && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888;{" "}
                                            {
                                              errors?.serviceDtlsList[index]
                                                ?.targetDays.message
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      defaultValue={lastPickup}
                                      name={`serviceDtlsList.${index}.lastPickup`}
                                      {...register(
                                        `serviceDtlsList.${index}.lastPickup`,
                                        {
                                          required: {
                                            value: watch("allowPickup"),
                                            message: REQUIRED_ERR_MSG,
                                          },
                                        }
                                      )}
                                      className=" col-md-4 form-control"
                                    />
                                    {!!errors?.serviceDtlsList &&
                                      !!errors?.serviceDtlsList[index]
                                        ?.lastPickup && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888;{" "}
                                            {
                                              errors?.serviceDtlsList[index]
                                                ?.lastPickup.message
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      defaultValue={lastLodgein}
                                      name={`serviceDtlsList.${index}.lastLodgein`}
                                      {...register(
                                        `serviceDtlsList.${index}.lastLodgein`,
                                        {
                                          required: {
                                            value: true,
                                            message: REQUIRED_ERR_MSG,
                                          },
                                        }
                                      )}
                                      className=" col-md-4 form-control"
                                    />
                                    {!!errors?.serviceDtlsList &&
                                      !!errors?.serviceDtlsList[index]
                                        ?.lastLodgein && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888;{" "}
                                            {
                                              errors?.serviceDtlsList[index]
                                                ?.lastLodgein.message
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td>
                                    <Controller
                                      name={`serviceDtlsList.${index}.ratechartId`}
                                      defaultValue={ratechartId}
                                      control={control}
                                      rules={{
                                        required: {
                                          value: true,
                                          message: REQUIRED_ERR_MSG,
                                        },
                                      }}
                                      render={({
                                        field: { onChange, value },
                                      }) => (
                                        <Select
                                          options={rateCodeList}
                                          placeholder="Select Rate"
                                          value={
                                            value === null
                                              ? null
                                              : rateCodeList.find(
                                                  (opt) => opt.value === value
                                                )
                                          }
                                          onChange={(val) => {
                                            onChange(val ? val.value : "");
                                          }}
                                          className="col-md-4 form-control p-0 "
                                          isSearchable={true}
                                          isClearable={true}
                                          menuPortalTarget={document.body}
                                          styles={customReactSelectMenuStyle}
                                        />
                                      )}
                                    />
                                    {!!errors?.serviceDtlsList &&
                                      !!errors?.serviceDtlsList[index]
                                        ?.ratechartId && (
                                        <div>
                                          <span className="badge bg-danger">
                                            &#9888;{" "}
                                            {
                                              errors?.serviceDtlsList[index]
                                                ?.ratechartId.message
                                            }
                                          </span>
                                        </div>
                                      )}
                                  </td>
                                  <td className="w-25">
                                    {index > 0 && (
                                      <div
                                        className="px-2 text-danger"
                                        onClick={() => remove(index)}
                                      >
                                        {GetSvgIcon("DashCircleNoFill")}
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-default border"
              onClick={resetData}
            >
              {watchServiceId === 0 ? "Clear" : "Reset"}
            </button>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet"
              onClick={handleSubmit(saveSLA)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
