import React, { useEffect, useState } from "react";
import { DownloadFile } from "../Helper/FileStoreService";
import { Controller } from "react-hook-form";
import { toBase64 } from "../Helper/CommonMethods";
import { Col, Row } from "react-bootstrap";
import {
  DATE_FORMAT_CLIENT,
  DATE_FORMAT_SERVER,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import GetSvgIcon from "../../images/svglist";
import img_sample from "../../../src/images/Vehicle_reg_sample.jpg";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import { LINEHAUL_PARTNER_COMPANY_USER_TYPE } from "../../ConfigConstants/ConfigConstants";
import { CallApiGet } from "../Helper/serviceApi";

export const DISABLE_INFO_ARR = ["GRANT", "PERMIT"];

const VehicleInfo = ({
  vhclData,
  vhclInfoArr,
  control,
  register,
  setValue,
  getValues,
  watch,
  replace,
  update,
  clearErrors,
  isReadOnly = false,
  isMandatory = false,
  errors,
}) => {
  const [minExpDays, setMinExpDays] = useState(new Date());

  const watchVhclId = watch("vhclId");
  const watchAssignTo = watch("assignTo");

  useEffect(() => {
    getConfigData();
  }, []);
  const selectFile = async (code, index, event) => {
    const file = event.target.files;
    let copyVhclInfos = watch("vhclInfos");
    let currVhclInfo = copyVhclInfos[index];
    if (file.length === 0) {
      const newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
      currVhclInfo.attachment1 = { ...newAttachment };
    } else {
      var fileName = file[0].name;
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }
      const newAttachment = {
        photo: photo,
        photoFileName: code,
        photoFileType: ext,
        photoBucketUrl: "",
      };
      currVhclInfo.attachment1 = { ...newAttachment };
      if (!!clearErrors) {
        clearErrors(`vhclInfos.${index}.attachment1`);
      }
    }
    copyVhclInfos[index] = { ...currVhclInfo };
    // replace([...copyVhclInfos]);
    update(index, { ...currVhclInfo });
  };

  const postDownload = () => {
    alert("File Downloaded Successfully!");
  };
  const getConfigData = async () => {
    const uri = "GetConfigDataById?id=65";
    let resp = await CallApiGet(uri);

    if (resp?.respCode === 200 && !!resp?.respData) {
      setMinExpDays(
        new Date(
          moment()
            .add(resp?.respData[0].value_num, "days")
            .format(DATE_FORMAT_SERVER)
        )
      );
    }
  };
  const expiryValidation = (argValue) => {
    if (!!argValue && argValue?.length > 0) {
      let date = new Date(argValue);
      console.log(date, minExpDays, date <= minExpDays);
      if (date < minExpDays) {
        return `Expiry can't be less than  ${minExpDays.getDate()}-${
          minExpDays.getMonth() + 1
        }-${minExpDays.getFullYear()}`;
      } else {
        return true;
      }
    } else {
      return isMandatory;
    }
  };
  return (
    <>
      <div className="fw-bold mt-2 theme-violet-txt border-bottom">
        Vehicle Information
        <span
          className="ms-1 tooltip-with-image"
          // title="Please upload a clear image.
          // Maximum file size: 10 MB.
          // Supported formats: JPEG, PNG, PDF."
        >
          {GetSvgIcon("ToolTip")}{" "}
          <span className="tooltip-content">
            <img
              src={img_sample}
              className="d-block"
              alt="..."
              style={{
                height: "100px",
                width: "270px",
                alignItems: "center",
              }}
            />
            <p className="font-12">
              Please upload a clear image.
              <br /> Maximum file size: 10 MB.
              <br />
              Supported formats: JPEG, PNG, PDF.
            </p>
          </span>
        </span>
      </div>
      <Row>
        {vhclInfoArr?.map((info, index) => (
          <Col md={2} key={info.id}>
            <br />
            <div key={index} className="info-border-container w-100">
              <div className="info-border-text theme-label  fw-bold font-12">
                {info.infoDesc}{" "}
                {isMandatory && <span className="text-danger">&#9733;</span>}
              </div>
              <Row className="pb-2">
                {info.infoCode !== "GRANT" && (
                  <Col md={12} className="pb-2">
                    <label className="theme-label">Expired On</label>
                    <div>
                      <Controller
                        control={control}
                        name={`vhclInfos.${index}.expyDate`}
                        defaultValue={info.expyDate}
                        rules={{
                          required: {
                            value: isMandatory,
                            message: REQUIRED_ERR_MSG,
                          },
                          validate: expiryValidation,
                        }}
                        render={({ field: { onChange, value } }) => (
                          <ReactDatePicker
                            placeholderText="dd-mm-yyyy"
                            className="form-control"
                            wrapperClassName="w-100"
                            dateFormat={DATE_FORMAT_CLIENT}
                            selected={value ? new Date(value) : value}
                            showTimeSelect={false}
                            onChange={(d) =>
                              onChange(
                                d !== null ? moment(d).format("YYYY-MM-DD") : ""
                              )
                            }
                            minDate={minExpDays}
                            showYearDropdown
                            disabled={
                              LINEHAUL_PARTNER_COMPANY_USER_TYPE ===
                              getUserInfo("userType")
                                ? +watchVhclId > 0 &&
                                  +watchAssignTo !== 204 &&
                                  DISABLE_INFO_ARR.includes(info.infoCode)
                                : isReadOnly
                            }
                          />
                        )}
                      />
                      {!!errors?.vhclInfos &&
                        errors?.vhclInfos[index]?.expyDate && (
                          <div>
                            <span className="text-danger fw-bold font-12">
                              &#9888;{" "}
                              {errors?.vhclInfos[index]?.expyDate?.message}
                            </span>
                          </div>
                        )}
                    </div>
                  </Col>
                )}
              </Row>
              <Row>
                <Col key={info.infoCode} md={12}>
                  <div {...register(`vhclInfos.${index}.attachment1`)}>
                    <div className="theme-violet-txt d-flex justify-content-center align-items-center">
                      <span
                        type="button"
                        className="position-relative d-inline-block cursor-pointer"
                      >
                        <input
                          type="file"
                          className="position-absolute w-100 h-100 top-0 start-0 opacity-0 cursor-pointer"
                          aria-describedby="button-addon2"
                          accept="application/pdf, image/jpeg, image/jpg"
                          id={info.infoCode + "attachment"}
                          onChange={(e) => selectFile(info.infoCode, index, e)}
                          disabled={
                            LINEHAUL_PARTNER_COMPANY_USER_TYPE ===
                            getUserInfo("userType")
                              ? +watchVhclId > 0 &&
                                +watchAssignTo !== 204 &&
                                DISABLE_INFO_ARR.includes(info.infoCode)
                              : isReadOnly
                          }
                        />
                        <span className="cursor-pointer">
                          {GetSvgIcon("BiFileEarmarkMed")}
                        </span>
                      </span>
                      <span
                        type="button"
                        className={
                          watch(`vhclInfos.${index}.attachment1`)?.photo
                            ?.length > 0
                            ? "text-black ps-1"
                            : "text-primary ps-1"
                        }
                        disabled={
                          watch(`vhclInfos.${index}.attachment1`)?.photo
                            ?.length > 0
                        }
                        onClick={() =>
                          DownloadFile(
                            vhclData.vhclId +
                              "_" +
                              info.attachment1.photoFileName,
                            postDownload
                          )
                        }
                        title={
                          "Download - " +
                          watch(`vhclInfos.${index}.attachment1.photoFileName`)
                        }
                      >
                        {GetSvgIcon("BiArrowDown")}
                      </span>
                    </div>
                    <span className=" text-center text-truncate">
                      <p className="m-0">
                        {watch(`vhclInfos.${index}.attachment1.photoFileName`)
                          ?.length > 0 &&
                          watch(`vhclInfos.${index}.attachment1.photoFileName`)}
                      </p>
                    </span>
                    {!!errors?.vhclInfos &&
                      errors?.vhclInfos[index]?.attachment1 && (
                        <div>
                          <span className="text-danger fw-bold font-12">
                            &#9888;{" "}
                            {errors?.vhclInfos[index]?.attachment1?.message}
                          </span>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        ))}
        {/* <Col md={2}>
          <div className="img-info-border">
            <img
              src={img_sample}
              className="d-block"
              alt="..."
              style={{ height: "100px", width: "270px", alignItems: "center" }}
            />
            <div style={{ fontSize: "12px" }}>
              Please upload a clear image. Maximum file size: 10 MB. Supported
              formats: JPEG, PNG, PDF.
            </div>
          </div>
        </Col> */}
      </Row>
    </>
  );
};

export default VehicleInfo;
