import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  defaultPageLimit,
  defaultRowsPerPageOptions,
  DATE_FORMAT_MOMENT,
} from "../Helper/Constants";
import moment from "moment";
import { Tooltip } from "react-tooltip";
import { customDateSort } from "../Helper/CommonMethods";

const MasterApprovalLogList = (props) => {
  const approvalhistoryColumns = [
    {
        name: "Approval Type",
        selector: (row) => row.approval_type,
        sortable: true,
        filterable: true,
        omit: props.showApprovalType === false,
    },
    {
      name: "Approval Step",
      selector: (row) => row.approval_step,
      sortable: true,
      filterable: true,
    },
    {
        name: "Approval Request Date",
        selector: (row) => !!row.approval_request_date ? moment(row.approval_request_date).format(DATE_FORMAT_MOMENT) : "NA",
        filterable: true,
        sortFunction: (rowA, rowB) => customDateSort(rowA.approval_request_date, rowB.approval_request_date)
    },
    {
      name: "Status",
      selector: (row) => (row.approval_status === "REJECT" ? "REJECTED" : row.approval_status),
      sortable: true,
      filterable: true,
    },
    {
        name: "Approved/Rejected By",
        selector: (row) => row.logged_by,
        cell: (row) => (
            <>
            <span data-tooltip-id={"approver-" + row.approval_id}>
                {row.logged_by ? row.logged_by : "NA"}
            </span>
            <Tooltip id={"approver-" + row.approval_id} content={row.logged_by} place="left" />
            </>
        ),
        sortable: true,
        filterable: true,
    },
    {
        name: "Approved/Rejected On",
        selector: (row) => !!row.logged_on ? moment(row.logged_on).format(DATE_FORMAT_MOMENT) : 'NA',
        filterable: true,
        sortFunction: (rowA, rowB) => customDateSort(rowA.logged_on, rowB.logged_on)
    },
    {
      name: "Remarks",
      selector: (row) => row.log_text,
      cell: (row) => (
        <>
          <span data-tooltip-id={"comments-" + row.approval_id}>{row.log_text}</span>
          <Tooltip id={"comments-" + row.approval_id} content={row.log_text} place="left" />
        </>
      ),
      filterable: true,
    },
  ];

  return (
    <div
      className="modal fade"
      id="master-approval-history-modal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      data-bs-backdrop="static"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title section-title" id="exampleModalLabel">
              Approval History
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body overflow-auto">
            <div className="row">
              <div>
                <div className="w-100">
                  <div className="row">
                    <div className="clearfix"></div>
                    <div className="col-md-12 mt-1 popup-data-table position-relative">
                      <DataTable
                        columns={approvalhistoryColumns}
                        data={props.data}

                        pagination
                        striped
                        highlightOnHover
                        paginationRowsPerPageOptions={defaultRowsPerPageOptions}
                        paginationPerPage={defaultPageLimit}
                        customStyles={customDataTableStyles}
                        defaultSortAsc={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-default border me-2" data-bs-dismiss="modal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterApprovalLogList;
