import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import {
  ALPHANUM_SPACE_SPCL_CHAR,
  NUMBER_FIVE_DECIMAL,
  NUMBER_NINE_DECIMAL,
  NUMBER_TWO_DECIMAL,
  PATTERN_ERR_MSG,
  REQUIRED_ERR_MSG,
} from "../Helper/Constants";
import { CallApiPost } from "../Helper/serviceApi";

export const intiCrDe = {
  isNote: "",
  amount: "",
  custId: 0,
  remarks: "",
};
export default function CreditAndDebitNote(props) {
  const { show, handleClose, custId, setSpinner } = props;
  const [dcnote, setDCNote] = useState(intiCrDe);
  const {
    register,
    reset,
    clearErrors,
    handleSubmit,
    watch,
    setError,
    formState: { errors },
  } = useForm({ defaultValue: dcnote });

  const saveNote = async (data) => {
    setSpinner(true);
    data.custId = custId;
    if (data.isNote === "false") data.amount = "-" + data.amount;

    let resp = await CallApiPost("addCreditDebitNote", data);
    setSpinner(false);
    if (resp.respCode === 200) {
      props.onSuccess("Note Successfully Saved");
    } else {
      props.showAlert(
        "An error occurred while saving the note. Please try again.",
        false
      );
      closeBtn();
      handleClose();
    }
  };
  const closeBtn = () => {
    reset(intiCrDe);
    clearErrors();
  };
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="form-label theme-label">
            <h5 className="section-title" id="exampleModalLabel">
              Debit and credit note
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-item-center mb-3">
            <div className="col-auto align-self-center me-3">
              <input
                className="form-check-input formsty"
                type="radio"
                name="filterType"
                id="inlineRadio1"
                value="true"
                {...register("isNote", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              <label
                className="form-check-label fw-bold ms-2"
                htmlFor="inlineRadio1"
              >
                Debit Note
              </label>
            </div>
            <div className="col-auto align-self-center me-3">
              <input
                className="form-check-input formsty"
                type="radio"
                name="filterType"
                id="inlineRadio2"
                value="false"
                {...register("isNote", {
                  required: {
                    value: true,
                    message: REQUIRED_ERR_MSG,
                  },
                })}
              />
              <label
                className="form-check-label fw-bold ms-2"
                htmlFor="inlineRadio2"
              >
                Credit Note
              </label>
            </div>
            {errors.isNote && (
              <div>
                <span className="badge bg-danger">
                  &#9888; This Field can not be blank!
                </span>
              </div>
            )}
          </div>
          <div className="col-lg-12 mb-3">
            <label
              htmlFor=""
              className="form-label theme-label"
              title="This field is mandatory"
            >
              Amount <span className="text-danger">&#9733;</span>
            </label>
            <input
              type="number"
              name="amount"
              {...register(`amount`, {
                required: {
                  value: true,
                  message: REQUIRED_ERR_MSG,
                },
                min: {
                  value: 1,
                  message: "Amount should be between RM 1.00 and RM 99999.99",
                },
                pattern: {
                  value: NUMBER_FIVE_DECIMAL,
                  message: "Amount should be between RM 1.00 and RM 99999.99",
                },
              })}
              className="  form-control"
              placeholder="Amount"
            />
            {errors.amount && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors.amount.message}
                </span>
              </div>
            )}
          </div>
          <div className="col-md-3">
            <label
              htmlFor="floatingInputGrid"
              className="form-label theme-label"
            >
              Remarks <span className="text-danger">&#9733;</span>
            </label>
          </div>
          <div className="col-md-12 mb-2">
            <input
              className="  form-control"
              placeholder="Remarks"
              {...register("remarks", {
                required: {
                  value: true,
                  message: REQUIRED_ERR_MSG,
                },
                pattern: {
                  value: ALPHANUM_SPACE_SPCL_CHAR,
                  message: PATTERN_ERR_MSG,
                },
              })}
            />
            {errors.remarks && (
              <div>
                <span className="badge bg-danger">
                  &#9888; {errors.remarks.message}
                </span>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="reset"
            className="btn btn-default border"
            onClick={closeBtn}
          >
            Clear
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary theme-violet"
            onClick={handleSubmit(saveNote)}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
