import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import ModalLayout from "../Layout/ModalLayout";
import { useForm } from "react-hook-form";
import { DECIMAL_REGEX, REQUIRED_ERR_MSG } from "../Helper/Constants";
import GetSvgIcon from "../../images/svglist";
import { Tooltip } from "react-tooltip";
import { LINEHAUL_CUSTOMER_USER_TYPE_LIST } from "../../ConfigConstants/ConfigConstants";
import { getUserInfo } from "../AuthUtils/AuthUtils";

const InboundBill = (props) => {
  const { modalConfig, handleClose, billData, generateInvoice } = props;
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({ defaultValues: billData });

  const [subTot, setSubTot] = useState(0);
  const disableColor = "#cdcfd0";
  const watchAdCharge = watch("additionalCharge");

  useEffect(() => {
    let st = billData.totalPrice - billData.sst;
    setSubTot(st);
    setValue("subTotal", st.toFixed(2));
    setValue("sst", billData.sst.toFixed(2));
    setValue("total", billData.totalPrice.toFixed(2));
    // setValue("additionalCharge","")
  }, []);

  const genInvoice = (data) => {
    generateInvoice(data);
  };
  const onError = (err) => {
    console.error(err);
  };
  const footer = !LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
    getUserInfo("userType")
  ) && (
    <button
      type="button"
      className="btn btn-outline-secondary theme-violet"
      onClick={handleSubmit(genInvoice, onError)}
      disabled={billData.id !== 0}
    >
      Generate Invoice
    </button>
  );

  const header = (
    <h5 className="modal-title section-title" id="exampleModalLabel">
      Fare Details
    </h5>
  );
  const handelPriceUpdate = () => {
    let addChrg = getValues("additionalCharge");
    let st = +subTot;
    st = st + +addChrg;
    let sst = (+st * billData.sstPercent) / 100;
    setValue("subTotal", (+st).toFixed(2));
    setValue("sst", sst.toFixed(2));
    setValue("total", (st + sst).toFixed(2));
  };

  const dropPointCharges = (
    <>
      <tr>
        <td className="col-md-3 px-1 px-1 text-nowrap ">Base Price : </td>
        <td className="col-md-2">
          <input
            className="form-control text-end"
            type="text"
            value={billData.basePrice.toFixed(2)}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td className=" col-md-1 fw-bold">RM</td>
        <td className="col-md-2 px-1 px-1 text-nowrap ">Max Drop Point :</td>
        <td className="col-md-2">
          <input
            className="form-control text-end ms-2"
            type="text"
            value={billData.maxDropPoints}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td className="col-md-2"></td>
      </tr>
      <tr>
        <td className="px-1 px-1 text-nowrap ">Extra Drop Point Price : </td>
        <td>
          <input
            className="form-control text-end"
            type="text"
            value={billData.extraDropPrice.toFixed(2)}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td className="  fw-bold">RM</td>
        <td className="px-1 px-1 text-nowrap ">Extra Drop Points :</td>
        <td>
          <input
            className="form-control text-end ms-2"
            type="text"
            value={billData.extraDropPoints}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td>
          <input
            className="form-control text-end  ms-5 w-75"
            type="text"
            value={billData.extraDropPointsUnitPrice.toFixed(2)}
            disabled
            style={{ background: disableColor }}
          />
        </td>
      </tr>
    </>
  );

  const containerVolCharges = (
    <>
      <tr>
        <td className="col-md-3 px-1 px-1 text-nowrap ">Total Volume: </td>
        <td className="col-md-2">
          <input
            className="form-control text-end"
            type="text"
            value={billData?.totalVol}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td className=" col-md-1 fw-bold"></td>
        <td className="col-md-2 px-1 px-1 text-nowrap ">Chargeable Volume:</td>
        <td className="col-md-2">
          <input
            className="form-control text-end ms-2"
            type="text"
            value={billData.chargeableVol}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td className="col-md-2"></td>
      </tr>
      <tr>
        <td className="px-1 px-1 text-nowrap ">Price : </td>
        <td>
          <input
            className="form-control text-end"
            type="text"
            value={billData.basePrice.toFixed(2)}
            disabled
            style={{ background: disableColor }}
          />
        </td>
        <td className="  fw-bold">RM</td>
        <td className="px-1 px-1 text-nowrap "></td>
        <td></td>
        <td>
          <input
            className="form-control text-end  ms-5 w-75"
            type="text"
            value={billData.unitPrice.toFixed(2)}
            disabled
            style={{ background: disableColor }}
          />
        </td>
      </tr>
    </>
  );

  const regionVolCharge = (
    <>
      {billData?.regionRateList?.map((reg, index) => (
        <tr>
          <td className="px-1 text-nowrap ">
            {`Charge for ${reg?.regionName}`}{" "}
          </td>
          <td>
            <input
              className="form-control text-end"
              type="text"
              value={reg?.amount?.toFixed(2)}
              disabled
              style={{ background: disableColor }}
            />
          </td>
          <td className="  fw-bold pe-3">RM</td>
          <td className=" px-1 text-nowrap ">Chargeable Volume :</td>
          <td>
            <input
              className="form-control text-end ms-2"
              type="text"
              value={reg?.chargeableVol}
              disabled
              style={{ background: disableColor }}
            />
          </td>
          <td>
            <input
              className="form-control text-end  ms-5 w-75"
              type="text"
              value={reg?.regionUnitPrice?.toFixed(2)}
              disabled
              style={{ background: disableColor }}
            />
          </td>
        </tr>
      ))}
    </>
  );

  const renderCharges = () => {
    switch (billData?.rateTypeId) {
      case 750:
        return dropPointCharges;
      case 751:
        return containerVolCharges;
      case 752:
        return regionVolCharge;
      default:
        break;
    }
  };

  return (
    <ModalLayout
      moadlConfig={modalConfig}
      handleMoalClose={handleClose}
      footer={footer}
      header={header}
    >
      <Container>
        <div className="theme-blue border border-1 rounded-2 ">
          <div className="row theme-header mb-3 mx-0">
            <div className="col-md-4 my-2 text-center">
              <span data-tooltip-id={"cust-" + billData.customerAccountCode}>
                {billData.customerName} ({billData.customerAccountCode})
              </span>
              <Tooltip
                id={"cust-" + billData.customerAccountCode}
                content={"Customer"}
              />
            </div>
            <div className="col-md-4 my-2 text-center">
              <span data-tooltip-id={"mno-" + billData.manfNo}>
                {billData.manfNo}
              </span>
              <Tooltip id={"mno-" + billData.manfNo} content={"Manifest No"} />
            </div>
            <div className="col-md-4 my-2 text-center">
              <span data-tooltip-id={"cno-" + billData.contNo}>
                {billData.contNo}
              </span>
              <Tooltip id={"cno-" + billData.contNo} content={"Container No"} />
            </div>
          </div>
          <div className="mx-2 my-3">
            <div className="fw-bold mb-2 d-flex justify-content-between align-item-center">
              <div>Fare Summary</div>
              <div className="text-end me-5">Unit Price</div>
            </div>
            <table className="w-100">
              {renderCharges()}
              <tr>
                <td className="px-1 text-nowrap ">ODA Charge : </td>
                <td>
                  <input
                    className="form-control text-end"
                    type="text"
                    value={billData.odaCharge.toFixed(2)}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td className="  fw-bold">RM</td>
                <td className="px-1 text-nowrap ">ODA Count :</td>
                <td>
                  <input
                    className="form-control text-end ms-2"
                    type="text"
                    value={billData.odaCnt}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td>
                  <input
                    className="form-control text-end  ms-5 w-75"
                    type="text"
                    value={billData.odaUnitPrice.toFixed(2)}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
              </tr>
              <tr>
                <td className="px-1 text-nowrap ">Address Change Charge : </td>
                <td>
                  <input
                    className="form-control text-end"
                    type="text"
                    value={billData.addressCngCharge.toFixed(2)}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td className="  fw-bold">RM</td>
                <td className="px-1 text-nowrap ">Address Change Count :</td>
                <td>
                  <input
                    className="form-control text-end ms-2"
                    type="text"
                    value={billData.addressChargeCnt}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td>
                  <input
                    className="form-control text-end ms-5 w-75"
                    type="text"
                    value={billData.addChngUnitPrice.toFixed(2)}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
              </tr>
              {!LINEHAUL_CUSTOMER_USER_TYPE_LIST.includes(
                getUserInfo("userType")
              ) && (
                <>
                  <tr>
                    <td className="px-1 text-nowrap ">Additional Charge : </td>
                    <td className="pt-2">
                      <input
                        className="form-control text-end"
                        type="number"
                        min={0}
                        {...register("additionalCharge", {
                          pattern: DECIMAL_REGEX,
                          onChange: (e) => {
                            setValue("additionalCharge", +e.target.value);
                          },
                          min: {
                            value: 0,
                            message: "please enter amount >= 0",
                          },
                        })}
                        disabled={billData.id !== 0}
                      />
                      {errors.additionalCharge && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.additionalCharge.message}
                          </span>
                        </div>
                      )}
                    </td>
                    <td className="  fw-bold">RM</td>

                    <td className="px-1 text-nowrap "></td>
                    <td></td>
                  </tr>
                  <tr>
                    <td className="px-1 text-nowrap ">Remark : </td>
                    <td colSpan={5} className="pt-1">
                      <div className="container px-0">
                        <div className="d-flex align-items-container">
                          <input
                            className="form-control "
                            type="text"
                            {...register("remark", {
                              required: {
                                value: watchAdCharge > 0,
                                message: REQUIRED_ERR_MSG,
                              },
                            })}
                            maxLength="100"
                          />
                          <span
                            className="ps-1 light-color-link fw-bold font-12 text-center"
                            onClick={handelPriceUpdate}
                          >
                            Click to Recalculate {GetSvgIcon("ArrowRepeat")}
                          </span>
                        </div>
                      </div>

                      {errors.remark && (
                        <div>
                          <span className="badge bg-danger">
                            &#9888; {errors.remark.message}
                          </span>
                        </div>
                      )}
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td colSpan="3">
                  <hr />
                </td>
              </tr>
              <tr>
                <td className="px-1 px-1 text-nowrap ">
                  <div>
                    Sub Total<span className="font-12"> (Excluding SST)</span>
                  </div>
                </td>
                <td>
                  <input
                    className="form-control text-end"
                    type="text"
                    {...register("subTotal")}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td className="  fw-bold">RM</td>
                <td className="px-1 px-1 text-nowrap "></td>
                <td></td>
              </tr>
              <tr>
                <td className="px-1 px-1 text-nowrap ">SST : </td>
                <td>
                  <input
                    className="form-control text-end"
                    type="text"
                    // value={availableCredits}
                    {...register("sst")}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td className="  fw-bold">RM</td>
                <td className="px-1 px-1 text-nowrap "></td>
                <td></td>
              </tr>
              <tr>
                <td colSpan="3">
                  <hr />
                </td>
              </tr>
              <tr>
                <td className="px-1 px-1 text-nowrap ">
                  <div>
                    Total Cost <span className="font-12">(Including SST)</span>
                  </div>
                </td>
                <td>
                  <input
                    className="form-control text-end fw-bold"
                    type="text"
                    // value={availableCredits}
                    {...register("total")}
                    disabled
                    style={{ background: disableColor }}
                  />
                </td>
                <td className="  fw-bold">RM</td>
                <td className="px-1 px-1 text-nowrap "></td>
                <td></td>
              </tr>
            </table>
          </div>
        </div>
      </Container>
    </ModalLayout>
  );
};

export default InboundBill;
