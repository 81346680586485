import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Layout";
import GetSvgIcon, { GetSvgUpload } from "../../../images/svglist";
import { DATE_FORMAT_CLIENT, DATE_FORMAT_SERVER } from "../../Helper/Constants";
import ReactDatePicker from "react-datepicker";
import PartnerBillingList from "./PartnerSummaryList";
import moment from "moment";
import { CallApiGet, CallApiPost } from "../../Helper/serviceApi";
import {
  exportToExcel,
  getKiplForm,
  permissionCheck,
  readLocalStorage,
  toBase64,
} from "../../Helper/CommonMethods";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import PartnerInvoiceList from "./PartnerInvoiceList";
import {
  INV,
  INVPAY,
  INVS,
  LINEHAUL_PARTNER_COMPANY_USER_TYPE,
  PATINV,
} from "../../../ConfigConstants/ConfigConstants";
import { Tooltip } from "react-tooltip";
import { invColumns } from "../InvoiceList";
import ModalConfirmAlertCustom from "../../Layout/ModalConfirmAlertCustom";
import PartnerSummaryList from "./PartnerSummaryList";

const initAlertConfig = {
  isSuccess: false,
  size: "",
  show: false,
  title: "",
  description: "",
};
const initSearchParam = {
  drCommYr: moment().year(),
  drCommMon: moment().month(),
  drCustType: "All",
};

const PartnerInvoiceMain = () => {
  const profileId = getUserInfo("acctId");
  const [spinner, setSpinner] = useState(false);
  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();
  const [alertConfig, setAlertConfig] = useState(initAlertConfig);
  const [isFromBooking, setIsFromBooking] = useState(false);
  let isFrombook = false;
  const [selectedCustId, setSelectedCustId] = useState();
  const [unBilledInvArr, setUnBilledInvArr] = useState([]);
  const [invoiceDataList, setInvoiceDataList] = useState([]);
  const [avlCreditLimit, setAvlCreditLimit] = useState(0);
  const [latestInvAmt, setLatestInvAmt] = useState();
  const [cnAmt, setCnAmt] = useState();
  const [isCreditBalanceCalled, setIsCreditBalanceCalled] = useState(false);
  const [financeRow, setFinanceRow] = useState([]);
  const [customerDtls, setcustomerDtls] = useState({});
  const [paymentStatusBtn, setPaymentStatusBtn] = useState(true);
  const [searchParams, setSearchParams] = useState(initSearchParam);
  const customerType = getUserInfo("customerType");
  let summaryCustId;
  const [searchBtn, setSearchBtn] = useState(true);
  const [showBackBtn, setShowBackBtn] = useState(false);
  const [summaryStatus, setSummaryStatus] = useState(false);
  const [pageTitle, setPageTitle] = useState();
  const [custTypeShow, setCustTypeShow] = useState(true);
  const [invPay, setInvPay] = useState(false);
  const [totalaBillAmount, setTotalaBillAmount] = useState(0.0);
  const [invoiceDistributionList, setInvoiceDistributionList] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [payMoadlConfig, setPayMoadlConfig] = useState(initAlertConfig);
  const [isRemarkBlank, setIsRemarkBlank] = useState(false);
  const [manualPayRemark, setManualPayRemark] = useState("");
  const [finalAmount, setFinalAmount] = useState(0);
  const [payCnModalConfig, setPayCnModalConfig] = useState(initAlertConfig);
  const [validatePay, setValidatePay] = useState(false);
  const [bankSlipModalConfig, setBankSlipModalConfig] =
    useState(initAlertConfig);
  const [isFileBlank, setIsFileBlank] = useState(false);
  const [creditNoteApplied, setCreditNoteApplied] = useState(false);
  const [bsFile, setBsFile] = useState({});
  const [bsFileName, setBsFileName] = useState();

  useEffect(() => {
    if (permissionCheck(INVS, "allowedRead")?.allowedRead) {
      setPageTitle("Finance Summary");
      setPaymentStatusBtn(false);
      setCustTypeShow(true);
      setSummaryStatus(true);
      if (permissionCheck(INVPAY, "allowedWrite")?.allowedWrite) {
        setInvPay(true);
        setPaymentStatusBtn(true);
      }
      financeSummary();
      // financeSummary();
    } else if (permissionCheck(PATINV, "allowedRead")?.allowedRead) {
      setPaymentStatusBtn(true);
      setPageTitle("Transaction Details");
      setCustTypeShow(false);
      setSummaryStatus(false);
      fetchAllInvoiceList(profileId, "");

      // financeSummary();
    }
    setCrrentDateRange();
  }, []);

  const exportData = () => {
    exportToExcel(invoiceDataList, invColumns, "invoice-list.xlsx");
  };

  const setCrrentDateRange = () => {
    if (!isFromBooking && !isFrombook) {
      setFromDate(
        moment({
          year: searchParams?.drCommYr,
          month: searchParams?.drCommMon,
          day: 1,
        })
      );
      setToDate(
        moment({
          year: searchParams?.drCommYr,
          month: searchParams?.drCommMon,
          day: 1,
        }).add(1, "M")
      );
    }
  };

  const hideAlert = () => {
    setAlertConfig({
      isSuccess: alertConfig.isSuccess,
      size: alertConfig.size,
      show: false,
      title: "",
      description: "",
    });
  };
  const showAlert = (data) => {
    setAlertConfig({
      isSuccess: data.isSuccess,
      size: "md",
      show: true,
      title: data.title,
      description: data.description,
    });
  };

  const setCurrentDateRange = () => {
    setFromDate(
      new Date(
        moment({
          year: searchParams?.drCommYr,
          month: searchParams?.drCommMon,
          day: 1,
        })
      )
    );

    setToDate(
      new Date(
        moment({
          year: searchParams?.drCommYr,
          month: searchParams?.drCommMon,
          day: 1,
        }).add(1, "M")
      )
    );
  };

  const validateDateRange = () => {
    let isValid = true;
    let tdate = moment(toDate);
    let fdate = moment(fromDate);
    let dayDiff = tdate.diff(fdate, "d");
    let monthDiff = tdate.diff(fdate, "M");

    if (dayDiff > 92 || monthDiff > 3) {
      isValid = false;
      showAlert({
        isSuccess: false,
        size: "md",
        show: true,
        title: "",
        description: "The date range should not be greater than 3 months.",
      });
      setCurrentDateRange();
    }

    return isValid;
  };

  const fetchAllInvoiceList = async (partner_id, type) => {
    if (validateDateRange()) {
      if (!partner_id) partner_id = selectedCustId;
      setSpinner(true);
      // let toiDate = !!readLocalStorage("invToDate")
      //   ? readLocalStorage("invToDate")
      //   : toDate;
      // let fromiDate = !!readLocalStorage("invFromDate")
      //   ? readLocalStorage("invFromDate")
      //   : fromDate;

      let toiDate = readLocalStorage("invToDate");
      let fromiDate = readLocalStorage("invFromDate");

      // let fromDate = readLocalStorage("invFromDate");

      var param = {};
      if (type === "UBL") param.unbilled = true;
      param.customerId = +partner_id;

      if (!!toiDate && !!fromiDate) {
        const searchFrom = moment(fromiDate).day(1).format(DATE_FORMAT_SERVER);
        const searchTo = moment(toiDate)
          .add(1, "M")
          .day(1)
          .format(DATE_FORMAT_SERVER);
        setToDate(searchTo);
        setFromDate(searchFrom);
        setIsFromBooking(true);
        isFrombook = true;

        param.dateFrom = searchFrom;
        param.dateTo = searchTo;
      } else {
        param.dateFrom = !!fromDate
          ? moment(fromDate).format(DATE_FORMAT_SERVER)
          : moment({
              year: searchParams?.drCommYr,
              month: searchParams?.drCommMon,
              day: 1,
            }).format(DATE_FORMAT_SERVER);

        param.dateTo = !!toDate
          ? moment(toDate).format(DATE_FORMAT_SERVER)
          : moment({
              year: searchParams?.drCommYr,
              month: searchParams?.drCommMon,
              day: 1,
            })
              .add(1, "M")
              .format(DATE_FORMAT_SERVER);
      }
      setSelectedCustId(partner_id);
      let data = await CallApiPost("getPatInvoiceList", param);

      if (data.respCode === 0) {
        /* The above code is declaring a variable `custTransDtls` and assigning it the result of calling
      the `financeDTLSummary` function using the `await` keyword. The `await` keyword is used to
      wait for a promise to resolve and return its resolved value. */
        // let custTransDtls = await financeDTLSummary();
        if (type === "UBL") {
          setUnBilledInvArr(data.respData);
        } else {
          setInvoiceDataList(data.respData);
        }
      }

      // if (!isCreditBalanceCalled) {
      // let credLimitResp = await CallApiGet("getAvlCredit?id=" + partner_id, "");
      // if (credLimitResp.respCode === 200) {
      //   setAvlCreditLimit(-credLimitResp.respData[0].payment_due);
      // }

      let latestInvResp = await CallApiGet(
        "getlatestInvDtls?id=" + partner_id + "&type=138",
        ""
      );
      if (latestInvResp.respCode === 200) {
        if (latestInvResp.respData.length > 0)
          setLatestInvAmt(latestInvResp.respData[0]?.invoice_amount);
        setCnAmt(latestInvResp.respData[0]?.cn_amount);
      }
      setIsCreditBalanceCalled(true);
      // }
      financeSummary();
      localStorage.removeItem("invToDate");
      setSpinner(false);
    }
  };

  const setUpCustDtls = (custTransDtls, partner_id) => {
    if (!!custTransDtls) {
      // setPaymentStatusBtn(true);
      summaryCustId = partner_id;
      // setSelectedCustId(customerId)
      setcustomerDtls({
        custId: partner_id,
        custName: custTransDtls.partner_name,
        custAccount: custTransDtls.acct_code,
        custOpeningBls:
          (custTransDtls.old_inv_amt ? custTransDtls.old_inv_amt : 0) -
          (custTransDtls.old_pmt_amt ? custTransDtls.old_pmt_amt : 0),
        // custOutStndBls:
        //   (custTransDtls.old_inv_amt ? custTransDtls.old_inv_amt : 0) -
        //   (custTransDtls.old_pmt_amt ? custTransDtls.old_pmt_amt : 0) +
        //   custTransDtls.inv_amt -
        //   custTransDtls.pmt_amt,
        custOutStndBls: custTransDtls.outstand_amount,
        custTotalBill: custTransDtls.inv_amt,
        custUnBill: custTransDtls.unb_amt,
        custUnPaid:
          custTransDtls.inv_amt > custTransDtls.pmt_amt
            ? custTransDtls.inv_amt - custTransDtls.pmt_amt
            : 0,
        custTotalPaid: custTransDtls?.pmt_amt,
        payAmount:
          custTransDtls.inv_amt > custTransDtls.pmt_amt
            ? custTransDtls.inv_amt - custTransDtls.pmt_amt
            : 0,
      });
    } else {
      setPaymentStatusBtn(false);
    }
  };

  const financeSummary = async () => {
    if (
      +customerType === 135 ||
      +customerType === 136 ||
      +customerType === 141 ||
      validateDateRange()
    ) {
      setSpinner(true);
      // setSummaryStatus(false);
      var param = {};
      // param.dateFrom = moment({
      //   year: searchParams?.drCommYr,
      //   month: searchParams?.drCommMon,
      //   day: 1,
      // }).format(DATE_FORMAT_SERVER);
      // param.dateTo = moment({
      //   year: searchParams?.drCommYr,
      //   month: searchParams?.drCommMon,
      //   day: 1,
      // })
      //   .add(1, "M")
      //   .format(DATE_FORMAT_SERVER);
      param.dateFrom = !!fromDate
        ? moment(fromDate).format(DATE_FORMAT_SERVER)
        : moment({
            year: searchParams?.drCommYr,
            month: searchParams?.drCommMon,
            day: 1,
          }).format(DATE_FORMAT_SERVER);

      param.dateTo = !!toDate
        ? moment(toDate).format(DATE_FORMAT_SERVER)
        : moment({
            year: searchParams?.drCommYr,
            month: searchParams?.drCommMon,
            day: 1,
          })
            .add(1, "M")
            .format(DATE_FORMAT_SERVER);
      param.customerType = searchParams?.drCustType;

      const uri = "patFinanceSummary";
      let resp = await CallApiPost(uri, param);
      if (resp.respCode === 200 || resp.respCode === 0) {
        setFinanceRow(resp?.respData);
        // disableBackBtn();
        let custId = !!summaryCustId ? summaryCustId : selectedCustId;
        let custDtl = !!custId
          ? resp?.respData.filter((a) => a.partner_id === +custId)[0]
          : resp?.respData[0];

        setUpCustDtls(custDtl, custDtl?.partner_id);
      }

      setSpinner(false);
    }
  };

  const setPayModalData = (type, data) => {
    console.log(invoiceDistributionList);
    setPayMoadlConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "CONFIRM PAYMENT",
      description: "Are you sure you want to Pay now",
    });
    payByCreditVal(false);
  };

  const enableBackBtn = () => {
    setShowBackBtn(true);
  };

  const disableBackBtn = () => {
    setShowBackBtn(false);
    setSearchBtn(true);
    setTotalaBillAmount(0.0);
  };

  const manualPay = async () => {
    if (invoiceDistributionList.length > 0) {
      let remark = document.getElementById("pay-remark").value;
      setIsRemarkBlank(!remark);
      if (!remark) {
        return;
      } else {
        handlePayMoalClose();
        // setValidatePay(false);
        let data = {
          paymentAmount: totalaBillAmount.toFixed(2),
          customerId: selectedCustId,
          creditNoteApplied,
          invoiceDistributionList,
          transactionRef: remark,
          customerType: 138,
        };
        const uri = "makeManualPayment";
        let resp = await CallApiPost(uri, data);

        if (resp.respCode === 200) {
          fetchAllInvoiceList(selectedCustId, "");
          setTotalaBillAmount(0.0);
          showAlert({
            isSuccess: true,
            size: "md",
            show: true,
            title: "",
            description: resp.respData,
          });
          // window.location.reload()
        } else {
          setTotalaBillAmount(0.0);
          showAlert({
            isSuccess: false,
            size: "md",
            show: true,
            title: "",
            description: "Some Error Occured!!",
          });
        }
      }
    }
    //  else {
    //   setValidatePay(true);
    //   setTimeout(() => {
    //     setValidatePay(false);
    //   }, 5000);
    // }
    setSpinner(false);
  };

  const handlePayMoalClose = (reload = false) => {
    setPayMoadlConfig({
      type: "",
      size: "xl",
      show: false,
      title: "",
    });
    setManualPayRemark("");
    clearSelection();
  };

  const setCnPayModalData = (type, data) => {
    setPayCnModalConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "CONFIRM PAYMENT",
      description: "Are you sure you want to Pay now",
    });
    setFinalAmount(totalaBillAmount);
  };

  const handleCnPayMoalClose = (reload = false) => {
    setPayCnModalConfig({
      type: "",
      size: "md",
      show: false,
      title: "",
    });
    clearSelection();
  };

  const clearSelection = () => {
    setClearSelectedRows(!clearSelectedRows);
    setTotalaBillAmount(0);
    setInvoiceDistributionList([]);
  };

  const prtInvDownload = async (orderData) => {
    setSpinner(true);
    let custType = false;

    const response = await CallApiGet(
      `generateAllInvoicePdf/${orderData.ref_no}/${custType}`
    );
    setSpinner(false);
    if (response.respCode === 200) {
      window.open(response.respData, "_blank");
    }
  };

  const payBillNow = async () => {
    setSpinner(true);
    if (invoiceDistributionList.length > 0) {
      setValidatePay(false);
      let data = {
        paymentAmount: finalAmount,
        customerId: profileId,
        invoiceDistributionList,
        creditNoteApplied,
        customerType: 138,
      };
      const uri = "makePayment";
      let resp = await CallApiPost(uri, data);
      if (resp.respCode === 200) {
        handleCnPayMoalClose();
        if (finalAmount > 0) {
          document.getElementById("paymentForm").innerHTML = getKiplForm(
            resp.respData,
            0
          );
          document.getElementById("onlinepayment").submit();
        } else {
          // handleCnPayMoalClose();
          window.location.reload();
        }
      }
    } else {
      setValidatePay(true);
      setTimeout(() => {
        setValidatePay(false);
      }, 5000);
    }
    setSpinner(false);
  };

  const payByCreditVal = (checked) => {
    setCreditNoteApplied(checked);
    if (checked) {
      let amt = totalaBillAmount - avlCreditLimit;
      if (amt > 0) {
        setFinalAmount(amt.toFixed(2));
      } else setFinalAmount(0);
    } else {
      setFinalAmount(totalaBillAmount);
    }
  };

  const setBsPayModalData = (type, data) => {
    setBankSlipModalConfig({
      isSuccess: false,
      size: "md",
      show: true,
      title: "Bank Slip Upload",
      description: "Are you sure you want to Pay now",
    });
    setFinalAmount(totalaBillAmount);
  };

  const handleBsPayMoalClose = (reload = false) => {
    setBankSlipModalConfig({
      type: "",
      size: "md",
      show: false,
      title: "",
    });

    setBsFile({
      photo: "",
      photoFileName: "",
      photoFileType: "",
      photoBucketUrl: "",
    });
    clearSelection();
    setBsFileName("");
    setIsRemarkBlank(false);
    setIsFileBlank(false);
  };

  const handelChangeData = (e) => {
    if (!!e) setIsRemarkBlank(false);
  };

  const uploadBankSlip = async () => {
    if (invoiceDistributionList.length > 0) {
      let remark = document.getElementById("bs-remark").value.trim();
      let file = bsFile.photoFileName;
      setIsRemarkBlank(!remark);
      setIsFileBlank(!file);
      if (!remark || !file) {
        return;
      } else {
        setSpinner(true);
        let payload = {
          customerId: profileId,
          uploadDate: new Date(),
          remarks: document.getElementById("bs-remark").value.trim(),
          stampUser: 0,
          invoiceDistributionList,
          bankSlipModel: bsFile,
        };
        const uri = "createBankSlipUploadRequest";
        let resp = await CallApiPost(uri, payload);
        handleBsPayMoalClose();
        if (resp.respCode === 200) {
          fetchAllInvoiceList(selectedCustId, "");
          setTotalaBillAmount(0.0);
          showAlert({
            isSuccess: true,
            size: "md",
            show: true,
            title: "",
            description: "Bank Slip Uploaded Successfully",
          });
          // window.location.reload()
        } else {
          setTotalaBillAmount(0.0);
          showAlert({
            isSuccess: false,
            size: "md",
            show: true,
            title: "",
            description: resp.respData,
          });
        }
        setSpinner(false);
      }
    }
  };

  const selectFile = async (event) => {
    const file = event.target.files;

    if (file.length === 0) {
      const newAttachment = {
        photo: "",
        photoFileName: "",
        photoFileType: "",
        photoBucketUrl: "",
      };
      setBsFile(newAttachment);
      setBsFileName("");
    } else {
      setIsFileBlank(false);
      var fileName = file[0].name;
      setBsFileName(fileName);
      var ext = fileName.substr(fileName.lastIndexOf(".") + 1);
      var photo = "";
      try {
        photo = await toBase64(file[0]);
      } catch (error) {
        console.error(error);
      }
      const newAttachment = {
        photo: photo,
        photoFileName: fileName,
        photoFileType: ext,
        photoBucketUrl: "",
      };
      setBsFile(newAttachment);
    }
  };

  return (
    <>
      <Layout
        spinnershow={spinner}
        alertConfig={alertConfig}
        hideAlert={hideAlert}
      >
        <div className="clearfix border-bottom pb-2">
          <div className="float-start">
            <div className="section-title mt-1">{pageTitle}</div>
          </div>
          {paymentStatusBtn && !invPay ? (
            <>
              <div className="float-end">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control fw-bold theme-blue-txt"
                    value={"" + totalaBillAmount.toFixed(2) + " RM"}
                    disabled
                  />
                  <button
                    className="theme-violet btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={setCnPayModalData}
                    disabled={invoiceDistributionList.length < 1}
                  >
                    Pay Now{" "}
                  </button>
                  {/* <button
                    className="theme-violet btn btn-outline-secondary ms-1"
                    type="button"
                    id="button-addon2"
                    onClick={setBsPayModalData}
                    disabled={invoiceDistributionList.length < 1}
                  >
                    Upload Bank Slip{" "}
                  </button> */}
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {invPay && custTypeShow && !summaryStatus ? (
            <>
              <div className="float-end">
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="form-control fw-bold theme-blue-txt"
                    value={"" + totalaBillAmount.toFixed(2) + " RM"}
                    disabled
                  />
                  <button
                    className="theme-violet btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={setPayModalData}
                    disabled={invoiceDistributionList.length < 1}
                  >
                    Manual Payment{" "}
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div id="paymentForm"></div>
        {validatePay && (
          <div id="errorMsg" className="alert alert-danger mt-2" role="alert">
            Please select the invoice to pay
            <br />
          </div>
        )}
        <div className="mt-2 d-flex align-items-end justify-content-between">
          {custTypeShow && showBackBtn && (
            <>
              <div className="col-auto">
                <div className="">
                  <button
                    className="btn btn-outline-secondary theme-violet text-nowrap"
                    type="button"
                    onClick={() => {
                      disableBackBtn();
                      setSummaryStatus(true);
                    }}
                  >
                    {GetSvgIcon("BiChevronCompactLeft")}
                    Back
                  </button>
                </div>
              </div>

              <div>
                <button
                  type="button"
                  className="btn btn-outline-secondary theme-violet  mt-2 mb-2 ms-4 "
                  onClick={exportData}
                  data-tooltip-id="csv-export"
                  disabled={invoiceDataList.length === 0 ? true : false}
                >
                  {GetSvgIcon("SpreadSheet")}
                </button>
                <Tooltip
                  id="csv-export"
                  content="Download to Excel"
                  place="top"
                  style={{ zIndex: "100" }}
                />
              </div>
            </>
          )}
        </div>

        <div className="d-flex align-item-center justify-content-start py-3 m-1 ">
          {/* {searchBtn && ( */}
          <div className="col-auto">
            <div className="border rounded p-2 me-3 wt-tbl float-start">
              <div className="d-flex align-item-center">
                <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label align-self-center me-2"
                    title="This field is mandatory"
                  >
                    From Date:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Trip Date"
                    className="form-control"
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!fromDate ? new Date(fromDate) : fromDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setFromDate(d)}
                    onSelect={(d) => setFromDate(d)}
                    maxDate={new Date(toDate)}
                    // minDate={new Date(moment(toDate).subtract(3,"months"))}
                  />
                </div>
                <div className="col-auto align-self-center d-flex">
                  <label
                    htmlFor=""
                    className="form-label theme-label ms-3 align-self-center"
                    title="This field is mandatory"
                  >
                    To Date:
                  </label>
                  <ReactDatePicker
                    placeholderText="Select Trip Date"
                    className="form-control "
                    dateFormat={DATE_FORMAT_CLIENT}
                    selected={!!toDate ? new Date(toDate) : toDate}
                    onFocus={(e) => e.target.blur()}
                    onChange={(d) => setToDate(d)}
                    onSelect={(d) => setToDate(d)}
                    // maxDate={new Date()}
                    minDate={new Date(fromDate)}
                  />
                </div>
              </div>
            </div>
            <button
              type="button"
              className="btn btn-outline-secondary theme-violet text-nowrap float-end mt-2"
              // onClick={() => loadDataBySbuAndTripDate()}

              // disabled={!(!!fromDate && !!toDate)}
              onClick={() => {
                if (summaryStatus) financeSummary();
                // financeSummary();
                if (!summaryStatus) fetchAllInvoiceList();
              }}
            >
              {GetSvgIcon("Search")}
            </button>
          </div>
          {/* )} */}
          {[135, 136, 141].includes(+customerType) && (
            <div>
              <button
                type="button"
                className="btn btn-outline-secondary theme-violet  mt-2 mb-2 ms-4 "
                onClick={exportData}
                data-tooltip-id="csv-export"
                disabled={invoiceDataList.length === 0 ? true : false}
              >
                {GetSvgIcon("SpreadSheet")}
              </button>
              <Tooltip
                id="csv-export"
                content="Download to Excel"
                place="top"
                style={{ zIndex: "100" }}
              />
            </div>
          )}
        </div>
        {summaryStatus ? (
          <PartnerSummaryList
            fetchAllInvoiceList={fetchAllInvoiceList}
            setSearchBtn={setSearchBtn}
            enableBackBtn={enableBackBtn}
            setUpCustDtls={setUpCustDtls}
            setSummaryStatus={setSummaryStatus}
            financeRow={financeRow}
          />
        ) : (
          <PartnerInvoiceList
            invoiceDataList={invoiceDataList}
            paymentStatusBtn={paymentStatusBtn}
            setInvoiceDistributionList={setInvoiceDistributionList}
            setTotalaBillAmount={setTotalaBillAmount}
            prtInvDownload={prtInvDownload}
            clearSelectedRows={clearSelectedRows}
            customerDtls={customerDtls}
            latestInvAmt={latestInvAmt}
            avlCreditLimit={avlCreditLimit}
          />
        )}
      </Layout>
      <ModalConfirmAlertCustom
        confirmAlertConfig={payMoadlConfig}
        hideConfirmAlert={handlePayMoalClose}
        confirmAlert={manualPay}
        cancelBtnName={"Cancel"}
        confirmBtnName={"Paid"}
        modalStyle={{ titelStyle: "theme-label fs-3 " }}
      >
        <>
          <div className="row">
            <div className="col-md-6  text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label mx-3"
              >
                Amount :
              </label>
            </div>
            <div className="col-md-6 mb-2 text-start">
              <label className="form-label ">
                <b>{totalaBillAmount.toFixed(2)} RM</b>
              </label>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6 text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Credit Note :
              </label>
            </div>
            <div className="col-md-6 mb-2 text-start">
              <label className="form-label">
                <b>{avlCreditLimit.toFixed(2)} RM</b>
              </label>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6 text-start "></div>
            <div className="col-md-6 mb-2 text-start ">
              <input
                className="form-check-input p-0 me-2 "
                type="checkbox"
                // style={{background: "aliceblue"}}
                // onChange={(e) => payByCreditVal(e.target.checked)}
                // checked={(e)=>payByCreditVal(e)}
                disabled={+avlCreditLimit < 1}
              />
              Apply Credit Note
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-md-6 text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Final Amount :
              </label>
            </div>
            <div className="col-md-6 mb-2 text-start ">
              <label className="form-label">
                <b>{(+finalAmount).toFixed(2)} RM</b>
              </label>
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-3">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Remark <span className="text-danger">&#9733;</span>
              </label>
            </div>
            <div className="col-md-9 mb-2">
              <textarea
                className="form-control"
                id="pay-remark"
                rows={4}
              ></textarea>
              {/* <input
              id="pay-remark"
              type="textarea"
              className="form-control"
              name="payRemark"
              
              autoComplete="false"
            /> */}
              {isRemarkBlank && (
                <div style={{ textAlign: "left" }}>
                  <span className="badge bg-danger">
                    &#9888; This field is required.
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      </ModalConfirmAlertCustom>

      <ModalConfirmAlertCustom
        confirmAlertConfig={payCnModalConfig}
        hideConfirmAlert={handleCnPayMoalClose}
        confirmAlert={payBillNow}
        cancelBtnName={"Cancel"}
        confirmBtnName={"PayNow"}
      >
        <>
          <div className="row">
            <div className="col-md-6  text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label fs-5"
              >
                Amount :
              </label>
            </div>
            <div className="col-md-6 mb-2 text-start">
              <label className="form-label fs-4">
                {totalaBillAmount.toFixed(2)} RM
              </label>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-6 text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label fs-5"
              >
                Credit Note :
              </label>
            </div>
            <div className="col-md-6 mb-2 text-start">
              <label className="form-label fs-4">
                {avlCreditLimit.toFixed(2)} RM
              </label>
            </div>
          </div>
          <div className="row ">
            <div className="col-md-6 text-start "></div>
            <div className="col-md-6 mb-2 text-start ">
              <input
                className="form-check-input p-0 me-2 "
                type="checkbox"
                // style={{background: "aliceblue"}}
                onChange={(e) => payByCreditVal(e.target.checked)}
                // checked={(e)=>payByCreditVal(e)}
                disabled={+avlCreditLimit < 1}
              />
              Apply Credit Note
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-md-6 text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label fs-5"
              >
                Final Amount :
              </label>
            </div>
            <div className="col-md-6 mb-2 text-start ">
              <label className="form-label fs-4">
                {(+finalAmount).toFixed(2)} RM
              </label>
            </div>
          </div> */}
        </>
      </ModalConfirmAlertCustom>
      <ModalConfirmAlertCustom
        confirmAlertConfig={bankSlipModalConfig}
        hideConfirmAlert={handleBsPayMoalClose}
        confirmAlert={uploadBankSlip}
        cancelBtnName={"Cancel"}
        confirmBtnName={"Upload"}
        modalStyle={{ titelStyle: "theme-label fs-3 " }}
      >
        <>
          <div className="row">
            <div className="col-md-3  text-start">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Bank Slip<span className="text-danger">&#9733;</span>
              </label>
            </div>
            <div className="col mb-2 text-start">
              <div className="Upload-Photo w-100 mb-2">
                <input
                  type="file"
                  accept="application/pdf, image/jpeg, image/jpg"
                  id="vhclImgR"
                  onChange={(e) => selectFile(e)}
                />
                <div className="uploaded-text ms-2">
                  {GetSvgUpload({ required: false })}
                  {bsFileName}
                </div>
              </div>
              {isFileBlank && (
                <div style={{ textAlign: "left" }}>
                  <span className="badge bg-danger">
                    &#9888; This field is required.
                  </span>
                </div>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-md-3">
              <label
                htmlFor="floatingInputGrid"
                className="form-label theme-label"
              >
                Remark <span className="text-danger">&#9733;</span>
              </label>
            </div>
            <div className="col-md-9 mb-2">
              <textarea
                className="form-control"
                id="bs-remark"
                rows={4}
                onChange={(e) => handelChangeData(e.target.value)}
              ></textarea>

              {isRemarkBlank && (
                <div style={{ textAlign: "left" }}>
                  <span className="badge bg-danger">
                    &#9888; This field is required.
                  </span>
                </div>
              )}
            </div>
          </div>
        </>
      </ModalConfirmAlertCustom>
    </>
  );
};

export default PartnerInvoiceMain;
