import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import GetSvgIcon from "../../../../images/svglist";
import { CallApiGet, CallApiPost } from "../../../Helper/serviceApi";
import Header from "../../../Layout/Header";
import Popup from "../../../Layout/Popup";
import ListPartnerStaff from "./ListPartnerStaff";
import { getUserInfo } from "../../../AuthUtils/AuthUtils";
import DriverModal from "./DriverModal";
import Spinner from "../../../Layout/Spinner";
import { LINEHAUL_PARTNER_COMPANY_USER_TYPE } from "../../../../ConfigConstants/ConfigConstants";
import CustomAlert from "../../../Layout/CustomAlert/CustomAlert";
import { Tooltip } from "react-tooltip";
import { useSearchParams } from "react-router-dom";

const initPhotoFile = {
  photo: "",
  photoFileType: "",
  photoBucketUrl: "",
};

const initDriver = {
  userId: 0,
  name: "",
  phone: "",
  email: "",
  profilePhoto: initPhotoFile,
  driverLicenseExpiryDate: null,
  driverLicenseImage: initPhotoFile,
  driverGDLExpiryDate: null,
  driverGDLImage: initPhotoFile,
  driverNRICImage: initPhotoFile,
  partnerTypeId: 137, // Partner_Personal
  partnerType: "Partner_Personal",
  phoneCode: "+60",
};

function PartnerStaffMain() {
  const [hideColums, setHideColums] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertBody, setAlertBody] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [showParent, setShowParent] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [title, setTitle] = useState();
  const modalRef = useRef();
  const [staffDriver, setStaffDriver] = useState([]);
  const [driverDetails, setDriverDetails] = useState(initDriver);
  const [driverUserId, setDriverUserId] = useState(0);
  const [activeFlag, setActiveFlag] = useState(false);
  const [searchParams] = useSearchParams();
  const isPartner =
    getUserInfo("userType") === LINEHAUL_PARTNER_COMPANY_USER_TYPE;
  const countryCodes = [
    { label: "+60", value: "+60" },
    { label: "+62", value: "+62" },
    { label: "+65", value: "+65" },
    { label: "+66", value: "+66" },
    { label: "+84", value: "+84" },
    { label: "+86", value: "+86" },
  ];
  useEffect(() => {
    setHideColums(isPartner ? true : false);
    callAllService();
  }, []);

  const callAllService = () => {
    setShowParent(true);
    fetchPartnerDriverList();
    setShowParent(false);
  };

  const fetchPartnerDriverList = async () => {
    let data = await CallApiPost("getPartnerDriverList", {});
    if (data.respCode === 401) {
      setStaffDriver([]);
    } else {
      setStaffDriver(data.respData);
      openDrModal(data.respData);
    }
  };

  const partColumns = [
    {
      name: "Driver Partner",
      selector: (row) => row.parent.companyName,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Partner Account Code",
      selector: (row) => row.parent.accountCode,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Partner Email",
      selector: (row) => row.parent.email,
      sortable: true,
      filterable: true,
      omit: hideColums,
    },
    {
      name: "Staff Account Code",
      selector: (row) => row.accountCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Staff Name",
      selector: (row) => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Staff Email",
      selector: (row) => row.email,
      sortable: true,
      filterable: true,
    },
    {
      name: "Staff Phone",
      selector: (row) => row.phone,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => row.approvalStatus,
      conditionalCellStyles: [
        {
          when: (row) => row.active === true,
          style: {
            color: "green",
          },
        },
        {
          when: (row) => row.active === false,
          style: {
            color: "red",
          },
        },
      ],
      sortable: true,
      filterable: true,
    },
    {
      cell: (row) => (
        <div
          className="me-3"
          data-bs-toggle="modal"
          data-bs-target="#add-modal-driver"
          onClick={() => setDriverDetailsData(row)}
        >
          {GetSvgIcon("EditPencil")}
        </div>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
    {
      name: "",
      cell: (row) =>
        (row.active === true || row.approvalStatus === "INACTIVE") && (
          <>
            <div
              data-tooltip-id={"part-user-" + row.userId}
              onClick={() => {
                setDriverUserId(row.userId);
                handleActiveDeactive(row.active, row.accountCode);
                toggleAlert(true);
              }}
            >
              {row.active === true
                ? GetSvgIcon("BixCircle")
                : GetSvgIcon("BiCheck2Circle")}
            </div>
            <Tooltip
              id={"part-user-" + row.userId}
              content={
                row.active === true
                  ? "Deactivate " + row.accountCode
                  : "Activate " + row.accountCode
              }
              place="left"
            />
          </>
        ),
      allowOverflow: true,
      button: true,
      width: "56px",
      omit: !hideColums,
    },
  ];

  const setDriverDetailsData = (data) => {
    let driverData = {
      ...data,
      partnerTypeId: 137,
      partnerType: "Partner_Personal",
    }; // 137: Partner_Personal
    if (driverData.phone) {
      let phone = driverData.phone;
      driverData.phoneCode = phone.substr(0, 3);
      driverData.phone = phone.substr(3);
    }
    setDriverDetails(driverData);
  };

  const onSuccess = (msg) => {
    setIsSuccess(true);
    setTitle("Success");
    setDescription(msg);
    modalRef.current.click();
  };
  const onFailure = (msg) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(msg);
    modalRef.current.click();
  };
  const onError = (error) => {
    setIsSuccess(false);
    setTitle("Error");
    setDescription(error || "Something went wrong!!!");
    modalRef.current.click();
  };

  const openPopup = (boolData, popupTitle, popupDesc) => {
    setIsSuccess(boolData);
    setTitle(popupTitle);
    setDescription(popupDesc);
    modalRef.current.click();
  };

  const toggleAlert = (boolData) => {
    setShowAlert(boolData);
  };

  const callback = () => {
    fetchPartnerDriverList();
  };

  const handleActiveDeactive = (active, accountCode) => {
    setActiveFlag(!active);
    if (active) {
      setAlertBody(
        <>
          Are you sure, you want to deactivate driver <b>{accountCode}</b>
        </>
      );
      setAlertTitle("Deactivate Driver");
    } else {
      setAlertBody(
        <>
          Are you sure, you want to activate driver <b>{accountCode}</b>
        </>
      );
      setAlertTitle("Activate Driver");
    }
  };

  const partUserActivation = async () => {
    const uri = "part-user-activation/" + driverUserId + "/" + activeFlag;
    let resp = await CallApiGet(uri);
    setDriverUserId(0);
    setActiveFlag(false);
    setShowAlert(false);
    switch (resp.respCode) {
      case 200:
      case 0:
        openPopup(true, "Success", "Driver status updated.");
        break;
      case 409:
        openPopup(false, "Error!", resp.respMessage);
        break;
      default:
        onError();
        break;
    }
  };

  const openDrModal = (listData) => {
    const driverId = +searchParams.get("driverId");
    if (!!driverId) {
      let data = listData.filter((a) => a.partnerId === driverId);
      setDriverDetailsData(data[0]);
      document.getElementById("drM").click();
    }
  };
  return (
    <div>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Partner Staff</div>
              </div>
              <div className="float-end d-flex">
                <div className="float-start me-2">
                  {isPartner && (
                    <button
                      className="btn btn-outline-secondary theme-violet text-nowrap"
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#add-modal-driver"
                      onClick={() => setDriverDetailsData(initDriver)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-plus-circle-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z" />
                      </svg>{" "}
                      Add Driver
                    </button>
                  )}
                </div>
                <div className="float-end">
                  <button
                    data-bs-toggle="modal"
                    data-bs-target="#success-modal"
                    id="modalPopup"
                    className="d-none"
                    ref={modalRef}
                  />
                </div>
              </div>
            </div>
            <ListPartnerStaff
              staffUserList={staffDriver}
              columns={partColumns}
            />
            <DriverModal
              driverDetails={driverDetails}
              countryCodes={countryCodes}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onError={onError}
              fetchPartnerStaff={fetchPartnerDriverList}
              isPartner={isPartner}
            />
          </main>
        </div>
      </div>
      <Popup
        isSuccess={isSuccess}
        title={title}
        description={description}
        handler={callback}
      />
      <Spinner show={showParent} />
      {showAlert && (
        <CustomAlert
          toggleReset={toggleAlert}
          alertTitle={alertTitle}
          alertBody={alertBody}
          callback={partUserActivation}
        />
      )}
      <div
        id="drM"
        className="me-3 d-non"
        data-bs-toggle="modal"
        data-bs-target="#add-modal-driver"
      ></div>
    </div>
  );
}

export default PartnerStaffMain;
