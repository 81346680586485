import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import GetSvgIcon from "../../../images/svglist";
import { getUserInfo } from "../../AuthUtils/AuthUtils";
import { DATE_FORMAT_MOMENT } from "../../Helper/Constants";
import { CallApiPost } from "../../Helper/serviceApi";
import Footer from "../../Layout/Footer";
import Header from "../../Layout/Header";
import Spinner from "../../Layout/Spinner";
import PartnerApprovalList from "./PartnerApprovalList";
import { LINEHAUL_PARTNER_TYPE_MAP } from "../../../ConfigConstants/ConfigConstants";
import { Tooltip } from "react-tooltip";
export default function PartnerApprovalMain() {
  const [partnerRows, setPartnerRows] = useState([]);
  const [show, setShow] = useState(false);
  useEffect(() => {
    getPartnerList();
  }, []);
  const userRoles = JSON.parse(getUserInfo("userRoles"));
  const payload = {
    ApproverId: getUserInfo("userId"),
    ApproverRoleId: userRoles[0].roleId,
    ApproverType: getUserInfo("userType"),
  };
  const getPartnerList = async () => {
    setShow(true);
    let rspData = await CallApiPost(
      "pending-partner-list-for-approver",
      payload
    );

    setShow(false);
    if (rspData.respCode === 200) {
      let data = rspData.respData;
      if (!!data && data.length > 0) {
        setPartnerRows(data);
      } else {
        setPartnerRows([]);
      }
    } else {
      setPartnerRows([]);
    }
  };
  const columns = [
    {
      name: "Account Code",
      selector: (row) => row.accountCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Partner Name",
      // selector: (row) => LINEHAUL_PARTNER_TYPE_MAP[row.partnerType],
      selector: (row) => row.partnerName,
      sortable: true,
      filterable: true,
    },
    {
      name: "Parent Account Code",
      selector: (row) => row.parentAcctCode,
      sortable: true,
      filterable: true,
    },
    {
      name: "Registration Date",
      selector: (row) =>
        moment(row.registrationDate).format(DATE_FORMAT_MOMENT),
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: (row) => row.partnerEmail,
      sortable: true,
      filterable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.partnerPhone,
      sortable: true,
      filterable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status === "REJECT" ? "REJECTED" : row.status),
      sortable: true,
      filterable: true,
    },
    {
      name: "Assign To",
      selector: (row) => row.assignTo,
      sortable: true,
      filterable: true,
    },
    {
      name: "",
      cell: (row) => (
        <>
          {row.parentAcctCode &&
          row.parentAcctCode !== null &&
          row.parentAcctCode !== undefined &&
          row.parentAcctCode !== "" &&
          row.parentAcctCode !== "NA" ? (
            <Link
              to="/partnerStaffDetails"
              state={{
                profileId: row.partnerProfileId,
                userType: row.partnerType,
                parentAcctCode: row.parentAcctCode,
              }}
              data-tooltip-id={"details-" + row.partnerProfileId}
            >
              {GetSvgIcon("EditPencil")}
            </Link>
          ) : (
            <Link
              to="/partnerDetails"
              state={{
                profileId: row.partnerProfileId,
                userType: row.partnerType,
                parentAcctCode: row.parentAcctCode,
              }}
              data-tooltip-id={"details-" + row.partnerProfileId}
            >
              {GetSvgIcon("EditPencil")}
            </Link>
          )}
          <Tooltip
            id={"details-" + row.partnerProfileId}
            content={"Edit"}
            place="left"
          />
        </>
      ),
      allowOverflow: true,
      button: true,
      width: "56px",
    },
  ];
  return (
    <>
      <Header />
      <div className="container-fluid min-ht-100">
        <div className="row">
          <main className="col-md-12 p-3">
            <div className="clearfix border-bottom pb-2">
              <div className="float-start">
                <div className="section-title mt-1">Partner Approval</div>
              </div>
            </div>
            <PartnerApprovalList
              columns={columns}
              partnerRows={partnerRows}
              setPartnerRows={setPartnerRows}
            />
          </main>
        </div>
      </div>
      <Spinner show={show} />
      <Footer />
    </>
  );
}
