import React from "react";
import DataTable from "react-data-table-component-with-filter";
import {
  customDataTableStyles,
  DATE_FORMAT_MOMENT,
  defaultPageLimit,
  defaultRowsPerPageOptions,
} from "../Helper/Constants";
import { getUserInfo } from "../AuthUtils/AuthUtils";
import {
  customDateSort,
  getOrderStatusBadgeClass2,
  openOrderDtls,
  permissionCheck,
} from "../Helper/CommonMethods";
import { ORDDTLS } from "../../ConfigConstants/ConfigConstants";
import moment from "moment";

const OrderInfoList = (props) => {
  const { tableData, getFullAddress, column } = props;

  return (
    <div className="col-md-12 customized-data-table position-relative">
      {
        <DataTable
          columns={column}
          data={tableData}
          noDataComponent="There are no order info found!!"
          pagination
          striped
          highlightOnHover
          customStyles={customDataTableStyles}
          paginationRowsPerPageOptions={defaultRowsPerPageOptions}
          paginationPerPage={defaultPageLimit}
        />
      }
    </div>
  );
};

export default OrderInfoList;
